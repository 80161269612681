// eslint-disable-next-line
import cartItem from "../cartItem";
import { ADD_TO_CART, GET_CARTITEMS, REMOVE_CART, GET_NUMBER } from "./Types";


export const DECREASE = "DECREASE";
export const INCREASE = "INCREASE";
export const REMOVE = "REMOVE";
export const CLEAR_CART = "CLEAR_CART";
export const GET_TOTALS = "GET_TOTALS";
export const TOGGLE_QTY = "TOGGLE_QTY";
export let items = []
export let itemsCart = []
export const removeItem = (id) => {
  //e.preventDefault();
  //window.location.reload()
  return { type: REMOVE, payload: { id } };
};

export const addToCart = (product) => {
  let cartItems = JSON.parse(localStorage.getItem('cartItems'));

  let alreadyExists = false;
  // postItem(product)
  if (cartItems) {
    //cartItems = [...cartItems, product];
    cartItems.forEach((x) => {
      if (x?.id === product?.id) {
        alreadyExists = true;
        x.count++;
      }
    });
    if (!alreadyExists) {

      cartItems.push({
        ...product,
        count: 1,
      });
    }
  } else {
    cartItems = [];
    // postItem({ ...product, count: 1 })

    cartItems.push({ ...product, count: 1 });
  }
  localStorage.setItem('cartItems', JSON.stringify(cartItems));
  //postCart()
  // eslint-disable-next-line
  let { total, count } = cartItems?.reduce(
    (cartTotal, cartItem) => {
      const { price, count } = cartItem;
      const itemTotal = price * count;

      cartTotal.total += itemTotal;
      cartTotal.count += count;

      return cartTotal;
    },
    {
      total: 0,
      count: 0
    }
  );
  total = parseFloat(total.toFixed(2));
  return {
    type: ADD_TO_CART,
    payload: cartItems,
    total: total
  }
}

export const removeCart = () => {
  // eslint-disable-next-line
  const oldCartItems = JSON.parse(localStorage.getItem('cartItems'));
  
  localStorage.removeItem('cartItems');
  
  return {
    type: REMOVE_CART,
    payload: []
  }
}


export const getCartItems = () => {

  const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
  
  let { total,
    // count 
  } = cartItems?.reduce(
    (cartTotal, cartItem) => {
      const { price, count } = cartItem;
      const itemTotal = price * count;

      cartTotal.total += itemTotal;
      cartTotal.count += count;

      return cartTotal;
    },
    {
      total: 0,
      count: 0
    }
  );
  total = parseFloat(total.toFixed(2));
  //return { ...state, total, count };
  return {
    type: GET_CARTITEMS,
    payload: cartItems,
    total: total,
    number: cartItems
  }
}

export const getNumber = () => {
  const cartItems = JSON.parse(localStorage.getItem('cartItems'));
  let number;
  if (cartItems) {
    number = cartItems.length;
  } else {
    number = 0;
  }
  return {
    type: GET_NUMBER,
    payload: number,
    products: cartItems || []
  }
}