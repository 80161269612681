import React, { useEffect, useState } from "react";
import {
  Pagination as ReactPagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
const PER_PAGE = 24;


function PageItem({ page, onClick }) {
  return (
    /* @ts-ignore */
    <PaginationItem active={page?.active} onClick={() => onClick(page?.page)}>
      {/* @ts-ignore */}
      <PaginationLink first href="#" previous={page?.previous} next={page.next}>
        {page?.page}
      </PaginationLink>
    </PaginationItem>
  );
}

const Pagination = ({ page, onPageChange, total, perPage = PER_PAGE }) => {
  const [pages, setPages] = useState([]);
  useEffect(() => {
    const numberOfPages = Math.ceil((total || 0) / perPage);
    const pages = [];
    for (let i = 1; i <= numberOfPages; i++) {
      const newPage = {
        active: page === i,
        page: i,
      };
      pages.push(newPage);
    }
    // console.log("p", pages, PER_PAGE, perPage);
    setPages(pages);
  }, [total, page, perPage]);
  if (pages?.length < 1) return null;
  return (
    /* @ts-ignore */
    <ReactPagination className="pagination-custom">
      {pages?.map((p) => (
        <PageItem page={p} onClick={onPageChange} />
      ))}
    </ReactPagination>
  );
};

export default Pagination;
