import React, { useState, useEffect } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import * as IconlyPack from "react-iconly";
import HeaderDashboard from "../../adminDashboard/headerDashboard/headerDashboard";
import NavVerticalDashboardAdmin from "../../adminDashboard/navVerticalDashboardAdmin";
import ContentProductDetails from "../../contentProductDetails/contentProductDetails";

function DetailsProductAdmin() {
  const location = useLocation();
  const history = useHistory();
  const [product, setProduct] = useState({});
  useEffect(() => {
    window.scrollTo(0, 0);
    if (location?.state?.product?.id) {
      setProduct(location.state.product);
    } else {
      setTimeout(() => {
        history.goBack();
      }, 100);
    }

    return () => {
      setProduct({});
    };
  }, [location]);

  return (
    <div className="component-articles-dashboard-admin">
      <HeaderDashboard />
      <div className="fixed-after-header-dashboard container-fluid">
        <div className="row">
          <div className="col-xl-2 col-lg-3 no-view-tablette">
            <NavVerticalDashboardAdmin />
          </div>
          <div className="col-xl-10 col-lg-9 px-xl-3 py-4 pr-lg-3 pl-lg-1">
            <div className="card-dashboard-content-table">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">
                      <NavLink
                        to={"/articles-admin"}
                        className="action-btn btn edit-btn"
                        title="Retour"
                      >
                        <IconlyPack.ArrowLeft
                          set="bold"
                          primaryColor="#000"
                          className="action-btn-icon action-edit-icon"
                          // onClick={() => onDesarchive(row?.id)}
                        />
                      </NavLink>
                      Détails Article
                    </h1>
                  </div>
                </div>
                <div className="parent-table-container">
                  {/* <GestionArticlesTable /> */}
                  <ContentProductDetails
                    productId={product?.id}
                    productItem={product}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsProductAdmin;
