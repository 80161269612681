import React, { Component } from "react";
import "./infosShopCard.css";
import MandatoryLoginSubscribe from "../mandatoryLoginSubscribe/index";
import FeatherIcon from "feather-icons-react";
import InfosShopCardSkeleton from "../skeleton/infosShopCardSkeleton/infosShopCardSkeleton";

export default class infosShopCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {shopDetails,loading} = this.props;

    if (loading) {
      return <InfosShopCardSkeleton />;
    } else {
      return (
        <div className="info-boutique-card dis-flex flex-col h-full">
          <div className="head-boutique-card">
            <div className="row">
              <div className="col-sm-9 col-10 itm-center">
                <h5 className="h-info-boutique-card text-up m-sm-0 my-2">
                  {shopDetails.shop_name}
                </h5>
              </div>
              <div className="col-sm-3 col-2 pl-md-0 pr-md-3 px-0">
                {this.props.is_user_connected === true ? (
                  <div>
                    <form className="pos-relative">
                      {this.props.userFollow.length ? (
                        <div>
                          <button
                            onClick={this.props.onFollowShop}
                            className="btn-follow-info-boutique-card-web-light fs-10"
                          >
                            Ne plus suivre
                          </button>
                          <button
                            onClick={this.props.onFollowShop}
                            className="btn-follow-info-boutique-card-mobile-light"
                          >
                            <FeatherIcon
                              className="icon-btn-follow-info-boutique-card-mobile"
                              icon="rss"
                            />
                          </button>
                        </div>
                      ) : (
                        <div>
                          <button
                            onClick={this.props.onFollowShop}
                            className="btn-follow-info-boutique-card-web fs-10"
                          >
                            Suivre
                          </button>
                          <button
                            onClick={this.props.onFollowShop}
                            className="btn-follow-info-boutique-card-mobile"
                          >
                            <FeatherIcon
                              className="icon-btn-follow-info-boutique-card-mobile"
                              icon="rss"
                            />
                          </button>
                        </div>
                      )}
                    </form>
                  </div>
                ) : (
                  <MandatoryLoginSubscribe />
                )}
              </div>
            </div>
          </div>
          <div className="body-boutique-card-infos">
            <div className="row">
              <div className="col-md-3 col-sm-3 col-5 mb-3">
                <p className="titre-info-boutique-card">abonnés</p>
                <p className="content-info-boutique-card">
                  {shopDetails.number_of_subscribers}
                </p>
              </div>
              <div className="col-md-6 col-sm-6 col-7 mb-3">
                <p className="titre-info-boutique-card">Vendeur</p>

                <p className="content-info-boutique-card">
                  {shopDetails?.user?.first_name +
                    " " +
                    shopDetails?.user?.last_name}
                </p>
              </div>
              {/* <div className="col-md-4 col-sm-5 col-7 mb-3">
                <p className="titre-info-boutique-card">Email</p>
                <p className="content-info-boutique-card">
                  {shopDetails?.user?.email}
                </p>
              </div>
              <div className="col-md-4 col-sm-4 col-6 mb-3">
                <p className="titre-info-boutique-card">Téléphone</p>
                <p className="content-info-boutique-card">
                  {shopDetails?.user?.phone || "Non disponible"}
                </p>
              </div> */}
            </div>
          </div>
        </div>
      );
    }
  }
}
