import React from "react";
import HeaderDashboard from "../headerDashboard/index";
import NavVerticalDashboardAdmin from "../navVerticalDashboardAdmin/index";
import UtilisateursTable from "./UtilisateursTable";

function UtilisateursDashboard() {
  return (
    <div className="component-articles-dashboard-admin">
      <HeaderDashboard />
      <div className="fixed-after-header-dashboard container-fluid">
        <div className="row">
          <div className="col-xl-2 col-lg-3 no-view-tablette">
            <NavVerticalDashboardAdmin />
          </div>
          <div className="col-xl-10 col-lg-9 px-xl-3 py-4 pr-lg-3 pl-lg-1">
            <div className="card-dashboard-content-table">
              <div className="head-card-dashboard-content-table">
                <div className="row mb-5">
                  <div className="col-md-9 col-sm-8 mb-sm-0 mb-3">
                    <p className="title-filter-dashboard">
                      Gestion des utilisateurs
                    </p>
                  </div>
                </div>
              </div>
              <div className="content-card-dashboard-content-table">
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="nav-tout"
                    role="tabpanel"
                    aria-labelledby="nav-tout-tab"
                  >
                    <UtilisateursTable />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UtilisateursDashboard;
