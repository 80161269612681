import React, { Component } from "react";
import "./modalConfirmationAddingOnCart.css";
import { Modal } from "rsuite";
import ConfirmationIcon from "../../img/icons/check-icon.png";
import { NavLink } from "react-router-dom";

export default class modalConfirmationAddingOnCart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="modal-container">
        <Modal
          size="xs"
          show={this.props.showConfirmation}
          onHide={this.props.closeConformation}
          className="rsuite-content-modal-custom"
        >
          <div>
            <Modal.Header>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <div className="my-5 flex-col itm-center">
              <img loading="lazy"
                src={ConfirmationIcon}
                alt=""
                className="icon-confirmation-modal mb-4"
              />
              <p className="title-confirmation-modal my-4">
                Le produit a bien été ajouté au panier
              </p>
            </div>
            <Modal.Footer className="text-center">
              <div className="row px-5">
                <div className="col-12 mb-2">
                  <button
                    onClick={this.props.closeConformation}
                    className="btn-change-password"
                  >
                    Continuer mes achats
                  </button>
                </div>
                <div className="col-12 mb-3">
                  <NavLink to="/checkout" className="btn-light-theme">
                    Valider ma commande
                  </NavLink>
                </div>
              </div>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    );
  }
}
