import React, { Suspense, useEffect, useMemo } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
  useLocation,
} from "react-router-dom";
// import Accueil from "./components/accueil/index";
import Connexion from "./components/connexion/index";
import Inscription from "./components/inscription/index";
import AllProductPage from "./components/allProductPage/index";
import Account from "./components/account/index";
import ProductDetails from "./components/productDetails/index";
import Checkout from "./components/checkout/index";
import Boutique from "./components/boutique/index";
import DetailsEvalutionBoutique from "./components/detailsEvaluationBoutique/index";
import InscriptionVendeur from "./components/inscriptionVendeur/index";
import AllShopPage from "./components/allShopPage/index";
import WishlistView from "./components/wishlistView/index";
import ProductByCategoryView from "./components/productByCategoryView/index";
import DashboardSeller from "./components/sellerDashboard/dashboardSeller/index";
import isAuthenticate from "./modules/secure/Secure";
import { useDispatch, useSelector } from "react-redux";
import CommandesDashboard from "./components/sellerDashboard/commandesDashboard";
import ParametresDashboard from "./components/sellerDashboard/parametresDashboard/index";
import HelpDashboard from "./components/sellerDashboard/helpDashboard/index";
import ArticlesDashboard from "./components/sellerDashboard/articlesDashboard/index";
import AddProductSteps from "./components/sellerDashboard/addProductSteps/index";
import DashboardAdmin from "./components/adminDashboard/dashboardAdmin/index";
import ShopDashboard from "./components/adminDashboard/shopDashboard/index";
import ParametresDashboardAdmin from "./components/adminDashboard/parametresDashboardAdmin/index";
import Error404 from "./components/error404/index";
import Transactions from "./components/adminDashboard/transactionDashboard/index";

import withClearCache from "./ClearCache";
import ArticlesDashboardAdmin from "./components/adminDashboard/articlesDashboardAdmin/index";
import CommandesDashboardAdmin from "./components/adminDashboard/commandesDashboardAdmin/index";
import UpdateProduct from "./components/sellerDashboard/updateProduct/index";
import CategoriesDashboardAdmin from "./components/adminDashboard/categoriesDashboardAdmin/index";
import { ToastContainer } from "react-toastify";
import DeliveryDashboardAdmin from "./components/adminDashboard/DeliveryDashboardAdmin/DeliveryDashboardAdmin";
import GestionAdmins from "./components/adminDashboard/administrateurs/GestionAdmins";
import ModifierMotDePasseUser from "./components/auth/ModierMotDePasse";
import MotDePassOublier from "./components/auth/MotDePassOublier";
import UtilisateursDashboard from "./components/adminDashboard/utilisateursDashboard/UtilisateursDashboard";
import CommandeDetails from "./components/sellerDashboard/commandesDashboard/CommandeDetails";
import Articledetails from "./components/sellerDashboard/tableArticles/Articledetails";
import DetailsProductAdmin from "./components/adminDashboard/tableArticles/DetailsProductAdmin";
import EditProductAdmin from "./components/adminDashboard/tableArticles/EditProductAdmin";
import DetailsCommande from "./components/adminDashboard/tableCommande/DetailsCommande";
import DetailsShopAdmin from "./components/adminDashboard/tableShop/DetailsShopAdmin";
import MessageContact from "./components/adminDashboard/MessageContact/MessageContact";
import GestionDesConditions from "./components/adminDashboard/GestionDesConditions/GestionDesConditions";
import ConditionGenerale from "./components/conditions/ConditionGenerale";
import Politique from "./components/conditions/Politique";
import Apropos from "./components/apropos/Apropos";
// import { fetchProducts } from "./productAction/productAction";
import PhoneConfirmation from "./components/phoneConfirmation/phoneConfirmation";
import { useMeQuery } from "./utils/api/user.api";
import { onSetUser, onSetUserToken } from "./utils/api/slice/user.slice";
import { fetchCategories } from "./categoryRedux/categoriesAction";
import LoaderSuspense from "./LoaderSuspense";
import { isAdmin, isSeller, isUser } from "./utils/utils";
const ClearCacheComponent = withClearCache(MainApp);

export const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<LoaderSuspense />}>
      <Component {...props} />
    </Suspense>
  );

const Accueil = Loader(React.lazy(() => import("./components/accueil/index")));

function App() {
  return <MainApp />;
}

function FetchUser() {
  var userToken = window.localStorage.getItem("userToken");
  const { user, token } = useSelector((s) => s?.user);
  const { isSuccess, data } = useMeQuery();
  const dispatch = useDispatch();
  useEffect(() => {
    if (isSuccess) {
      if (data?.id === user?.id || !user?.id) {
        window.localStorage.setItem("userID", data?.id);
        window.localStorage.setItem("userType", data?.user_type);
        if (token) {
          dispatch(onSetUser(data));
        } else {
          dispatch(onSetUserToken({ user: data, token: userToken }));
        }
      }
    }
  }, [isSuccess, data]);

  return null;
}

// UserRoutes
const IsUserAllow = ({ children }) => {
  const { user, token } = useSelector((state) => state.user);
  // const [params] = useSearchParams();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  let next = searchParams.get("next");
  let path = searchParams.get("next") || "/connexion";
  if (token) {
    if (isAdmin(user)) {
      path = "/dashboard-admin";
    }

    if (isSeller(user)) {
      path = "/dashboard-vendeur";
    }
  }

  // console.log("path",path,!token)

  // return children;
  return !token || !isUser(user) || next ? (
    <Redirect to={path} replace />
  ) : (
    children
  );
};

const IsAdminAllow = ({ children }) => {
  const { user, token } = useSelector((state) => state.user);
  let path = "/connexion";
  if (token) {
    if (isUser(user)) {
      path = "/mon-compte";
    }

    if (isSeller(user)) {
      path = "/dashboard-vendeur";
    }
  }

  // return children;
  return !token || !isAdmin(user) ? <Redirect to={path} replace /> : children;
};

const IsSellerAllow = ({ children }) => {
  const { user, token } = useSelector((state) => state.user);
  let path = "/connexion";
  if (token) {
    if (isUser(user)) {
      path = "/mon-compte";
    }

    if (isAdmin(user)) {
      path = "/dashboard-admin";
    }
  }

  // return children;
  return !token || !isSeller(user) ? <Redirect to={path} replace /> : children;
};

export const RedirectAuthRoute = ({ children }) => {
  const { token, user } = useSelector((state) => state.user);
  let path = "/";

  if (token) {
    if (isUser(user)) {
      path = "/mon-compte";
    }

    if (isAdmin(user)) {
      path = "/dashboard-admin";
    }

    if (isSeller(user)) {
      path = "/dashboard-vendeur";
    }
  }

  return !token && !user?.id ? children : <Redirect to={path} replace />;
};

export const IsAuthAllow = ({ children }) => {
  const { token, user } = useSelector((state) => state.user);
  let path = "/connexion?next=/checkout";

  return token && user?.id ? children : <Redirect to={path} replace />;
};

function MainApp() {
  const dispatch = useDispatch();
  // console.log("Build date:", getBuildDate(packageJson.buildDate));
  // var isLogInExpired = isAuthenticate();
  // var userType = window.localStorage.getItem("userType");
  var token = window.localStorage.getItem("userToken");
  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);
  return (
    <Router>
      <div>
        {!!token && <FetchUser />}
        <ToastContainer />
        <Switch>
          <Route exact path="/" component={Accueil} />
          <Route exact path="/connexion">
            <RedirectAuthRoute>
              {" "}
              <Connexion />
            </RedirectAuthRoute>
          </Route>
          <Route exact path="/reset-password">
            <RedirectAuthRoute>
              <MotDePassOublier />{" "}
            </RedirectAuthRoute>
          </Route>
          <Route exact path="/confirm-phone" component={PhoneConfirmation} />
          <Route
            exact
            path="/change-password"
            component={ModifierMotDePasseUser}
          />
          <Route exact path="/inscription">
            {/* {token ? <Redirect to={`/`} /> : <Inscription />} */}
            <RedirectAuthRoute>
              <Inscription />{" "}
            </RedirectAuthRoute>
          </Route>
          <Route exact path="/tous-les-produits" component={AllProductPage} />
          <Route exact path="/code-oublie" component={MotDePassOublier} />
          <Route
            exact
            path="/modifier-code"
            component={ModifierMotDePasseUser}
          />
          <Route
            exact
            path="/categorie/:id"
            component={ProductByCategoryView}
          />
          <Route path="/mon-compte">
            {/* {!token ? (
              <Redirect to={`/connexion/?next=${"/mon-compte"}`} />
            ) : (
              <Account />
            )} */}
            <IsUserAllow>
              <Account />
            </IsUserAllow>
          </Route>
          <Route path="/mon-compte/:status">
            {/* {!token ? (
              <Redirect to={`/connexion/?next=${"/mon-compte"}`} />
            ) : (
              <Account />
            )} */}
            <IsUserAllow>
              <Account />
            </IsUserAllow>
          </Route>
          <Route exact path="/details-produit/:id" component={ProductDetails} />
          <Route path="/checkout">
            {/* {!token ? (
              <Redirect to={`/connexion/?next=${"/checkout"}`} />
            ) : (
              <Checkout />
            )} */}
            <IsAuthAllow>
              <Checkout />
            </IsAuthAllow>
          </Route>
          <Route exact path="/boutique/:id" component={Boutique} />
          <Route
            exact
            path="/details-evaluation-boutique/:id"
            component={DetailsEvalutionBoutique}
          />
          <Route path="/inscription-vendeur">
            {/* {!token ? (
              <Redirect to={`/connexion/?next=${"/inscription-vendeur"}`} />
            ) : (
              <InscriptionVendeur />
            )} */}
            <IsUserAllow>
              <InscriptionVendeur />
            </IsUserAllow>
          </Route>
          <Route exact path="/toutes-les-boutiques" component={AllShopPage} />
          <Route
            exact
            path="/condition-generale"
            component={ConditionGenerale}
          />
          <Route
            exact
            path="/politique-confidentialite"
            component={Politique}
          />
          <Route exact path="/a-propos" component={Apropos} />
          <Route path="/mes-favoris">
            {/* {!token ? (
              <Redirect to={`/connexion/?next=${"/mes-favoris"}`} />
            ) : (
              <WishlistView />
            )} */}
            <IsUserAllow>
              <WishlistView />
            </IsUserAllow>
          </Route>

          {/* Routing dashboard vendeur */}
          <Route path="/dashboard-vendeur">
            {/* {!token ? (
              <Redirect to={`/connexion/?next=${"/dashboard-vendeur"}`} />
            ) : userType === "vendeur" ? (
              <DashboardSeller />
            ) : (
              <Error404 />
            )} */}
            <IsSellerAllow>
              <DashboardSeller />
            </IsSellerAllow>
          </Route>
          <Route path="/articles">
            {/* {!token ? (
              <Redirect to={`/connexion/?next=${"/articles"}`} />
            ) : userType === "vendeur" ? (
              <ArticlesDashboard />
            ) : (
              <Error404 />
            )} */}
            <IsSellerAllow>
              <ArticlesDashboard />
            </IsSellerAllow>
          </Route>
          <Route
            exact
            path="/article/:id"
            // component={UpdateProduct}
          >
            <IsSellerAllow>
              <AddProductSteps />
            </IsSellerAllow>
          </Route>
          <Route
            path="/details-article/:id"
            exact
            // component={Articledetails}
            // render={() => {
            //   isLogInExpired ||
            //   isLogInExpired === undefined ||
            //   !token ||
            //   userType !== "vendeur" ? (
            //     <Redirect to={`/connexion`} />
            //   ) : (
            //     <Articledetails />
            //   );
            // }}
          >
            <IsSellerAllow>
              <Articledetails />
            </IsSellerAllow>
          </Route>
          <Route path="/commandes">
            {/* {!token ? (
              <Redirect to={`/connexion/?next=${"/commandes"}`} />
            ) : userType === "vendeur" ? (
              <CommandesDashboard />
            ) : (
              <Error404 />
            )} */}
            <IsSellerAllow>
              <CommandesDashboard />
            </IsSellerAllow>
          </Route>
          <Route path="/commande-details/:id">
            {/* {isLogInExpired ||
            isLogInExpired === undefined ||
            !token ||
            userType !== "vendeur" ? (
              <Redirect to={`/connexion`} />
            ) : (
              <CommandeDetails />
            )} */}
            <IsSellerAllow>
              <CommandeDetails />
            </IsSellerAllow>
          </Route>
          <Route path="/ajouter-un-produit">
            {/* {!token ? (
              <Redirect to={`/connexion/?next=${"/ajouter-un-produit"}`} />
            ) : userType === "vendeur" ? (
              <AddProductSteps />
            ) : (
              <Error404 />
            )} */}
            <IsSellerAllow>
              <AddProductSteps />
            </IsSellerAllow>
          </Route>
          <Route path="/parametres">
            {/* {!token ? (
              <Redirect to={`/connexion/?next=${"/parametres"}`} />
            ) : userType === "vendeur" ? (
              <ParametresDashboard />
            ) : (
              <Error404 />
            )} */}
            <IsSellerAllow>
              <ParametresDashboard />
            </IsSellerAllow>
          </Route>
          <Route path="/aide">
            {/* {!token ? (
              <Redirect to={`/connexion/?next=${"/aide"}`} />
            ) : userType === "vendeur" ? (
              <HelpDashboard />
            ) : (
              <Error404 />
            )} */}
            <IsSellerAllow>
              <HelpDashboard />
            </IsSellerAllow>
          </Route>

          {/* Routing dashboard */}
          <Route path="/dashboard-admin">
            {/* {!token ? (
              <Redirect to={`/connexion/?next=${"/dashboard-admin"}`} />
            ) : userType === "admin" ? (
              <DashboardAdmin />
            ) : (
              <Error404 />
            )} */}
            <IsAdminAllow>
              <DashboardAdmin />
            </IsAdminAllow>
          </Route>
          <Route path="/boutiques-admin">
            {/* {!token ? (
              <Redirect to={`/connexion/?next=${"/boutiques-admin"}`} />
            ) : userType === "admin" ? (
              <ShopDashboard />
            ) : (
              <Error404 />
            )} */}
            <IsAdminAllow>
              <ShopDashboard />
            </IsAdminAllow>
          </Route>
          <Route path="/articles-admin">
            {/* {!token ? (
              <Redirect to={`/connexion/?next=${"/articles-admin"}`} />
            ) : userType === "admin" ? (
              <ArticlesDashboardAdmin />
            ) : (
              <Error404 />
            )} */}
            <IsAdminAllow>
              <ArticlesDashboardAdmin />
            </IsAdminAllow>
          </Route>
          <Route path="/modifier-article-admin/:id">
            {/* {isLogInExpired ||
            isLogInExpired === undefined ||
            !token ||
            (userType && userType !== "admin") ? (
              <Redirect to={`/connexion`} />
            ) : (
              <EditProductAdmin />
            )} */}
            <IsAdminAllow>
              <EditProductAdmin />
            </IsAdminAllow>
          </Route>
          <Route path="/details-commande-admin/:id">
            {/* {isLogInExpired ||
            isLogInExpired === undefined ||
            !token ||
            (userType && userType !== "admin") ? (
              <Redirect to={`/connexion-admin`} />
            ) : (
              <DetailsCommande />
            )} */}
            <IsAdminAllow>
              <DetailsCommande />
            </IsAdminAllow>
          </Route>
          <Route path="/details-boutique-admin/:id">
            {/* {!token || (userType && userType !== "admin") ? (
              <Redirect to={`/connexion-admin`} />
            ) : (
              <DetailsShopAdmin />
            )} */}
            <IsAdminAllow>
              <DetailsShopAdmin />
            </IsAdminAllow>
          </Route>
          <Route path="/categories-admin">
            {/* {!token ? (
              <Redirect to={`/connexion/?next=${"/categories-admin"}`} />
            ) : userType === "admin" ? (
              <CategoriesDashboardAdmin />
            ) : (
              <Error404 />
            )} */}
            <IsAdminAllow>
              <CategoriesDashboardAdmin />
            </IsAdminAllow>
          </Route>
          <Route path="/commandes-admin">
            {/* {!token ? (
              <Redirect to={`/connexion/?next=${"/commandes-admin"}`} />
            ) : userType === "admin" ? (
              <CommandesDashboardAdmin />
            ) : (
              <Error404 />
            )} */}
            <IsAdminAllow>
              <CommandesDashboardAdmin />
            </IsAdminAllow>
          </Route>
          <Route path="/details-article-admin/:id">
            {/* {isLogInExpired ||
            isLogInExpired === undefined ||
            !token ||
            (userType && userType !== "admin") ? (
              <Redirect to={`/connexion`} />
            ) : (
              <DetailsProductAdmin />
            )} */}
            <IsAdminAllow>
              <DetailsProductAdmin />
            </IsAdminAllow>
          </Route>
          <Route path="/transactions">
            {/* {!token ? (
              <Redirect to={`/connexion/?next=${"/transactions"}`} />
            ) : userType === "admin" ? (
              <Transactions />
            ) : (
              <Error404 />
            )} */}
            <IsAdminAllow>
              <Transactions />
            </IsAdminAllow>
          </Route>
          <Route path="/mode-payment-delivery-admin">
            {/* {!token ? (
              <Redirect
                to={`/connexion/?next=${"/mode-payment-delivery-admin"}`}
              />
            ) : userType === "admin" ? (
              <DeliveryDashboardAdmin />
            ) : (
              <Error404 />
            )} */}
            <IsAdminAllow>
              <DeliveryDashboardAdmin />
            </IsAdminAllow>
          </Route>
          <Route path="/administrateurs">
            {/* {!token ? (
              <Redirect to={`/connexion/?next=${"/administrateurs"}`} />
            ) : userType === "admin" ? (
              <GestionAdmins />
            ) : (
              <Error404 />
            )} */}
            <IsAdminAllow>
              <GestionAdmins />
            </IsAdminAllow>
          </Route>
          <Route path="/utilisateurs">
            {/* {!token ? (
              <Redirect to={`/connexion/?next=${"/utilisateurs"}`} />
            ) : userType === "admin" ? (
              <UtilisateursDashboard />
            ) : (
              <Error404 />
            )} */}
            <IsAdminAllow>
              <UtilisateursDashboard />
            </IsAdminAllow>
          </Route>
          <Route path="/messages-contact">
            {/* {!token ? (
              <Redirect to={`/connexion/?next=${"/messages-contact"}`} />
            ) : userType === "admin" ? (
              <MessageContact />
            ) : (
              <Error404 />
            )} */}
            <IsAdminAllow>
              <MessageContact />
            </IsAdminAllow>
          </Route>
          <Route path="/gestion-des-conditions">
            {/* {!token ? (
              <Redirect to={`/connexion/?next=${"/gestion-des-conditions"}`} />
            ) : userType === "admin" ? (
              <GestionDesConditions />
            ) : (
              <Error404 />
            )} */}
            <IsAdminAllow>
              <GestionDesConditions />
            </IsAdminAllow>
          </Route>
          <Route path="/parametres-admin">
            {/* {!token ? (
              <Redirect to={`/connexion/?next=${"/parametres-admin"}`} />
            ) : userType === "admin" ? (
              <ParametresDashboardAdmin />
            ) : (
              <Error404 />
            )} */}
            <IsAdminAllow>
              <ParametresDashboardAdmin />
            </IsAdminAllow>
          </Route>
          <Route component={Error404} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
