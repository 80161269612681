import React, { Component, useEffect, useState } from "react";
import "./shopDashboard.css";
import HeaderDashboard from "../headerDashboard/index";
import NavVerticalDashboardAdmin from "../navVerticalDashboardAdmin/index";
import TableShop from "../tableShop/index";
import { useGetShopListQuery } from "../../../utils/api/shop.api";

function ShopDashboard() {
  const [status, setStatus] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const {
    data = { results: [], count: 0 },
    isLoading,
    refetch,
    isFetching
  } = useGetShopListQuery({ status, page, limit });

  useEffect(() => {
    if (!isLoading) {
      refetch();
    }
  }, [status]);

  return (
    <div className="component-shop-dashboard">
      <HeaderDashboard />
      <div className="fixed-after-header-dashboard container-fluid">
        <div className="row">
          <div className="col-xl-2 col-lg-3 no-view-tablette">
            <NavVerticalDashboardAdmin />
          </div>
          <div className="col-xl-10 col-lg-9 px-xl-3 py-4 pr-lg-3 pl-lg-1">
            <div className="card-dashboard-content-table">
              <div className="head-card-dashboard-content-table">
                <div className="row mb-5">
                  <div className="col-md-9 col-sm-8 mb-sm-0 mb-3">
                    <p className="title-filter-dashboard">
                      Toutes les boutiques
                    </p>
                  </div>
                  <div className="col-md-3 col-sm-4 pl-sm-0"></div>
                </div>

                <div className="row">
                  <div className="col-xl-9">
                    <nav>
                      <div
                        className="nav nav-tabs nav-tabs-horizontal-commandes"
                        id="nav-tab"
                        role="tablist"
                      >
                        <button
                          className={`nav-link nav-horizontal-commandes ${!status ? "active" : ""}`}
                          id="nav-tout-tab"
                          role="tab"
                          aria-controls="nav-tout"
                          aria-selected="true"
                          onClick={() => setStatus("")}
                        >
                          Tout
                        </button>
                        <button
                          className={`nav-link nav-horizontal-commandes ${status === "active" ? "active" : ""}`}
                          id="nav-active-tab"
                          data-toggle="tab"
                          role="tab"
                          aria-controls="nav-active"
                          aria-selected="false"
                          onClick={() => setStatus("active")}
                        >
                          Activées
                        </button>
                        <button
                          className={`nav-link nav-horizontal-commandes ${status === "desactive" ? "active" : ""}`}
                          id="nav-desactive-tab"
                          data-toggle="tab"
                          role="tab"
                          aria-controls="nav-desactive"
                          aria-selected="false"
                          onClick={() => setStatus("desactive")}
                        >
                          Désactivées
                        </button>
                      </div>
                    </nav>
                  </div>
                  <div className="col-xl-3"></div>
                </div>
              </div>
              <div className="content-card-dashboard-content-table">
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="nav-tout"
                    role="tabpanel"
                    aria-labelledby="nav-tout-tab"
                  >
                    <TableShop
                      shop={data?.results}
                      fetchShops={refetch}
                      isLoading={isFetching}
                      setPage={setPage}
                      page={page}
                      setLimit={setLimit}
                      limit={limit}
                      count={data?.count}
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-active"
                    role="tabpanel"
                    aria-labelledby="nav-active-tab"
                  >
                    <TableShop
                      shop={data?.results}
                      fetchShops={refetch}
                      setPage={setPage}
                      page={page}
                      setLimit={setLimit}
                      limit={limit}
                      count={data?.count}
                      isLoading={isFetching}
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-desactive"
                    role="tabpanel"
                    aria-labelledby="nav-desactive-tab"
                  >
                    <TableShop
                      shop={data?.results}
                      fetchShops={refetch}
                      setPage={setPage}
                      page={page}
                      setLimit={setLimit}
                      limit={limit}
                      count={data?.count}
                      isLoading={isFetching}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShopDashboard;
