import React, { Component } from 'react'
import Skeleton from 'react-loading-skeleton';

export default class CheckoutSkeleton extends Component {

    render() {
        return (
            <div>
                <div className="component-checkout-process-steps">
                    <div className="row px-md-0 m-b-15">
                        <div className="col-lg-4 col-md-6 m-b-20 px-md-2 dis-flex">
                            <div className="item-step-checkout h-full">
                                <div className="row m-b-25">
                                    <div className="col">
                                        <Skeleton circle={true} width={30} height={30} style={{ border: 'solid #eee' }} className="step-item-process-card" />
                                        <Skeleton className="title-proccess-checkout-card m-b-10" width={`65%`} height={20}  />
                                    </div>
                                    <button className="btn-edit-step-item-process-card" >
                                        <Skeleton className="icon-edit-step-item-process-card" width={20} height={20} />
                                    </button>
                                </div>
                                <Skeleton className="client-name-proccess-checkout" />
                                <Skeleton className="client-info-proccess-checkout" />
                                <Skeleton className="client-info-proccess-checkout m-b-20" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 m-b-20 px-md-2 dis-flex">
                            <div className="item-step-checkout h-full">
                                <div className="row m-b-25">
                                    <div className="col">
                                        <Skeleton circle={true} width={30} height={30} style={{ border: 'solid #eee' }} className="step-item-process-card" />
                                        <Skeleton className="title-proccess-checkout-card m-b-10" width={`65%`} height={20} />
                                    </div>
                                </div>
                                <Skeleton className="fs-12 fw-400 text-dark lh-1-4 m-t-3 m-b-10" />

                                {Array(2).fill().map(delivery => {
                                    return (
                                            <div className="custom-control custom-radio m-b-20">
                                                <Skeleton circle={true} width={10} height={10} style={{ border: 'solid #eee' }} className="custom-control-input" />
                                                <Skeleton className="input-item-step-checkout fw-600 fs-14 lh-1-4 mb-10" width={200} height={20} />
                                                <Skeleton className="input-item-step-checkout fs-12 fw-300 lh-1" height={50} />
                                                <div className="row">
                                                    <div className="col-6">
                                                        <Skeleton className="info-input-item-step-checkout mr-3" width={`100%`} />
                                                    </div>
                                                    <div className="col-6">
                                                        <Skeleton className="info-input-item-step-checkout" width={`100%`}/>
                                                    </div>
                                                </div>
                                            </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 m-b-20 px-md-2 dis-flex">
                            <div className="item-step-checkout h-full">
                                <div className="row m-b-25">
                                    <div className="col">
                                        <Skeleton circle={true} width={30} height={30} style={{ border: 'solid #eee' }} className="step-item-process-card" />
                                        <Skeleton className="title-proccess-checkout-card m-b-10" width={`65%`} height={20} />
                                    </div>
                                </div>
                                <Skeleton className="fs-12 fw-400 text-dark lh-1-4 m-t-3 m-b-10" />
                                <div className="custom-control custom-radio m-b-35">
                                    <Skeleton circle={true} width={10} height={10} style={{ border: 'solid #eee' }} className="custom-control-input" />
                                    <Skeleton className="input-item-step-checkout fw-600 fs-14 lh-1-4 mb-10"
                                        width={200} height={20} />
                                    <Skeleton className="input-item-step-checkout fs-12 fw-300 lh-1-2" height={40} />
                                </div>
                                <div className="custom-control custom-radio m-b-30">
                                    <Skeleton circle={true} width={10} height={10} style={{ border: 'solid #eee' }} className="custom-control-input" />
                                    <Skeleton className="orange-money-icon-item-step-checkout" width={50} height={30} />
                                    <Skeleton className="input-item-step-checkout fs-12 fw-300 lh-1-2" height={50} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row px-md-0">
                    <div className="container-fluid px-md-2">
                        <div className="item-step-checkout p-md-4 m-b-30">
                            <div className="row">
                                <div className="col-lg-4 col-md-5">
                                    <Skeleton className="h-theme fw-600 text-up mb-3" />
                                </div>
                            </div>
                            <article>
                                {Array(2).fill().map(item => {
                                    return (
                                        <div className="component-cart-item b-b-1 mb-3">
                                            <div className="row">
                                                <div className="col-xl-6 col-lg-5 col-md-12">
                                                    <div className="row">
                                                        <div className="col-sm-3 m-b-20">
                                                            <Skeleton className="img-thumbs-commande-item" variant="rect" height={100} width={`100%`} />
                                                        </div>
                                                        <div className="pl-lg-0 col-sm-9 m-b-20">
                                                            <Skeleton className="h-theme fw-600 text-up mb-2" />
                                                            <Skeleton className="" variant="rect" height={71} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 m-b-20 dis-flex align-content-md-center px-2">
                                                    <div>
                                                        <Skeleton variant="text" className="h-theme fw-600 text-up m-b-12" />
                                                        <div className="content-qty-cart-item" style={{ border: '2px solid #eee', padding: 0 }}>
                                                            <div className="amount-btn left" style={{ marginTop: 0, marginLeft: 4, height: 17, width: 17 }}>
                                                                <Skeleton className="icon-amount-btn" />
                                                            </div>
                                                            <Skeleton className="" variant="text" style={{ width: 10, height: 17 }} />
                                                            <div className="amount-btn right"  style={{ marginTop: 0, marginRight: 4, height: 17, width: 17 }}>
                                                                <Skeleton className="icon-amount-btn" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 m-b-20 px-2">
                                                    <div>
                                                        <Skeleton className="h-theme fw-600 text-up mb-2" variant="text" />
                                                        <Skeleton  className="" variant="text" height={19}  />
                                                    </div>
                                                </div>
                                                <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 m-b-20 px-2">
                                                    <div>
                                                        <Skeleton className="h-theme fw-600 text-up mb-1" variant="text" />
                                                        <button className="btn-icon-action-commande m-t-4">
                                                            <Skeleton className="icon-commande-favoris" width={30} height={19} />
                                                        </button>
                                                        <button className="btn-icon-action-commande m-t-4" >
                                                            <Skeleton className="icon-commande-delete" width={30} height={19} />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </article>

                            <div className="row">
                                <div className="col-lg-8 dis-flex itm-center">
                                    <div className="row w-full m-b-30">
                                        <div className="col-lg-8 col-md-10">
                                            <Skeleton className="resume-item fw-300 fs-14" style={{ height: 15, marginBottom: 15 }} /><br />
                                            <Skeleton className="resume-item fw-300 fs-14" style={{ height: 15 }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <Skeleton className="resume-item fw-400 fs-14 m-b-20" width={`50%`} height={20} />
                                    {" "}<Skeleton className="value-resume-item fs-14 fw-700 m-t-5" width={`30%`} height={20} />
                                    <Skeleton className="resume-item fw-400 fs-14 m-b-20" width={`50%`} height={20} />
                                    {" "}<Skeleton className="value-resume-item fs-14 fw-700 m-t-5" width={`30%`} height={20} />
                                    <Skeleton className="resume-item fw-400 fs-14 m-b-20" width={`50%`} height={20} />
                                    {" "}<Skeleton className="value-resume-item fs-14 fw-700 m-t-5" width={`30%`} height={20} />
                                    <Skeleton className="resume-item fw-700 fs-14 m-b-20" width={`50%`} height={20} />
                                    {" "} <Skeleton className="value-resume-item-ttc m-t-5" width={`40%`} height={20} style={{ border: 'solid #eee' }} />

                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 col-sm-6 mt-5 pl-sm-0">
                                <Skeleton className="btn-theme-light py-2 text-up w-full" style={{ border: 'solid #eee' }} />

                            </div>
                        </div>
                    </div>
                </div>
                <div className="row dis-flex justify-content-center">
                    <div className="col-md-7">
                        <Skeleton className="btn-theme" style={{ border: 'solid #eee', borderRadius: 25 }} />
                    </div>
                </div>
            </div>
        );
    }

}