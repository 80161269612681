import React, { useRef } from 'react'
import { getAvatar } from '../../../utils/utils'
import ReactQuill from 'react-quill'
import { useForm } from 'react-hook-form'
import { Loader } from 'rsuite'
import api from '../../../http/global-vars'
import axios from 'axios'
import { toast } from 'react-toastify'

function DetailsMessageModal({ message }) {
  const refInput = useRef()
  const [response, setResponse] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)
  const [responses, setResponses] = React.useState([])
  let userID = window.localStorage.getItem('userID')
  let config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
    },
  }
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    setError,
    clearErrors,
  } = useForm()
  React.useEffect(() => {
    if (message?.id && userID) {
      getResponses(message?.id)
      register('message')
      setValue('message', message.id)
      register('response', {
        required: 'La réponse est obligatoire.',
      })
      register('user')
      setValue('user', userID)
    }
  }, [message?.id])

  const getResponses = (id) => {
    let url = `${api}responsebycontact/${id}/`
    axios
      .get(url, config)
      .then((res) => {
        setResponses(res?.data?.results)
        console.log('res', res)
      })
      .catch((err) => {
        console.log('err', err?.response)
      })
  }

  const handleChange = (value) => {
    setResponse(value)
    setValue('response', value)
  }

  const onSubmit = async (formValues, e) => {
    console.log('formValues', formValues)
    setIsLoading(true)
    let url = `${api}responsecontact/`
    await axios
      .post(url, formValues, config)
      .then(async (res) => {
        console.log('res response', res)
        await getResponses(message?.id)
        await setIsLoading(false)
        await setValue("response", "")
        await setResponse("")
        await refInput.current.editor.setText("");
      })
      .catch((err) => {
        setIsLoading(false)
        console.log('err', err)
        toast.error(
          `Une erreur de statut ${
            err?.response?.status || 'inconnue'
          } est survenue`,
        )
      })
    // sendData(formValues)
  }

  React.useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key)
        })
      }, 5000)
    }
  }, [formState])
  return (
    <div className="modal-dialog modal-dialog-centered modal-lg">
      <div className="modal-content py-2">
        <div className="diekkes-modal-header d-flex justify-content-between align-items-center px-3">
          <h1 className="diekkes-modal-title">
            Réponse à <span>({message?.email})</span>
          </h1>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="form-container px-3">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row my-4 border-bottom">
              <div className="col-md-12 form-group">
                <p className="admin-form-label fw-500 fs-15">
                  <span className="fw-400">Objet:&nbsp;</span> {message?.objet}{" "}
                </p>
              </div>

              <div className="col-md-12 form-group">
                <p
                  className="admin-form-label fs-15"
                  dangerouslySetInnerHTML={{ __html: message?.message }}
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-12 form-group">
                <label htmlFor="desc-mision" className="admin-form-label">
                  Réponse
                </label>
                <ReactQuill
                  ref={refInput}
                  name="text"
                  defaultValue={response}
                  onChange={(value) => handleChange(value)}
                />
                {formState?.errors && formState?.errors?.response && (
                  <div className="alert alert-danger epm-alert-danger closer m-t-10">
                    {formState?.errors?.response?.message}
                  </div>
                )}
              </div>
            </div>
            <div className="btn-container gap-3">
              <button
                className="admin-btn-cancel admin-btn-typo"
                type="button"
                data-dismiss="modal"
              >
                Annuler
              </button>
              <button
                className="admin-btn-add admin-btn-typo"
                type="submit"
                disabled={isLoading}
              >
                {!isLoading ? (
                  "Envoyer"
                ) : (
                  <>
                    En cours&nbsp;
                    <Loader />
                  </>
                )}
              </button>
            </div>
          </form>
          {responses?.length
            ? responses.map((resp) => (
                <div className="row my-3">
                  <div className="col-12 itm-center d-flex">
                    <img loading="lazy"
                      className="avatar-auteur-response-card mx-1"
                      src={getAvatar(resp?.user?.avatar)}
                      alt="Avatar"
                      width={"50"}
                      height={"50"}
                    />
                    <p className="text-response-card fs-12 fw-300 sender-name">
                      Par&nbsp;
                      <strong>
                        {resp?.user?.first_name + " " + resp?.user?.last_name}
                      </strong>
                      <br />
                      <span className="cm-sender-email">
                        {resp?.user?.email}{" "}
                      </span>
                    </p>
                  </div>
                  <div className="col-12 itm-center mx-1">
                    <p
                      className="text-response-card fs-12 fw-300"
                      style={style.resp_style}
                      dangerouslySetInnerHTML={{ __html: resp?.response }}
                    />
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
}

export default DetailsMessageModal

const style = {
  resp_style: {
    margin: '1em 0px 0em 0em',
    fontSize: '20px',
    fontWeight: '500',
  },
  select_style: {
    display: 'flex',
    alignItems: 'self-start',
    flexDirection: 'row-reverse',
  },
}
