export const AppStorage = {
  getStorage() {
    const remember = localStorage.getItem("remember");
    if (remember && parseInt(remember) === 1) return localStorage;
    return sessionStorage;
  },
  getItem(key, defualtValue = null) {
    const data = this.getStorage().getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    return defualtValue;
  },
  setItem(key, value) {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  },
  removeItem(key) {
    window.sessionStorage.removeItem(key);
  },
  clear() {
    window.sessionStorage.clear();
  },
};

export const AppLocalStorage = {
  getItem(key, defualtValue = null) {
    const data = window.localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    return defualtValue;
  },
  setItem(key, value) {
    window.localStorage.setItem(key, JSON.stringify(value));
  },
  removeItem(key) {
    window.localStorage.removeItem(key);
  },
  clear() {
    window.localStorage.clear();
  },
};
