import React, { Component } from 'react'
import Skeleton from 'react-loading-skeleton'

export default class ProductSkeleton extends Component {
    render() {
        return (
          <div className="table-container">
            <div className="row as-row">
              <div className="col-xs-6 col-sm-6 col-md-6 col-lg-8">
                <div className="btn-group btn-group-sm" role="group"></div>
              </div>
              <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4">
                <div className="form-group form-group-sm react-bs-table-search-form input-group input-group-sm">
                  <Skeleton height={45} />
                  <span className="input-group-btn">
                    <Skeleton height={45} />
                  </span>
                </div>
              </div>
              <div className="col-md-12 as-col-table-container">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col" style={{ color: "#c501ff" }}>
                        Nom
                      </th>
                      <th scope="col" style={{ color: "#c501ff" }}>
                        Crée
                      </th>
                      <th scope="col" style={{ color: "#c501ff" }}>
                        Prix
                      </th>
                      <th scope="col" style={{ color: "#c501ff" }}>
                        Stock disponible
                      </th>
                      <th scope="col" style={{ color: "#c501ff" }}>
                        Actif
                      </th>
                      <th scope="col" style={{ color: "#c501ff" }}>
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">
                        <Skeleton />
                      </th>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <Skeleton />
                      </th>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <Skeleton />
                      </th>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <Skeleton />
                      </th>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <Skeleton />
                      </th>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        );
    }
}
