import React, {Component} from 'react';
import './filterByPrice.css';
import RangeSlider from '../rangeSlider/rangeSlider';
import FilterByPriceSkeleton from '../skeleton/filterByPriceSkeleton/filterByPriceSkeleton';

export default class filterByPrice extends Component {
  constructor(props) {
      super(props);
      this.state = { loading: false };
  }
  componentDidMount(){
    this.setState({ loading: true });
    const timer = setTimeout(() => {
        this.setState({ loading: false });
    }, 1000);

    return () => clearTimeout(timer);
  }
  render() {
    if(this.state.loading){
      return <FilterByPriceSkeleton/>
    }
    else{
      return <div className="component-filter-by-price">
        <p className="titre-filter-type">Prix (F CFA)</p>

        <section className="range-slider">
            <RangeSlider />
        </section>
      </div>;
    }
  }
}