import React, { useEffect, useState } from "react";
import "./ConditionGeneral.css";

import Header from "../header/index";
import Footer from "../footer/index";
import axios from "axios";
import api from "../../http/global-vars";
import Skeleton from "react-loading-skeleton";

var ConditionGenerale = (props) => {
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [cgu, setCgu] = useState({});
  useEffect(() => {
    window.scrollTo(10,10)
  }, [])
  useEffect(() => {
    getCgu();
  }, []);
  const getCgu = () => {
    axios
      .get(`${api}condition_generale_utilisation`)
      .then((response) => {
        setCgu(response.data.results[0]);
        setIsLoadingPage(false);
      })
      .catch((error) => {
        setIsLoadingPage(false);
        console.error(error);
      });
  };
  return (
    <div className="component-coaching-area">
      <Header />
      <div className="bg-categorie-contain pt-5 p-b-45 fixed-after-header">
        <div className="container">
          <div className="text-center pt-4 pb-5">
            <h2 className="titre-condition-generale pb-3">
              Conditions Générales d'utilisation
            </h2>
          </div>
          <div className="cgu-text">
            {!isLoadingPage ? (
              <div dangerouslySetInnerHTML={{ __html: cgu?.text }} />
            ) : (
              <>
                <Skeleton
                  height={200}
                  style={{ backgroundColor: "#C4C4C4" }}
                  className="text-condition-generale pb-3"
                />
                <Skeleton
                  height={200}
                  style={{ backgroundColor: "#C4C4C4" }}
                  className="text-condition-generale pb-3"
                />
                <Skeleton
                  height={200}
                  style={{ backgroundColor: "#C4C4C4" }}
                  className="text-condition-generale pb-3"
                />
              </>
            )}
            {/* <p className="text-condition-generale pb-3">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis
              neque id volutpat vulputate ante. Accumsan pharetra, elementum
              amet tempor ante. Tristique scelerisque iaculis donec pellentesque
              convallis sed. Mattis cursus sed gravida turpis lacus, amet, ut
              est. Elementum pellentesque bibendum quisque ornare donec.
              Curabitur feugiat a eu eget. Egestas nibh ac sit pretium. Sed diam
              sit dignissim et facilisis. Bibendum sit dictumst pharetra felis.
              Nulla euismod porttitor quisque sed ipsum dictum nunc diam. Tortor
              nulla odio amet nunc in. Lorem faucibus ridiculus integer aliquet
              massa ac consectetur amet. Aliquam adipiscing congue pellentesque
              eu euismod ut mollis dolor auctor. Integer et interdum eget et.
              Sem bibendum quis faucibus enim a. Habitasse at egestas dolor
              mauris fusce. Maecenas scelerisque donec commodo ullamcorper non.
              Lobortis odio vitae lectus quam. Ut dolor dictumst gravida vel
              massa eget justo, massa tellus. Turpis et justo turpis at
              porttitor amet. Ut venenatis, scelerisque purus vulputate. Morbi.
            </p> */}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ConditionGenerale;