import React, {Component} from 'react';
import './transactionDashboard.css';
import HeaderDashboard from '../headerDashboard/index';
import NavVerticalDashboardAdmin from '../navVerticalDashboardAdmin/index';
import TableTransactions from '../tableTransactions/index';
import { connect } from 'react-redux';
import { fetchTransactions } from '../../../transactionAction/transactionAction';

class transactionDashboard extends Component {
  constructor(props) {
      super(props);
      this.state = {};
  }
  
  componentDidMount(){
    this.props.fetchTransactions()
  }
  
  render() {
    let AllTransactions = this.props.transactions?.reverse()
    let transactionsEspece = this.props.transactions?.reverse().filter((f) => f.order.payment_mode?.name === "espece")
    let transactionsElectronic = this.props.transactions?.reverse().filter((f) => f.order.payment_mode?.name === "electronique")
    
    
    return <div className="component-transaction-dashboard">
      <HeaderDashboard />
      <div className="fixed-after-header-dashboard container-fluid">
        <div className="row">
          <div className="col-xl-2 col-lg-3 no-view-tablette">
            <NavVerticalDashboardAdmin />
          </div>
          <div className="col-xl-10 col-lg-9 px-xl-3 py-4 pr-lg-3 pl-lg-1">
            <div className="card-dashboard-content-table">
              <div className="head-card-dashboard-content-table">
                <div className="row mb-5">
                  <div className="col-md-9 col-sm-8 mb-sm-0 mb-3">
                    <p className="title-filter-dashboard">Transactions</p>
                  </div>
                  <div className="col-md-3 col-sm-4 pl-sm-0">

                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-9">
                    <nav>
                      <div className="nav nav-tabs nav-tabs-horizontal-commandes" id="nav-tab" role="tablist">
                        <a className="nav-link nav-horizontal-commandes active" id="nav-tout-tab" data-toggle="tab" href="#nav-tout" role="tab" aria-controls="nav-tout" aria-selected="true">Toutes les transactions</a>
                        <a className="nav-link nav-horizontal-commandes" id="nav-espece-tab" data-toggle="tab" href="#nav-espece" role="tab" aria-controls="nav-espece" aria-selected="true">Espèce</a>
                        <a className="nav-link nav-horizontal-commandes" id="nav-electronique-tab" data-toggle="tab" href="#nav-electronique" role="tab" aria-controls="nav-electronique" aria-selected="true">Eléctronique</a>
                      </div>
                    </nav>
                  </div>
                  <div className="col-xl-3">

                  </div>
                </div>
              </div>
              <div className="content-card-dashboard-content-table">
                <div className="tab-content" id="nav-tabContent">
                  <div className="tab-pane fade show active" id="nav-tout" role="tabpanel" aria-labelledby="nav-tout-tab">
                    <TableTransactions transactions={AllTransactions} />
                  </div>
                  <div className="tab-pane fade" id="nav-espece" role="tabpanel" aria-labelledby="nav-espece-tab">
                    <TableTransactions transactions={transactionsEspece} />
                  </div>
                  <div className="tab-pane fade" id="nav-electronique" role="tabpanel" aria-labelledby="nav-electronique-tab">
                    <TableTransactions transactions={transactionsElectronic} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
}

export default connect(
  (state) => ({
    transactions: state.transactions.items
  }),
  {
    fetchTransactions
  },
)(transactionDashboard)