import React, { Component } from 'react';
import './chartVentes.css';
import Chart from 'react-apexcharts';
import moment from 'moment';
// import _ from 'lodash';


function ChartVentes({ data }) {
  const options = {
    chart: {
      id: "basic-bar",
    },
    xaxis: {
      categories: [
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
        "Dimanche",
      ],
    },
    fill: {
      colors: ["rgba(122, 28, 195, 0) 96.74%)"],
    },
    stroke: {
      show: true,
      curve: ["smooth", "straight", "stepline", "#c501ff"],
      lineCap: "butt",
      colors: ["rgb(122, 28, 195)"],
      width: 1,
      dashArray: 0,
    },
    dataLabels: {
      style: {
        colors: ["rgb(122, 28, 195)"],
      },
    },
  };
  const series = [
    {
      name: "Ventes",
      data: data?.map((el) => el?.count_ventes),
    },
  ];
  return (
    <Chart
        className="chart-dashboard-marketplace"
        options={options}
        series={series}
        type="area"
        width="100%"
        height="350"
      />
  )
}

export default ChartVentes