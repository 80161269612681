export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const FILTER_PRODUCTS_BY_SIZE = "FILTER_PRODUCTS_BY_SIZE";
export const ORDER_PRODUCTS_BY_PRICE = "ORDER_PRODUCTS_BY_PRICE";
export const FILTER_PRODUCTS_BY_PRICE = "FILTER_PRODUCTS_BY_PRICE";
export const FILTER_PRODUCTS_BY_CATEGORY = "FILTER_PRODUCTS_BY_CATEGORY";
export const FILTER_PRODUCTS_BY_SHOP = "FILTER_PRODUCTS_BY_SHOP";
export const FILTER_PRODUCTS_BY_RATE = "FILTER_PRODUCTS_BY_RATE";
export const PRODUCTS_BY_SHOP = "PRODUCTS_BY_SHOP";
export const ORDERS_BY_SHOP = "ORDERS_BY_SHOP";
export const ORDERS = "ORDERS";


export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const CREATE_ORDER = "CREATE_ORDER";
export const CLEAR_ORDER = "CLEAR_ORDER";
export const CLEAR_CART = "CLEAR_CART";
export const FETCH_ORDERS = "FETCH_ORDERS";


export const SHOPS = "SHOPS";
export const ALLSHOPS = "ALLSHOPS";
export const PROMOTESHOP = "PROMOTESHOP";
export const TRANSACTIONS = "TRANSACTIONS";

export const USERS = "USERS";

export const CATEGORIES = "CATEGORIES"