import React from "react";
import { Loader, Message, Modal } from "rsuite";
import PhoneInput from "react-phone-input-2";
import { useForm } from "react-hook-form";
import { NavLink, useHistory } from "react-router-dom";
import axios from "axios";
import api from "../../http/global-vars";
import { toast } from "react-toastify";

function InscriptionPhoneModal({ handleClose, show }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [phone, setPhone] = React.useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = useForm();
  const history = useHistory()

  React.useEffect(() => {
    register("phone", {
      required: "Veuillez saisir un numéro de téléphone valide!",
    });
  }, [register]);

  React.useEffect(() => {
    if (errors) {
      setTimeout(() => {
        Object.entries(errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 5000);
    }
  }, [errors]);

  const handleChange = (phone, country) => {
    console.log("+" + phone,country);
    setPhone(phone)
    if (country?.format?.match(/\./g)?.length === phone?.length) {
      setValue("phone", phone);
    }
  };

  const onSubmit = async(data, e) => {
    console.log("data", data);
    setIsLoading(true)
    await axios.post(`${api}auth/register-phone/`,data).then((res) => {
        console.log('res',res)
        setIsLoading(true);
        if (
          (res.status === 200 || res.status === 201) &&
          res?.data?.status === "success"
        ) {
          toast.success("Inscription validée avec succès !");

          history.push("/connexion")
        } else {
          
          if (res?.data?.message) {
            toast.error(res?.data?.message);
          } else {
            toast.error(
              "Une erreur est survenue lors de votre inscription! Veuillez réessayer."
            );
          }

        }
    }).catch((error) => {
        setIsLoading(true);
        console.log("err",error?.response)
         if (
          error?.response?.data?.error?.phone[0] &&
          error?.response?.data?.error?.phone[0] ===
            "user with this phone already exists."
        ) {
          setError('phone',{message: "Votre numéro de téléphone est déjà associé à un compte!"})
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(
            "Une erreur est survenue lors de votre inscription! Veuillez réessayer."
          );
        }
    });

  };
  return (
    <div className="modal-container">
      <Modal
        size="xs"
        show={show}
        onHide={handleClose}
        backdrop={"static"}
        className="rsuite-content-modal-custom"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header>
            <Modal.Title>Connexion</Modal.Title>
          </Modal.Header>
          <div className="my-5 flex-col itm-center">
            <div className="add-family-form-container w-100">
              <div className="crud-form">
                <div className="row crud-row pb-1">
                  <div className="col-md-12 crud-col-item">
                    <div className="form-group form-group-custom-form required">
                      <label htmlFor="code">Prénom</label>
                      <input
                        type="text"
                        maxLength={30}
                        name="first_name"
                        {...register("first_name", {
                          required: "Veuillez saisir votre prénom!",
                        })}
                        className="form-control form-control-custom-form"
                        placeholder="Prénom *"
                      />
                      {errors?.first_name && (
                        <Message
                          className="alert-rsuite-custom"
                          type="error"
                          description={errors.first_name?.message}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 crud-col-item">
                    <div className="form-group form-group-custom-form required">
                      <label htmlFor="code">Nom</label>
                      <input
                        type="text"
                        maxLength={30}
                        name="last_name"
                        {...register("last_name", {
                          required: {
                            value: true,
                            message: "Veuillez saisir votre nom!",
                          },
                        })}
                        className="form-control form-control-custom-form"
                        placeholder="Nom *"
                      />
                      {errors?.last_name && (
                        <Message
                          className="alert-rsuite-custom"
                          type="error"
                          description={errors.last_name?.message}
                        />
                      )}
                    </div>
                  </div>

                  <div className="col-md-12 crud-col-item">
                    <div className="form-group form-group-custom-form ">
                      <label htmlFor="code">Adresse</label>
                      <input
                        type="text"
                        maxLength={30}
                        name="adresse"
                        {...register("adresse")}
                        className="form-control form-control-custom-form"
                        placeholder="Adresse"
                      />
                      {errors?.adresse && (
                        <Message
                          className="alert-rsuite-custom"
                          type="error"
                          description={errors.adresse?.message}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label for="phone">Téléphone</label>

                      <PhoneInput
                        defaultMask={".. ... .. .."}
                        inputClass="form-control form-control-custom-form"
                        country={"sn"}
                        onlyCountries={["sn"]}
                        inputProps={{
                          name: "telephone",
                          required: true,
                          autoFocus: true,
                        }}
                        disableDropdown={true}
                        enableAreaCodes={true}
                        prefix="+"
                        value={phone}
                        onChange={(phone, country) =>
                          handleChange(phone, country)
                        }
                        countryCodeEditable={false}
                        inputStyle={{
                          width: "100%",
                          paddingBottom: "22px",
                          borderRadius: "10px",
                        }}
                      />
                      {errors?.phone && (
                        <Message
                          className="alert-rsuite-custom"
                          type="error"
                          description={errors?.phone?.message}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="code">Code</label>
                      <input
                        type="number"
                        name="code"
                        {...register("code",{
                            required: "Veuillez saisir un code secret!"
                        })}
                        id="code"
                        className="form-control form-control-custom-form"
                      />

                      {errors?.code && (
                        <Message
                          className="alert-rsuite-custom"
                          type="error"
                          description={errors.code?.message}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 crud-col-item">
                    <div className="custom-control custom-checkbox m-t-40 m-b-40">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck1"
                        {...register("condition")}
                      />
                      <label
                        className="custom-control-label custom-control-label-form-signup"
                        htmlFor="customCheck1"
                      >
                        Oui, je veux recevoir par e-mail des infos sur les
                        dernières tendances. Désinscription possible à tout
                        moment. (facultatif)
                      </label>
                    </div>
                    {errors?.condition && (
                      <Message
                        className="alert-rsuite-custom"
                        type="error"
                        description={errors.condition?.message}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal.Footer className="text-center">
            <div className="row px-5">
              <div className="col-6 mb-2">
                <button onClick={(e) => handleClose(e)} className="btn-theme">
                  Annuler
                </button>
              </div>
              <div className="col-6 mb-2">
                {!isLoading && (
                  <button className="btn-light-theme" type="submit">
                    INSCRIPTION
                  </button>
                )}

                {isLoading && (
                  <button className="btn-light-theme">
                    En cours&nbsp;
                    <Loader />
                  </button>
                )}
              </div>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}

export default InscriptionPhoneModal;
