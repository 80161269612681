import React, { useEffect, useState } from "react";
import { Rate } from "rsuite";
import FeatherIcon from "feather-icons-react";
import { useDispatch, useSelector } from "react-redux";
import FilterByCategorySkeleton from "../skeleton/filterByCategorySkeleton/filterByCategorySkeleton";
import FilterBySellerSkeleton from "../skeleton/filterBySellerSkeleton/filterBySellerSkeleton";
import { useHistory,useParams } from "react-router-dom";
import { QueryUrl } from "../../utils/utils";
import { fetchCategories } from "../../categoryRedux/categoriesAction";
import { fetchShops } from "../../shopAction/shopAction";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";

function FilterItems({ searchParams, query, setQuery,setPage }) {
  const history = useHistory();
  const {id} = useParams()

  React.useEffect(() => {
    let params = QueryUrl("", query);
    history.replace({ search: params });
  }, [query]);

  // useEffect(() => {
  //   if (id) {
  //     let queryCopy = { ...query };
  //     queryCopy = {
  //       ...queryCopy,
  //       boutique: `${id}`,
  //     };
  //     // console.log("query selected", queryCopy);
  //     setQuery(queryCopy);
  //   }
  // }, [id])

  return (
    <div className="card-filter">
      <p className="titre-filter-component fw-500">Filtrer par</p>
      {/* <p className="nom-categorie-filter">Mode</p> */}

      {/* <div className="content-type-filter">
                <FilterByCategory categoryId={this.props.categoryId} />
            </div> */}

      <div className="content-type-filter">
        <FilterByCategory
          searchParams={searchParams}
          setQuery={setQuery}
          query={query}
          setPage={setPage}
        />
      </div>
      {!id && (
        <div className="content-type-filter">
          <FilterBySeller
            searchParams={searchParams}
            setQuery={setQuery}
            query={query}
            setPage={setPage}
          />
        </div>
      )}

      <div className="content-type-filter">
        <FilterByRate
          setQuery={setQuery}
          query={query}
          setPage={setPage}
          searchParams={searchParams}
        />
      </div>

      <div className="content-type-filter">
        <FilterByPrice
          setQuery={setQuery}
          query={query}
          setPage={setPage}
          searchParams={searchParams}
        />
      </div>
    </div>
  );
}

export default FilterItems;

const FilterByCategory = ({ searchParams, setQuery, query,setPage }) => {
  const dispatch = useDispatch();
  const { data, isLoading } = useSelector((s) => s?.categories);
  const categories = searchParams?.getAll("category");
  const [selectedCat, setSelectedCat] = useState(categories || []);


  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  useEffect(() => {
    let queryCopy = { ...query };
    queryCopy = {
      ...queryCopy,
      category: selectedCat?.length ? selectedCat : "",
    };
    // console.log("query selectedCat", queryCopy);
    setQuery(queryCopy);
  }, [selectedCat]);

  const handleChange = (e) => {
    let selectedCopy = [...selectedCat];
    if (e?.target?.checked) {
      selectedCopy?.push(parseInt(e?.target?.value));
    } else {
      selectedCopy = selectedCopy?.filter(
        (el) => parseInt(el) !== parseInt(e?.target?.value)
      );
    }
    setSelectedCat(selectedCopy);
    setPage(1);
  };

  return (
    <div>
      {!isLoading ? (
        <div>
          <p className="titre-filter-type">Catégories</p>
          {data?.map((category) => (
            <div
              key={category?.id}
              className="custom-control custom-checkbox custom-checkbox-filter"
            >
              <label for="">
                <input
                  type="checkbox"
                  value={category?.id}
                  onChange={(e) => handleChange(e)}
                  className="custom-control-input"
                  id={"filter_category" + category?.id}
                  name={category?.name}
                  checked={
                    selectedCat?.includes(category?.id) ||
                    selectedCat?.some((el) => parseInt(el) === category?.id)
                  }
                />
                <label
                  className="custom-control-label custom-control-label-filter text-bold"
                  htmlFor={"filter_category" + category?.id}
                >
                  {category?.name}
                </label>
              </label>
              <ul>
                {category?.subcategories?.length
                  ? category?.subcategories?.map((item) => (
                      <div
                        key={item.id}
                        className="custom-control custom-checkbox custom-checkbox-filter p-1"
                      >
                        <input
                          type="checkbox"
                          value={item.id}
                          onChange={(e) => handleChange(e)}
                          className="custom-control-input"
                          id={"filter_category" + item.id}
                          name={item.name}
                          checked={
                            selectedCat?.includes(item?.id) ||
                            selectedCat?.some((el) => parseInt(el) === item?.id)
                          }
                        />
                        <label
                          className="custom-control-label custom-control-label-filter"
                          htmlFor={"filter_category" + item.id}
                        >
                          {item.name}
                        </label>
                      </div>
                    ))
                  : null}
              </ul>
            </div>
          ))}
        </div>
      ) : (
        <FilterByCategorySkeleton />
      )}
    </div>
  );
};

const FilterBySeller = ({ searchParams, setQuery, query,setPage }) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const { items, isLoading } = useSelector((s) => s?.shops);
  const [sellers, setSellers] = useState(items);
  const boutiques = searchParams?.getAll("boutique");
  const [selected, setSelected] = useState(boutiques || []);

  useEffect(() => {
    dispatch(fetchShops());
  }, [dispatch]);

  useEffect(() => {
    let queryCopy = { ...query };
    queryCopy = {
      ...queryCopy,
      boutique: selected?.length ? selected : "",
    };
    // console.log("query selected", queryCopy);
    setQuery(queryCopy);
  }, [selected]);

  const handleChange = (e) => {
    let selectedCopy = [...selected];
    if (e?.target?.checked) {
      selectedCopy?.push(parseInt(e?.target?.value));
    } else {
      selectedCopy = selectedCopy?.filter(
        (el) => el !== parseInt(e?.target?.value)
      );
    }
    setSelected(selectedCopy);
    setPage(1);
  };

  useEffect(() => {
    if (search.trim().length) {
      setSellers(
        items?.filter((el) =>
          el?.shop_name.toLowerCase().includes(search?.toLowerCase())
        )
      );
    } else {
      setSellers(items);
    }
  }, [search, items]);

  const handleInputChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div>
      {!isLoading ? (
        <div className="component-filter-by-seller">
          <p className="titre-filter-type">Vendeur</p>
          <div className="form-group has-search ">
            <FeatherIcon icon="search" className="icon-search-filter" />
            <input
              type="search"
              className="form-control"
              placeholder="Chercher un vendeur"
              onChange={handleInputChange}
              autoComplete
              value={search}
            />
          </div>
          {sellers?.slice(0, 20)?.map((provider) => (
            <div
              key={provider?.id}
              className="custom-control custom-checkbox custom-checkbox-filter"
            >
              <input
                type="checkbox"
                value={provider.id}
                onChange={(e) => handleChange(e)}
                className="custom-control-input"
                id={"filter_provider" + provider?.id}
                name={provider?.shop_name}
                checked={
                  selected?.includes(provider?.id) ||
                  selected?.some((el) => parseInt(el) === provider?.id)
                }
              />
              <label
                className="custom-control-label custom-control-label-filter"
                htmlFor={"filter_provider" + provider?.id}
              >
                {provider?.shop_name}
              </label>
            </div>
          ))}
          {/* {this.props.categoryList.slice(0, 8).map(category => (
        <li key={category.id} className="li-categories">
          <NavLink className="categories-link" to={`/categorie/${category.id}`}>
            {category.name}
            <FeatherIcon className="icon-categorie-link" icon="chevron-right" />
          </NavLink>
        </li>
      ))} */}
        </div>
      ) : (
        <FilterBySellerSkeleton />
      )}
    </div>
  );
};

const FilterByRate = ({ setQuery, query,setPage }) => {
  const [rate, setRate] = useState("");

  useEffect(() => {
    let queryCopy = { ...query };
    queryCopy = {
      ...queryCopy,
      note: rate,
    };
    // console.log("query selected", queryCopy);
    setQuery(queryCopy);
  }, [rate]);
  const handleChange = (e) => {
    e.persist();
    if (e?.target?.checked) {
      setRate(parseInt(e?.target?.value));
    } else {
      setRate("");
    }
    setPage(1);
  };
  return (
    <div className="component-filter-by-rate">
      <p className="titre-filter-type">Évaliation clients</p>

      <div className={`radio ${rate === 5 ? "active-rate" : ""}`}>
        <label className="mb-0 hov-pointer">
          <input
            type="checkbox"
            name="optradio"
            className="d-none"
            value={5}
            checked={rate === 5}
            onChange={(e) => handleChange(e)}
          />
          <span className="rating-custom-filter-text dis-block">
            <Rate
              value={5}
              allowHalf
              readOnly
              className="rating-custom-filter dis-inline-flex"
            />{" "}
            &nbsp; et plus
          </span>
        </label>
      </div>
      <div className={`radio ${rate === 4 ? "active-rate" : ""}`}>
        <label className="mb-0 hov-pointer">
          <input
            type="checkbox"
            checked={rate === 4}
            name="optradio"
            className="d-none"
            value={4}
            onChange={(e) => handleChange(e)}
          />
          <span className="rating-custom-filter-text dis-block">
            <Rate
              value={4}
              allowHalf
              readOnly
              className="rating-custom-filter dis-inline-flex"
            />{" "}
            &nbsp; et plus
          </span>
        </label>
      </div>
      <div className={`radio ${rate === 3 ? "active-rate" : ""}`}>
        <label className="mb-0 hov-pointer">
          <input
            type="checkbox"
            checked={rate === 3}
            name="optradio"
            className="d-none"
            value={3}
            onChange={(e) => handleChange(e)}
          />
          <span className="rating-custom-filter-text dis-block">
            <Rate
              value={3}
              allowHalf
              readOnly
              className="rating-custom-filter dis-inline-flex"
            />{" "}
            &nbsp; et plus
          </span>
        </label>
      </div>
      <div className={`radio ${rate === 2 ? "active-rate" : ""}`}>
        <label className="mb-0 hov-pointer">
          <input
            type="checkbox"
            checked={rate === 2}
            name="optradio"
            className="d-none"
            value={2}
            onChange={(e) => handleChange(e)}
          />
          <span className="rating-custom-filter-text dis-block">
            <Rate
              value={2}
              allowHalf
              readOnly
              className="rating-custom-filter dis-inline-flex"
            />{" "}
            &nbsp; et plus
          </span>
        </label>
      </div>
      <div className={`radio ${rate === 1 ? "active-rate" : ""}`}>
        <label className="mb-0 hov-pointer">
          <input
            type="checkbox"
            checked={rate === 1}
            name="optradio"
            className="d-none"
            value={1}
            onChange={(e) => handleChange(e)}
          />
          <span className="rating-custom-filter-text dis-block">
            <Rate
              value={1}
              allowHalf
              readOnly
              className="rating-custom-filter dis-inline-flex"
            />{" "}
            &nbsp; et plus
          </span>
        </label>
      </div>
    </div>
  );
};

const FilterByPrice = ({ setQuery, query, searchParams,setPage }) => {
  // const [value, setValue] = useState([0, 0]);
  const [value, setValue] = useState({
    min: searchParams?.get("price_min") || 1000,
    max: searchParams?.get("price_max") || 10000,
  });
 

  const handleFilter = () => {
    let queryCopy = { ...query };
    queryCopy = {
      ...queryCopy,
      ...{ price_min: value?.min, price_max: value?.max },
    };
    setPage(1);
    setQuery(queryCopy);
  };
  return (
    <div className="component-filter-by-price">
      <p className="titre-filter-type">Prix (F CFA)</p>

      <section className="range-slider">
        {/* <RangeSlider /> */}
        <div>
          {/* <Row>
            <Col md={24} className="m-b-20">
              <RangeSlider
                progress
                style={{ marginTop: 16 }}
                value={value}
                max={1000000}
                onChange={(value) => {
                  setValue(value);
                }}
              />
            </Col>
            <Col md={11} sm={24}>
              <InputGroup>
                <InputNumber
                  min={0}
                  max={1000000}
                  // value={value[0]}
                  onChange={(nextValue) => {
                    const [end] = value;
                    console.log(nextValue,end);
                    if (nextValue > end) {
                      return;
                    }
                    // setValue([nextValue, end]);
                  }}
                />
                <InputGroup.Addon> - </InputGroup.Addon>
                <InputNumber
                  min={0}
                  max={1000000}
                  value={value[1]}
                  onChange={(nextValue) => {
                    const [start] = value;
                    if (start > nextValue) {
                      return;
                    }
                    setValue([start, nextValue]);
                  }}
                />
              </InputGroup>
            </Col>
          </Row> */}

          <div className="pb-4 pt-3">
            <div className="position-relative">
              <InputRange
                maxValue={20000}
                minValue={0}
                value={value}
                onChange={(value) => setValue(value)}
              />
            </div>
            <div className="d-flex gap-2 mt-5 container-price align-items-center">
              <div className="price-container">{value?.min || 0}</div>-
              <div className="price-container">{value?.max || 0}</div>
              <button
                className="btn btn-price-selected"
                onClick={() => handleFilter()}
              >
                ok
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
