import React, {Component} from 'react';
import './evaluateProductModal.css';
import { Modal, ButtonToolbar, Rate } from 'rsuite';
import StarIcon from '@material-ui/icons/Star';
import api from '../../http/global-vars';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

export default class evaluateProductModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem('userToken'),
      requestConfig: {
          headers: { Authorization: `Bearer ${window.localStorage.getItem('userToken')}` }
      },
      userID: window.localStorage.getItem('userID'),
      api: api,
      rate: {},
      fields: {},
      errors: {},
      productEvaluation: {
        star: '',
        text: ''
      },
      show: false,
      isSuccessEvaluating: false,
      isErrorEvaluating: false
    };
    this.onGetRate = this.onGetRate.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }


  close = (e) => {
    e.preventDefault()
    this.setState({ show: false });
  }
  open = () => {
    this.setState({ show: true });
  }

  onGetRate = (star) => {
    let rate = this.state.rate
    rate['star'] = star
    this.setState(rate);
  }

  handleChange = (e) => {
    let fields = this.state.fields
    fields[e.target.name] = e.target.value

    this.setState({
        fields: fields
    })

  }

  onSubmitEvaluation = (e) => {
    e.preventDefault()

    let productEvaluation = this.state.productEvaluation
    productEvaluation['star'] = this.state.rate.star
    productEvaluation['text'] = this.state.fields.text
    productEvaluation['product'] = this.props.product.id
    productEvaluation['user'] = this.state.userID
    this.setState(productEvaluation);

    var api = this.state.api

    axios.post(api + 'commentaire/', productEvaluation)
      .then(response => {
        if(response.status === 200 || response.status === 201){
            toast.success("Votre avis sur ce produit à été bien pris en compte !")
            this.setState({
                isSuccessEvaluating: true,
                show: false
            })
            setTimeout(() => {
                this.setState({
                    isSuccessEvaluating:  false
                })
              this.props.getProductById()
            }, 5000);
            this.props.onGetEvaluationByProduct()
        }
        else{
          toast.error("Une erreur est survenue! Veuillez réessayer.")
          this.setState({
              isErrorEvaluating:  true
          })
          setTimeout(() => {
              this.setState({
                  isErrorEvaluating:  false
              })
          }, 5000);
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
  render() {
    const product = this.props.product
    return <div className="component-evaluate-product-modal">
      <ButtonToolbar>
        <div className="pos-relative">
          <span  onClick={this.open} className="btn-evaluate-product-modal">
            <StarIcon className="icon-btn-evaluate-product-modal" />
            évaluer le produit
          </span>
        </div>
      </ButtonToolbar>
      
      <Modal size="sm" show={this.state.show} onHide={this.close} className="theme-light-rs-modal">
        <form  onSubmit={ (e) => this.onSubmitEvaluation(e) }>
          <Modal.Header>
            <Modal.Title>{product.name}</Modal.Title>
          </Modal.Header>
          <div>
            <div className="py-sm-5 pt-5 pb-3 dis-flex justify-content-center">
              <Rate className="rate-evaluate-product-modal" defaultValue={0} onChange={ this.onGetRate } name="star" size="md" />
            </div>
            <div className="mb-3">
              <textarea name="text" onChange={ this.handleChange } className="form-control placeholder-evaluate-product-modal" rows="6" placeholder="Ajouter un commentaire"></textarea>
            </div>
          </div>
          <Modal.Footer>
              <div className="row px-2 pb-3 dis-flex justify-content-end">
                <div className="col-sm-3 col-6 px-2">
                  <button className="cancel-btn-evaluate-product-modal" onClick={(e) => this.close(e)}>Annuler</button>
                </div>
                <div className="col-sm-3 col-6 px-2">
                  <button className="submit-btn-evaluate-product-modal">Soumettre</button>
                </div>
              </div>
          </Modal.Footer>
        </form>
      </Modal>
      
      {this.state.isSuccessEvaluating &&
        <ToastContainer className="fs-13" />
      }
      {this.state.isErrorEvaluating &&
        <ToastContainer className="fs-13" />
      }
    </div>;
  }
}