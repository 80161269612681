import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Message } from "rsuite";

export default class StepsInscriptionVendeur extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  render() {
    return (
      <div>
        <p className="consigne-title-steps">
          Ajouter les informations de la boutique
        </p>
        <div className="row">
          <div className="col-md-6 pr-lg-5">
            <div className="form-group mb-4">
              <input
                type="text"
                maxLength={30}
                className="form-control input-steps-inscription-vendeur"
                placeholder="Prénom *"
                value={this.props.userInfos.first_name}
                onChange={this.props.handleChangeCreateShop}
                name="first_name"
              />
              {this.props.is_empty_first_name && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={this.props.errors.first_name}
                />
              )}
            </div>

            <div className="form-group mb-4">
              <input
                type="text"
                maxLength={30}
                className="form-control input-steps-inscription-vendeur"
                placeholder="Nom d'affichage / Nom de la boutique *"
                value={this.props.userInfos.shop_name}
                onChange={this.props.handleChangeCreateShop}
                name="shop_name"
              />
              {this.props.is_empty_shop_name && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={this.props.errors.shop_name}
                />
              )}
            </div>

            <div className="form-group mb-4">
              <input
                type="text"
                maxLength={30}
                className="form-control input-steps-inscription-vendeur"
                placeholder="N° téléphone *"
                value={this.props.userInfos.phone}
                onChange={this.props.handleChangeCreateShop}
                name="phone"
              />
              {this.props.is_empty_phone && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={this.props.errors.phone}
                />
              )}
            </div>
            <div className="form-group mb-4">
              <input
                type="text"
                maxLength={30}
                className="form-control input-steps-inscription-vendeur"
                placeholder="Statut"
                value={this.props.userInfos.statut}
                onChange={this.props.handleChangeCreateShop}
                name="statut"
              />
            </div>
          </div>
          <div className="col-md-6 pl-lg-5">
            {/* <div className="form-group mb-4">
                  <input
                    type="text"
                    maxLength={30}
                    className="form-control input-steps-inscription-vendeur"
                    placeholder="Whatsapp / Numéro de téléphone alternatif"
                    value={this.props.userInfos.alternative_phone}
                    onChange={this.props.handleChangeCreateShop}
                    name="alternative_phone"
                  />
                  {this.props.same_number_phone && (
                    <Message
                      className="alert-rsuite-custom"
                      type="error"
                      description={this.props.errors.alternative_phone}
                    />
                  )}
                </div> */}
            <div className="form-group mb-4">
              <input
                type="text"
                maxLength={30}
                className="form-control input-steps-inscription-vendeur"
                placeholder="Nom *"
                value={this.props.userInfos.last_name}
                onChange={this.props.handleChangeCreateShop}
                name="last_name"
              />
              {this.props.is_empty_last_name && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={this.props.errors.last_name}
                />
              )}
            </div>

            <div className="form-group mb-4">
              <input
                type="text"
                maxLength={30}
                className="form-control input-steps-inscription-vendeur"
                placeholder="Adresse *"
                name="adresse"
                value={this.props.userInfos.adresse}
                onChange={this.props.handleChangeCreateShop}
              />
              {this.props.is_empty_adresse && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={this.props.errors.adresse}
                />
              )}
            </div>
            <div className="form-group mb-4">
              <input
                type="text"
                maxLength={30}
                className="form-control input-steps-inscription-vendeur"
                placeholder="Personne responsable"
                name="person_in_charge"
                value={this.props.userInfos.person_in_charge}
                onChange={this.props.handleChangeCreateShop}
              />
              {this.props.is_empty_person_in_charge && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={this.props.errors.person_in_charge}
                />
              )}
            </div>

            <div className="form-group mb-4">
              <input
                type="text"
                maxLength={15}
                className="form-control input-steps-inscription-vendeur"
                placeholder="NINEA"
                name="ninea"
                value={this.props.userInfos.ninea}
                onChange={this.props.handleChangeCreateShop}
              />
              {this.props.is_empty_ninea && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={this.props.errors.ninea}
                />
              )}
            </div>

            {/* <div className="form-group mb-4">
                            <input type="email" 
                                maxLength={50}
                                disabled="disabled"
                                className="form-control input-steps-inscription-vendeur" 
                                placeholder="Adresse mail *"
                                value={this.props.userInfos.email}
                                onChange={ this.props.handleChangeCreateShop }
                                name="email" />
                            {this.props.is_empty_email && (
                                <Message className="alert-rsuite-custom" type="error" description={this.props.errors.email} />
                            )}
                        </div> */}

            <div className="p-t-6">
              <p className="label-steps-inscription-vendeur mb-3">
                Condition Générale Vendsell
              </p>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="TermsConditions"
                  name="terms_and_condition"
                  defaultChecked={this.props.checked}
                  onChange={this.props.handleChangeCreateShop}
                />
                <label
                  className="custom-control-label checkbox-steps-inscription-vendeur"
                  htmlFor="TermsConditions"
                >
                  Je comprends et accepte les{" "}
                  <a
                    className="link-terms-steps-inscription-vendeur"
                    href="/condition-generale"
                    target={"_blank"}
                  >
                    Terms and conditions
                  </a>{" "}
                  DIEKKES
                </label>
              </div>
              {this.props.is_empty_terms_and_condition && (
                <div className="text-danger lh-1 form-alert-danger fs-12 mt-2">
                  {this.props.errors.terms_and_condition}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
