import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import * as IconlyPack from "react-iconly";
import "../auth/MotdePasseOublie.css";
import Header from "../header/index";
import api from "../../http/global-vars";
import axios from "axios";
import { Loader } from "rsuite";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import ReSendPhone from "./ReSendPhone";

function PhoneConfirmation() {
  const history = useHistory();
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const location = useLocation();
  const [phone, setPhone] = React.useState("");
  const [show, setShow] = useState(false);
  const [hasSend, setHasSend] = useState(false)
  const [disabled, setDisabled] = useState(false);
  let date = new Date();
  let endDate = new Date(date.getTime() + 5 * 60000);
  let time = window.localStorage.getItem("countDown");
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const timerId = useRef();

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    watch,
    reset,
    setValue,
  } = useForm();

  const getTwoDigitValue = (value) => {
    if (value < 10) {
      return "0" + value;
    }
    return "" + value;
  };

  const calculateCountdown = () => {
    const startDate = new Date();

    if (!time || time === undefined) {
      window.localStorage.setItem("countDown", endDate);
    }
    const timeRemaining = new Date(time).getTime() - startDate.getTime();

    // console.log("timeRemaining", timeRemaining);

    if (timeRemaining > 0) {
      const start_date = new Date(startDate);
      // const end_date = new Date(endDate);
      const start_millis = start_date.getTime(); // Get timestamp of start date
      const end_millis = new Date(time).getTime(); // Get timestamp of end date

      // Convert to seconds, 1 second = 1000 milli seconds
      const old_sec = start_millis / 1000;
      const current_sec = end_millis / 1000;

      // Get remaining seconds
      let seconds = current_sec - old_sec;

      let days = Math.floor(seconds / (24 * 60 * 60)); // 1 day is equal to 24 hours, each hour has 60 mins and each minute has 60 seconds
      seconds -= days * 24 * 60 * 60; // Get remaining seconds

      let hours = Math.floor(seconds / (60 * 60)); // 1 hour has 60 mins and each minute has 60 seconds
      seconds -= hours * 60 * 60; // Get remaining seconds

      let minutes = Math.floor(seconds / 60); // 1 minute is equal to 60 seconds
      seconds -= minutes * 60; // Get remaining seconds

      minutes = Math.abs(minutes);
      seconds = Math.floor(Math.abs(seconds));

      setMinutes(minutes);
      setSeconds(seconds);
      clearTimeout(timerId.current);
      timerId.current = setTimeout(calculateCountdown, 1000);
    } else {
      setDisabled(false);
      setHasSend(false);
      clearTimeout(timerId.current);
    }
  };


  

  useEffect(() => {
    // console.log(nbErr);
    if (hasSend) {
      setDisabled(true);
      calculateCountdown();
    }

    return () => {
      // clearTimeout(timer)
      clearTimeout(timerId.current);
      timerId.current = null;
    };
  }, [hasSend]);

  const convertedMins = getTwoDigitValue(minutes);
  const convertedSeconds = getTwoDigitValue(seconds);

  const handleClose = (e) => {
    e?.preventDefault();
    setShow(false);
  };

  useEffect(() => {
    if (location?.state?.phone) {
      // if (location?.state?.phone?.startWith("+"))
      // console.log("test", location?.state?.phone?.startWith("+"));
      setPhone(location?.state?.phone);
      setValue("phone", location?.state?.phone);
    }
  }, [location]);

  useEffect(() => {
    register("phone", {
      required: "Le numéro de téléphone est obligatoire",
    });
  }, [register]);

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  const handleChange = (phone, country) => {
    console.log("+" + phone, country);
    setPhone(phone);
    if (country?.format?.match(/\./g)?.length === phone?.length) {
      setValue("phone", "+" + phone);
    }
  };

  const onSubmit = async (data, e) => {
    console.log("data", data);
    setIsSuccessfullySubmitted(true);

    await axios
      .post(`${api}validation_phone_number/`, data)
      .then((res) => {
        console.log(res);
        setIsSuccessfullySubmitted(false);

        Swal.fire({
          icon: "success",
          title: "Votre numéro a été validé avec succés!",
          text: "Vous pouvez maintenant vous connecter",
          showConfirmButton: false,
          allowOutsideClick: false,
          timer: 5000,
        }).then(() => {
          history.push({
            pathname: "/connexion",
            replace: true,
          });
        });
      })
      .catch((e) => {
        setIsSuccessfullySubmitted(false);
        console.log(e?.response);
        if (e?.response?.data?.message) {
          //   toast.error(error?.response?.data);
          Swal.fire({
            icon: "error",
            title: e?.response?.data?.message,
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title:
              "Oupsss! Une erreur est survenue. Veuillez réessayer plus tard.",
            showConfirmButton: false,
            timer: 3000,
          });
        }
      });
  };

  const onResend = (e) => {
    e?.preventDefault();
    // setShow(true);
    if (phone) {
      send(phone);
    } else {
      setShow(true);
    }
  };
  const send = (phone) => {
    setShow(false);
    setPhone(phone);
    setValue("phone", phone);
    axios
      .post(`${api}resend-code/`, { phone: phone })
      .then((res) => {
        console.log(res);
        Swal.fire({
          icon: "success",
          title: "Code renvoyé avec succès!",
          showConfirmButton: false,
          timer: 3000,
        });
        
        let date = new Date();
        let endDate = new Date(date.getTime() + 2 * 60000);
        window.localStorage.setItem("countDown", endDate);
        setHasSend(true);
      })
      .catch((e) => {
        setIsSuccessfullySubmitted(false);
        console.log(e?.response?.data);
        if (e?.response?.data?.message) {
          //   toast.error(error?.response?.data);
          Swal.fire({
            icon: "error",
            title: e?.response?.data?.message,
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title:
              "Oupsss! Une erreur est survenue. Veuillez réessayer plus tard.",
            showConfirmButton: false,
            timer: 3000,
          });
        }
      });
  };
  return (
    <div>
      <Header />
      <div className="login-page-container p-t-40 p-b-150 fixed-after-header">
        <section className="container">
          <div>
            <form
              className="row auth-page-row"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="col-md-6 offset-md-3 auth-page-col">
                <div className="row auth-form-row">
                  <h1 className="auth-form-title m-b-90">
                    Confirmation du N° de téléphone
                  </h1>
                  <div className="col-md-6 offset-md-3 m-b-20">
                    <p className="text-modifier-password">
                      Veuillez entrer votre numéro de téléphone et le code reçu
                      par sms.
                    </p>
                  </div>

                  <div className="col-md-12 auth-input-col m-b-20">
                    <div className="form-group auth-form-group position-relative">
                      <PhoneInput
                        defaultMask={".. ... .. .."}
                        inputClass="form-control auth-form-control pl-5"
                        country={"sn"}
                        onlyCountries={["sn"]}
                        inputProps={{
                          name: "telephone",
                          required: true,
                          autoFocus: true,
                        }}
                        disableDropdown={true}
                        enableAreaCodes={true}
                        prefix="+"
                        value={phone}
                        onChange={(phone, country) =>
                          handleChange(phone, country)
                        }
                        countryCodeEditable={false}
                        inputStyle={{
                          width: "100%",
                          paddingBottom: "22px",
                          borderRadius: "10px",
                        }}
                      />
                    </div>
                    {formState.errors && formState.errors.phone && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.phone?.message}
                      </div>
                    )}
                  </div>
                  <div className="col-md-12 auth-input-col m-b-20">
                    <div className="form-group auth-form-group position-relative">
                      <input
                        name="code"
                        type="number"
                        min={0}
                        autoComplete="off"
                        className="form-control auth-form-control pl-5"
                        id="code"
                        {...register("code", {
                          required: "le code est requis",
                        })}
                        placeholder="Entrer le code reçu par sms"
                      />
                      {formState?.errors?.code && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {" "}
                          {formState?.errors?.code?.message}{" "}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 offset-md-3 d-flex justify-content-center auth-input-col m-b-5">
                    {!isSuccessfullySubmitted && (
                      <button className="btn-form-dark">ENVOYER</button>
                    )}

                    {isSuccessfullySubmitted && (
                      <button className="btn-form-dark">
                        En cours &nbsp;
                        <Loader />
                      </button>
                    )}
                  </div>
                  <div className="col-md-12 d-flex justify-content-center auth-input-col m-b-5">
                    <p className="text-center fs-14 fw-300">
                      J'ai pas reçu le code !{" "}
                      <button
                        className="link-form-signup-login bg-transparent"
                        type="button"
                        disabled={disabled}
                        onClick={(e) => onResend(e)}
                      >
                        Renvoyer{" "}
                        {!!disabled && `dans ${convertedMins}:${convertedSeconds}`}
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
        <ReSendPhone show={show} handleClose={handleClose} onSend={send} />
      </div>
      {/* <ToastContainer /> */}
    </div>
  );
}

export default PhoneConfirmation;
