import React, { Component } from "react";
import "./cart.css";
import FeatherIcon from "feather-icons-react";
import EmptyCartImage from "../../img/empty_cart.png";
import api from "../../http/global-vars";
import axios from "axios";
import baseUrl from "../../http/backend-base-url";
import Skeleton from "react-loading-skeleton";
import { getCartItems, removeCart, removeItem } from "../reduxCart/cartActions";
import { connect, useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import FavoriteIcon from "@material-ui/icons/Favorite";
import CartItem from "../cartItem/cartItem";
import { useHistory } from "react-router-dom";

// class cart extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       token: window.localStorage.getItem("userToken"),
//       requestConfig: {
//         headers: {
//           Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
//         },
//       },
//       api: api,
//       userID: window.localStorage.getItem("userID"),
//       isPageLoading: false,
//       cart: [],
//       itemsCart: [],
//       productWhishlist: [],
//       isWishlistEmpty: false,
//       isSuccessFavoris: false,
//       isErrorFavoris: false,
//       favorisInfos: {},
//     };
//   }

//   componentDidMount() {
//     this.onGetWishlistProduct();
//     this.setState({ isPageLoading: true });
//     const timer = setTimeout(() => {
//       this.props.getM();
//       this.setState({ isPageLoading: false });
//     }, 3000);
//     // Cancel the timer while unmounting
//     return () => clearTimeout(timer);
//   }

//   onGetWishlistProduct = () => {
//     var api = this.state.api;
//     var userID = this.state.userID;
//     var config = this.state.requestConfig;

//     axios
//       .get(api + `favorisbyuser/${userID}/`, config)
//       .then((response) => {
//         if (response.status === 200 || response.status === 201) {
//           if (!response.data.length) {
//             this.setState({
//               isWishlistEmpty: true,
//             });
//           } else {
//             this.setState({
//               productWhishlist: response.data,
//             });
//           }
//         }
//       })
//       .catch((error) => {
//         if (error) {
//           this.setState({ loading: false });
//         }
//       });
//   };

//   onAddToFavoris = (e, product) => {
//     e.preventDefault();

//     let favorisInfos = this.state.favorisInfos;
//     favorisInfos["product"] = product.id;
//     favorisInfos["user"] = this.state.userID;
//     this.setState(favorisInfos);

//     var api = this.state.api;
//     var data = this.state.favorisInfos;
//     var config = this.state.requestConfig;
//     axios
//       .post(api + "favoris/", data, config)
//       .then((response) => {
//         if (response.status === 200 || response.status === 201) {
//           // toast.success("Vous suivez cette boutique !")
//           this.setState({
//             isSuccessFavoris: true,
//           });
//           setTimeout(() => {
//             this.setState({
//               isSuccessFavoris: false,
//             });
//           }, 5000);
//           this.onGetWishlistProduct();
//         } else {
//           // toast.error("Une erreur est survenue! Veuillez rééssayer plus tard.")
//           this.setState({
//             isErrorFavoris: true,
//           });
//           setTimeout(() => {
//             this.setState({
//               isErrorFavoris: false,
//             });
//           }, 5000);
//         }
//       })
//       .catch((error) => {
//         if (error) {
//           this.setState({ loading: false });
//         }
//       });
//   };

//   onDeleteToFavoris = (e, productItem) => {
//     e.preventDefault();

//     var api = this.state.api;
//     var productId = productItem.id;
//     let favorisId = this.state.productWhishlist.filter(
//       (f) => f.product?.id === productId
//     )[0]?.id;
//     var config = this.state.requestConfig;
//     let url = api + `favoris/${favorisId}/`;
//     axios
//       .delete(url, config)
//       .then((response) => {
//         this.onGetWishlistProduct();
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };

//   removeItem = (e, id) => {
//     e.preventDefault();
//     this.props.remove(id);
//     this.props.getM();
//     window.scrollTo({ top: 0, behavior: "smooth" });
//   };

//   redirect = (e) => {
//     e.preventDefault();
//     window.location = "/tous-les-produits";
//   };

//   checkout = (e) => {
//     e.preventDefault();
//     window.location = "/checkout";
//   };

//   render() {
//     if (this.state.isPageLoading) {
//       return <CartSkeleton />;
//     } else {
//       if (!this.props.localCartItems?.length) {
//         window.scrollTo(0, 0);
//         return (
//           <section className="cart-content checkout">
//             <img
//               loading="lazy"
//               className="image-cart-empty"
//               src={EmptyCartImage}
//               alt=""
//             />
//             <header>
//               <h2 className="h-theme">Votre panier est vide!</h2>
//               <h4 className="">
//                 Veuillez ajouter des produits pour pouvoir acheter.
//               </h4>
//             </header>
//             <div className="dis-flex justify-content-center mt-5">
//               <div className="col-md-5 col-sm-7">
//                 <NavLink
//                   className="btn-theme dis-flex justify-content-center"
//                   to="/tous-les-produits"
//                 >
//                   Commencer mes achats
//                 </NavLink>
//               </div>
//             </div>
//           </section>
//         );
//       } else {
//         return (
//           <div className="content-card-account">
//             <form>
//               {this.props.localCartItems?.reverse().map((item) => {
//                 return (
//                   <div className="commande-item m-b-40" key={item.id}>
//                     <div className="row m-b-20">
//                       <div className="col-xl-6 col-lg-6 col-md-12">
//                         <div className="row">
//                           <div className="col-sm-3 m-b-20">
//                             <img
//                               loading="lazy"
//                               className="img-thumbs-commande-item"
//                               src={baseUrl + item.image[0]?.image_small}
//                               alt=""
//                             />
//                           </div>
//                           <div className="col-sm-9 m-b-20">
//                             <p className="m-b-12 text-black fs-10 fw-300">
//                               Vendeur : {item.provider.shop_name}
//                             </p>
//                             <p className="text-commande-item m-b-10 fw-300 fs-14">
//                               {item.name}
//                             </p>
//                             <p className="text-commande-item m-b-30 fw-300 fs-12">
//                               {item.description}
//                             </p>
//                           </div>
//                         </div>
//                       </div>
//                       <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 m-b-20 dis-flex justify-content-center">
//                         <div>
//                           <p className="h-theme fw-600 text-up">Quantité</p>
//                           <select
//                             className="form-control qty-commande-item"
//                             id="inputRegion"
//                           >
//                             <option>{item.count}</option>
//                           </select>
//                         </div>
//                       </div>
//                       <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 m-b-20 dis-flex justify-content-center">
//                         <div>
//                           <p className="h-theme fw-600 text-up">
//                             Prix unitaire
//                           </p>
//                           <p className="text-dark fs-14 ff-open fw-400 text-center">
//                             {item.price} FCFA
//                           </p>
//                         </div>
//                       </div>
//                       <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 m-b-20 dis-flex justify-content-center">
//                         <div>
//                           <p className="h-theme fw-600 text-up">Action</p>

//                           {this.state.productWhishlist !== undefined &&
//                           this.state.productWhishlist.length ? (
//                             this.state.productWhishlist.some(
//                               (v) => parseInt(v?.product?.id) === item?.id
//                             ) ? (
//                               <button
//                                 className="btn-icon-action-commande"
//                                 onClick={(e) => this.onDeleteToFavoris(e, item)}
//                               >
//                                 <FavoriteIcon className="icon-commande-favoris" />
//                               </button>
//                             ) : (
//                               <button
//                                 className="btn-icon-action-commande"
//                                 onClick={(e) => this.onAddToFavoris(e, item)}
//                               >
//                                 <FeatherIcon
//                                   className="icon-commande-favoris"
//                                   icon="heart"
//                                 />
//                               </button>
//                             )
//                           ) : (
//                             <button
//                               className="btn-icon-action-commande"
//                               onClick={(e) => this.onAddToFavoris(e, item)}
//                             >
//                               <FeatherIcon
//                                 className="icon-commande-favoris"
//                                 icon="heart"
//                               />
//                             </button>
//                           )}

//                           <button
//                             className="btn-icon-action-commande"
//                             onClick={(e) => this.removeItem(e, item?.id)}
//                           >
//                             <FeatherIcon
//                               className="icon-commande-delete"
//                               icon="trash-2"
//                             />
//                           </button>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 );
//               })}
//               <div className="row justify-content-end m-b-200">
//                 <div className="col-xl-3 col-lg-4 col-md-6">
//                   <button
//                     onClick={this.redirect}
//                     className="btn-user-info-form-reverse"
//                   >
//                     Poursuivre mes achats
//                   </button>
//                 </div>
//                 <div className="col-lg-4 col-md-6">
//                   <button
//                     onClick={this.checkout}
//                     className="btn-user-info-form"
//                   >
//                     Finaliser ma commande
//                   </button>
//                 </div>
//               </div>
//             </form>
//           </div>
//         );
//       }
//     }
//   }
// }

// const CartSkeleton = () => {
//   return (
//     <div className="content-card-account">
//       <article>
//         {Array(3)
//           .fill()
//           .map((item, i) => {
//             return (
//               <div key={i} className="component-cart-item b-b-1 mb-3">
//                 <div className="row">
//                   <div className="col-xl-6 col-lg-5 col-md-12">
//                     <div className="row">
//                       <div className="col-sm-3 m-b-20">
//                         <Skeleton
//                           className="img-thumbs-commande-item"
//                           variant="rect"
//                           height={100}
//                           width={`100%`}
//                         />
//                       </div>
//                       <div className="pl-lg-0 col-sm-9 m-b-20">
//                         <Skeleton className="h-theme fw-600 text-up mb-2" />
//                         <Skeleton className="" variant="rect" height={71} />
//                       </div>
//                     </div>
//                   </div>
//                   <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 m-b-20 dis-flex align-content-md-center px-2">
//                     <div>
//                       <Skeleton
//                         variant="text"
//                         className="h-theme fw-600 text-up m-b-12"
//                       />
//                       <div
//                         className="content-qty-cart-item"
//                         style={{ border: "2px solid #eee", padding: 0 }}
//                       >
//                         <div
//                           className="amount-btn left"
//                           style={{
//                             marginTop: 0,
//                             marginLeft: 4,
//                             height: 17,
//                             width: 17,
//                           }}
//                         >
//                           <Skeleton className="icon-amount-btn" />
//                         </div>
//                         <Skeleton
//                           className=""
//                           variant="text"
//                           style={{ width: 10, height: 17 }}
//                         />
//                         <div
//                           className="amount-btn right"
//                           style={{
//                             marginTop: 0,
//                             marginRight: 4,
//                             height: 17,
//                             width: 17,
//                           }}
//                         >
//                           <Skeleton className="icon-amount-btn" />
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 m-b-20 px-2">
//                     <div>
//                       <Skeleton
//                         className="h-theme fw-600 text-up mb-2"
//                         variant="text"
//                       />
//                       <Skeleton className="" variant="text" height={19} />
//                     </div>
//                   </div>
//                   <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 m-b-20 px-2">
//                     <div>
//                       <Skeleton
//                         className="h-theme fw-600 text-up mb-1"
//                         variant="text"
//                       />
//                       <button className="btn-icon-action-commande m-t-4">
//                         <Skeleton
//                           className="icon-commande-favoris"
//                           width={30}
//                           height={19}
//                         />
//                       </button>
//                       <button className="btn-icon-action-commande m-t-4">
//                         <Skeleton
//                           className="icon-commande-delete"
//                           width={30}
//                           height={19}
//                         />
//                       </button>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             );
//           })}
//       </article>
//       <div className="row justify-content-end m-b-200">
//         <div className="col-xl-3 col-lg-4 col-md-6">
//           <Skeleton
//             className="btn-user-info-form-reverse"
//             style={{ border: "solid #eee", borderRadius: 25 }}
//           />
//         </div>
//         <div className="col-lg-4 col-md-6">
//           <Skeleton
//             className="btn-user-info-form"
//             style={{ border: "solid #eee", borderRadius: 25 }}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// const mapStateToProps = (state) => {
//   return {
//     localCartItems: state.cart.cartItems,
//     total: state.cart.total,
//     cartId: state.cart.cartId,
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return {
//     getM: () => dispatch(getCartItems()),
//     rmAll: () => dispatch(removeCart()),
//     remove: (id) => dispatch(removeItem(id)),
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(cart);

function Cart() {
  const dispatch = useDispatch();
  const { cartItems } = useSelector((state) => state.cart);
    const history = useHistory()
  if (!cartItems || cartItems.length === 0) {
    return (
      <section className="cart-content checkout">
        <img
          loading="lazy"
          className="image-cart-empty"
          src={EmptyCartImage}
          alt=""
        />
        <header>
          <h2 className="h-theme">Votre panier est vide!</h2>
          <h4 className="">
            Veuillez ajouter des produits pour pouvoir acheter.
          </h4>
        </header>
        <div className="dis-flex justify-content-center mt-5">
          <div className="col-md-5 col-sm-7">
            <NavLink
              className="btn-theme dis-flex justify-content-center"
              to="/tous-les-produits"
            >
              Commencer mes achats
            </NavLink>
          </div>
        </div>
      </section>
    );
  }
  return (
    <div className="content-card-account">
      <div>
        {cartItems.map((item) => {
          return (
            // <div className="commande-item m-b-40" key={item.id}>
            //   <div className="row m-b-20">
            //     <div className="col-xl-6 col-lg-6 col-md-12">
            //       <div className="row">
            //         <div className="col-sm-3 m-b-20">
            //           <img
            //             loading="lazy"
            //             className="img-thumbs-commande-item"
            //             src={baseUrl + item.image[0]?.image_small}
            //             alt=""
            //           />
            //         </div>
            //         <div className="col-sm-9 m-b-20">
            //           <p className="m-b-12 text-black fs-10 fw-300">
            //             Vendeur : {item.provider.shop_name}
            //           </p>
            //           <p className="text-commande-item m-b-10 fw-300 fs-14">
            //             {item.name}
            //           </p>
            //           <p className="text-commande-item m-b-30 fw-300 fs-12">
            //             {item.description}
            //           </p>
            //         </div>
            //       </div>
            //     </div>
            //     <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 m-b-20 dis-flex justify-content-center">
            //       <div>
            //         <p className="h-theme fw-600 text-up">Quantité</p>
            //         <select
            //           className="form-control qty-commande-item"
            //           id="inputRegion"
            //         >
            //           <option>{item.count}</option>
            //         </select>
            //       </div>
            //     </div>
            //     <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 m-b-20 dis-flex justify-content-center">
            //       <div>
            //         <p className="h-theme fw-600 text-up">Prix unitaire</p>
            //         <p className="text-dark fs-14 ff-open fw-400 text-center">
            //           {item.price} FCFA
            //         </p>
            //       </div>
            //     </div>
            //     <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 m-b-20 dis-flex justify-content-center">
            //       <div>
            //         <p className="h-theme fw-600 text-up">Action</p>

            //         {this.state.productWhishlist !== undefined &&
            //         this.state.productWhishlist.length ? (
            //           this.state.productWhishlist.some(
            //             (v) => parseInt(v?.product?.id) === item?.id
            //           ) ? (
            //             <button
            //               className="btn-icon-action-commande"
            //               onClick={(e) => this.onDeleteToFavoris(e, item)}
            //             >
            //               <FavoriteIcon className="icon-commande-favoris" />
            //             </button>
            //           ) : (
            //             <button
            //               className="btn-icon-action-commande"
            //               onClick={(e) => this.onAddToFavoris(e, item)}
            //             >
            //               <FeatherIcon
            //                 className="icon-commande-favoris"
            //                 icon="heart"
            //               />
            //             </button>
            //           )
            //         ) : (
            //           <button
            //             className="btn-icon-action-commande"
            //             onClick={(e) => this.onAddToFavoris(e, item)}
            //           >
            //             <FeatherIcon
            //               className="icon-commande-favoris"
            //               icon="heart"
            //             />
            //           </button>
            //         )}

            //         <button
            //           className="btn-icon-action-commande"
            //           onClick={(e) => this.removeItem(e, item?.id)}
            //         >
            //           <FeatherIcon
            //             className="icon-commande-delete"
            //             icon="trash-2"
            //           />
            //         </button>
            //       </div>
            //     </div>
            //   </div>
            // </div>
            <CartItem key={item?.product.id} item={item} data={cartItems} />
          );
        })}
        <div className="row justify-content-end m-b-200">
          <div className="col-xl-3 col-lg-4 col-md-6">
            <button
              onClick={() => history.push("/tous-les-produits")}
              className="btn-user-info-form-reverse"
            >
              Poursuivre mes achats
            </button>
          </div>
          <div className="col-lg-4 col-md-6">
            <button onClick={() => history.push("/checkout")} className="btn-user-info-form">
              Finaliser ma commande
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cart;
