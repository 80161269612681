import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useHistory,useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import * as IconlyPack from "react-iconly";
import baseUrl from "../../../http/backend-base-url";
import HeaderDashboard from "../../adminDashboard/headerDashboard/headerDashboard";
import NavVerticalDashboardAdmin from "../../adminDashboard/navVerticalDashboardAdmin";
import BannerShop from "../../../img/bannerBoutique.png";
import moment from "moment";
import EditBannerShopModal from "./EditBannerShopModal";
import axios from "axios";
import api from "../../../http/global-vars";

function DetailsShopAdmin() {
  var location = useLocation();
  const {id} = useParams()
  const [show, setShow] = useState(false);
  var history = useHistory();
  const [shop, setShop] = useState(location?.state || {});
  let config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
    },
  };

  const handleToggle = () => {
    setShow(!show);
  };

  const getShop = () => {
    axios
      .get(`${api}fournisseur/${id}/`, config)
      .then((res) => {
        // console.log("shop", res);
        setShop(res.data)
      })
      .catch((err) => {
        if (!location?.state?.id) {
          history.goBack();
        }
      });
  }

  useEffect(() => {
    if (id) {
      getShop()
    } else {
      setTimeout(() => {
        history.goBack();
      }, 100);
    }
  }, [id])

  useEffect(() => {
    if (location?.state?.id) {
      setShop(location.state);
    } 
  }, [location]);
  return (
    <div className="component-articles-dashboard-admin">
      <HeaderDashboard />
      <div className="fixed-after-header-dashboard container-fluid">
        <div className="row">
          <div className="col-xl-2 col-lg-3 no-view-tablette">
            <NavVerticalDashboardAdmin />
          </div>
          <div className="col-xl-10 col-lg-9 px-xl-3 py-4 pr-lg-3 pl-lg-1">
            <div className="card-dashboard-content-table">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title">
                      <NavLink
                        to={"/boutiques-admin"}
                        className="action-btn btn edit-btn"
                        title="Retour"
                      >
                        <IconlyPack.ArrowLeft
                          set="bold"
                          primaryColor="#000"
                          className="action-btn-icon action-edit-icon"
                          // onClick={() => onDesarchive(row?.id)}
                        />
                      </NavLink>
                      Détails Boutique
                    </h1>
                  </div>
                </div>
                <div className="parent-table-container">
                  <div className="body-theme p-t-10 p-b-45">
                    <div className="container">
                      <div className="container-banner-shop">
                        {!shop?.banner ||
                        shop?.banner?.includes("uploads/bannier.png") ? (
                          <img loading="lazy"
                            className="w-full m-b-12 banner-shop-details image"
                            src={BannerShop}
                            alt={shop?.shop_name}
                          />
                        ) : (
                          <div
                            className="m-b-12 banner-shop-details image"
                            style={{
                              background: `url(${
                                shop?.banner
                                  ? baseUrl + shop.banner
                                  : BannerShop
                              })`,
                            }}
                          ></div>
                        )}
                        <div className="middle">
                          <div
                            class="btn btn-edt ajouter-btn-modal"
                            onClick={() => handleToggle()}
                          >
                            Modifier
                          </div>
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-lg-7 m-b-12 pr-md-3">
                          {/* teste */}
                          <div className="info-boutique-card dis-flex flex-col h-full">
                            <div className="head-boutique-card">
                              <div className="row">
                                <div className="col-sm-9 col-10 itm-center">
                                  <h5 className="h-info-boutique-card text-up m-sm-0 my-2">
                                    {shop?.shop_name}
                                  </h5>
                                </div>
                                <div className="col-sm-3 col-2 pl-md-0 pr-md-3 px-0"></div>
                              </div>
                            </div>
                            <div className="body-boutique-card-infos">
                              <div className="row">
                                <div className="col-md-4 col-sm-3 col-5 mb-3">
                                  <p className="titre-info-boutique-card">
                                    abonnés
                                  </p>
                                  <p className="content-info-boutique-card">
                                    {shop?.number_of_subscribers}
                                  </p>
                                </div>
                                <div className="col-md-4 col-sm-5 col-7 mb-3">
                                  <p className="titre-info-boutique-card">
                                    Vendeur
                                  </p>

                                  <p className="content-info-boutique-card">
                                    depuis&nbsp;
                                    {moment(shop?.created_at)
                                      .locale("fr")
                                      .fromNow(true)}
                                  </p>
                                </div>
                                <div className="col-md-4 col-sm-4 col-6 mb-3">
                                  <p className="titre-info-boutique-card">
                                    Produits
                                  </p>

                                  <p className="content-info-boutique-card">
                                    {shop?.number_of_products}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* end */}
                        </div>
                        <div className="col-lg-5 m-b-12 pl-lg-0 dis-flex">
                          <div className="evaluation-boutique-card">
                            <div className="head-boutique-card">
                              <div className="row">
                                <div className="col-xl-8 col-lg-8 col-sm-9 col-10 itm-center">
                                  <h6 className="h-evaluation-boutique-card mb-0 text-up">
                                    évaluation
                                  </h6>
                                </div>
                                <div className="col-xl-4 col-lg-4 pl-xl-3 pr-xl-3 p-lg-0 col-sm-3 col-2 pl-md-3 pr-md-3 pl-sm-0">
                                  <form className="pos-relative"></form>
                                </div>
                              </div>
                            </div>
                            <div className="body-evaluation-boutique">
                              <div className="row">
                                <div className="col-xl-4 col-lg-4 col-sm-4 col-4 mb-3">
                                  <p className="titre-evaluation-boutique-card">
                                    Note{" "}
                                  </p>
                                  <p className="content-evaluation-boutique-card">
                                    {parseFloat(shop?.note)?.toFixed(1)}/5
                                  </p>
                                </div>
                                {/* <div className="col-xl-4 col-lg-4 col-sm-4 col-4 mb-3">
                                  <p className="titre-evaluation-boutique-card">
                                    Commandes{" "}
                                  </p>
                                  <p className="content-evaluation-boutique-card">
                                    {shop?.nb_orders}
                                  </p>
                                </div> */}
                                <div className="col-xl-4 col-lg-4 col-sm-4 col-4 mb-3">
                                  <p className="titre-evaluation-boutique-card">
                                    Ventes
                                  </p>
                                  <p className="content-evaluation-boutique-card">
                                    {shop?.sales}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 m-b-12 pr-md-3">
                          {/* teste */}
                          <div className="info-boutique-card dis-flex flex-col h-full">
                            <div className="head-boutique-card">
                              <div className="row">
                                <div className="col-sm-9 col-10 itm-center">
                                  <h5 className="h-info-boutique-card text-up m-sm-0 my-2">
                                    Informations supplémentaires
                                  </h5>
                                </div>
                                <div className="col-sm-3 col-2 pl-md-0 pr-md-3 px-0"></div>
                              </div>
                            </div>
                            <div className="body-boutique-card-infos">
                              <div className="row">
                                <div className="col-md-4 col-sm-3 col-5 mb-3">
                                  <p className="titre-info-boutique-card">
                                    Proprietaire
                                  </p>
                                  <p className="content-info-boutique-card">
                                    {shop?.user?.first_name +
                                      " " +
                                      shop?.user?.last_name}
                                  </p>
                                </div>
                                <div className="col-md-4 col-sm-5 col-7 mb-3">
                                  <p className="titre-info-boutique-card">
                                    Email
                                  </p>

                                  <p className="content-info-boutique-card">
                                    {shop?.user?.email}
                                  </p>
                                </div>
                                <div className="col-md-4 col-sm-4 col-6 mb-3">
                                  <p className="titre-info-boutique-card">
                                    Adresse
                                  </p>

                                  <p className="content-info-boutique-card">
                                    {(shop?.adresse || "") +
                                      "," +
                                      shop?.city +
                                      "," +
                                      shop?.country}
                                  </p>
                                </div>

                                <div className="col-md-4 col-sm-5 col-7 mb-3">
                                  <p className="titre-info-boutique-card">
                                    Ninea
                                  </p>

                                  <p className="content-info-boutique-card">
                                    {shop?.ninea}
                                  </p>
                                </div>
                                <div className="col-md-4 col-sm-4 col-6 mb-3">
                                  <p className="titre-info-boutique-card">
                                    Entité
                                  </p>

                                  <p className="content-info-boutique-card">
                                    {shop?.option}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* end */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <ContentProductDetails
                    productId={product?.id}
                    productItem={product}
                  /> */}
                  <EditBannerShopModal
                    shop={shop}
                    show={show}
                    close={handleToggle}
                    fn={getShop}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsShopAdmin;
