import React, { Component } from "react";
import FeatherIcon from "feather-icons-react";
import Dropzone from "react-dropzone";
import { Message } from "rsuite";
import baseUrl from "../../../../http/backend-base-url";
import { UseDeleteImageProduct } from "../form/UseAddOrEditProduct";

function ImagesProduct({ setImages, images, setValue, errors }) {
  const onDelete = UseDeleteImageProduct();
  const onDrop = (files) => {
    console.log("files", files);
    // this.setState({ files: [...this.state.files, ...files] });
    setImages((prev) => [...prev, ...files]);
  };

  const onRemoveImage = (i, file) => {
    // let newFiles = images?.filter((f) => images.indexOf(f) !== i);
    if (file?.id) {
      onDelete(file?.id);
      // deleteData({ productId: item?._id, pictureId: file?._id });
    } else {
      const newArr = [...images];
      newArr.splice(i, 1);
      setImages(newArr);
    }
  };
  return (
    <div className="mt-3">
      <p className="consigne-title-steps mb-5">Ajouter des images</p>

      <Dropzone onDrop={onDrop}>
        {({ getRootProps, getInputProps }) => (
          <section className="mb-5">
            <div
              {...getRootProps({
                className: "dropzone container-dropzone-import",
              })}
            >
              <input {...getInputProps()} name="image" />
              <p>Parcourir ou deposer des images</p>
              <p className="fw-600 fs-14">Taille des images 500X500</p>
            </div>
          </section>
        )}
      </Dropzone>
      {errors?.image && (
        <Message
          className="alert-rsuite-custom"
          type="error"
          description={errors?.image?.message}
        />
      )}

      <div className="row px-2">
        {images?.length > 0
          ? images?.map((file, i) => (
              <div
                className="col-xl-3 col-lg-4 col-md-3 col-sm-4 col-6 px-2 mb-3 dis-flex pos-relative"
                key={i}
              >
                <div
                  className="image-product-upload"
                  style={{
                    background: `url(${
                      file?.image_medium
                        ? baseUrl + file.image_medium
                        : URL.createObjectURL(file)
                    })`,
                  }}
                ></div>
                <span
                  className="btn-remove-image-product-upload"
                  onClick={() => onRemoveImage(i, file)}
                >
                  <FeatherIcon
                    icon="trash-2"
                    className="icon-remove-image-product-upload"
                  />
                </span>
              </div>
            ))
          : null}
      </div>
    </div>
  );
}

export default ImagesProduct;
