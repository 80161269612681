import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { useAddOrEditUserMutation } from "../../../utils/api/user.api";
import { useSelector } from "react-redux";
import { cleannerError } from "../../../utils/utils";

function UseEditUserInfos() {
  const validationSchema = yup.object().shape({
    last_name: yup.string().required().label("Le nom"),
    first_name: yup.string().required().label("Le prénom"),
    phone: yup.string().required().label("Le N° téléphone"),
    email: yup.string().email().required().label("L'email"),
    adresse: yup.string().required().label("L'adresse"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [addOrUpdateUser, { isLoading, isSuccess, error, isError }] =
    useAddOrEditUserMutation();
  const { user } = useSelector((s) => s?.user);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Les informations ont été modifiées avec succès!`,
        iconColor: "#c501ff",
        showConfirmButton: false,
        timer: 2000,
      });
    }
    const err = error;
    if (isError) {
      if (err?.data?.email?.includes("user with this email already exists.")) {
        Swal.fire({
          icon: "error",
          title: "Un utilisateur avec cet email exite déjà!",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Erreur de statut ${err?.status}`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [isLoading]);

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (user?.id) {
      // console.log("user", user);
      let fields = ["last_name", "first_name", "phone", "email", "adresse"];

      for (let field of fields) {
        register(field);

        setValue(field, user[field]);
      }
    }
  }, [user]);

  const onSubmit = async (data) => {
    const fd = new FormData();

    for (let key of Object.keys(data)) {
      if (key === "avatar") {
        if (data[key]) {
          fd.append(key, data[key]);
        }
      } else {
        fd.append(key, data[key]);
      }
    }
    await addOrUpdateUser({
      slug: user?.id,
      data: fd,
    });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
  };
}

export default UseEditUserInfos;
