import React, { Component, useEffect, useState } from "react";
import "./contentProductDetails.css";
import FeatherIcon from "feather-icons-react";
import { NavLink, withRouter } from "react-router-dom";
import ReactAudioPlayer from "react-audio-player";
import baseUrl from "../../http/backend-base-url";
import MandatoryLogin from "../mandatoryLogin/index";
import ImageGallery from "react-image-gallery";
import { Rate, Message, Modal } from "rsuite";
import api from "../../http/global-vars";
import axios from "axios";
import { addToCart } from "../reduxCart/cartActions";
import { connect, useDispatch, useSelector } from "react-redux";
import ContentProductDetailsSkeleton from "../skeleton/contentProductDetailsSkeleton/contentProductDetailsSkeleton";
import EvaluateProductModal from "../evaluateProductModal/index";
import MandatoryLoginEvaluateProduct from "../mandatoryLoginEvaluateProduct/index";
import ConfirmationIcon from "../../img/icons/check-icon.png";
import "react-image-gallery/styles/css/image-gallery.css";
import { isAdmin } from "../../utils/utils";
import {
  addCartProduct,
  getCartCount,
  getSubTotal,
  getTotalAmount,
} from "../../utils/api/slice/useCartSlice";
import Swal from "sweetalert2";
import {
  useAddOrDeleteFavoriteMutation,
  useLazyGetFavoriteListQuery,
} from "../../utils/api/favoris.api";
import { useProductFromLocation } from "../../utils/api/product.api";

function ContentProductDetails() {
  const [product, isLoading, id, findById] = useProductFromLocation();
  const [imagesProducts, setImagesProducts] = useState([]);
  const [size, setSize] = useState("");
  const [pointure, setPointure] = useState("");
  const [show, setShow] = useState(false);
  const [evaluationProducts, setEvaluationProducts] = useState([]);
  const { user, token } = useSelector((s) => s?.user);
  const dispatch = useDispatch();
  const [toggle, { isSuccess, isError, error }] =
    useAddOrDeleteFavoriteMutation();
  const [getFavorite, { data: favorites = [], isLoading: loading }] =
    useLazyGetFavoriteListQuery();
  let item = favorites?.find((el) => parseInt(el?.product?.id) === product?.id);

  useEffect(() => {
    if (user?.id) {
      getFavorite(user?.id);
    }
  }, [user]);

  useEffect(() => {
    onGetEvaluationByProduct();
  }, [id]);

  useEffect(() => {
    // window.scFade(0, 10);
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: item
          ? `Ce produit a été supprimé de vos favories avec succès!`
          : `Ce produit a été ajouté à vos favories avec succès!`,
        iconColor: "#c501ff",
        showConfirmButton: false,
        timer: 3000,
      });
    }

    if (isError) {
      Swal.fire({
        icon: "error",
        title: error?.data?.message
          ? error?.data?.message
          : `Une erreur de statut ${error?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess]);

  const onGetEvaluationByProduct = () => {
    if (id && id !== undefined) {
      axios
        .get(api + `commentairebyproduct/${id}/`)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            setEvaluationProducts(response.data);
          }
        })
        .catch((error) => {
          if (error) {
            console.log("error", error);
          }
        });
    }
  };

  const closeConformation = () => {
    setShow(false);
  };
  const openConfirmation = () => {
    setShow(true);
  };

  const handleClick = (e, product) => {
    e.preventDefault();
    // dispatch(addToCart(product));
    dispatch(
      addCartProduct({
        product,
        qty: 1,
        selectedPointure: pointure || "",
        selectedSize: size || "",
      })
    );
    dispatch(getCartCount());
    dispatch(getSubTotal());
    // dispatch(calculateTax());
    dispatch(getTotalAmount());

    openConfirmation();
    setTimeout(() => {
      closeConformation();
    }, 10000);
  };

  useEffect(() => {
    if (product?.image?.length) {
      setImagesProducts(
        product?.image?.map((el) => {
          return {
            original: baseUrl + el?.image_high,
            thumbnail: baseUrl + el?.image_small,
          };
        })
      );
    } else {
      setImagesProducts([]);
    }
  }, [item]);

  const handleToggle = async () => {
    await toggle({ id: item?.id, product: product?.id, user: user?.id });
  };
  return (
    <>
      {!!isLoading && <ContentProductDetailsSkeleton />}
      {!isLoading && (
        <div className="content-details-product p-lg-5 p-md-5 p-4 m-b-15">
          <div className="row m-b-100">
            <div className="col-xl-6 col-lg-7 col-md-6 m-b-50">
              <ImageGallery
                items={imagesProducts}
                loading="lazy"
                // showIndex={0}
                infinite
              />
            </div>
            <div className="col-xl-6 col-lg-5 col-md-6">
              <div className="row px-2 mb-3">
                <div className="col-xl-6 col-lg-7 col-md-7 col-sm-6 px-2 mb-2">
                  <NavLink
                    to={{
                      pathname: isAdmin(user)
                        ? `/details-boutique-admin/${product?.provider?.id}`
                        : parseInt(user?.id) !==
                          parseInt(product?.provider?.user)
                        ? `/boutique/${product?.provider?.id}`
                        : "",
                      state: product?.provider,
                    }}
                    className="name-shop-content-details-product h-theme no-link"
                  >
                    <FeatherIcon
                      className="icon-name-shop-content-details-product"
                      icon="home"
                    />
                    {product?.provider?.shop_name}
                  </NavLink>
                </div>
                <div className="col-xl-3 col-lg-5 col-md-5 col-sm-3 mb-2 px-2">
                  {parseInt(user?.id) ===
                  parseInt(product?.provider?.user) ? null : (
                    <NavLink
                      className="link-name-shop-content-details-product"
                      to={{
                        pathname: isAdmin(user)
                          ? `/details-boutique-admin/${product?.provider?.id}`
                          : parseInt(user?.id) !==
                            parseInt(product?.provider?.user)
                          ? `/boutique/${product?.provider?.id}`
                          : "",
                        state: product?.provider,
                      }}
                    >
                      Visiter la boutique
                    </NavLink>
                  )}
                </div>
              </div>

              <p className="name-product-content-details-product">
                {product?.name}
              </p>

              <Rate
                value={product?.averagestar}
                readOnly
                allowHalf
                className="rating-custom-medium"
              />

              <div className="row">
                <div className="col-lg-8 col-md-8 col-sm-6 m-b-15">
                  <p className="price-content-details-product">
                    {Intl.NumberFormat("fr-FR").format(
                      parseInt(product?.price)
                    )}{" "}
                    <span className="fw-300">FCFA</span>
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-8 col-lg-10 pr-md-0 col-md-11 col-sm-8">
                  {product?.quantity <= 0 ? (
                    <p className="product-quantity-message mb-4">
                      Produit indisponible!
                    </p>
                  ) : (
                    <p className="product-quantity-message mb-4">
                      {product?.quantity} exemplaires disponible(s)
                    </p>
                  )}
                  {!!product?.pointures && product?.pointure && (
                    <div class="content-sizing">
                      {/* <h5 className="text-theme">Pointure:</h5> */}
                      <div>
                        <select
                          className="form-select border-sizing px-3"
                          onChange={(e) => setPointure(e?.target?.value)}
                        >
                          <option value="">Pointure</option>
                          {!!product?.pointures?.split(",")?.length &&
                            product?.pointures
                              ?.split(",")
                              ?.sort((a, b) => parseInt(a) - parseInt(b))
                              ?.map((t, i) => (
                                <option value={t} key={i}>
                                  {t}
                                </option>
                              ))}
                        </select>
                      </div>
                    </div>
                  )}

                  {!!product?.tailles && product?.taille && (
                    <div class="d-flex al">
                      {/* <h5 className="text-theme">Taille:</h5> */}
                      <div>
                        <select
                          className="form-select border-sizing"
                          onChange={(e) => setSize(e?.target?.value)}
                        >
                          <option value="">Taille</option>
                          {!!product?.tailles?.split(",")?.length &&
                            product?.tailles?.split(",")?.map((t, i) => (
                              <option value={t} key={i}>
                                {t}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  )}

                  <h5 className="text-theme mt-5 mb-3">Description</h5>

                  {product?.description_audio ? (
                    <ReactAudioPlayer
                      className="audio-lecteur-content"
                      src={`${baseUrl}${product?.description_audio}`}
                      controls
                    />
                  ) : (
                    <Message
                      type="warning"
                      className="fs-13"
                      description="Il n'y a pas de description audio pour ce produit!"
                    />
                  )}

                  <div className="m-t-40">
                    {parseInt(user?.id) === parseInt(product?.provider?.user) ||
                    isAdmin(user) ? null : user ? (
                      product?.quantity === 0 ? (
                        <div>
                          <button
                            disabled
                            className="btn-theme-add-to-cart m-b-75"
                          >
                            <FeatherIcon
                              className="icon-btn-theme-add-to-cart"
                              icon="shopping-cart"
                            />
                            Acheter
                          </button>
                        </div>
                      ) : (
                        <div>
                          <button
                            onClick={(e) => handleClick(e, product)}
                            className="btn-theme-add-to-cart m-b-75"
                          >
                            <FeatherIcon
                              className="icon-btn-theme-add-to-cart"
                              icon="shopping-cart"
                            />
                            Acheter
                          </button>
                        </div>
                      )
                    ) : (
                      <MandatoryLogin />
                    )}
                  </div>

                  <div className="ligne-theme"></div>
                  <h6 className="text-black ff-raleway fw-700 m-b-10">
                    Informations sur le vendeur
                  </h6>
                  <div className="row">
                    <div className="col-sm-7">
                      <NavLink
                        to={{
                          pathname: isAdmin(user)
                            ? `/details-boutique-admin/${product?.provider?.id}`
                            : parseInt(user?.id) !==
                              parseInt(product?.provider?.user)
                            ? `/boutique/${product?.provider?.id}`
                            : "",
                          state: product?.provider,
                        }}
                        className="name-info-vendeur-content-details-product fs-18 lh-1 no-link"
                      >
                        {product?.provider?.shop_name}
                      </NavLink>
                      <p className="name-info-vendeur-content-details-product fs-12 mb-3">
                        {product?.provider?.number_of_subscribers} abonnés
                      </p>
                    </div>
                    <div className="col-sm-5 px-md-0 dis-flex">
                      {parseInt(user?.id) ===
                      parseInt(product?.provider?.user) ? null : (
                        <NavLink
                          className="see-shop-info-vendeur-content-details-product ml-md-auto"
                          to={{
                            pathname: isAdmin(user)
                              ? `/details-boutique-admin/${product?.provider?.id}`
                              : parseInt(user?.id) !==
                                parseInt(product?.provider?.user)
                              ? `/boutique/${product?.provider?.id}`
                              : "",
                            state: product?.provider,
                          }}
                        >
                          Voir sa boutique
                          <FeatherIcon
                            className="icon-see-shop-info-vendeur-content-details-product ml-xl-3 ml-md-1"
                            icon="arrow-right"
                          />
                        </NavLink>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-md-5">
            <div className="col-md-11">
              <h5 className="h-theme">Détails</h5>
              <p
                className="details-content-details-product"
                dangerouslySetInnerHTML={{ __html: product?.description }}
              />
            </div>
          </div>

          <div className="my-5">
            <h5 className="h-theme mb-3">Avis du produit</h5>
            {!evaluationProducts?.length ? (
              <div className="pl-0 mb-3 col-xl-6 col-lg-6 col-md-6 col-sm-8">
                <div className="alert alert-info marketplace-alert-info empty-badge-alert">
                  <div className="row empty-alert-row">
                    <div className="col-2 empty-icon-col">
                      <FeatherIcon
                        icon="alert-circle"
                        className="m-b-3 empty-alert-icon rotateIn"
                      />
                    </div>
                    <div className="col-10 empty-textual-col">
                      <p> Ce produit n'a pas encore été évaluer!</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="mb-3">
                {evaluationProducts?.map((evaluation) => (
                  <div
                    className="pl-0 mb-3 col-xl-4 col-lg-6 col-md-6 col-sm-8"
                    key={evaluation.id}
                  >
                    <p className="fullname-user-evaluation">
                      {evaluation.user.first_name +
                        " " +
                        evaluation.user.last_name}
                    </p>
                    <Rate
                      value={evaluation.star}
                      allowHalf
                      readOnly
                      className="rating-user-evaluation"
                    />
                    <p className="commentaire-user-evaluation">
                      {evaluation.text}
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="m-t-60">
            {parseInt(user?.id) === parseInt(product?.provider?.user) ||
            isAdmin(user) ? null : token ? (
              !!product?.id && (
                <EvaluateProductModal
                  product={product}
                  getProductById={findById}
                  onGetEvaluationByProduct={onGetEvaluationByProduct}
                />
              )
            ) : (
              <MandatoryLoginEvaluateProduct />
            )}
          </div>
          {/* Modal ajout panier  */}
          <div className="modal-container">
            <Modal
              size="xs"
              show={show}
              onHide={closeConformation}
              className="rsuite-content-modal-custom"
            >
              <div>
                <Modal.Header>
                  <Modal.Title></Modal.Title>
                </Modal.Header>
                <div className="my-5 flex-col itm-center">
                  <img
                    loading="lazy"
                    src={ConfirmationIcon}
                    alt=""
                    className="icon-confirmation-modal mb-4"
                  />
                  <p className="title-confirmation-modal my-4">
                    Le produit a bien été ajouté au panier
                  </p>
                </div>
                <Modal.Footer className="text-center">
                  <div className="row px-5">
                    <div className="col-12 mb-2">
                      <button
                        onClick={() => closeConformation()}
                        className="btn-change-password"
                      >
                        Continuer mes achats
                      </button>
                    </div>
                    <div className="col-12 mb-3">
                      <NavLink to="/checkout" className="btn-light-theme">
                        Valider ma commande
                      </NavLink>
                    </div>
                  </div>
                </Modal.Footer>
              </div>
            </Modal>
          </div>
        </div>
      )}
    </>
  );
}

export default ContentProductDetails;
