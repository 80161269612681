import React, { Component } from "react";
import "./connexion.css";
import Header from "../header/index";
import Footer from "../footer/index";
import { NavLink } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import api from "../../http/global-vars";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loader, Message } from "rsuite";
import SocialLogin from "../socialLogin/index";
import ModalShopDeleted from "../modalShopDeleted/index";
import Swal from "sweetalert2";
import UseLoginForm from "./form/UseLoginForm";

// export default class connexion extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       token: window.localStorage.getItem("userToken"),
//       requestConfig: {
//         headers: {
//           Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
//         },
//       },
//       userID: window.localStorage.getItem("userID"),
//       api: api,
//       fields: {},
//       errors: {},
//       loginInProgress: false,
//       isSuccessLogin: false,
//       isErrorLogin: false,
//       is_empty_email: false,
//       is_empty_password: false,
//       path: "",
//       shopDetails: [],
//       userInfos: [],
//       showShopDeleted: false,
//       showShopDiseabled: false,
//       step: 0,
//     };
//     this.handleChange = this.handleChange.bind(this);
//   }

//   componentDidMount() {
//     let path = window.location.search.split("?next=")[1];
//     this.setState({ path });
//     window.scrollTo({ top: 0, behavior: "smooth" });
//   }

//   handleChange = (e) => {
//     let fields = this.state.fields;
//     fields[e.target.name] = e.target.value;

//     this.setState({
//       fields: fields,
//     });
//   };

//   onValidateFormData = () => {
//     let fields = this.state.fields;
//     let errors = this.state.errors;
//     var isValidForm = true;

//     if (!fields["username"]) {
//       isValidForm = false;
//       this.setState({
//         is_empty_email: true,
//       });
//       setTimeout(() => {
//         this.setState({
//           is_empty_email: false,
//         });
//       }, 5000);

//       errors["username"] =
//         "Veuillez renseigner votre N° Téléphone ou votre adresse e-mail!";
//     }

//     if (!fields["password"]) {
//       isValidForm = false;
//       this.setState({
//         is_empty_password: true,
//       });
//       errors["password"] = "Veuillez renseigner votre code!";
//       setTimeout(() => {
//         this.setState({
//           is_empty_password: false,
//         });
//       }, 5000);
//     }

//     this.setState({
//       errors: errors,
//     });

//     return isValidForm;
//   };

//   onSubmitConnexion = (e) => {
//     e.preventDefault();

//     if (this.onValidateFormData()) {
//       this.setState({
//         loginInProgress: true,
//       });

//       var api = this.state.api;
//       var data = this.state.fields;

//       axios
//         .post(api + "auth/connexion/", data)
//         .then((response) => {
//           console.log("la reponse", response);
//           //   debugger;
//           if (response.status === 200 || response.status === 201) {
//             if (response?.data?.message) {
//               toast.error(response?.data?.message);
//             } else {
//               window.localStorage.setItem("userToken", response.data.token);
//               var token = response.data.token;
//               let requestConfig = {
//                 headers: { Authorization: `Bearer ${token}` },
//               };
//               this.onGetConnectedUserInfos(requestConfig);
//             }
//           } else {
//             toast.error(
//               "Une erreur est survenue lors de votre connexion! Veuillez réessayer."
//             );
//             this.setState({
//               isErrorLogin: true,
//             });
//             setTimeout(() => {
//               this.setState({
//                 isErrorLogin: false,
//               });
//             }, 5000);
//           }
//         })
//         .catch((error) => {
//           console.log("erreur", error.response);
//           // debugger;
//           if (
//             error?.response?.data?.message ===
//             "Votre compte n'est validé. Veuillez valider votre compte."
//           ) {
//             Swal.fire({
//               icon: "warning",
//               title: "Votre numéro de téléphone n'a pas été validé!",
//               text: "Veuillez confirmer votre numéro de téléphone pour pouvoir vous connecter",
//               // iconColor: "#c501ff",
//               showConfirmButton: true,
//               confirmButtonText: "Confirmer",
//               confirmButtonColor: "#c501ff",
//               // timer: 1500,
//             }).then((results) => {
//               if (results?.isConfirmed) {
//                 // window.location = "confirm-phone";
//                 // history.push("/confirm-phone");
//                 this.props.history.replace({
//                   pathname: "/confirm-phone",
//                   // search: searchParams.toString(),
//                   state: data,
//                 });
//               }
//             });
//           } else if (
//             error?.response?.data?.message === "User doesn't exist" ||
//             error?.response?.data?.message === "Email doesn't exist"
//           ) {
//             toast.error(
//               "L'adresse e-mail ou le mot de passe n'existe pas! Veuillez verifier puis réessayer plus tard."
//             );
//             this.setState({
//               loginInProgress: false,
//               isErrorLogin: true,
//             });
//             setTimeout(() => {
//               this.setState({
//                 isErrorLogin: false,
//               });
//             }, 15000);
//           } else if (
//             error?.response?.data?.message === "This shop  is archived"
//           ) {
//             toast.error(
//               "Votre boutique n'existe plus! Veuillez contactez l'administrateur."
//             );
//             this.setState({
//               loginInProgress: false,
//               isErrorLogin: true,
//             });
//             setTimeout(() => {
//               this.setState({
//                 isErrorLogin: false,
//               });
//             }, 15000);
//           } else if (
//             error?.response?.data?.message === "This shop  is not active"
//           ) {
//             toast.error(
//               "Votre boutique à été desactivé ! Veuillez contacter l'administrateur."
//             );
//             this.setState({
//               loginInProgress: false,
//               isErrorLogin: true,
//             });
//             setTimeout(() => {
//               this.setState({
//                 isErrorLogin: false,
//               });
//             }, 15000);
//           } else if (error?.response?.data?.message === "Password incorrect") {
//             toast.error("Votre mot de passe est incorrecte");
//             this.setState({
//               loginInProgress: false,
//               isErrorLogin: true,
//             });
//             setTimeout(() => {
//               this.setState({
//                 isErrorLogin: false,
//               });
//             }, 15000);
//           } else if (error?.response?.data?.message) {
//             toast.error(error?.response?.data?.message);
//             this.setState({
//               loginInProgress: false,
//               isErrorLogin: true,
//             });
//             setTimeout(() => {
//               this.setState({
//                 isErrorLogin: false,
//               });
//             }, 15000);
//           } else {
//             toast.error(
//               "Une erreur est survenue lors de la connexion ! Veuillez contacter l'administrateur."
//             );
//             this.setState({
//               loginInProgress: false,
//               isErrorLogin: true,
//             });
//             setTimeout(() => {
//               this.setState({
//                 isErrorLogin: false,
//               });
//             }, 15000);
//           }
//         });
//     }
//   };

//   onGetShopInfos = (config, id) => {
//     this.setState({
//       loading: true,
//     });
//     var api = this.state.api;
//     var userID = this.state.userID;
//     axios
//       .get(api + `fournisseurbyuser/${id}/`, config)
//       .then((response) => {
//         if (response.status === 200 || response.status === 201) {
//           if (response.data[0]?.is_active) {
//             this.setState({
//               shopDetails: response.data[0],
//               userInfos: response.data[0].user,
//               loading: false,
//             });
//             window.location = "/dashboard-vendeur";
//           } else {
//             window.localStorage.setItem("userType", "standard");
//             toast.success("/");
//           }

//           this.setState({
//             isLoginInProgress: false,
//           });
//         }
//       })
//       .catch((error) => {
//         if (error) {
//           this.setState({
//             loading: false,
//           });
//         }
//       });
//   };

//   onGetConnectedUserInfos = async (config) => {
//     var api = this.state.api;
//     await axios
//       .get(api + "me/", config)
//       .then(async (response) => {
//         if (response.status === 200) {
//           window.localStorage.setItem("userID", response.data.data.id);
//           window.localStorage.setItem(
//             "userFirstName",
//             response.data.data.first_name
//           );
//           window.localStorage.setItem(
//             "userLastName",
//             response.data.data.last_name
//           );
//           window.localStorage.setItem(
//             "userFullname",
//             response.data.data.first_name + " " + response.data.data.last_name
//           );
//           window.localStorage.setItem("userEmail", response.data.data.email);
//           window.localStorage.setItem("userType", response.data.data.user_type);
//           var userType = response.data.data.user_type;

//           toast.success("Connexion réussie !");
//           this.setState({
//             isSuccessLogin: true,
//           });

//           setTimeout(() => {
//             this.setState({
//               isSuccessLogin: false,
//             });
//             if (
//               this.state.path !== undefined &&
//               this.state.path !== "/connexion"
//             ) {
//               window.location = this.state.path;
//             } else {
//               if (userType === "admin") {
//                 window.location = "/dashboard-admin";
//                 this.setState({
//                   isLoginInProgress: false,
//                 });
//               }
//               if (userType === "vendeur") {
//                 // window.location = "/dashboard-vendeur";
//                 this.onGetShopInfos(config, response.data.data.id);
//               }
//               if (userType === "usager") {
//                 window.location = "/";
//                 this.setState({
//                   isLoginInProgress: false,
//                 });
//               }
//             }
//           }, 4000);
//         }
//       })
//       .catch((error) => {
//         toast.error(
//           "Oupsss! Une erreur est survenue lors de votre connexion. Veuillez réessayer plus tard."
//         );
//         this.setState({
//           isErrorLogin: true,
//           isLoginInProgress: false,
//         });

//         setTimeout(() => {
//           this.setState({
//             isErrorLogin: false,
//           });
//         }, 5000);
//       });
//   };

//   onLogoutUser = () => {
//     window.localStorage.removeItem("userToken");
//     // setTimeout(() => {
//     // window.location = '/connexion'
//     // }, 500);
//   };

//   closeShopDeleted = (e) => {
//     // e.preventDefault();
//     this.setState({ showShopDeleted: false });
//   };
//   openShopDeleted = (e) => {
//     this.setState({ showShopDeleted: true });
//   };

//   render() {
//     return (
//       <div>
//         <Header />
//         <div className="p-t-40 p-b-150 fixed-after-header">
//           <div className="container">
//             <p className="m-b-40">
//               <NavLink to="/" className="routing-link">
//                 Accueil
//               </NavLink>{" "}
//               / <span className="routing-link">Connexion</span>
//             </p>
//             <div className="col">
//               <div className="row">
//                 <div className="col-xl-6 col-lg-6 col-md-5 form-left">
//                   <h2 className="text-up text-white fw-600 text-center lh-1 m-b-0">
//                     connexion
//                   </h2>
//                 </div>
//                 <div className="col-xl-6 col-lg-6 col-md-7 form-right">
//                   <h2 className="fw-600 text-black m-b-70">Connectez-vous</h2>

//                   <form onSubmit={(e) => this.onSubmitConnexion(e)}>
//                     <div className="form-group form-group-custom-form">
//                       <input
//                         type="text"
//                         name="username"
//                         onChange={this.handleChange}
//                         className="form-control form-control-custom-form"
//                         placeholder="téléphone ou Adresse mail"
//                       />
//                       {this.state.is_empty_email === true && (
//                         <Message
//                           className="alert-rsuite-custom"
//                           type="error"
//                           description={this.state.errors.username}
//                         />
//                       )}
//                     </div>
//                     <div className="form-group form-group-custom-form">
//                       <input
//                         type="password"
//                         maxLength={30}
//                         name="password"
//                         onChange={this.handleChange}
//                         className="form-control form-control-custom-form"
//                         placeholder="Code secret"
//                       />
//                       {this.state.is_empty_password === true && (
//                         <Message
//                           className="alert-rsuite-custom"
//                           type="error"
//                           description={this.state.errors.password}
//                         />
//                       )}
//                     </div>
//                     {/* <div className="form-group form-group-custom-form d-flex justify-content-end align-items-center">
//                       <NavLink
//                         to="/reset-password"
//                         className="link-form-signup-login"
//                       >
//                         Mot de passe oublié ?
//                       </NavLink>
//                     </div> */}

//                     <div className="form-group form-group-custom-form d-flex justify-content-end align-items-center">
//                       <NavLink
//                         to="/reset-password"
//                         className="link-form-signup-login"
//                       >
//                         Mode de passe oublié ?
//                       </NavLink>
//                     </div>

//                     {this.state.loginInProgress === false ? (
//                       <button type="submit" className="btn-form-dark">
//                         Connexion
//                       </button>
//                     ) : (
//                       <button
//                         type="submit"
//                         disabled
//                         className="btn-form-dark in-progress-btn"
//                       >
//                         Connexion en cours &nbsp;
//                         <Loader />
//                       </button>
//                     )}
//                   </form>

//                   <SocialLogin
//                     onGetConnectedUserInfos={(e) =>
//                       this.onGetConnectedUserInfos(e)
//                     }
//                   />

//                   <p className="fs-14 fw-300 lh-1">Première visite ?</p>
//                   <p className=" fs-14 fw-300 lh-1 m-b-30">
//                     <NavLink
//                       to="/inscription"
//                       className="link-form-signup-login"
//                     >
//                       Inscrivez-vous{" "}
//                     </NavLink>
//                     en quelques clics !
//                   </p>

//                   <NavLink to="/inscription" className="btn-form-light">
//                     S’INSCRIRE
//                   </NavLink>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <Footer />

//         {this.state.isSuccessLogin && <ToastContainer className="fs-13" />}
//         {this.state.isErrorLogin && <ToastContainer className="fs-13" />}

//         {/* Modal Boutique n'existe pas  */}
//         <ModalShopDeleted
//           openShopDeleted={this.openShopDeleted}
//           closeShopDeleted={this.closeShopDeleted}
//           showShopDeleted={this.state.showShopDeleted}
//         />
//       </div>
//     );
//   }
// }

function Connexion() {
  const { register, errors, isLoading, onSubmit } = UseLoginForm();
  return (
    <div>
      <Header />
      <div className="p-t-40 p-b-150 fixed-after-header">
        <div className="container">
          <p className="m-b-40">
            <NavLink to="/" className="routing-link">
              Accueil
            </NavLink>{" "}
            / <span className="routing-link">Connexion</span>
          </p>
          <div className="col">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-5 form-left">
                <h2 className="text-up text-white fw-600 text-center lh-1 m-b-0">
                  connexion
                </h2>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-7 form-right">
                <h2 className="fw-600 text-black m-b-70">Connectez-vous</h2>

                <form onSubmit={onSubmit}>
                  <div className="form-group form-group-custom-form">
                    <input
                      type="text"
                      {...register("username")}
                      className="form-control form-control-custom-form"
                      placeholder="téléphone ou Adresse mail"
                    />
                    {errors?.username && (
                      <Message
                        className="alert-rsuite-custom"
                        type="error"
                        description={errors?.username?.message}
                      />
                    )}
                  </div>
                  <div className="form-group form-group-custom-form">
                    <input
                      type="password"
                      maxLength={30}
                      {...register("password")}
                      className="form-control form-control-custom-form"
                      placeholder="Code secret"
                    />
                    {errors?.password && (
                      <Message
                        className="alert-rsuite-custom"
                        type="error"
                        description={errors?.password?.message}
                      />
                    )}
                  </div>
                  {/* <div className="form-group form-group-custom-form d-flex justify-content-end align-items-center">
                      <NavLink
                        to="/reset-password"
                        className="link-form-signup-login"
                      >
                        Mot de passe oublié ?
                      </NavLink>
                    </div> */}

                  <div className="form-group form-group-custom-form d-flex justify-content-end align-items-center">
                    <NavLink
                      to="/reset-password"
                      className="link-form-signup-login"
                    >
                      Mode de passe oublié ?
                    </NavLink>
                  </div>

                  {isLoading === false ? (
                    <button type="submit" className="btn-form-dark">
                      Connexion
                    </button>
                  ) : (
                    <button
                      type="submit"
                      disabled
                      className="btn-form-dark in-progress-btn"
                    >
                      Connexion en cours &nbsp;
                      <Loader />
                    </button>
                  )}
                </form>

                <SocialLogin />

                <p className="fs-14 fw-300 lh-1">Première visite ?</p>
                <p className=" fs-14 fw-300 lh-1 m-b-30">
                  <NavLink to="/inscription" className="link-form-signup-login">
                    Inscrivez-vous{" "}
                  </NavLink>
                  en quelques clics !
                </p>

                <NavLink to="/inscription" className="btn-form-light">
                  S’INSCRIRE
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      {/* Modal Boutique n'existe pas  */}
      {/* <ModalShopDeleted
        openShopDeleted={this.openShopDeleted}
        closeShopDeleted={this.closeShopDeleted}
        showShopDeleted={this.state.showShopDeleted}
      /> */}
    </div>
  );
}

export default Connexion;
