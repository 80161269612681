import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import api from "../../http/global-vars";
import { QueryUrl } from "../utils";
import { prepareHeaders } from "./user.api";

export const CategoryApi = createApi({
  reducerPath: "categoryApi",
  tagTypes: ["categoryList"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${api}`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getCategoryList: builder.query({
      providesTags: ["categoryList"],
      query: (query) => QueryUrl(`category`, query),
    }),
  }),
});

export const { useGetCategoryListQuery } = CategoryApi;
