import React, { useState } from "react";
import "./articlesDashboardAdmin.css";
import HeaderDashboard from "../headerDashboard/index";
import NavVerticalDashboardAdmin from "../navVerticalDashboardAdmin/index";
import TableArticles from "../tableArticles/index";

function ArticlesDashboardAdmin() {
  const [filter, setFilter] = useState("")
  return (
    <div className="component-articles-dashboard-admin">
      <HeaderDashboard />
      <div className="fixed-after-header-dashboard container-fluid">
        <div className="row">
          <div className="col-xl-2 col-lg-3 no-view-tablette">
            <NavVerticalDashboardAdmin />
          </div>
          <div className="col-xl-10 col-lg-9 px-xl-3 py-4 pr-lg-3 pl-lg-1">
            <div className="card-dashboard-content-table">
              <div className="head-card-dashboard-content-table">
                <div className="row mb-5">
                  <div className="col-md-9 col-sm-8 mb-sm-0 mb-3">
                    <p className="title-filter-dashboard">Tous les produits</p>
                  </div>
                  <div className="col-md-3 col-sm-4 pl-sm-0"></div>
                </div>

                <div className="row">
                  <div className="col-xl-9">
                    <nav>
                      <div
                        className="nav nav-tabs nav-tabs-horizontal-commandes"
                        id="nav-tab"
                        role="tablist"
                      >
                        <button
                          className={`nav-link nav-horizontal-commandes ${
                            !filter ? "active" : ""
                          }`}
                          id="nav-tout-tab"
                          onClick={() => setFilter("")}
                        >
                          Tout
                        </button>
                        <button
                          className={`nav-link nav-horizontal-commandes ${
                            filter === "active" ? "active" : ""
                          }`}
                          id="nav-active-tab"
                          onClick={() => setFilter("active")}
                        >
                          Activées
                        </button>
                        <button
                          className={`nav-link nav-horizontal-commandes ${
                            filter === "desactive" ? "active" : ""
                          }`}
                          id="nav-desactive-tab"
                          onClick={() => setFilter("desactive")}
                        >
                          Desactivées
                        </button>
                        <button
                          className={`nav-link nav-horizontal-commandes ${
                            filter === "rupture" ? "active" : ""
                          }`}
                          id="nav-en-rupture-tab"
                          onClick={() => setFilter("rupture")}
                        >
                          En rupture
                        </button>
                        <button
                          className={`nav-link nav-horizontal-commandes ${
                            filter === "best" ? "active" : ""
                          }`}
                          id="nav-top-produits-tab"
                          onClick={() => setFilter("best")}
                        >
                          Meilleurs produits
                        </button>
                      </div>
                    </nav>
                  </div>
                  <div className="col-xl-3"></div>
                </div>
              </div>
              <div className="content-card-dashboard-content-table">
                <div className="tab-content" id="nav-tabContent">
                  <div className="tab-pane fade show active">
                    <TableArticles filter={filter} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArticlesDashboardAdmin;
