import React, { Component, useState } from "react";
import "./commandesDashboard.css";
import HeaderDashboard from "../headerDashboard/index";
import NavVerticalDashboard from "../navVerticalDashboard/index";
import TableCommande from "../tableCommande/index";
import TableCommandePlaced from "../tableCommande/tableCommandePlaced";
import api from "../../../http/global-vars";
import axios from "axios";
// import _ from 'lodash';
import CommandeSkeleton from "../backSkeleton/CommandeSkeleton";
import { connect, useSelector } from "react-redux";
import { fetchOrdersByShop } from "../../../orderAction/orderAction";
import { useGetOrderBySellerQuery } from "../../../utils/api/order.api";

// class commandesDashboard extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       token: window.localStorage.getItem("userToken"),
//       requestConfig: {
//         headers: {
//           Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
//         },
//       },
//       userID: window.localStorage.getItem("userID"),
//       api: api,
//       commandes: [],
//       loading: false,
//       shopDetails: [],
//     };
//   }

//   componentDidMount() {
//     var config = this.state.requestConfig;
//     this.onGetShopInfos(config);
//     this.setState({ loading: true });
//   }

//   onGetShopInfos = (config) => {
//     this.setState({
//       loading: true,
//     });
//     var api = this.state.api;
//     var userID = this.state.userID;
//     axios
//       .get(api + `fournisseurbyuser/${userID}/`, config)
//       .then((response) => {
//         if (response.status === 200 || response.status === 201) {
//           this.props.fetchOrdersByShop(response.data[0]?.id);
//           this.setState({
//             shopDetails: response.data[0],
//             userInfos: response.data[0].user,
//             loading: false,
//           });
//           setTimeout(() => {
//             this.setState({
//               loading: false,
//             });
//           }, 1000);
//         }
//       })
//       .catch((error) => {
//         if (error) {
//           this.setState({
//             loading: false,
//           });
//         }
//       });
//   };

//   onGetOrdersByShop = () => {
//     this.setState({ loading: true });
//     var api = this.state.api;
//     var config = this.state.requestConfig;
//     var shopID = this.state.shopDetails.id;

//     axios
//       .get(api + `orderbyfournisseur/${shopID}/`, config)
//       .then((response) => {
//         if (response.status === 200 || response.status === 201) {
//           this.setState({
//             loading: false,
//           });
//         }
//       })
//       .catch((error) => {
//         this.setState({ loading: false });
//       });
//   };

//   render() {
//     let allOrders = this.props.commandes?.reverse();
//     let orderPlaced = this.props.commandes
//       ?.filter((f) => f.status === "placed")
//       .reverse();
//     let orderOnReady = this.props.commandes
//       ?.filter((f) => f.status === "ready")
//       .reverse();
//     let orderOnShipped = this.props.commandes
//       ?.filter((f) => f.status === "shipped")
//       .reverse();
//     let orderOnDelivred = this.props.commandes
//       ?.filter((f) => f.status === "delivered")
//       .reverse();
//     let orderOnCanceled = this.props.commandes
//       ?.filter((f) => f.status === "canceled")
//       .reverse();
//     let orderOnReturned = this.props.commandes
//       ?.filter((f) => f.status === "returned")
//       .reverse();
//     let orderOnFailed = this.props.commandes
//       ?.filter((f) => f.status === "failed")
//       .reverse();

//     return (
//       <div className="p-b-200">
//         <HeaderDashboard />
//         <div className="fixed-after-header-dashboard container-fluid">
//           <div className="row">
//             <div className="col-xl-2 col-lg-3 no-view-tablette">
//               <NavVerticalDashboard />
//             </div>
//             <div className="col-xl-10 col-lg-9 px-xl-3 py-4 pr-lg-3 pl-lg-1">
//               <div className="card-dashboard-content-table">
//                 <div className="head-card-dashboard-content-table">
//                   <p className="title-filter-dashboard mb-5">Commandes</p>

//                   <div className="row">
//                     <div className="col-xl-9">
//                       <nav>
//                         <div
//                           className="nav nav-tabs nav-tabs-horizontal-commandes"
//                           id="nav-tab"
//                           role="tablist"
//                         >
//                           <a
//                             className="nav-link nav-horizontal-commandes active"
//                             id="nav-tout-tab"
//                             data-toggle="tab"
//                             href="#nav-tout"
//                             role="tab"
//                             aria-controls="nav-tout"
//                             aria-selected="true"
//                           >
//                             Tout
//                           </a>
//                           <a
//                             className="nav-link nav-horizontal-commandes"
//                             id="nav-en-attente-tab"
//                             data-toggle="tab"
//                             href="#nav-en-attente"
//                             role="tab"
//                             aria-controls="nav-en-attente"
//                             aria-selected="false"
//                           >
//                             En attente{" "}
//                             <span class="badge badge-theme">
//                               {orderPlaced?.length}
//                             </span>
//                           </a>
//                           <a
//                             className="nav-link nav-horizontal-commandes"
//                             id="nav-pret-a-livrer-tab"
//                             data-toggle="tab"
//                             href="#nav-pret-a-livrer"
//                             role="tab"
//                             aria-controls="nav-pret-a-livrer"
//                             aria-selected="false"
//                           >
//                             Prêt à livré
//                           </a>
//                           <a
//                             className="nav-link nav-horizontal-commandes"
//                             id="nav-en-livraison-tab"
//                             data-toggle="tab"
//                             href="#nav-en-livraison"
//                             role="tab"
//                             aria-controls="nav-en-livraison"
//                             aria-selected="false"
//                           >
//                             En livraison
//                           </a>
//                           <a
//                             className="nav-link nav-horizontal-commandes"
//                             id="nav-livree-tab"
//                             data-toggle="tab"
//                             href="#nav-livree"
//                             role="tab"
//                             aria-controls="nav-livree"
//                             aria-selected="false"
//                           >
//                             Livré
//                           </a>
//                           <a
//                             className="nav-link nav-horizontal-commandes"
//                             id="nav-annulee-tab"
//                             data-toggle="tab"
//                             href="#nav-annulee"
//                             role="tab"
//                             aria-controls="nav-annulee"
//                             aria-selected="false"
//                           >
//                             Annulé
//                           </a>
//                           <a
//                             className="nav-link nav-horizontal-commandes"
//                             id="nav-echec-tab"
//                             data-toggle="tab"
//                             href="#nav-echec"
//                             role="tab"
//                             aria-controls="nav-echec"
//                             aria-selected="false"
//                           >
//                             Echec
//                           </a>
//                           <a
//                             className="nav-link nav-horizontal-commandes"
//                             id="nav-retournee-tab"
//                             data-toggle="tab"
//                             href="#nav-retournee"
//                             role="tab"
//                             aria-controls="nav-retournee"
//                             aria-selected="false"
//                           >
//                             Retourné
//                           </a>
//                         </div>
//                       </nav>
//                     </div>
//                     <div className="col-xl-3"></div>
//                   </div>
//                 </div>
//                 <div className="content-card-dashboard-content-table">
//                   <div className="tab-content" id="nav-tabContent">
//                     <div
//                       className="tab-pane fade show active"
//                       id="nav-tout"
//                       role="tabpanel"
//                       aria-labelledby="nav-tout-tab"
//                     >
//                       {this.state.loading ? (
//                         <CommandeSkeleton />
//                       ) : (
//                         <TableCommande
//                           shop={this.state.shopDetails}
//                           commandes={allOrders}
//                         />
//                       )}
//                     </div>
//                     <div
//                       className="tab-pane fade"
//                       id="nav-en-attente"
//                       role="tabpanel"
//                       aria-labelledby="nav-en-attente-tab"
//                     >
//                       <TableCommandePlaced
//                         shop={this.state.shopDetails}
//                         commandes={orderPlaced}
//                         onGetOrdersByShop={this.onGetOrdersByShop}
//                       />
//                     </div>
//                     <div
//                       className="tab-pane fade"
//                       id="nav-pret-a-livrer"
//                       role="tabpanel"
//                       aria-labelledby="nav-pret-a-livrer-tab"
//                     >
//                       <TableCommande
//                         shop={this.state.shopDetails}
//                         commandes={orderOnReady}
//                       />
//                     </div>
//                     <div
//                       className="tab-pane fade"
//                       id="nav-en-livraison"
//                       role="tabpanel"
//                       aria-labelledby="nav-en-livraison-tab"
//                     >
//                       <TableCommande
//                         shop={this.state.shopDetails}
//                         commandes={orderOnShipped}
//                       />
//                     </div>
//                     <div
//                       className="tab-pane fade"
//                       id="nav-livree"
//                       role="tabpanel"
//                       aria-labelledby="nav-livree-tab"
//                     >
//                       <TableCommande
//                         shop={this.state.shopDetails}
//                         commandes={orderOnDelivred}
//                       />
//                     </div>
//                     <div
//                       className="tab-pane fade"
//                       id="nav-annulee"
//                       role="tabpanel"
//                       aria-labelledby="nav-annulee-tab"
//                     >
//                       <TableCommande
//                         shop={this.state.shopDetails}
//                         commandes={orderOnCanceled}
//                       />
//                     </div>
//                     <div
//                       className="tab-pane fade"
//                       id="nav-echec"
//                       role="tabpanel"
//                       aria-labelledby="nav-echec-tab"
//                     >
//                       <TableCommande
//                         shop={this.state.shopDetails}
//                         commandes={orderOnFailed}
//                       />
//                     </div>
//                     <div
//                       className="tab-pane fade"
//                       id="nav-retournee"
//                       role="tabpanel"
//                       aria-labelledby="nav-retournee-tab"
//                     >
//                       <TableCommande
//                         shop={this.state.shopDetails}
//                         commandes={orderOnReturned}
//                       />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

// export default connect(
//   (state) => ({
//     commandes: state.orders.items,
//   }),
//   {
//     fetchOrdersByShop,
//   }
// )(commandesDashboard);

function CommandesDashboard() {
  const [status, setStatus] = useState("");
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { user } = useSelector((s) => s?.user);
  const {
    data = {
      results: [],
      count: 0,
      placed_count: 0,
      shipped_count: 0,
      delivered_count: 0,
      canceled_count: 0,
      failed_count: 0,
      returned_count: 0,
      ready_count: 0,
    },
    isFetching,
    refetch,
  } = useGetOrderBySellerQuery({
    status,
    page,
    limit,
    q: query,
    id: user?.provider?.id,
  });

  return (
    <div className="component-commandes-dashboard-admin">
      <HeaderDashboard />
      <div className="fixed-after-header-dashboard container-fluid">
        <div className="row">
          <div className="col-xl-2 col-lg-3 no-view-tablette">
            <NavVerticalDashboard />
          </div>
          <div className="col-xl-10 col-lg-9 px-xl-3 py-4 pr-lg-3 pl-lg-1">
            <div className="card-dashboard-content-table">
              <div className="head-card-dashboard-content-table">
                <div className="row mb-5">
                  <div className="col-md-9 col-sm-8 mb-sm-0 mb-3">
                    <p className="title-filter-dashboard">
                      Toutes les commandes
                    </p>
                  </div>
                  <div className="col-md-3 col-sm-4 pl-sm-0"></div>
                </div>

                <div className="row">
                  <div className="col-xl-10">
                    <nav>
                      <div
                        className="nav nav-tabs nav-tabs-horizontal-commandes"
                        id="nav-tab"
                        role="tablist"
                      >
                        <button
                          // className="nav-link nav-horizontal-commandes active"
                          className={`nav-link nav-horizontal-commandes ${
                            status === "" ? "active" : ""
                          }`}
                          id="nav-tout-tab"
                          onClick={() => setStatus("")}
                        >
                          Tout
                        </button>
                        <button
                          className={`nav-link nav-horizontal-commandes ${
                            status === "placed" ? "active" : ""
                          }`}
                          id="nav-en-attente-tab"
                          onClick={() => setStatus("placed")}
                        >
                          En attente{" "}
                          <span class="badge badge-theme">
                            {data?.placed_count}
                          </span>
                        </button>
                        <button
                          className={`nav-link nav-horizontal-commandes ${
                            status === "ready" ? "active" : ""
                          }`}
                          id="nav-pret-a-livrer-tab"
                          onClick={() => setStatus("ready")}
                        >
                          Prêt à livré{" "}
                          <span class="badge badge-theme">
                            {data?.ready_count}
                          </span>
                        </button>
                        <button
                          className={`nav-link nav-horizontal-commandes ${
                            status === "shipped" ? "active" : ""
                          }`}
                          id="nav-en-livraison-tab"
                          onClick={() => setStatus("shipped")}
                        >
                          En livraison{" "}
                          <span class="badge badge-theme">
                            {data?.shipped_count}
                          </span>
                        </button>
                        <button
                          className={`nav-link nav-horizontal-commandes ${
                            status === "delivered" ? "active" : ""
                          }`}
                          id="nav-livree-tab"
                          onClick={() => setStatus("delivered")}
                        >
                          Livré
                          <span class="badge badge-theme">
                            {data?.delivered_count}
                          </span>
                        </button>
                        <button
                          className={`nav-link nav-horizontal-commandes ${
                            status === "canceled" ? "active" : ""
                          }`}
                          id="nav-annulee-tab"
                          onClick={() => setStatus("canceled")}
                        >
                          Annulé
                          <span class="badge badge-theme">
                            {data?.canceled_count}
                          </span>
                        </button>
                        <button
                          className={`nav-link nav-horizontal-commandes ${
                            status === "failed" ? "active" : ""
                          }`}
                          id="nav-echec-tab"
                          onClick={() => setStatus("failed")}
                        >
                          Echec{" "}
                          <span class="badge badge-theme">
                            {data?.failed_count}
                          </span>
                        </button>
                        <button
                          className={`nav-link nav-horizontal-commandes ${
                            status === "returned" ? "active" : ""
                          }`}
                          id="nav-retournee-tab"
                          onClick={() => setStatus("returned")}
                        >
                          Retourné
                          {/* <span class="badge badge-theme">
                            {" "}
                            {data?.returned_count}{" "}
                          </span> */}
                        </button>
                      </div>
                    </nav>
                  </div>
                  <div className="col-xl-2"></div>
                </div>
              </div>
              <div className="content-card-dashboard-content-table">
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="nav-tout"
                    role="tabpanel"
                    aria-labelledby="nav-tout-tab"
                  >
                    <TableCommande
                      commandes={data?.results}
                      fetchOrders={refetch}
                      isLoading={isFetching}
                      setPage={setPage}
                      page={page}
                      setLimit={setLimit}
                      limit={limit}
                      count={data?.count}
                      setQuery={setQuery}
                      shop={user?.provider}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommandesDashboard;
