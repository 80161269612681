import React from "react";
import "./categorieListAccueil.css";
import CategoryListSkeleton from "../skeleton/categorieListSkeleton/categorieListSkeleton";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";

// export default class categorieListAccueil extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       api: api,
//       categoryList: [],
//       categories: [],
//       subcategories: [],
//       loading: false,
//     };
//   }

//   componentDidMount() {
//     this.onGetCategories();
//   }
//   onGetCategories = () => {
//     this.setState({ loading: true });

//     var api = this.state.api;
//     axios
//       .get(api + "category/")
//       .then((response) => {
//         if (response.status === 200 || response.status === 201) {
//           let parents = response?.data?.filter(
//             (item) => item?.category_id === null && !item?.category_id
//           );
//           let childs = response?.data?.filter(
//             (item) => item?.category_id !== null && item?.category_id
//           );
//           for (let i = 0; i < parents.length; i++) {
//             const element = parents[i];
//             element.subcategories = childs
//               .sort(function (a, b) {
//                 if (a.name < b.name) {
//                   return -1;
//                 }
//                 if (a.name > b.name) {
//                   return 1;
//                 }
//                 return 0;
//               })
//               .filter((item) => item?.category_id === element?.id);
//           }

//           this.setState({
//             categoryList: parents?.sort(function (a, b) {
//               if (a.name < b.name) {
//                 return -1;
//               }
//               if (a.name > b.name) {
//                 return 1;
//               }
//               return 0;
//             }),
//             loading: false,
//           });
//         }
//       })
//       .catch((error) => {
//         if (error) {
//           this.setState({ loading: false });
//         }
//       });
//   };

//   render() {
//     if (this.state.loading) {
//       return <CategoryListSkeleton />;
//     } else {
//       return (

//       );
//     }
//   }
// }

function CategorieListAccueil() {
  const categoryList = useSelector((s) => s?.categories);
  return (
    <>
      {!!categoryList?.isLoading && <CategoryListSkeleton />}
      {!categoryList?.isLoading && (
        <div className="card-categorie-accueil">
          <p className="h-theme fw-400 mb-3">Catégories</p>

          <ul className="ul-categories">
            {categoryList?.data
              ?.sort((a, b) => a?.position - b?.position)
              .map((item) => (
                <li className="li-categories" key={item?.id}>
                  <div className="dropdown dropdown-categorie-list">
                    <a
                      href={`/tous-les-produits?category=${item.id}`}
                      className="categories-link"
                    >
                      {item?.name}
                      <FeatherIcon
                        className="icon-categorie-link"
                        icon="chevron-right"
                      />
                    </a>
                    {item?.subcategories?.length ? (
                      <div
                        className="dropdown-content-categorie-list min-w-auto"
                        style={{ width: "max-content" }}
                      >
                        <p className="h-theme fw-400 mb-3">Sous Catégories</p>
                        <div className="row">
                          {item?.subcategories?.map((sub) => (
                            <div key={sub?.id} className="col-md-4 m-b-20">
                              <a
                                className="h-link-dropdown-categorie-list"
                                href={`/tous-les-produits?category=${sub?.id}`}
                              >
                                {sub?.name}
                              </a>
                            </div>
                          ))}

                          {/* <div className="col-sm-4 m-b-20">
                        <NavLink
                          to="#"
                          className="h-link-dropdown-categorie-list"
                        >
                          Mode
                        </NavLink>
                        <NavLink
                          to="#"
                          className="link-dropdown-categorie-list"
                        >
                          Prêt-à-porter
                        </NavLink>
                        <NavLink
                          to="#"
                          className="link-dropdown-categorie-list"
                        >
                          Lingerie
                        </NavLink>
                        <NavLink
                          to="#"
                          className="link-dropdown-categorie-list"
                        >
                          Chaussures
                        </NavLink>
                        <NavLink
                          to="#"
                          className="link-dropdown-categorie-list"
                        >
                          Sacs
                        </NavLink>
                        <NavLink
                          to="#"
                          className="link-dropdown-categorie-list"
                        >
                          Sports
                        </NavLink>
                      </div> */}
                          {/* <div className="col-sm-6 m-b-20">
                        <NavLink
                          to="#"
                          className="h-link-dropdown-categorie-list"
                        >
                          Beauté
                        </NavLink>
                        <NavLink
                          to="#"
                          className="link-dropdown-categorie-list"
                        >
                          Maquillage
                        </NavLink>
                        <NavLink
                          to="#"
                          className="link-dropdown-categorie-list"
                        >
                          Soins ,Visqges et Corps
                        </NavLink>
                        <NavLink
                          to="#"
                          className="link-dropdown-categorie-list"
                        >
                          Coiffure
                        </NavLink>
                      </div> */}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </li>
              ))}

            <span className="ligne d-block m-t-0 m-b-0 w-95"></span>
            {/* <CategorieListItems categoryList={this.state.categoryList} /> */}
          </ul>
        </div>
      )}
    </>
  );
}

export default CategorieListAccueil;
