import React, { Component } from 'react'
import './productByCategoryView.css'
import Header from '../header/index'
import Footer from '../footer/index'
import SortBy from '../sortBy/index'
import FilterAllProduct from '../filterAllProduct/index'
import api from '../../http/global-vars'
import axios from 'axios'
import ProductItemByCategory from '../productItemByCategory/index'
import Skeleton from 'react-loading-skeleton'
import { connect } from 'react-redux'

class productByCategoryView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      categoryId: this.props.match.params.id,
      api: api,
      loading: false,
      categoryInfos: [],
      dataLength: [],
    }
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    // this.onGetCategoryById()
    // this.setState({ loading: true })
    this.setState({ loading: true, categoryId: this.props.match.params.id })

    setTimeout(() => {
      this.onGetCategoryById()
    }, 1000)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.setState({ loading: true, categoryId: this.props.match.params.id })

      setTimeout(() => {
        this.onGetCategoryById()
      }, 1000)

      window.scrollTo(0, 0)
    }
  }

  onGetCategoryById = () => {
    var api = this.state.api
    var categoryId = this.state.categoryId

    axios
      .get(api + `category/${categoryId}`)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.setState({
            categoryInfos: response.data,
            loading: false,
            dataLength: this.child?.props?.filteredCategory,
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  render() {
    const categoryInfos = this.state.categoryInfos

    let data = this.state.dataLength
    return (
      <div>
        <Header />
        <div className="body-theme p-t-15 p-b-45 fixed-after-header">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-3">
                <FilterAllProduct categoryId={this.props.match.params.id} />
              </div>
              <div className="col-xl-9 col-lg-9 pl-lg-0 dis-flex">
                <div className="content-item-card w-full">
                  <div className="content-item-card-head">
                    <div className="row dis-flex">
                      <div className="col-xl-3 col-lg-4 col-md-4 col-10">
                        {this.state.loading === true ? (
                          <Skeleton className="fs-13 fw-600 text-black-50" />
                        ) : (
                          <p className="fs-13 fw-600 text-black-50">
                            {categoryInfos.name}{' '}
                            <span className="fw-300 m-l-10">
                              ( {this?.props?.filteredCategory?.length}{' '}
                              résultats)
                            </span>
                          </p>
                        )}
                      </div>
                      <div className="col-xl-3 col-lg-4 pl-lg-4 col-md-4 col-2 ml-auto">
                        <SortBy />
                      </div>
                    </div>
                  </div>
                  <div className="content-item-card-body">
                    <div className="row px-2">
                      <ProductItemByCategory
                        onRef={(ref) => (this.child = ref)}
                        categoryId={this.props.match.params.id}
                        category={this.state.categoryInfos}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.products.items,
    filteredCategory: state?.products?.filteredItems,
  }
}
export default connect(
  mapStateToProps,
  null,
)(productByCategoryView)