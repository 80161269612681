import React, { useEffect, useState } from "react";
import HeaderDashboard from "../headerDashboard/index";
import NavVerticalDashboard from "../navVerticalDashboard/index";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import * as IconlyPack from "react-iconly";
// import ContentProductDetails from "../../contentProductDetails/contentProductDetails";
import ContentProductAdmin from "./ContentProductAdmin";

function Articledetails() {
  const location = useLocation();
  const history = useHistory();
  const [product, setProduct] = useState({});
  useEffect(() => {
    window.scrollTo(0, 0);
    if (location?.state?.product?.id) {
      setProduct(location.state.product);
      console.log(location)
    } else {
      setTimeout(() => {
        history.goBack();
      }, 100);
    }

    return () => {
      setProduct({});
    };
  }, [location]);

  return (
    <div className="p-b-200">
      <HeaderDashboard />
      <div className="fixed-after-header-dashboard container-fluid">
        <div className="row">
          <div className="col-xl-2 col-lg-3 no-view-tablette">
            <NavVerticalDashboard />
          </div>
          <div className="col-xl-10 col-lg-9 px-xl-3 py-4 pr-lg-3 pl-lg-1">
            <div className="card-dashboard-content-table">
              <div className="head-card-dashboard-content-table">
                <div className="row mb-5">
                  <div className="col-md-9 col-sm-8 mb-sm-0 mb-3">
                    <p className="title-filter-dashboard">
                      <NavLink
                        to={"/articles"}
                        className="action-btn btn edit-btn"
                        title="Retour"
                      >
                        <IconlyPack.ArrowLeft
                          set="bold"
                          primaryColor="#000"
                          className="action-btn-icon action-edit-icon"
                          // onClick={() => onDesarchive(row?.id)}
                        />
                      </NavLink>
                      Catalogue
                    </p>
                  </div>
                </div>

                <div className="content-card-dashboard-content-table">
                  <ContentProductAdmin />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Articledetails;
