import React, {Component} from 'react';
import './parametresDashboardAdmin.css';
import HeaderDashboard from '../headerDashboard/index';
import NavVerticalDashboardAdmin from '../navVerticalDashboardAdmin/index';
import api from '../../../http/global-vars';
import axios from 'axios';
import { Message } from 'rsuite';
import { ToastContainer, toast } from "react-toastify";
import ReseauSocial from './ReseauSocial';

export default class parametresDashboardAdmin extends Component {
  constructor(props) {
      super(props);
      this.state = {
        token: window.localStorage.getItem('userToken'),
        requestConfig: {
            headers: { Authorization: `Bearer ${window.localStorage.getItem('userToken')}` }
        },
        userID: window.localStorage.getItem('userID'),
        api: api,
        loading: false,
        adminInfos: {},
        errors: {},
        is_empty_email: false,
        old_email: '',
        is_invalid_email: false,
        is_empty_first_name: false,
        is_empty_last_name: false,
        is_empty_phone: false,
        is_update_email: false,
        isSuccessUpdateParameters: false,
        isErrorUpdateParameters: false,
        updateAdminParametersInProgress: false
      };
      this.handleChangeAdminParameters = this.handleChangeAdminParameters.bind(this);
  }

  componentDidMount() {
    window.scrollTo({top: 0, behavior: 'smooth'});
    var config = this.state.requestConfig
    this.onGetConnectedUserInfos(config)
  }
    
  onGetConnectedUserInfos = (config) => {
      this.setState({
        loading: true
      })
      var api = this.state.api
      axios.get(api + 'me/', config)
          .then(response => {
              this.setState({
                loading: false
              })
              if(response.status === 200){
                  this.setState({
                      adminInfos: response.data.data, 
                      old_email: response.data.data.email
                  })
              }
          })
          .catch(error => {
              if (error) {
                this.setState({
                  loading: false
                })
              }
          })
  }

  handleChangeAdminParameters = (e) => {
    e.persist();
    // let adminInformations = this.state.adminInfos
    this.setState(prevState => ({
      adminInfos: { ...prevState.adminInfos, [e.target.name]: e.target.value },
    }))
  }
  onValidateAdminParameters = () => {
      let fields = this.state.adminInfos
      let errors = this.state.errors
      var isValidForm = true
  
      if(!fields['first_name']){
        isValidForm = false
        this.setState({
          is_empty_first_name: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_first_name: false
          })
        }, 5000);
        errors['first_name'] = 'Le prénom est obligatoire!'
      }
      if(!fields['last_name']){
        isValidForm = false
        this.setState({
          is_empty_last_name: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_last_name: false
          })
        }, 5000);
        errors['last_name'] = 'Le nom de famille est obligatoire!'
      }
      if(!fields['email']){
        isValidForm = false
        this.setState({
          is_empty_email: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_email: false
          })
        }, 5000);
        errors['email'] = "L adresse e-mail est obligatoire!"
      }
      if (typeof fields["email"] !== "undefined") {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(fields["email"])) {
            isValidForm = false
            this.setState({
                is_invalid_email: true
            })
            setTimeout(() => {
                this.setState({
                    is_invalid_email: false
                })
            }, 5000);
  
            errors['invalid_email'] = 'Votre adresse email n\'est pas valide!'
        }
      }
      if(!fields['phone']){
          isValidForm = false
          this.setState({
            is_empty_phone: true
          })
          setTimeout(() => {
            this.setState({
              is_empty_phone: false
            })
          }, 5000);
          errors['phone'] = 'Le numéro de téléphone est obligatoire!'
      }
  
      this.setState({
        errors
      })
  
      return isValidForm
  }
  
  onLogoutAdmin = () => {
    toast.warning('Votre e-mail à été modifié également! Vous serrez déconnecté dans quelques secondes...')
    window.localStorage.removeItem('userToken')

    this.setState({
        is_update_email: true
    })
    setTimeout(() => {
      this.setState({
        is_update_email: false
      })
      window.location = '/connexion'
    }, 6000);
  }
 
  onSubmitUpdateAdminInfos = (e) => {
    e.preventDefault()
    if(this.onValidateAdminParameters()){
      this.setState({updateAdminParametersInProgress: true})
      
      var api = this.state.api
      var config = this.state.requestConfig
      var dataAdmin = new FormData()
      var adminID = this.state.userID
      var adminEmail = this.state.old_email

      dataAdmin.append('first_name', this.state.adminInfos.first_name)
      dataAdmin.append('last_name', this.state.adminInfos.last_name)
      dataAdmin.append('email', this.state.adminInfos.email)
      dataAdmin.append('phone', this.state.adminInfos.phone)
    
    
      axios.put(`${api}user/${adminID}/`, dataAdmin, config)
        .then(response => {
          this.setState({updateAdminParametersInProgress: true})
          if (response.status === 200 || response.status === 201) {
            if(adminEmail !== this.state.adminInfos.email){
              this.onLogoutAdmin()
            }
            else{
              window.localStorage.setItem('userFirstName', dataAdmin.first_name)
              window.localStorage.setItem('userLastName', dataAdmin.last_name)
              window.localStorage.setItem('userFullname', dataAdmin.first_name + ' ' + dataAdmin.last_name)
              toast.success('Votre compte administrateur à été modifié avec succès!')
              this.setState({
                  isSuccessUpdateParameters: true
              })
              setTimeout(() => {
                  this.setState({
                    isSuccessUpdateParameters: false
                  })
                  window.location.reload()
              }, 4000);
            }
          }
          else{
              toast.error('Oupsss! Une erreur est survenue lors de la mise à jour de votre compte administrateur')
              this.setState({
                  isErrorUpdateParameters: true
              })
              setTimeout(() => {
                  this.setState({
                    isErrorUpdateParameters: false
                  })
              }, 5000);
          }
        
        })
        .catch(error => {
          if (error) {
            this.setState({ loading: false })
          }
    
        })
    
      
    }
  }
 
  render() {
    return (
      <div className="component-parametres-dashboard-admin">
        <HeaderDashboard />
        <div className="fixed-after-header-dashboard container-fluid">
          <div className="row">
            <div className="col-xl-2 col-lg-3 no-view-tablette">
              <NavVerticalDashboardAdmin />
            </div>
            <div className="col-xl-10 col-lg-9 px-xl-3 py-4 pr-lg-3 pl-lg-1">
              <div className="content-view-dashboard">
                <p className="title-filter-dashboard mb-5">Paramètres</p>

                <form onSubmit={(e) => this.onSubmitUpdateAdminInfos(e)}>
                  <div className="my-5">
                    <p className="consigne-title-steps parametres">
                      Informations de l'administrateur
                    </p>
                    <div className="row">
                      <div className="col-md-6 pr-xl-5 pr-lg-0 pr-md-4">
                        <div className="form-group pos-relative mb-4">
                          <label
                            htmlFor="inputPrenom"
                            className="user-info-label"
                          >
                            Prénom
                          </label>
                          <input
                            type="text"
                            maxLength={30}
                            id="inputPrenom"
                            onChange={this.handleChangeAdminParameters}
                            name="first_name"
                            className="form-control input-steps-inscription-vendeur dashboard-parametre-input"
                            value={this.state.adminInfos.first_name}
                            placeholder="Prénom *"
                          />
                          {this.state.is_empty_first_name && (
                            <Message
                              className="alert-rsuite-custom"
                              type="error"
                              description={this.state.errors.first_name}
                            />
                          )}
                        </div>
                        <div className="form-group pos-relative mb-4">
                          <label htmlFor="inputNom" className="user-info-label">
                            Nom
                          </label>
                          <input
                            type="text"
                            maxLength={30}
                            id="inputNom"
                            onChange={this.handleChangeAdminParameters}
                            name="last_name"
                            className="form-control input-steps-inscription-vendeur dashboard-parametre-input"
                            value={this.state.adminInfos.last_name}
                            placeholder="Nom de famille *"
                          />
                          {this.state.is_empty_last_name && (
                            <Message
                              className="alert-rsuite-custom"
                              type="error"
                              description={this.state.errors.last_name}
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 pl-lg-5 pl-md-4">
                        <div className="form-group pos-relative mb-4">
                          <label
                            htmlFor="inputPhone"
                            className="user-info-label"
                          >
                            N° téléphone
                          </label>
                          <input
                            type="number"
                            maxLength={30}
                            id="inputPhone"
                            onChange={this.handleChangeAdminParameters}
                            name="phone"
                            className="form-control input-steps-inscription-vendeur dashboard-parametre-input number-input-custom"
                            value={this.state.adminInfos.phone}
                            placeholder="N° téléphone *"
                          />
                          {this.state.is_empty_phone && (
                            <Message
                              className="alert-rsuite-custom"
                              type="error"
                              description={this.state.errors["phone"]}
                            />
                          )}
                        </div>
                        <div className="form-group pos-relative mb-4">
                          <label
                            htmlFor="inputEmail"
                            className="user-info-label"
                          >
                            Adresse e-mail
                          </label>
                          <input
                            type="email"
                            maxLength={50}
                            id="inputEmail"
                            onChange={this.handleChangeAdminParameters}
                            name="email"
                            className="form-control input-steps-inscription-vendeur dashboard-parametre-input"
                            value={this.state.adminInfos.email}
                            placeholder="Adresse e-mail *"
                          />
                          {this.state.is_empty_email && (
                            <Message
                              className="alert-rsuite-custom"
                              type="error"
                              description={this.state.errors["email"]}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row dis-flex">
                    <div className="ml-auto col-lg-6 col-md-7 pl-lg-5 pl-md-4">
                      <button
                        type="submit"
                        className="btn-user-info-form text-up"
                      >
                        ENREGISTRER LES MODIFICATIONS
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="content-view-dashboard mt-5">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="title-filter-dashboard mb-5">
                      Réseaux sociaux
                    </h1>
                  </div>
                </div>
                <ReseauSocial />
              </div>
            </div>
          </div>
        </div>
        {this.state.is_update_email ||
        this.state.isSuccessUpdateParameters ||
        this.state.isErrorUpdateParameters ? (
          <ToastContainer className="fs-13" />
        ) : (
          ""
        )}
      </div>
    );
  }
}