import React, { Component } from "react";
import "./changePasswordModal.css";
import { Modal, ButtonToolbar, Loader } from "rsuite";
import api from "../../http/global-vars";
import axios from "axios";
import { Message } from "rsuite";
import { toast } from "react-toastify";

export default class ChangePasswordModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      show: false,
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      is_empty_old_password: false,
      is_empty_new_password: false,
      is_empty_new_password_confirmation: false,
      not_same_password: false,
      changePasswordInProgress: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  close = () => {
    this.setState({ show: false });
  };
  open = () => {
    this.setState({ show: true });
  };

  handleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;

    this.setState({
      fields: fields,
    });
  };

  onValidateChangePassword = () => {
    let fields = this.state.fields;
    let errors = this.state.errors;
    var isValidForm = true;

    if (!fields["old_password"]) {
      isValidForm = false;
      this.setState({
        is_empty_old_password: true,
      });
      errors["old_password"] = "Veuillez renseigner votre mot de passe actuel!";
      setTimeout(() => {
        this.setState({
          is_empty_old_password: false,
        });
      }, 5000);
    }
    if (!fields["new_password"]) {
      isValidForm = false;
      this.setState({
        is_empty_new_password: true,
      });
      errors["new_password"] = "Veuillez renseigner votre nouveau mot de passe!";
      setTimeout(() => {
        this.setState({
          is_empty_new_password: false,
        });
      }, 5000);
    }
    if (!fields["new_password_confirmation"]) {
      isValidForm = false;
      this.setState({
        is_empty_new_password_confirmation: true,
      });
      errors["new_password_confirmation"] =
        "Veuillez confirmer votre nouveau mot de passe!";
      setTimeout(() => {
        this.setState({
          is_empty_new_password_confirmation: false,
        });
      }, 5000);
    }
    if (typeof fields["new_password"] !== "undefined") {
      if (fields["new_password"] !== fields["new_password_confirmation"]) {
        isValidForm = false;
        this.setState({
          not_same_password: true,
        });
        errors["not_same_password"] = "Les deux mots de passe ne sont pas identiques";
        setTimeout(() => {
          this.setState({
            not_same_password: false,
          });
        }, 5000);
      }
    }

    this.setState({
      errors: errors,
    });

    return isValidForm;
  };

  onChangePassword = (e) => {
    e.preventDefault();

    if (this.onValidateChangePassword()) {
      this.setState({ changePasswordInProgress: true });

      // var api = this.state.api;
      var data = this.state.fields;

      axios
        .put(api + "changepassword/", data, this.state.requestConfig)
        .then((response) => {
          console.log(response);
          if (response?.data?.status === "success") {
            toast.success("Votre mot de passe a été bien modifié!");
            this.setState({ changePasswordInProgress: false });
            this.close();
          } else {
            toast.error("Une error est survenue lors de la modification.");
          }

          // debugger
        })
        .catch((error) => {
          if (error) {
            let errors = this.state.errors;
            this.setState({ changePasswordInProgress: false });
            if (
              error?.response?.data?.old_password?.length &&
              error?.response?.data?.old_password[0] === "Wrong password."
            ) {
              this.setState({
                is_empty_old_password: true,
              });
              errors["old_password"] = "Votre mot de passe est incorrect!";
              setTimeout(() => {
                this.setState({
                  is_empty_old_password: false,
                });
              }, 5000);
            } else {
              toast.error("Une error est survenue lors de la modification.");
            }
            this.setState({ errors });
            console.log(error?.response);
            // debugger
          }
        });
    }
  };

  render() {
    return (
      <div className="component-change-password-modal">
        <ButtonToolbar>
          <p
            className="h-theme fw-500 ff-raleway hov-pointer"
            onClick={this.open}
          >
            Changer votre mot de passe ici
          </p>
        </ButtonToolbar>

        <Modal
          size="xs"
          className="rsuite-content-modal-custom"
          show={this.state.show}
          onHide={this.close}
        >
          <form onSubmit={(e) => this.onChangePassword(e)}>
            <Modal.Header>
              <Modal.Title>Modifier votre mot de passe</Modal.Title>
            </Modal.Header>
            <div>
              <div className="form-group mt-5">
                <input
                  name="old_password"
                  onChange={this.handleChange}
                  className="form-control input-change-password"
                  type="password"
                  placeholder="Mot de passe actuel"
                />
                {this.state.is_empty_old_password === true && (
                  <Message
                    className="alert-rsuite-custom"
                    type="error"
                    description={this.state.errors.old_password}
                  />
                )}
              </div>
              <div className="form-group">
                <input
                  name="new_password"
                  onChange={this.handleChange}
                  className="form-control input-change-password"
                  type="password"
                  placeholder="Nouveau mot de passe"
                />
                {this.state.is_empty_new_password === true && (
                  <Message
                    className="alert-rsuite-custom"
                    type="error"
                    description={this.state.errors.new_password}
                  />
                )}
              </div>
              <div className="form-group mb-5">
                <input
                  name="new_password_confirmation"
                  onChange={this.handleChange}
                  className="form-control input-change-password"
                  type="password"
                  placeholder="Confirmer mot de passe"
                />
                {this.state.is_empty_new_password_confirmation === true && (
                  <Message
                    className="alert-rsuite-custom"
                    type="error"
                    description={this.state.errors.new_password_confirmation}
                  />
                )}
                {this.state.not_same_password === true && (
                  <Message
                    className="alert-rsuite-custom"
                    type="error"
                    description={this.state.errors.not_same_password}
                  />
                )}
              </div>
            </div>
            <Modal.Footer className="text-center">
              {!this.state.changePasswordInProgress && (
                <button type="submit" className="btn-change-password">
                  Envoyer
                </button>
              )}

              {this.state.changePasswordInProgress && (
                <button type="submit" className="btn-change-password">
                  Mise à jour en cours &nbsp;
                  <Loader />
                </button>
              )}
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    );
  }
}
