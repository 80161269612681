import React, { Component, useEffect, useState } from "react";
import "./commandes.css";
import FeatherIcon from "feather-icons-react";
import {
  // ToastContainer,
  toast,
} from "react-toastify";
import api from "../../http/global-vars";
import axios from "axios";
import baseUrl from "../../http/backend-base-url";
import Skeleton from "react-loading-skeleton";
import { NavLink, useLocation } from "react-router-dom";
import EmptyCartImage from "../../img/commande.png";
import ThumbsProduct from "../../img/product1.png";
import { formatMoney } from "../../utils/utils";
import { useGetOrderByUserQuery } from "../../utils/api/order.api";
import { useSelector } from "react-redux";
import { CustomPagination } from "../../modules/Pagination/CustomPagination";
// class commandes extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       token: window.localStorage.getItem("userToken"),
//       requestConfig: {
//         headers: {
//           Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
//         },
//       },
//       api: api,
//       userID: window.localStorage.getItem("userID"),
//       isPageLoading: false,
//       orders: [],
//       productWhishlist: [],
//       isErrorFavoris: false,
//       isSuccessFavoris: false,
//     };
//   }

//   componentDidMount() {
//     this.getOrderByUser();
//     console.log(this.props);
//     const { search } = this.props.location;
//     if (search === "?commande") {
//       window.$("#nav-commandes-tab").click();
//     }
//   }

//   getOrderByUser = () => {
//     this.setState({ isPageLoading: true });
//     const url = api + "orderbyuser/" + this.state.userID + "/";
//     axios
//       .get(url, this.state.requestConfig)
//       .then((response) => {
//         console.log(response);
//         response.data.forEach((element) => {
//           const groups = element?.cartitem.reduce((groups, item) => {
//             const key = item?.item?.provider?.id;
//             if (!groups[key]) {
//               groups[key] = [];
//             }
//             groups[key].push(item);
//             return groups;
//           }, {});

//           const itemsByShops = Object.keys(groups).map((key) => {
//             // console.log("item show", groups[key][0]);
//             return {
//               provider: groups[key][0]?.item?.provider,
//               data: groups[key],
//             };
//           });
//           element.cartitem = itemsByShops;
//         });

//         this.setState({
//           order: response?.data?.sort(
//             (a, b) => new Date(b?.order_date) - new Date(a?.order_date)
//           ),
//           isPageLoading: false,
//         });
//         this.getWishlistByUser();
//       })
//       .catch((e) => {
//         console.log("error", e);
//       });
//   };

//   onAddToFavoris = (e, product) => {
//     e.preventDefault();

//     let favorisInfos = this.state.favorisInfos;
//     favorisInfos["product"] = product?.id;
//     favorisInfos["user"] = this.state.userID;
//     this.setState(favorisInfos);

//     var api = this.state.api;
//     var data = this.state.favorisInfos;
//     var config = this.state.requestConfig;

//     axios
//       .post(api + "favoris/", data, config)
//       .then((response) => {
//         if (response.status === 200 || response.status === 201) {
//           // toast.success("Vous suivez cette boutique !")
//           this.setState({
//             isSuccessFavoris: true,
//           });
//           setTimeout(() => {
//             this.setState({
//               isSuccessFavoris: false,
//             });
//           }, 5000);
//           this.onGetProductItem();
//         } else {
//           toast.error("Une erreur est survenue! Veuillez rééssayer plus tard.");
//           this.setState({
//             isErrorFavoris: true,
//           });
//           setTimeout(() => {
//             this.setState({
//               isErrorFavoris: false,
//             });
//           }, 5000);
//         }
//       })
//       .catch((error) => {
//         if (error) {
//           this.setState({ loading: false });
//         }
//       });
//   };

//   getWishlistByUser = () => {
//     let url = api + `favorisbyuser/${this.state.userID}/`;
//     axios
//       .get(url, this.state.requestConfig)
//       .then((response) => {
//         this.setState({ productWhishlist: response.data });
//       })
//       .catch((error) => {
//         console.log("error", error);
//       });
//   };

//   onDeleteToFavoris = (e, productItem) => {
//     e.preventDefault();

//     var api = this.state.api;
//     var productId = productItem.id;
//     let favorisId = this.state.productWhishlist.filter(
//       (f) => f.product?.id === productId
//     )[0]?.id;
//     var config = this.state.requestConfig;
//     let url = api + `favoris/${favorisId}/`;
//     axios
//       .delete(url, config)
//       .then((response) => {
//         this.onGetProductItem();
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };

//   render() {
//     const { order } = this.state;
//     if (this.state.isPageLoading) {
//       return <CommandSkeleton />;
//     } else {
//       if (order?.length) {
//         window.scrollTo(10, 100);
//         return (
//           <div className="content-card-account">
//             {order?.map((ord, i) => {
//               return (
//                 <form key={ord?.id}>
//                   <div className="commande-item">
//                     <p className="text-commande-item m-b-30 fw-600 fs-14">
//                       Détails de la livraison
//                     </p>
//                     {ord?.cartitem?.map((cart, i) => (
//                       <div className="component-cart-item b-b-1 mb-3" key={i}>
//                         <div className="mb-3 shop-cart">
//                           {cart?.provider?.shop_name}
//                         </div>
//                         {cart?.data?.map((item) => (
//                           <div className="row m-b-20">
//                             <div className="col-xl-6 col-lg-6 col-md-12">
//                               <div className="row">
//                                 <div className="col-sm-3 m-b-20">
//                                   <img
//                                     loading="lazy"
//                                     className="img-thumbs-commande-item"
//                                     src={
//                                       item?.item?.image?.length ||
//                                       (item?.item?.couverture &&
//                                         item?.item?.couverture !== undefined)
//                                         ? item?.item?.couverture
//                                           ? baseUrl + item?.item?.couverture
//                                           : baseUrl +
//                                             item?.item?.image[0]?.image_small
//                                         : ThumbsProduct
//                                     }
//                                     alt=""
//                                   />
//                                 </div>
//                                 <div className="col-sm-9 m-b-20">
//                                   <p className="text-commande-item m-b-10 fw-300 fs-14">
//                                     {item.item.name}
//                                   </p>
//                                   <p
//                                     className="text-commande-item m-b-30 fw-300 fs-12"
//                                     dangerouslySetInnerHTML={{
//                                       __html: item?.item?.description,
//                                     }}
//                                   />
//                                   {item?.pointure || item?.taille ? (
//                                     <p className="text-commande-item m-b-30 fw-400 fs-12">
//                                       <span className="h-theme ">
//                                         {item?.pointure
//                                           ? "Pointure:"
//                                           : "Taille:"}
//                                       </span>{" "}
//                                       {item?.pointure || item?.taille}
//                                     </p>
//                                   ) : null}
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 m-b-20">
//                               <div>
//                                 <p className="h-theme fw-600 text-up">
//                                   Quantité
//                                 </p>
//                                 <p
//                                   className="text-dark fs-14 ff-open fw-400 text-center"
//                                   id="inputRegion"
//                                 >
//                                   {item.quantity}
//                                 </p>
//                               </div>
//                             </div>
//                             <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 m-b-20">
//                               <div>
//                                 <p className="h-theme fw-600 text-up">
//                                   Prix unitaire
//                                 </p>
//                                 <p className="text-dark fs-14 ff-open fw-400 text-center">
//                                   {formatMoney(
//                                     item?.price || item?.item?.price,
//                                     ord?.currency
//                                   )}
//                                 </p>
//                               </div>
//                             </div>
//                             {/* <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 m-b-20">
//                             {cart?.provider?.shop_name}
//                           </div> */}
//                           </div>
//                         ))}
//                       </div>
//                     ))}

//                     <div className="row">
//                       <div className="col-lg-8 dis-flex itm-center">
//                         {/* <div className="m-b-30">
//                           <p className="resume-item fw-300 fs-14">
//                             Date de livraison
//                           </p>
//                           <p className="resume-item fw-600 fs-14 ">
//                             Livré entre jeudi 3 sept. et vendredi 4 sept.
//                           </p>
//                         </div> */}
//                       </div>
//                       <div className="col-lg-4">
//                         <p className="resume-item fw-400 fs-14 m-b-20 d-flex">
//                           Statut de la commande :{" "}
//                           <span
//                             className={`badge d-flex align-items-center fs-14 fw-700`}
//                           >
//                             {ord?.status === "created"
//                               ? "Envoyée"
//                               : ord?.status === "placed"
//                               ? "En cours de préparation"
//                               : ord?.status === "ready"
//                               ? "Prêt à être livrer"
//                               : ord?.status === "shipped"
//                               ? "En cours de livraison"
//                               : ord?.status === "delivered"
//                               ? "Livrée"
//                               : ord?.status === "canceled"
//                               ? "Annulée"
//                               : ord?.status === "returned"
//                               ? "Retournée"
//                               : ord?.status === "failed"
//                               ? "Echec de livraison"
//                               : ""}
//                           </span>
//                         </p>
//                         <p className="resume-item fw-400 fs-14 m-b-20">
//                           Prix ​​sous-total :{" "}
//                           <span className="value-resume-item fs-14 fw-700">
//                             {/* {Intl.NumberFormat("fr-FR").format(
//                             parseInt(ord.order_total)
//                           )} */}
//                             {formatMoney(ord?.cart?.total, ord?.currency)}
//                           </span>
//                         </p>
//                         <p className="resume-item fw-400 fs-14 m-b-20">
//                           Frais de livraison (+) :{" "}
//                           <span className="value-resume-item fs-14 fw-700">
//                             {ord?.shipping > 0
//                               ? formatMoney(ord?.shipping, ord?.currency)
//                               : "Livraison offerte"}
//                           </span>
//                         </p>
//                         <p className="resume-item fw-400 fs-14 m-b-20">
//                           Remise (-) :{" "}
//                           <span className="value-resume-item fs-14 fw-700">
//                             {formatMoney(0, ord?.currency)}
//                           </span>
//                         </p>
//                         <p className="resume-item fw-700">
//                           Total à payer :{" "}
//                           <span className="value-resume-item fw-700 fs-17 lh-1-4">
//                             {/* {format(
//                             parseFloat(ord.order_total) +
//                               parseFloat(ord.shipping)
//                           )} */}
//                             {formatMoney(ord.order_total, ord?.currency)}
//                           </span>
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                 </form>
//               );
//             })}
//           </div>
//         );
//       } else {
//         return (
//           <div className="content-cart-account">
//             <section className="cart-content checkout">
//               <img
//                 loading="lazy"
//                 className="image-commande-empty"
//                 src={EmptyCartImage}
//                 alt=""
//               />
//               <header>
//                 <h2 className="h-theme">
//                   Vous n'avez fait aucune commande pour l'instant!
//                 </h2>
//                 <h4 className="">
//                   Veuillez ajouter des produits pour pouvoir acheter.
//                 </h4>
//               </header>
//               <div className="dis-flex justify-content-center mt-5">
//                 <div className="col-md-5 col-sm-7">
//                   <NavLink
//                     className="btn-theme dis-flex justify-content-center"
//                     to="/tous-les-produits"
//                   >
//                     Commencer mes achats
//                   </NavLink>
//                 </div>
//               </div>
//             </section>
//           </div>
//         );
//       }
//     }
//   }
// }
// export default withRouter(commandes);
const CommandSkeleton = () => {
  return (
    <div className="content-card-account">
      <article>
        {Array(3)
          .fill()
          .map((item, i) => {
            return (
              <div key={i} className="component-cart-item b-b-1 mb-3">
                <div className="row">
                  <div className="col-xl-6 col-lg-5 col-md-12">
                    <div className="row">
                      <div className="col-sm-3 m-b-20">
                        <Skeleton
                          className="img-thumbs-commande-item"
                          variant="rect"
                          height={100}
                          width={`100%`}
                        />
                      </div>
                      <div className="pl-lg-0 col-sm-9 m-b-20">
                        <Skeleton className="h-theme fw-600 text-up mb-2" />
                        <Skeleton className="" variant="rect" height={71} />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 m-b-20 dis-flex align-content-md-center px-2">
                    <div>
                      <Skeleton
                        variant="text"
                        className="h-theme fw-600 text-up m-b-12"
                      />
                      <div
                        className="content-qty-cart-item"
                        style={{ border: "2px solid #eee", padding: 0 }}
                      >
                        <div
                          className="amount-btn left"
                          style={{
                            marginTop: 0,
                            marginLeft: 4,
                            height: 17,
                            width: 17,
                          }}
                        >
                          <Skeleton className="icon-amount-btn" />
                        </div>
                        <Skeleton
                          className=""
                          variant="text"
                          style={{ width: 10, height: 17 }}
                        />
                        <div
                          className="amount-btn right"
                          style={{
                            marginTop: 0,
                            marginRight: 4,
                            height: 17,
                            width: 17,
                          }}
                        >
                          <Skeleton className="icon-amount-btn" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 m-b-20 px-2">
                    <div>
                      <Skeleton
                        className="h-theme fw-600 text-up mb-2"
                        variant="text"
                      />
                      <Skeleton className="" variant="text" height={19} />
                    </div>
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 m-b-20 px-2">
                    <div>
                      <Skeleton
                        className="h-theme fw-600 text-up mb-1"
                        variant="text"
                      />
                      <button className="btn-icon-action-commande m-t-4">
                        <Skeleton
                          className="icon-commande-favoris"
                          width={30}
                          height={19}
                        />
                      </button>
                      <button className="btn-icon-action-commande m-t-4">
                        <Skeleton
                          className="icon-commande-delete"
                          width={30}
                          height={19}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </article>
      <div className="row">
        <div className="col-lg-8 dis-flex itm-center">
          <div className="row w-full m-b-30">
            <div className="col-lg-8 col-md-10">
              <Skeleton
                className="resume-item fw-300 fs-14"
                style={{ height: 15, marginBottom: 15 }}
              />
              <br />
              <Skeleton
                className="resume-item fw-300 fs-14"
                style={{ height: 15 }}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <Skeleton
            className="resume-item fw-400 fs-14 m-b-20"
            width={`50%`}
            height={20}
          />{" "}
          <Skeleton
            className="value-resume-item fs-14 fw-700 m-t-5"
            width={`30%`}
            height={20}
          />
          <Skeleton
            className="resume-item fw-400 fs-14 m-b-20"
            width={`50%`}
            height={20}
          />{" "}
          <Skeleton
            className="value-resume-item fs-14 fw-700 m-t-5"
            width={`30%`}
            height={20}
          />
          <Skeleton
            className="resume-item fw-400 fs-14 m-b-20"
            width={`50%`}
            height={20}
          />{" "}
          <Skeleton
            className="value-resume-item fs-14 fw-700 m-t-5"
            width={`30%`}
            height={20}
          />
          <Skeleton
            className="resume-item fw-700 fs-14 m-b-20"
            width={`50%`}
            height={20}
          />{" "}
          <Skeleton
            className="value-resume-item-ttc m-t-5"
            width={`40%`}
            height={20}
            style={{ border: "solid #eee" }}
          />
        </div>
      </div>
    </div>
  );
};

function Commandes() {
  const { search } = useLocation();
  const { user } = useSelector((s) => s?.user);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const {
    data = { results: [], count: 0 },
    isLoading,
    refetch,
  } = useGetOrderByUserQuery({ id: user?.id, page, limit });

  useEffect(() => {
    if (!isLoading) {
      refetch();
    }
    if (search === "?commande") {
      window.$("#nav-commandes-tab").click();
    }
  }, []);
  return (
    <div>
      {!!isLoading && <CommandSkeleton />}
      {!isLoading && (
        <div className="content-card-account">
          {!!data?.results?.length &&
            data?.results?.map((ord, i) => {
              return (
                <div key={ord?.id}>
                  <div className="commande-item">
                    <p className="text-commande-item m-b-30 fw-600 fs-14">
                      Détails de la livraison
                    </p>
                    {ord?.cartitem?.map((item, i) => (
                      <div className="component-cart-item b-b-1 mb-3" key={i}>
                        <div className="mb-3 shop-cart">
                          {item?.item?.provider?.shop_name}
                        </div>
                        <div className="row m-b-20">
                          <div className="col-xl-6 col-lg-6 col-md-12">
                            <div className="row">
                              <div className="col-sm-3 m-b-20">
                                <img
                                  loading="lazy"
                                  className="img-thumbs-commande-item"
                                  src={
                                    item?.item?.image?.length ||
                                    (item?.item?.couverture &&
                                      item?.item?.couverture !== undefined)
                                      ? item?.item?.couverture
                                        ? baseUrl + item?.item?.couverture
                                        : baseUrl +
                                          item?.item?.image[0]?.image_small
                                      : ThumbsProduct
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="col-sm-9 m-b-20">
                                <p className="text-commande-item m-b-10 fw-300 fs-14">
                                  {item.item.name}
                                </p>
                                <p
                                  className="text-commande-item m-b-30 fw-300 fs-12"
                                  dangerouslySetInnerHTML={{
                                    __html: item?.item?.description,
                                  }}
                                />
                                {item?.pointure || item?.taille ? (
                                  <p className="text-commande-item m-b-30 fw-400 fs-12">
                                    <span className="h-theme ">
                                      {item?.pointure ? "Pointure:" : "Taille:"}
                                    </span>{" "}
                                    {item?.pointure || item?.taille}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 m-b-20">
                            <div>
                              <p className="h-theme fw-600 text-up">Quantité</p>
                              <p
                                className="text-dark fs-14 ff-open fw-400 text-center"
                                id="inputRegion"
                              >
                                {item.quantity}
                              </p>
                            </div>
                          </div>
                          <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 m-b-20">
                            <div>
                              <p className="h-theme fw-600 text-up">
                                Prix unitaire
                              </p>
                              <p className="text-dark fs-14 ff-open fw-400 text-center">
                                {formatMoney(
                                  item?.price || item?.item?.price,
                                  ord?.currency
                                )}
                              </p>
                            </div>
                          </div>
                          {/* <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 m-b-20">
                        {cart?.provider?.shop_name}
                      </div> */}
                        </div>
                      </div>
                    ))}

                    <div className="row">
                      <div className="col-lg-8 dis-flex itm-center">
                        {/* <div className="m-b-30">
                      <p className="resume-item fw-300 fs-14">
                        Date de livraison
                      </p>
                      <p className="resume-item fw-600 fs-14 ">
                        Livré entre jeudi 3 sept. et vendredi 4 sept.
                      </p>
                    </div> */}
                      </div>
                      <div className="col-lg-4">
                        <p className="resume-item fw-400 fs-14 m-b-20 d-flex">
                          Statut de la commande :{" "}
                          <span
                            className={`badge d-flex align-items-center fs-14 fw-700`}
                          >
                            {ord?.status === "created"
                              ? "Envoyée"
                              : ord?.status === "placed"
                              ? "En cours de préparation"
                              : ord?.status === "ready"
                              ? "Prêt à être livrer"
                              : ord?.status === "shipped"
                              ? "En cours de livraison"
                              : ord?.status === "delivered"
                              ? "Livrée"
                              : ord?.status === "canceled"
                              ? "Annulée"
                              : ord?.status === "returned"
                              ? "Retournée"
                              : ord?.status === "failed"
                              ? "Echec de livraison"
                              : ""}
                          </span>
                        </p>
                        <p className="resume-item fw-400 fs-14 m-b-20">
                          Prix ​​sous-total :{" "}
                          <span className="value-resume-item fs-14 fw-700">
                            {/* {Intl.NumberFormat("fr-FR").format(
                        parseInt(ord.order_total)
                      )} */}
                            {formatMoney(ord?.cart?.total, ord?.currency)}
                          </span>
                        </p>
                        <p className="resume-item fw-400 fs-14 m-b-20">
                          Frais de livraison (+) :{" "}
                          <span className="value-resume-item fs-14 fw-700">
                            {ord?.shipping > 0
                              ? formatMoney(ord?.shipping, ord?.currency)
                              : "Livraison offerte"}
                          </span>
                        </p>
                        <p className="resume-item fw-400 fs-14 m-b-20">
                          Remise (-) :{" "}
                          <span className="value-resume-item fs-14 fw-700">
                            {formatMoney(0, ord?.currency)}
                          </span>
                        </p>
                        <p className="resume-item fw-700">
                          Total à payer :{" "}
                          <span className="value-resume-item fw-700 fs-17 lh-1-4">
                            {/* {format(
                        parseFloat(ord.order_total) +
                          parseFloat(ord.shipping)
                      )} */}
                            {formatMoney(ord.order_total, ord?.currency)}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          {!data?.results?.length && (
            <section className="cart-content checkout">
              <img
                loading="lazy"
                className="image-commande-empty"
                src={EmptyCartImage}
                alt=""
              />
              <header>
                <h2 className="h-theme">
                  Vous n'avez fait aucune commande pour l'instant!
                </h2>
                <h4 className="">
                  Veuillez ajouter des produits pour pouvoir acheter.
                </h4>
              </header>
              <div className="dis-flex justify-content-center mt-5">
                <div className="col-md-5 col-sm-7">
                  <NavLink
                    className="btn-theme dis-flex justify-content-center"
                    to="/tous-les-produits"
                  >
                    Commencer mes achats
                  </NavLink>
                </div>
              </div>
            </section>
          )}
          {!!data?.results?.length && (
            <div className="row">
              <div class="col-md-12 mt-3">
                <CustomPagination
                  page={page}
                  perPage={limit}
                  nbPages={data?.count}
                  showPerpage={false}
                  onChange={(page, perPage) => {
                    window.scrollTo(200, 200);
                    setPage(page);
                    setLimit(perPage);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Commandes;
