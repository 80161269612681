import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLoginSocialMutation } from "../../../utils/api/user.api";
import { AppLocalStorage } from "../../../utils/storage";
import { onSetUserToken } from "../../../utils/api/slice/user.slice";
import Swal from "sweetalert2";

function UseSocialLogin() {
  const dispatch = useDispatch();
  const [loginUser, { isLoading, isSuccess, error, isError, data }] =
    useLoginSocialMutation();

  useEffect(() => {
    if (isSuccess) {
      if (data?.token) {
        console.log(data);
        window.localStorage.setItem("userID", data?.details?.id);
        window.localStorage.setItem("userType", data?.details?.user_type);
        window.localStorage.setItem("userToken", data?.token);
        dispatch(onSetUserToken({ user: data?.details, token: data?.token }));
      }
    }
    const err = error;

    if (isError) {
      // console.log("error", err);
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess]);

  const onSubmit = async(response) => {
    if (response?.email) {
        await loginUser(response)
    }
  }
  return {
    isLoading,
    onSubmit
  }
}

export default UseSocialLogin;
