import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ChangeEvent, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useHistory } from "react-router-dom";
import {
  useAddOrEditProductMutation,
  useDeleteImageProductMutation,
} from "../../../../utils/api/product.api";
import { useGetCategoryListQuery } from "../../../../utils/api/category.api";
import { isSeller } from "../../../../utils/utils";
import { useSelector } from "react-redux";

function UseAddOrEditProduct(shop, product) {
  const [step, setStep] = useState(0);
  const validationSchema = [
    //validation for step1
    yup.object({
      category: yup
        .array()
        .of(yup.string().label("La catégorie"))
        .min(1)
        .nullable()
        .required()
        .label("La catégorie"),
      name: yup.string().required().nullable().label("Le nom du produit"),
      model: yup.string().label("Le modèle"),
      brand: yup.string().label("La marque"),
      main_color: yup.string().label("La couleur"),
      pointure: yup.boolean().required("Ce champ est obligatoire").nullable(),
      pointures: yup
        .array()
        .when("pointure", {
          is: (val) => val === true || val === "true",
          then: () =>
            yup
              .array()
              .of(yup.string().label("Pointure"))
              .min(1)
              .label("Pointure")
              .required(),
        })
        .nullable()
        .label("Pointure"),
      taille: yup.boolean().required("Ce champ est obligatoire").nullable(),
      tailles: yup
        .array()
        .when("taille", {
          is: (val) => val === true || val === "true",
          then: () =>
            yup
              .array()
              .of(yup.string().label("Taille"))
              .min(1)
              .label("Taille")
              .required(),
        })
        .nullable()
        .label("Taille"),
    }),
    //validation for step2
    yup.object({
      description: yup.string().label("La description"),
      description_audio: yup.string().label("La description audio").nullable(),
      dimension: yup.string().label("La dimension"),
      weight: yup
        .number()
        .transform((val) => (isNaN(val) ? null : val))
        .nullable()
        .label("Le poids"),
    }),
    //validation for step3
    yup.object({
      tax: yup.string().label("Le taxe").nullable(),
      price: yup.string().label("Le prix").required(),
      quantity: yup.string().label("La quantité").required(),
    }),
    //validation for step4
    yup.object({
      hasEdit: yup.boolean().default(false),
      // image: yup
      //   .array()
      //   .of(yup.mixed().label("L'image"))
      //   .min(1)
      //   .required()
      //   .label("L'image")
      //   .nullable(),
      image: yup.array().when("hasEdit", {
        is: false,
        then: () =>
          yup
            .array()
            .of(yup.mixed().label("L'image"))
            .min(1)
            .required()
            .label("L'image")
            .nullable(),
      }),
    }),
  ];
  const currentValidationSchema = validationSchema[step];
  const methods = useForm({
    shouldUnregister: false,
    resolver: yupResolver(currentValidationSchema),
    mode: "onChange",
  });
  const {
    handleSubmit,
    register,
    trigger,
    control,
    formState: { errors },
    clearErrors,
    getValues,
    setValue,
    resetField,
  } = methods;
  const { user } = useSelector((s) => s?.user);
  const history = useHistory();
  const [sendData, { isLoading, isError, isSuccess, error }] =
    useAddOrEditProductMutation();
  const [audio, setAudio] = useState(null);
  const [option, setOption] = useState([]);
  const [pointure, setPointure] = useState([]);
  const [taille, setTaille] = useState([]);
  const [options, setOptions] = useState([]);
  const [images, setImages] = useState([]);
  const { data = [] } = useGetCategoryListQuery({});

  useEffect(() => {
    if (shop?.id && isSeller(user)) {
      setValue("provider", shop?.id);
    }
  }, [shop]);

  useEffect(() => {
    if (images?.length) {
      setValue(
        "image",
        images?.filter((s) => !s?.id)
      );
    } else {
      resetField("image");
    }
  }, [images]);

  useEffect(() => {
    if (errors) {
      console.log("errors", errors);
      setTimeout(
        () => Object.entries(errors).map(([key]) => clearErrors(key)),
        3000
      );
    }
  }, [errors]);

  useEffect(() => {
    if (data?.length) {
      setOptions(
        data?.map((item) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        })
      );
    }
  }, [data]);

  const callBackRecorder = (voiceRecorder) => {
    setAudio(voiceRecorder);
    setValue("description_audio", voiceRecorder);
  };

  const handleSelectCategory = (val) => {
    setOption(val);
    setValue("category", val);
  };

  const handleSelectPointure = (val) => {
    setPointure(val);
    setValue("pointures", val);
  };

  const handleSelectTaille = (val) => {
    setTaille(val);
    setValue("tailles", val);
  };

  useEffect(() => {
    if (product?.id) {
      setValue("hasEdit", true);
      const fields = [
        "name",
        "description",
        "price",
        "quantity",
        "brand",
        "model",
        "main_color",
        "dimension",
        "weight",
        "tax",
        "pointure",
        "taille",
        "provider",
        "category",
      ];
      for (let field of fields) {
        register(field);
        if (field !== "provider") {
          setValue(field, product[field]);
        } else {
          setValue(field, product[field]?.id);
        }
      }

      if (product?.pointure) {
        setPointure(product?.pointures?.split(","));
        setValue("pointures", product?.pointures?.split(","));
      } else {
        setValue("pointures", []);
      }

      if (product?.taille) {
        setTaille(product?.tailles?.split(","));
        setValue("tailles", product?.tailles?.split(","));
      } else {
        setValue("tailles", []);
      }

      if (product?.image?.length) {
        setImages(product?.image);
      } else {
        setValue("hasEdit", false);
        setImages([]);
      }

      if (product?.category) {
        setOption(product?.category);
      }
    } else {
      setValue("hasEdit", false);
    }
  }, [product]);

  useEffect(() => {
    if (isSuccess) {
      setStep(0);
      Swal.fire({
        icon: "success",
        title: "La produit a été enregistré avec succès!",
        iconColor: "#c501ff",
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        if (isSeller(user)) {
          history.push(`/articles`);
        } else {
          history.push(`/articles-admin`);
        }
      });
    }
    const err = error;

    if (isError) {
      console.log("error", err);

      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess, isLoading]);

  const handleNext = async (e) => {
    e?.preventDefault();

    const isStepValid = await trigger();

    if (isStepValid) setStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = (e) => {
    e?.preventDefault();
    setStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onSubmit = async (data) => {
    // console.log(data, "daaa");
    const fd = new FormData();

    for (let key of Object.keys(data)) {
      if (key === "image") {
        if (data?.image?.length) {
          data?.image.forEach((item) => {
            fd.append("image", item);
          });
        }
      } else if (key === "category") {
        if (data?.category?.length) {
          data?.category.forEach((item) => {
            fd.append("category", item);
          });
        }
      } else {
        fd.append(key, data[key]);
      }
    }

    await sendData({ id: product?.id, data: fd });
  };

  return {
    onSubmit: handleSubmit(onSubmit),
    register,
    handleBack,
    handleNext,
    step,
    errors,
    setValue,
    getValues,
    Controller,
    control,
    option,
    handleSelectCategory,
    options,
    audio,
    isLoading,
    callBackRecorder,
    taille,
    pointure,
    handleSelectPointure,
    handleSelectTaille,
    setImages,
    images,
  };
}

export default UseAddOrEditProduct;
export function UseDeleteImageProduct() {
  const [deleteData] = useDeleteImageProductMutation();
  const onDelete = async (id) => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cet image ?`,
      text: "Cet action supprime l'image définitivement.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: "#c501ff",
      confirmButtonColor: "#c501ff",
      preConfirm: () => {
        return deleteData(id);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      console.log(result, "archiver");
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `L'image a été supprimée avec succès!`,
            iconColor: "#c501ff",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onDelete;
}
