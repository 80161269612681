import React, { useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Loader, Message } from "rsuite";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";
import api from "../../../http/global-vars";
import { useDispatch } from "react-redux";
import { fetchOrders } from "../../../orderAction/orderAction";

function CommandeValidation({ order }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch();
  //   const [error, setError] = React.useState(null);
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        status: yup
          .string()
          .required("Veillez choisir le statut de la commande."),
      }),
    []
  );

  const { handleSubmit, formState, clearErrors, reset, setValue, register } =
    useForm({
      resolver: yupResolver(validationSchema),
    });

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
    },
  };

  useEffect(() => {
    if (order?.id) {
      setValue("status", order?.status);
    }

    return () => {
      reset();
    };
  }, [order]);

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 5000);
    }
  }, [formState, clearErrors]);

  const onSubmit = async (data, e) => {
    setIsLoading(true);
    console.log("data", data);
    await axios
      .put(`${api}order/${order?.id}/`, data, requestConfig)
      .then(async (res) => {
        console.log("res", res);
        if (res.status === 200 || res.status === 201) {
          await dispatch(fetchOrders());

          await Swal.fire({
            icon: "success",
            title: "Statut de la commande modifié avec succés.",
            showConfirmButton: false,
            timer: 1000,
          });
          await window.$("#orderStatus" + order?.id).modal("hide");
          //   toast.success("Mot de passe modifié avec succés.");
          await setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("error", error?.response);
        if (error?.response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: error?.response?.data?.message,
            showConfirmButton: false,
            timer: 5000,
          });
        } else {
          //   toast.error("Oops! Une erreur est survenue lors de la modification.");
          Swal.fire({
            icon: "error",
            title:
              "Oops! Une erreur est survenue lors de la soumission. Veuillez rééssayer plut tard.",
            showConfirmButton: true,
            // timer: 5000,
          });
        }
      });
  };

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title">
            Validation commande
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="row crud-row">
                <div className="col-md-12 crud-col-item">
                  <div className="form-group crud-form-group required" style={{textAlign: "start"}}>
                    <label htmlFor="statut">Statut</label>

                    <select
                      className="form-control"
                      style={{ borderRadius: "10px", height: 50 }}
                      name="status"
                      id="statut"
                      {...register("status")}
                    >
                      <option value="" hidden>
                        Sélectionner le statut de la commande
                      </option>
                      <option value="placed">En cours de préparation</option>
                      <option value="ready">Prêt à être livrer</option>
                      <option value="shipped">En cours de livraison</option>
                      <option value="delivered">Livrer</option>
                      <option value="canceled">Annuler</option>
                      <option value="returned">Retourner</option>
                      <option value="failed">Echec de livraison</option>
                    </select>

                    {formState?.errors && formState?.errors?.status && (
                      <Message
                        className="alert-rsuite-custom"
                        type="error"
                        description={formState?.errors?.status?.message}
                      />
                    )}
                  </div>
                </div>

                <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                  <button
                    className="btn btn-default cancelleb-btn"
                    data-dismiss="modal"
                  >
                    Retour
                  </button>

                  {!isLoading && (
                    <button className="btn btn-primary crud-submit-btn">
                      Enregistrer
                    </button>
                  )}

                  {isLoading && (
                    <button className="btn btn-primary crud-submit-btn">
                      En cours &nbsp;
                      <Loader />
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommandeValidation;
