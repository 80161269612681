import React, {Component, useState} from 'react';
import './commandesDashboardAdmin.css';
import HeaderDashboard from '../headerDashboard/index';
import NavVerticalDashboardAdmin from '../navVerticalDashboardAdmin/index';
// import TableCommande from '../tableCommande/index';
import AllOrders from '../tableCommande/allOrders';
import PlacedOrders from '../tableCommande/placedOrders';
import ReadyOrders from '../tableCommande/readyOrders';
import ShippingOrders from '../tableCommande/shippingOrders';
import DeliveredOrders from '../tableCommande/deliveredOrders';
import CanceledOrders from '../tableCommande/canceledOrders';
import FailedOrders from '../tableCommande/failedOrders';
import ReturnedOrders from '../tableCommande/returnedOrders';
import { connect } from 'react-redux'
import { fetchOrders } from '../../../orderAction/orderAction'
import { useGetOrderListQuery } from '../../../utils/api/order.api';

function CommandesDashboardAdmin() {
  const [status, setStatus] = useState("");
  const [query, setQuery] = useState("")
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const {
    data = {
      results: [],
      count: 0,
      placed_count: 0,
      shipped_count: 0,
      delivered_count: 0,
      canceled_count: 0,
      failed_count: 0,
      returned_count: 0,
      ready_count: 0,
    },
    isFetching,
    refetch,
  } = useGetOrderListQuery({ status,page,limit });

  return (
    <div className="component-commandes-dashboard-admin">
      <HeaderDashboard />
      <div className="fixed-after-header-dashboard container-fluid">
        <div className="row">
          <div className="col-xl-2 col-lg-3 no-view-tablette">
            <NavVerticalDashboardAdmin />
          </div>
          <div className="col-xl-10 col-lg-9 px-xl-3 py-4 pr-lg-3 pl-lg-1">
            <div className="card-dashboard-content-table">
              <div className="head-card-dashboard-content-table">
                <div className="row mb-5">
                  <div className="col-md-9 col-sm-8 mb-sm-0 mb-3">
                    <p className="title-filter-dashboard">
                      Toutes les commandes
                    </p>
                  </div>
                  <div className="col-md-3 col-sm-4 pl-sm-0"></div>
                </div>

                <div className="row">
                  <div className="col-xl-10">
                    <nav>
                      <div
                        className="nav nav-tabs nav-tabs-horizontal-commandes"
                        id="nav-tab"
                        role="tablist"
                      >
                        <button
                          // className="nav-link nav-horizontal-commandes active"
                          className={`nav-link nav-horizontal-commandes ${
                            status === "" ? "active" : ""
                          }`}
                          id="nav-tout-tab"
                          role="tab"
                          aria-controls="nav-tout"
                          aria-selected="true"
                          onClick={() => setStatus("")}
                        >
                          Tout
                        </button>
                        <button
                          className={`nav-link nav-horizontal-commandes ${
                            status === "placed" ? "active" : ""
                          }`}
                          id="nav-en-attente-tab"
                          role="tab"
                          aria-controls="nav-en-attente"
                          aria-selected="false"
                          onClick={() => setStatus("placed")}
                        >
                          En attente{" "}
                          <span class="badge badge-theme">
                            {data?.placed_count}
                          </span>
                        </button>
                        <button
                          className={`nav-link nav-horizontal-commandes ${
                            status === "ready" ? "active" : ""
                          }`}
                          id="nav-pret-a-livrer-tab"
                          role="tab"
                          aria-controls="nav-pret-a-livrer"
                          aria-selected="false"
                          onClick={() => setStatus("ready")}
                        >
                          Prêt à livré{" "}
                          <span class="badge badge-theme">
                            {data?.ready_count}
                          </span>
                        </button>
                        <button
                          className={`nav-link nav-horizontal-commandes ${
                            status === "shipped" ? "active" : ""
                          }`}
                          id="nav-en-livraison-tab"
                          role="tab"
                          aria-controls="nav-en-livraison"
                          aria-selected="false"
                          onClick={() => setStatus("shipped")}
                        >
                          En livraison{" "}
                          <span class="badge badge-theme">
                            {data?.shipped_count}
                          </span>
                        </button>
                        <button
                          className={`nav-link nav-horizontal-commandes ${
                            status === "delivered" ? "active" : ""
                          }`}
                          id="nav-livree-tab"
                          role="tab"
                          aria-controls="nav-livree"
                          aria-selected="false"
                          onClick={() => setStatus("delivered")}
                        >
                          Livré
                          <span class="badge badge-theme">
                            {data?.delivered_count}
                          </span>
                        </button>
                        <button
                          className={`nav-link nav-horizontal-commandes ${status === "canceled" ? "active" : ""}`}
                          id="nav-annulee-tab"
                          role="tab"
                          aria-controls="nav-annulee"
                          aria-selected="false"
                          onClick={() => setStatus("canceled")}
                        >
                          Annulé
                          <span class="badge badge-theme">
                            {data?.canceled_count}
                          </span>
                        </button>
                        <button
                          className={`nav-link nav-horizontal-commandes ${status === "failed" ? "active" : ""}`}
                          id="nav-echec-tab"
                          role="tab"
                          aria-controls="nav-echec"
                          aria-selected="false"
                          onClick={() => setStatus("failed")}
                        >
                          Echec{" "}
                          <span class="badge badge-theme">
                            {data?.failed_count}
                          </span>
                        </button>
                        <button
                          className={`nav-link nav-horizontal-commandes ${status === "returned" ? "active" : ""}`}
                          id="nav-retournee-tab"
                          role="tab"
                          aria-controls="nav-retournee"
                          aria-selected="false"
                          onClick={() => setStatus("returned")}
                        >
                          Retourné
                          <span class="badge badge-theme">
                            {" "}
                            {data?.returned_count}{" "}
                          </span>
                        </button>
                      </div>
                    </nav>
                  </div>
                  <div className="col-xl-2"></div>
                </div>
              </div>
              <div className="content-card-dashboard-content-table">
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="nav-tout"
                    role="tabpanel"
                    aria-labelledby="nav-tout-tab"
                  >
                    <AllOrders
                      commandes={data?.results}
                      fetchOrders={refetch}
                      isLoading={isFetching}
                      setPage={setPage}
                      page={page}
                      setLimit={setLimit}
                      limit={limit}
                      count={data?.count}
                      setQuery={setQuery}
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-en-attente"
                    role="tabpanel"
                    aria-labelledby="nav-en-attente-tab"
                  >
                    <PlacedOrders
                      commandes={data?.results}
                      fetchOrders={refetch}
                      isLoading={isFetching}
                      setPage={setPage}
                      page={page}
                      setLimit={setLimit}
                      limit={limit}
                      count={data?.count}
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-pret-a-livrer"
                    role="tabpanel"
                    aria-labelledby="nav-pret-a-livrer-tab"
                  >
                    <ReadyOrders
                      commandes={data?.results}
                      fetchOrders={refetch}
                      isLoading={isFetching}
                      setPage={setPage}
                      page={page}
                      setLimit={setLimit}
                      limit={limit}
                      count={data?.count}
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-en-livraison"
                    role="tabpanel"
                    aria-labelledby="nav-en-livraison-tab"
                  >
                    <ShippingOrders
                      commandes={data?.results}
                      fetchOrders={refetch}
                      isLoading={isFetching}
                      setPage={setPage}
                      page={page}
                      setLimit={setLimit}
                      limit={limit}
                      count={data?.count}
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-livree"
                    role="tabpanel"
                    aria-labelledby="nav-livree-tab"
                  >
                    <DeliveredOrders
                      commandes={data?.results}
                      fetchOrders={refetch}
                      isLoading={isFetching}
                      setPage={setPage}
                      page={page}
                      setLimit={setLimit}
                      limit={limit}
                      count={data?.count}
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-annulee"
                    role="tabpanel"
                    aria-labelledby="nav-annulee-tab"
                  >
                    <CanceledOrders
                      commandes={data?.results}
                      fetchOrders={refetch}
                      isLoading={isFetching}
                      setPage={setPage}
                      page={page}
                      setLimit={setLimit}
                      limit={limit}
                      count={data?.count}
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-echec"
                    role="tabpanel"
                    aria-labelledby="nav-echec-tab"
                  >
                    <FailedOrders
                      commandes={data?.results}
                      fetchOrders={refetch}
                      isLoading={isFetching}
                      setPage={setPage}
                      page={page}
                      setLimit={setLimit}
                      limit={limit}
                      count={data?.count}
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-retournee"
                    role="tabpanel"
                    aria-labelledby="nav-retournee-tab"
                  >
                    <ReturnedOrders
                      commandes={data?.results}
                      fetchOrders={refetch}
                      isLoading={isFetching}
                      setPage={setPage}
                      page={page}
                      setLimit={setLimit}
                      limit={limit}
                      count={data?.count}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommandesDashboardAdmin