import React, {Component} from 'react';
import Skeleton from 'react-loading-skeleton';

export default class filterBySellerSkeleton extends Component {
  constructor(props) {
      super(props);
      this.state = {};
  }

  
  
  render() {
    return <div className="component-filter-by-seller m-b-50">
      <Skeleton className="titre-filter-type mb-2" width={`50%`} />

      <Skeleton className="form-group has-search " height={32} />
      {Array(5).fill().map((seller, i) => ( 
        <Skeleton 
        className="custom-control custom-checkbox custom-checkbox-filter"
        height={16}
        width={`85%`}
        />
      ))}
    </div>
  }
}