import React, { Component } from "react";
import HeaderDashboard from "../headerDashboard/index";
import NavVerticalDashboardAdmin from "../navVerticalDashboardAdmin/index";
import FeatherIcon from "feather-icons-react";
import AdminsTable from "./AdminsTable";

export default class GestionAdmins extends Component {
  render() {
    return (
      <div className="component-articles-dashboard-admin">
        <HeaderDashboard />
        <div className="fixed-after-header-dashboard container-fluid">
          <div className="row">
            <div className="col-xl-2 col-lg-3 no-view-tablette">
              <NavVerticalDashboardAdmin />
            </div>
            <div className="col-xl-10 col-lg-9 px-xl-3 py-4 pr-lg-3 pl-lg-1">
              <div className="card-dashboard-content-table">
                <div className="head-card-dashboard-content-table">
                  <div className="row mb-5">
                    <div className="col-md-9 col-sm-8 mb-sm-0 mb-3">
                      <p className="title-filter-dashboard">
                        Gestion des administrateurs
                      </p>
                    </div>
                    <div className="col-md-3 col-sm-4 pl-sm-0">
                      <div
                        className="adding-item-container linkable"
                        data-toggle="modal"
                        data-target=".add-admin-modal"
                      >
                        <span className="icon-container">
                          <FeatherIcon
                            icon="plus-circle"
                            className="icon-action-table-grey"
                          />
                        </span>
                        <span className="adding-item-label">
                          Ajouter un administrateur
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="row"></div>
                </div>
                <div className="content-card-dashboard-content-table">
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="nav-tout"
                      role="tabpanel"
                      aria-labelledby="nav-tout-tab"
                    >
                      <AdminsTable />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
