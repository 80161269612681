import moment from "moment";
import baseUrl from "../http/backend-base-url";
import Avatar from "../img/avatar.png";
import LogoShop from "../img/logo-item.png";
import BannerShop from "../img/bannerBoutique.png";
import { useLocation, useHistory } from "react-router-dom";
import { useEffect } from "react";

/**
 * Function returning the build date(as per provided epoch)
 * @param epoch Time in milliseconds
 */
export const getBuildDate = (epoch) => {
  const buildDate = moment(epoch).format("DD-MM-YYY HH:MM");
  return buildDate;
};

export const formatMoney = (num) => {
  // console.log(num)
  var p = parseInt(num).toFixed(2).split(".");
  return (
    p[0]
      .split("")
      .reverse()
      .reduce(function (acc, num, i, orig) {
        return num === "-" ? acc : num + (i && !(i % 3) ? " " : "") + acc;
      }, "") + " CFA"
  );
};

export const getBanner = (image) => {
  if (
    image &&
    !image?.includes("default.png") &&
    !image?.includes("uploads/bannier.png")
  ) {
    if (image.indexOf("http") === 0) return image;
    return baseUrl + image || BannerShop;
  }
  return BannerShop;
};

export const getImage = (image) => {
  if (
    image &&
    !image?.includes("default.png") &&
    !image?.includes("mediafiles/logo.png")
  ) {
    if (image.indexOf("http") === 0) return image;
    return baseUrl + image || LogoShop;
  }
  return LogoShop;
};

export const getAvatar = (avatar) => {
  if (avatar && !avatar?.includes("default.png")) {
    if (avatar.indexOf("http") === 0) return avatar;
    return baseUrl + avatar || Avatar;
  }
  return Avatar;
};

export function QueryUrl(baseUrl, additionalSearchQuery) {
  if (additionalSearchQuery) {
    for (let key of Object.keys(additionalSearchQuery)) {
      let val = additionalSearchQuery[key];
      if (Array.isArray(val)) {
        if (val.length > 0) {
          const string = val.map((v) => `${key}=${v}`).join("&");
          if (baseUrl.includes("?")) {
            baseUrl += `&${string}`;
          } else {
            baseUrl += `?${string}`;
          }
        }
      } else if (typeof val === "boolean") {
        if (baseUrl.includes("?")) {
          baseUrl += `&${key}=${val}`;
        } else {
          baseUrl += `?${key}=${val}`;
        }
      } else {
        if (val) {
          if (baseUrl.includes("?")) {
            baseUrl += `&${key}=${val}`;
          } else {
            baseUrl += `?${key}=${val}`;
          }
        }
      }
    }
  }
  return baseUrl;
}

export const isAdmin = (user) => {
  return user?.user_type === "admin" || user?.user_type === "superadmin";
};

export const isSeller = (user) => {
  return user?.user_type === "vendeur";
};

export const isUser = (user) => {
  return user?.user_type === "usager" || user?.user_type === "standard";
};

export function useLocationState(defaultValue, redirectToWhenNull = null) {
  const state = useLocation().state;
  const navigate = useHistory();
  useEffect(() => {
    if (!state && redirectToWhenNull) {
      navigate.push(redirectToWhenNull);
    }
  }, [state]);
  return state || defaultValue;
}

export function cleannerError(errors, cleanner, timeOut = 3000) {
  if (errors) {
    setTimeout(
      () => Object.entries(errors).map(([key]) => cleanner(key)),
      timeOut
    );
  }
}
