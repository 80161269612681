import React, { useState, useEffect, useCallback } from 'react'
import './search.css'
// import FeatherIcon from 'feather-icons-react';
import api from './../../http/global-vars'
import axios from 'axios'
import Suggestions from './Suggestions'
import SearchIcon from '@material-ui/icons/Search'
import { NavLink } from 'react-router-dom'

function Search() {
  const [query, setQuery] = useState('')
  const [results, setResults] = useState([])
  const [searching, setSearching] = useState(false)

  useEffect(() => {
    getInfo(query)
  }, [query])

  const getInfo = (word) => {
    if (word?.length) {
      let url = api + `search/?word=${word}`;
      axios.get(`${url}`).then(({ data }) => {
        // console.log('res', data)
        setResults(data);
        setSearching(true);
      });
    } else {
      setResults([]);
      setSearching(false);
    }
    
  }

  const handleInputChange = useCallback((e) => {
    setQuery(e.target.value)
  }, [])

  return (
    <form>
      <div className="inner-form">
        <div className="input-field second-wrap pos-relative">
          <input
            type="text"
            placeholder="Chercher un produit..."
            onChange={(e) => handleInputChange(e)}
            autoComplete={true}
          />
          <SearchIcon className="icon-input-search" />
          {query.length ? (
            // <Suggestions results={results} query={query} />
            <ul className="search-result-container">
              {results.map((r) => {
                return (
                  <li
                    key={r?.name + r?.slug + r?.id}
                    className="search-result-item"
                  >
                    <NavLink
                      to={
                        r?.name_type !== 'shop'
                          ? `/details-produit/${r?.id}`
                          : `/boutique/${r?.id}`
                      }
                      key={`search${r?.slug}`}
                      className="no-link"
                    >
                      {r?.name}
                    </NavLink>
                  </li>
                )
              })}
            </ul>
          ) : null}
        </div>
      </div>
    </form>
  )
}

export default Search
