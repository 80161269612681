import React, { Component, useEffect, useState } from "react";
import "./checkoutContainer.css";
import CartItem from "../cartItem/index";
import { connect, useDispatch, useSelector } from "react-redux";
// import { getCartItems, removeCart } from "../reduxCart/cartActions";
import CheckoutProcessSteps from "../checkoutProcessSteps/index";
import EmptyCartImage from "../../img/empty_cart.png";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import api from "../../http/global-vars";
import CheckoutSkeleton from "../skeleton/checkoutSkeleton/checkoutSkeleton";
import { Modal } from "rsuite";
import ConfirmationIcon from "../../img/icons/check-icon.png";
import {
  Loader,
  // Message
} from "rsuite";
import Moment from "react-moment";
import Swal from "sweetalert2";
import {
  getCartCount,
  getDeliveryAmount,
  getSubTotal,
  getTotalAmount,
  removeCart,
} from "../../utils/api/slice/useCartSlice";

function CheckoutContainer() {
  const dispatch = useDispatch();
  const { cartItems, subAmount, totalCount, totalAmount } = useSelector(
    (state) => state.cart
  );
  const { user, token } = useSelector((state) => state?.user);
  const localCartItems = useSelector((state) => state.cart?.cartItems);
  const total = useSelector((state) => state.cart.total);
  // const format = useFormatAmount();
  // const child = useRef();
  const { search } = useLocation();
  const history = useHistory();
  const [errors, setErrors] = useState({});
  const [fields, setFields] = useState({});
  const [loading, setLoading] = useState(true);
  const [orderInProgress, setOrderInProgress] = useState(false);
  const [userInfos, setUserInfos] = useState({});
  const [is_empty_livraison, setIs_empty_livraison] = useState(false);
  const [is_empty_paiement, setIs_empty_paiement] = useState(false);
  const [is_empty_adresse, setIs_empty_adresse] = useState(false);
  const [cartByUser, setCartByUser] = useState({});
  const [orderInfos, setOrderInfos] = useState({});
  const [payementType, setPayementType] = useState({});
  const [showConfirmation, setshowConfirmation] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [livraison, setLivraison] = useState({ amount: 0 });
  let requestConfig = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
    },
  };
  let orderCode = window.localStorage.getItem("orderCode");

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(getDeliveryAmount(0));
    getCartItems();
    var config = requestConfig;

    if (token) {
      onGetCart();
    }
  }, []);

  useEffect(() => {
    // console.log("params", search);
    if (search && token) {
      if (search?.includes("success")) {
        // alert("cool");
        Swal.fire({
          icon: "success",
          title: "Paiement effectué avec succès!",
          text: "Vous pouvez suivre l'évolution de votre commande.",
          showConfirmButton: true,
          // timer: 3000,
          confirmButtonText: "Voir les détails de votre commande",
          iconColor: "#c501ff",
          allowOutsideClick: false,
          confirmButtonColor: "#c501ff",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await localStorage.removeItem("cartItems");
            await history.push(`/mon-compte/?${"commande"}`);
            await remove();
          }
        });
      }

      if (search?.includes("error")) {
        // alert("cool");
        Swal.fire({
          icon: "error",
          title: "Paiement échoué!",
          text: "Veuillez réessayer de nouveau depuis les détails de la commande.",
          showConfirmButton: true,
          // timer: 3000,
          confirmButtonText: "Voir les détails de votre commande",
          // iconColor: "#B5853B",
          allowOutsideClick: false,
          confirmButtonColor: "#c501ff",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await localStorage.removeItem("cartItems");
            await history.push(`/mon-compte/${"commande"}`);
            await remove();
          }
        });
      }
    }
  }, [search, token, orderCode]);

  const onGetCart = () => {
    var url = api + `cartbyuser/${user?.id}/`;
    var config = requestConfig;

    axios
      .get(url, config)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setCartByUser(response.data[0]);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getCartItems = () => {
    dispatch(getSubTotal());
    dispatch(getCartCount());
    dispatch(getTotalAmount());
  };

  const remove = async (e) => {
    // e.preventDefault()
    //with redux
    await window.scrollTo({ top: 0, behavior: "smooth" });
    await dispatch(removeCart());
    await getCartItems();
  };

  const addCart = async (e) => {
    e.preventDefault();
    const data = {};
    data["number_of_items"] = cartItems?.length;
    data["total"] = totalAmount;

    data["shipping"] = parseInt(livraison?.amount);
    data["order_total"] = (
      parseInt(totalAmount) + parseInt(livraison?.amount)
    ).toFixed(2);
    data["delivery"] = fields?.livraison;
    data["payment_mode"] = fields?.paiement;
    data["owner"] = parseInt(user?.id);
    data["user"] = parseInt(user?.id);
    data["billing_address"] = user?.adresse;
    data["shipping_address"] = user?.adresse;

    const url = api + "cart/";
    if (onValidateOrder()) {
      if (token) {
        // console.log("data", data);
        window.localStorage.removeItem("modePayment");
        window.localStorage.removeItem("orderData");
        setOrderInProgress(true);
        await axios
          .post(url, data, requestConfig)
          .then(async (response) => {
            // console.log("response", response)

            await postItem(response.data.id);
          })
          .catch((e) => {
            console.log("error cart", e?.response);
            setOrderInProgress(false);
          });
      } else {
        window.localStorage.setItem(
          "modePayment",
          JSON.stringify(payementType)
        );
        window.localStorage.setItem("orderData", JSON.stringify(data));
        Swal.fire({
          icon: "warning",
          title: "Vous avez déjà un compte?",
          text: "Connectez-vous pour valider vos achats.",
          showConfirmButton: true,
          // timer: 3000,
          confirmButtonText: "Connexion",
          allowOutsideClick: false,
        }).then(async (result) => {
          if (result.isConfirmed) {
            await history.push(`/connexion/?next=${window.location.pathname}`);
          }
        });
      }
    } else {
      window.scrollTo(0, 0);
    }
  };

  const postItem = async (idCart) => {
    // eslint-disable-next-line

    await cartItems.forEach(async (item) => {
      const url = api + "cartitem/";
      await axios
        .post(
          url,
          {
            quantity: item?.qty,
            cart: idCart,
            item: item?.product?.id,
            mode_livraison: livraison?.id,
            price: item?.product?.shown_price,
            pointure: item?.selectedPointure || "",
            taille: item?.selectedSize || "",
          },
          requestConfig
        )
        .then(() => {})
        .catch((e) => {
          console.log("error", e?.response);
        });
    });

    setTimeout(async () => {
      let url;
      if (payementType?.name?.toLowerCase() === "espece") {
        onSubmitOrder(idCart);
      } else {
        url = `${api}paydunya/?cart=${idCart}&page=${"checkout"}&env=${
          window.location.origin
        }`;
      }
      if (url) {
        await axios
          .get(url)
          .then(async (res) => {
            await closeLoading();
            console.log("res", res);
            if (res?.data?.url) {
              await window.open(`${res.data.url}`, "_parent");
            }

            if (res?.data?.id) {
              await setOrderInfos(res?.data);
              await openConfirmation();
            }
          })
          .catch((err) => {
            console.log("err", err?.response);
            closeLoading();
            setOrderInProgress(false);
            if (err?.response?.data?.message) {
              Swal.fire({
                icon: "error",
                title: err?.response?.data?.message,
                showConfirmButton: false,
                timer: 3000,
              });
            } else {
              Swal.fire({
                icon: "error",
                title:
                  "Une erreur est survenue lors de la validation, veuillez reessayer plus tard !",
                showConfirmButton: false,
                timer: 3000,
              });
            }
          });
      }

      // await remove();
    }, 3000);
  };

  const handleChange = (e, data) => {
    fields[e.target.name] = e.target.value;

    if (e.target.name === "livraison") {
      setLivraison(data);
      dispatch(getDeliveryAmount(parseInt(data?.amount)));
      getCartItems();
    }
    if (e.target.name === "paiement") {
      setPayementType(data);
    }

    setFields(fields);
  };

  const onValidateOrder = () => {
    var isValidForm = true;

    if (!fields["livraison"]) {
      isValidForm = false;

      setIs_empty_livraison(true);
      setTimeout(() => {
        setIs_empty_livraison(false);
      }, 5000);
      errors["livraison"] =
        "Veuillez séléctionner le mode de livraison que vous souhaitez!";
    }

    if (!fields["paiement"]) {
      isValidForm = false;
      setIs_empty_paiement(true);
      setTimeout(() => {
        setIs_empty_paiement(false);
      }, 5000);
      errors["paiement"] = "Veuillez séléctionner un mode de paiement!";
    }

    if (!user?.adresse) {
      // console.log("adresse", userInfos);
      isValidForm = false;

      setIs_empty_adresse(true);
      setTimeout(() => {
        setIs_empty_adresse(false);
      }, 5000);
      errors["adresse"] = "Veuillez renseigner votre adresse !";
    }
    setErrors(errors);

    return isValidForm;
  };

  const onSubmitOrder = (cartId) => {
    // e.preventDefault()

    if (onValidateOrder()) {
      // var userCart = this.state.cartByUser
      var config = requestConfig;
      

      let order = {};
      order["status"] = "placed";
      order["billing_address"] = user?.adresse;
      order["shipping_address"] = user?.adresse;
      order["shipping"] = livraison?.amount;
      order["order_total"] = parseInt(totalAmount);
      order["cart"] = cartId;
      order["user"] = user?.id;
      order["delivery"] = fields.livraison;
      order["payment_mode"] = fields.paiement;

      console.log("order", order);

      axios
        .post(api + "order/", order, config)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            setOrderInfos(response?.data);
            setOrderInProgress(false);
            if (payementType.name === "espece") {
              openConfirmation();
            }
          }
        })
        .catch((error) => {
          setOrderInProgress(false);
        });
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const closeLoading = (e) => {
    e?.preventDefault();
    setShowLoading(false);
  };

  const closeConformation = (e) => {
    e.preventDefault();
    setshowConfirmation(false);
    remove();
  };
  const openConfirmation = () => {
    setshowConfirmation(true);
  };
  if (loading) {
    return <CheckoutSkeleton />;
  } else {
    if (!cartItems || cartItems.length === 0) {
      return (
        <section className="cart-content checkout">
          <img
            loading="lazy"
            className="image-cart-empty"
            src={EmptyCartImage}
            alt=""
          />
          <header>
            <h2 className="h-theme">Votre panier est vide!</h2>
            <h4 className="">
              Veuillez ajouter des produits pour pouvoir acheter.
            </h4>
          </header>
          <div className="dis-flex justify-content-center mt-5">
            <div className="col-md-5 col-sm-7">
              <NavLink
                className="btn-theme dis-flex justify-content-center"
                to="/tous-les-produits"
              >
                Commencer mes achats
              </NavLink>
            </div>
          </div>
        </section>
      );
    } else {
      return (
        <div>
          <CheckoutProcessSteps
            userInfos={user}
            handleChange={handleChange}
            is_empty_livraison={is_empty_livraison}
            is_empty_paiement={is_empty_paiement}
            errors={errors}
            // onRef={(ref) => (child = ref)}
            is_empty_adresse={is_empty_adresse}
          />
          <div className="row px-md-0">
            <div className="container-fluid px-md-2">
              <div className="item-step-checkout p-md-4 m-b-30">
                <p className="text-commande-item m-b-30 fw-600 fs-14">
                  Détails de la livraison
                </p>

                <article>
                  {cartItems?.map((item) => {
                    return (
                      <CartItem
                        key={item?.product.id}
                        item={item}
                        data={cartItems}
                      />
                    );
                  })}
                </article>

                <div className="row">
                  <div className="col-lg-8 dis-flex itm-center">
                    <div className="row w-full m-b-30">
                      <div className="col-lg-8 col-md-10">
                        <p className="resume-item fw-300 fs-14">
                          Date de livraison
                        </p>
                        {/* <p className="resume-item fw-600 fs-14 ">Livré entre jeudi 3 sept. et vendredi 4 sept.</p> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <p className="resume-item fw-400 fs-14 m-b-20">
                      Prix ​​sous-total :{" "}
                      <span className="value-resume-item fs-14 fw-700">
                        {Intl.NumberFormat("fr-FR").format(parseInt(subAmount))}{" "}
                        FCFA
                      </span>
                    </p>
                    <p className="resume-item fw-400 fs-14 m-b-20">
                      Frais de livraison (+) :{" "}
                      <span className="value-resume-item fs-14 fw-700">
                        {Intl.NumberFormat("fr-FR").format(
                          parseInt(livraison?.amount || 0)
                        )}{" "}
                        FCFA
                      </span>
                    </p>
                    <p className="resume-item fw-400 fs-14 m-b-20">
                      Remise (-) :{" "}
                      <span className="value-resume-item fs-14 fw-700">
                        0 FCFA
                      </span>
                    </p>
                    <p className="resume-item fw-700 itm-center m-b-20">
                      Total à payer :{" "}
                      <span className="value-resume-item-ttc">
                        {Intl.NumberFormat("fr-FR").format(
                          parseInt(totalAmount)
                        )}{" "}
                        FCFA
                      </span>
                    </p>
                  </div>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 mt-5 pl-sm-0">
                  <button
                    className="btn-theme-light py-2 text-up w-full"
                    onClick={(e) => remove(e)}
                  >
                    Vider le panier
                  </button>
                </div>
              </div>
              <div className="row dis-flex justify-content-center">
                <div className="col-md-7">
                  {!orderInProgress ? (
                    <button
                      onClick={(e) => addCart(e)}
                      className="btn-user-info-form text-up"
                    >
                      CONFIRMER LA COMMANDE
                    </button>
                  ) : (
                    <button disabled className="btn-form-dark in-progress-btn">
                      COMMANDE EN COURS &nbsp; <Loader />
                    </button>
                  )}

                  {/* Modal de confirmation  */}
                  <div className="modal-container">
                    <Modal
                      size="xs"
                      show={showConfirmation}
                      onHide={closeConformation}
                      className="rsuite-content-modal-custom"
                    >
                      <form>
                        <Modal.Header>
                          <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <div className="my-5 flex-col itm-center">
                          <img
                            loading="lazy"
                            src={ConfirmationIcon}
                            alt=""
                            className="icon-confirmation-modal mb-4"
                          />
                          <p className="title-confirmation-modal mb-4">
                            Votre commande à été prise en compte
                          </p>

                          <p className="text-confirmation-modal">
                            <span className="fw-500">
                              Numéro de commande :{" "}
                            </span>
                            {orderInfos.order_code}
                          </p>
                          <p className="text-confirmation-modal">
                            <span className="fw-500">Date de commande : </span>{" "}
                            <Moment format="DD/MM/YYYY">
                              {orderInfos.order_date}
                            </Moment>
                          </p>
                          {/* <p className="text-confirmation-modal">
                              <span className="fw-500">
                                Date de livraison :{" "}
                              </span>{" "}
                              08/09/2020
                            </p> */}
                          {/* <p className="text-confirmation-modal"><span className="fw-500">Mode de paiement : </span> {this.state.orderInfos.payment_mode}</p> */}
                          <p className="text-confirmation-modal">
                            <span className="fw-500">Total : </span>
                            {Intl.NumberFormat("fr-FR").format(
                              parseInt(totalAmount)
                            )}{" "}
                            FCFA
                          </p>
                        </div>
                        <Modal.Footer className="text-center">
                          <button
                            onClick={closeConformation}
                            className="btn-change-password"
                          >
                            Fermer
                          </button>
                        </Modal.Footer>
                      </form>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default CheckoutContainer;
