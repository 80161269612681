import React, { Component } from "react";
import "./header.css";
import FeatherIcon from "feather-icons-react";
import { NavLink } from "react-router-dom";
import Logo from "../../img/Diekkes.png";
import CategorieListAccueil from "../categorieListAccueil/index";
import api from "./../../http/global-vars";
import axios from "axios";
import CartCounter from "../cartCounter/index";
import Search from "../search/index";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Skeleton from "react-loading-skeleton";
import { FormatColorReset } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { isAdmin, isSeller, isUser } from "../../utils/utils";

function Header() {
  const { user } = useSelector((s) => s?.user);
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white box-shadow nav-header-custom fixed-top">
      <div className="container-fluid pl-xl-5 pl-lg-3">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <NavLink className="navbar-brand" to="/">
          <img loading="lazy" className="logo-header" src={Logo} alt="Marketplace" />
        </NavLink>

        <div className="dropdown dropdown-categorie-header-desktop">
          <button
            className="btn-dropdown-categorie-header"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <FeatherIcon
              className="icon-btn-dropdown-categorie-header"
              icon="menu"
            />
          </button>
          <div
            className="dropdown-menu content-dropdown-categorie-header"
            aria-labelledby="dropdownMenuButton"
          >
            <CategorieListAccueil />
          </div>
        </div>

        <div className="no-view-desktop">
          <CartCounter />
        </div>

        <div className="collapse navbar-collapse" id="navbarNav">
          <div className="s003 col-xl-5 col-lg-5 col-md-5 no-view-tablette">
            <Search />
          </div>
          <form className="form-inline no-view-tablette">
            {!isSeller(user) && !isAdmin(user) ? (
              <NavLink
                className="link-header-item mr-xl-3 mr-lg-2 b-rh-0 add-vendeur-btn"
                to="/inscription-vendeur"
              >
                <p className="text-white fw-400 fs-14 ff-raleway text-left">
                  Devenir
                </p>
                <p className="text-item-header h-theme fw-700 fs-14 text-left ff-raleway">
                  Vendeur
                </p>
              </NavLink>
            ) : (
              <></>
            )}

            {isSeller(user) ? (
              <NavLink className="link-header-item" to="/dashboard-vendeur">
                <DashboardIcon className="icon-item-header" />
                <p className="text-item-header">Ma boutique</p>
              </NavLink>
            ) : (
              <></>
            )}

            {isAdmin(user) ? (
              <NavLink className="link-header-item" to="/dashboard-admin">
                <DashboardIcon className="icon-item-header" />
                <p className="text-item-header">Tableau de bord</p>
              </NavLink>
            ) : (
              <></>
            )}

            <CartCounter />

            <NavLink className="link-header-item" to="/mes-favoris">
              <FeatherIcon className="icon-item-header" icon="heart" />
              <p className="text-item-header">Whishlist</p>
            </NavLink>
            <NavLink className="link-header-item b-rh-0" to="/mon-compte">
              <AccountCircleIcon className="icon-item-header" />
              {/* <FeatherIcon className="icon-item-header" icon="user" /> */}
              <p className="text-item-header">Mon compte</p>
            </NavLink>
          </form>
          <div className="no-view-desktop mt-4 body-theme p-4">
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <NavLink
                  className="dis-block ml-0 link-navbar-mobile m-b-15"
                  to=""
                  id="navbarDropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <FeatherIcon
                    className="icon-navbar-mobile mr-2"
                    icon="menu"
                  />
                  Catégories
                </NavLink>
                <div
                  className="dropdown-menu dropdown-menu content-dropdown-categorie-header m-b-20"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <CategorieListAccueil />
                </div>
              </li>
            </ul>

            {!isSeller(user) && !isAdmin(user) ? (
              <div className="m-b-15">
                <NavLink
                  to="/inscription-vendeur"
                  className="link-navbar-mobile"
                >
                  <FeatherIcon
                    className="icon-navbar-mobile"
                    icon="shopping-bag"
                  />{" "}
                  Devenir vendeur
                </NavLink>
              </div>
            ) : (
              <></>
            )}

            {isSeller(user) ? (
              <div className="m-b-15">
                <NavLink to="/dashboard-vendeur" className="link-navbar-mobile">
                  <FeatherIcon
                    className="icon-navbar-mobile"
                    icon="shopping-bag"
                  />{" "}
                  Ma boutique
                </NavLink>
              </div>
            ) : (
              <></>
            )}

            <div className="m-b-15">
              <NavLink to="/mes-favoris" className="link-navbar-mobile">
                <FeatherIcon className="icon-navbar-mobile" icon="heart" />{" "}
                Whishlist
              </NavLink>
            </div>
            <div className="m-b-15">
              <NavLink to="/mon-compte" className="link-navbar-mobile">
                <FeatherIcon className="icon-navbar-mobile" icon="user" /> Mon
                compte
              </NavLink>
            </div>
            <div className="">
              <NavLink to="" className="link-navbar-mobile">
                <FeatherIcon className="icon-navbar-mobile" icon="phone" />{" "}
                Contact
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className="s003 col-md-12 p-l-0 p-r-0 m-t-20 no-view-desktop">
        <Search />
      </div>
    </nav>
  );
}

export default Header;
