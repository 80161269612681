import React, {Component} from 'react';
import './commentaireItem.css';
import { Rate } from 'rsuite';
import CommentaireItemSkeleton from '../skeleton/commentaireItemSkeleton/commentaireItemSkeleton';
import Moment from 'react-moment';
import baseUrl from '../../http/backend-base-url';

export default class commentaireItem extends Component {
  constructor(props) {
      super(props);
      this.state = {
        loading: false,
        baseUrl: baseUrl,
      };
  }

  componentDidMount() {
    this.setState({ loading: true });
    const timer = setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);

    return () => clearTimeout(timer);
  }

  render() {
    const baseUrl = this.state.baseUrl;
    if(this.state.loading){
      return <CommentaireItemSkeleton/>
    }
    else{
      return  this.props.evaluationByFournisseur.slice(0, this.state.limit).map(evaluation => (
        <li className="item-content-commentaire-content-details-page" key={evaluation.id}>
          <div className="row">
            <div className="col-lg-2 col-md-3 col-sm-4 col-7 mb-3">
              <img loading="lazy" className="w-full" src={baseUrl + evaluation.product.image[0]} alt={evaluation.product.name} />
            </div>
            <div className="col-md-8 col-sm-8">
              <div className="col-lg-6 col-md-9 px-0 content-titre-product-commentaire-item mb-2">
                <p className="titre-product-commentaire-item">{evaluation.product.name}</p>
              </div>
              <Rate value={evaluation.star} allowHalf readOnly className="rating-comment-product" />

              <p className="commentaire-product-commentaire-item">“{evaluation.text}”</p>
              <p className="auteur-product-commentaire-item">
                <Moment format="DD MM YYYY">
                {evaluation.created_at} 
                </Moment> {" "}
                 par {evaluation.user.first_name + " " + evaluation.user.last_name}
              </p>
            </div>
          </div>
        </li>
      ));
    }
  }
}