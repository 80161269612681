import React, { Component } from "react";
import "./userInformations.css";
import FeatherIcon from "feather-icons-react";
import { Loader, Message } from "rsuite";
import ChangePasswordModal from "../changePasswordModal/index";
import UseEditUserInfos from "./form/UseEditUserInfos";

function UserInformations() {
  const { onSubmit, register, errors, isLoading } = UseEditUserInfos();
  return (
    <div className="content-card-account-infos">
      <ChangePasswordModal />
      <form onSubmit={onSubmit}>
        <div className="row m-t-50">
          <div className="col-md-6">
            <div className="form-group-user-info">
              <label htmlFor="inputPrenom" className="user-info-label">
                Prénom
              </label>
              <div className="pos-relative">
                <input
                  type="text"
                  className="form-control user-info-input"
                  id="inputPrenom"
                  placeholder="Prénom"
                  {...register("first_name")}
                />
                <FeatherIcon className="user-info-input-icon" icon="edit" />
              </div>
              {errors?.first_name && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={errors?.first_name?.message}
                />
              )}
            </div>
            <div className="form-group-user-info">
              <label htmlFor="inputNom" className="user-info-label">
                Nom
              </label>
              <div className="pos-relative">
                <input
                  type="text"
                  className="form-control user-info-input"
                  id="inputNom"
                  placeholder="Nom"
                  {...register("last_name")}
                />
                <FeatherIcon className="user-info-input-icon" icon="edit" />
              </div>
              {errors?.last_name && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={errors?.last_name?.message}
                />
              )}
            </div>
            <div className="form-group-user-info">
              <label htmlFor="inputEmail" className="user-info-label">
                Adresse mail
              </label>
              <div className="pos-relative">
                <input
                  type="email"
                  className="form-control user-info-input"
                  id="inputEmail"
                  placeholder="Adresse mail"
                  {...register("email")}
                />
                <FeatherIcon className="user-info-input-icon" icon="edit" />
              </div>
              {errors?.email && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={errors?.email?.message}
                />
              )}
            </div>
            <div className="form-group-user-info">
              <label htmlFor="inputTelephone" className="user-info-label">
                N° téléphone
              </label>
              <div className="pos-relative">
                <input
                  type="text"
                  className="form-control user-info-input"
                  id="inputTelephone"
                  placeholder="Tel"
                  {...register("phone")}
                />
                <FeatherIcon className="user-info-input-icon" icon="edit" />
              </div>
              {errors?.phone && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={errors?.phone?.message}
                />
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group-user-info">
              <label
                htmlFor="inputAdresseLivraison"
                className="user-info-label"
              >
                Adresse de livraison
              </label>
              <div className="pos-relative">
                <input
                  type="text"
                  className="form-control user-info-input"
                  id="inputAdresseLivraison"
                  placeholder="Saisir une adresse de livraison"
                  {...register("adresse")}
                />
                <FeatherIcon className="user-info-input-icon" icon="edit" />
              </div>
              {errors?.adresse && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={errors?.adresse?.message}
                />
              )}
            </div>
            <div className="form-group-user-info">
              <label htmlFor="inputRegion" className="user-info-label">
                Région
              </label>
              <div className="pos-relative">
                <input
                  type="text"
                  className="form-control user-info-input"
                  id="inputRegion"
                  placeholder="Saisir votre region"
                  {...register("region")}
                />
                <FeatherIcon className="user-info-input-icon" icon="edit" />
              </div>
              {/* {this.state.is_empty_adresse && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={this.state.errors["adresse"]}
                />
              )} */}
            </div>
            <div className="form-group-user-info">
              <label htmlFor="inputVille" className="user-info-label">
                Ville
              </label>
              <div className="pos-relative">
                <input
                  type="text"
                  maxLength={30}
                  className="form-control user-info-input"
                  id="inputVille"
                  placeholder="Saisir votre ville"
                  {...register("ville")}
                />
                <FeatherIcon className="user-info-input-icon" icon="edit" />
              </div>
            </div>
          </div>
        </div>
        <div className="row dis-flex">
          <div className="ml-auto col-lg-6 col-md-7 m-t-50">
            {!isLoading ? (
              <button type="submit" className="btn-user-info-form text-up">
                ENREGISTRER LES MODIFICATIONS
              </button>
            ) : (
              <button
                type="submit"
                className="btn-user-info-form in-progress-btn dis-flex justify-content-center itm-center"
              >
                Mise à jour en cours &nbsp;
                <Loader />
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

export default UserInformations;
