import { SHOPS, PROMOTESHOP, ALLSHOPS } from '../types';
import api from '../http/global-vars';


export const fetchShops = () => {
  return async (dispatch) => {
    const url = api + 'visiteur/fournisseur/'
    const res = await fetch(url)
    const data = await res.json()
    
    dispatch({
      type: SHOPS,
      payload: data.results,
      isLoading: false,
    });
  }
}

export const fetchAllShops = () => {
  return async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const url = api + "fournisseur/";
    const res = await fetch(url,config);
    const data = await res.json();

    dispatch({
      type: ALLSHOPS,
      payload: data.results,
      isLoading: false,
    });
  };
};

export const fetchPromoteShops = () => {
    return async (dispatch) => {
      // const config = {
      //   headers: {
      //     Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
      //   },
      // }
      const url = api + 'fournisseurbypromote/'
      const res = await fetch(url)
      const data = await res.json()
      // console.log('poooopoooo', data)
      dispatch({
        type: PROMOTESHOP,
        payload: data.filter((res) => res?.is_archive === false),
        isLoading: false
      });
    }
}

