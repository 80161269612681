import React, { useEffect, useState } from "react";
import "./ConditionGeneral.css";

import Header from "../header/index";
import Footer from "../footer/index";
import axios from "axios";
import api from "../../http/global-vars";
import Skeleton from "react-loading-skeleton";

function Politique() {
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [pc, setPc] = useState({});
  useEffect(() => {
    window.scrollTo(10,10)
  }, [])
  useEffect(() => {
    getPc();
  }, []);
  const getPc = () => {
    axios
      .get(`${api}politique_confidentialite`)
      .then((response) => {
        setPc(response.data.results[0]);
        setIsLoadingPage(false);
      })
      .catch((error) => {
        setIsLoadingPage(false);
        console.error(error);
      });
  };
  return (
    <div className="component-coaching-area">
      <Header />
      <div className="bg-categorie-contain pt-5 p-b-45 fixed-after-header">
        <div className="container">
          <div className="text-center pt-4 pb-5">
            <h2 className="titre-condition-generale pb-3">
              Politique de Confidentialité 
            </h2>
          </div>
          <div className="cgu-text">
            {!isLoadingPage ? (
              <div dangerouslySetInnerHTML={{ __html: pc?.text }} />
            ) : (
              <>
                <Skeleton
                  height={200}
                  style={{ backgroundColor: "#C4C4C4" }}
                  className="text-condition-generale pb-3"
                />
                <Skeleton
                  height={200}
                  style={{ backgroundColor: "#C4C4C4" }}
                  className="text-condition-generale pb-3"
                />
                <Skeleton
                  height={200}
                  style={{ backgroundColor: "#C4C4C4" }}
                  className="text-condition-generale pb-3"
                />
              </>
            )}
            
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Politique