import React from "react";
import "./productItemWhishlist.css";
import EmptyWhishlistImage from "../../img/hooked.png";
import { NavLink } from "react-router-dom";
import ProductItem4ItemsSkeleton from "../skeleton/ProductItem4ItemsSkeleton/ProductItem4ItemsSkeleton";
import { ProductItem } from "../allProductPage/AllProductItemPage";
import { useLazyGetFavoriteListQuery } from "../../utils/api/favoris.api";
import { useSelector } from "react-redux";

function ProductItemWhishlist() {
  const { user } = useSelector((s) => s?.user);
  const [getFavorite, { data: favorites = [], isLoading }] =
    useLazyGetFavoriteListQuery();

  React.useEffect(() => {
    if (user?.id) {
      getFavorite(user?.id);
    }
  }, [user]);
  return (
    <>
      {!!isLoading && <ProductItem4ItemsSkeleton />}
      {!isLoading && (
        <div className="dis-content">
          {!favorites?.length ? (
            <section className="cart-content checkout">
              <img
                loading="lazy"
                className="image-cart-empty"
                src={EmptyWhishlistImage}
                alt=""
              />
              <header>
                <h2 className="h-theme">Votre liste d'envies est vide!</h2>
                <h4 className="">
                  Veuillez ajouter des produits dans votre liste d'envies.
                </h4>
              </header>
              <div className="dis-flex justify-content-center mt-5">
                <div className="col-md-9 col-sm-7">
                  <NavLink
                    className="btn-theme dis-flex justify-content-center"
                    to="/tous-les-produits"
                  >
                    Tous les produits
                  </NavLink>
                </div>
              </div>
            </section>
          ) : (
            <div className="row px-2 mt-5">
              {favorites?.map((productItem) => (
                <ProductItem
                  product={productItem?.product}
                  key={productItem?.product?.id}
                  favorites={favorites}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default ProductItemWhishlist;
