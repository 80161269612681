import React, { useEffect, useState } from "react";
import "./tableArticles.css";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import FeatherIcon from "feather-icons-react";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import api from "../../../http/global-vars";
import axios from "axios";
import { Alert } from "rsuite";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import Moment from "react-moment";
import { toast } from "react-toastify";
import DeleteArticleModal from "./DeleteArticleModal";
import { NavLink } from "react-router-dom";
import { useGetProductListAdminQuery } from "../../../utils/api/product.api";
import Swal from "sweetalert2";
import { CustomPagination } from "../../../modules/Pagination/CustomPagination";
import TableSkeleton from "../../skeleton/TableSkeleton";


function TableArticles({ filter }) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [query,setQuery] = useState("")
  const {
    data = { results: [], count: 0 },
    isLoading,
    refetch,
  } = useGetProductListAdminQuery({
    page,
    limit,
    q: query,
    status: filter,
  });

  useEffect(() => {
    if (!isLoading) {
      refetch();
    }
  }, []);

  const productActivation = (cell, row) => {
    return (
      <div className="d-flex justify-content-center">
        <ToggleActivation item={row} refetch={refetch} />
      </div>
    );
  };

  const productAction = (cell, row) => {
    return (
      <div className="dis-flex justify-content-center">
        <NavLink
          to={{
            pathname: `/details-article-admin/${row?.id}`,
            state: { product: row },
          }}
          className="btn-action-table-grey clear"
          title="Details"
        >
          <FeatherIcon icon="eye" className="icon-action-table-grey" />
        </NavLink>
        <NavLink
          to={{
            pathname: `/modifier-article-admin/${row?.id}`,
            state: { product: row },
          }}
          className="btn-action-table-grey clear"
          title="Modifier"
        >
          <FeatherIcon icon="edit" className="icon-action-table-grey" />
        </NavLink>
        <HandleToggle item={row} refetch={refetch} />
      </div>
    );
  };

  const productCreated = (cell, row) => {
    return <Moment format="DD/MM/YYYY">{row.created_at}</Moment>;
  };

  const productVisible = (cell, row) => {
    return (
      <div className="dis-flex justify-content-center">
        {row.is_active === true ? (
          <CheckIcon className="check-icon-table-marketplace" />
        ) : (
          <CloseIcon className="close-icon-table-marketplace" />
        )}
      </div>
    );
  };

  const productPrice = (cell, row) => {
    return Intl.NumberFormat("fr-FR").format(parseInt(row.price)) + " FCFA";
  };

  const renderPaginationPanel = (props) => {
    const { count } = data
    return (
      <div class="col-md-12">
        <CustomPagination
          page={page}
          perPage={limit}
          nbPages={count}
          onChange={(page, perPage) => {
            window.scrollTo(200, 200);
            setPage(page)
            setLimit(perPage)
          }}
        />
      </div>
    );
  };

  const renderCustomSearchPanel = (props) => {
    return (
      <div className="form-group form-group-sm react-bs-table-search-form input-group input-group-sm">
        <input
          type="search"
          className="form-control"
          placeholder="Recherche"
          onChange={(e) => setQuery(e.target.value)}
        />
        {/* <span className="input-group-btn">
          <button
            className="btn btn-warning my-custom-class react-bs-table-search-clear-btn"
            type="button"
            onClick={(e) => setQuery("")}
          >
            <span>Effacer</span>
          </button>
        </span> */}
      </div>
    );
  };

  const options = {
    noDataText: "Aucune donnée disponible",
    paginationPanel: renderPaginationPanel,
    sizePerPage: limit,
    searchPanel: renderCustomSearchPanel,
  };
  let headers = [
    { text: "Actions" },
    { text: "Nom" },
    { text: "Créé" },
    { text: "Prix" },
    { text: "Stock disponible" },
    { text: "Visible" },
  ];
  return (
    <div className="table-container">
      {!!isLoading && <TableSkeleton headers={headers} />}
      {!isLoading && (
        <BootstrapTable
          className="head-table-custom"
          options={options}
          data={data?.results}
          search={true}
          multiColumnSearch={true}
          striped
          hover
          multiColumnSort={2}
          pagination
          version="4"
        >
          <TableHeaderColumn
            className="th-table-custom"
            dataFormat={productAction}
            dataField="id"
            width="150"
            thStyle={{ textAlign: "center" }}
            tdStyle={{
              fontWeight: 400,
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.7)",
              borderTop: "none",
              borderBottom: "none",
              padding: "1rem",
              textAlign: "center",
            }}
          >
            Actions
          </TableHeaderColumn>
          <TableHeaderColumn
            isKey
            dataField="name"
            width="230"
            tdStyle={{
              fontWeight: 400,
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.7)",
              borderTop: "none",
              borderBottom: "none",
              padding: "1rem",
              whiteSpace: "normal",
              lineHeight: 1,
            }}
            thStyle={{ textAlign: "center" }}
            className="th-table-custom"
          >
            Nom
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="created_at"
            className="th-table-custom"
            width="150"
            dataFormat={productCreated}
            thStyle={{ textAlign: "center" }}
            tdStyle={{
              fontWeight: 400,
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.7)",
              borderTop: "none",
              borderBottom: "none",
              padding: "1rem",
              textAlign: "center",
            }}
          >
            Crée
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="price"
            className="th-table-custom"
            dataFormat={productPrice}
            width="200"
            thStyle={{ textAlign: "center" }}
            tdStyle={{
              fontWeight: 400,
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.7)",
              borderTop: "none",
              borderBottom: "none",
              padding: "1rem",
              textAlign: "center",
            }}
          >
            Prix
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="quantity"
            className="th-table-custom"
            width="200"
            thStyle={{ textAlign: "center" }}
            tdStyle={{
              fontWeight: 400,
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.7)",
              borderTop: "none",
              borderBottom: "none",
              padding: "1rem",
              textAlign: "center",
            }}
          >
            Stock disponible
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="action"
            className="th-table-custom"
            dataFormat={productVisible}
            width="150"
            thStyle={{ textAlign: "center" }}
            tdStyle={{
              fontWeight: 400,
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.7)",
              borderTop: "none",
              borderBottom: "none",
              padding: "1rem",
              textAlign: "center",
            }}
          >
            Visible
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="is_active"
            className="th-table-custom"
            dataFormat={productActivation}
            width="150"
            thStyle={{ textAlign: "center" }}
            tdStyle={{
              fontWeight: 400,
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.7)",
              borderTop: "none",
              borderBottom: "none",
              padding: "1rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Actif
          </TableHeaderColumn>
        </BootstrapTable>
      )}
    </div>
  );
}

export default TableArticles;

const HandleToggle = ({ item, refetch }) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
    },
  };

  const handleClose = (e) => {
    e.preventDefault();
    setShow(false);
  };
  const handleOpen = () => {
    setShow(true);
  };

  const onDeleteProduct = (e) => {
    e.preventDefault();
    setIsLoading(true);
    // if (window.confirm("Confirmez-vous la suppression du produit?")) {
    var productID = item?.id;
    var data = new FormData();

    data.append("is_archive", !item?.is_archive);

    axios
      .put(`${api}product/${productID}/`, data, config)
      .then((response) => {
        setIsLoading(false);
        if (response.status === 200 || response.status === 201) {
          toast.success("Le produit à été supprimé.");
          refetch();
          handleClose();
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <button
        // onClick={() => this.onDeleteProduct(row)}
        onClick={() => handleOpen()}
        className="btn-action-table-grey clear"
      >
        <FeatherIcon icon="trash-2" className="icon-action-table-grey" />
      </button>
      <DeleteArticleModal
        handleClose={handleClose}
        show={show}
        onDeleteProduct={onDeleteProduct}
        archivageInProgress={isLoading}
      />
    </>
  );
};

const ToggleActivation = ({ item, refetch }) => {
  const toggle = () => {
    var productID = item?.id;
    var data = new FormData();

    data.append("is_active", !item?.is_active);

    axios
      .put(`${api}product/${productID}/`, data)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          refetch();
        }
      })
      .catch((error) => {
        Alert.error("Une erreur est survenue");
      });
  };

  const handleChange = () => {
    // var cel = cell

    Swal.fire({
      title: "Confirmez-vous l'action ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return toggle();
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("res", result);
        Swal.fire({
          icon: "success",
          title: item?.is_active
            ? "Le produit à été désactivé avec succès."
            : "Le produit à été activé avec succès.",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };
  return (
    <>
      <BootstrapSwitchButton
        checked={item?.is_active}
        size="xs"
        width="55"
        onstyle="outline-success"
        offstyle="outline-danger"
        onlabel="OUI"
        offlabel="NON"
        onChange={(checked) => handleChange(checked, item.id)}
      />
    </>
  );
};
