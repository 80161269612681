import React,{Component} from 'react';
import { Message } from 'rsuite'

export default class InfosBancaire extends Component {
    constructor() {
        super();
        this.state = {

        };
        
    }
    componentDidMount(){
        window.scrollTo({top: 0, behavior: 'smooth'});
    }
    render() {
        return (
            <div>
                <p className="consigne-title-steps mb-4">Ajouter un compte bancaire</p>

                <div className="row mb-5">
                    <div className="col-md-5 mb-4">
                        <p className="label-steps-inscription-vendeur">Veuillez sélectionner si vous êtes une personne physique ou une entité commerciale / entreprise</p>
                    </div>
                </div>
                
                <div className="row pb-5">
                    <div className="col-md-6 pr-lg-5">
                        <div className="form-group mb-4">
                            <input type="text" 
                                maxLength={30}
                                className="form-control input-steps-inscription-vendeur" 
                                placeholder="Nom de votre banque" 
                                name="bank_name"
                                value={this.props.userInfos.bank_name}
                                onChange={ this.props.handleChangeCreateShop }
                            />
                            {this.props.is_empty_bank_name && (
                                <Message className="alert-rsuite-custom" type="error" description={this.props.errors.bank_name} />
                            )}
                        </div>
                        <div className="form-group mb-4">
                            <input type="number" 
                                maxLength={30}
                                className="form-control input-steps-inscription-vendeur number-input-custom" 
                                placeholder="Numéro de compte" 
                                name="account_number"
                                value={this.props.userInfos.account_number}
                                onChange={ this.props.handleChangeCreateShop }
                            />
                            {this.props.is_empty_account_number && (
                                <Message className="alert-rsuite-custom" type="error" description={this.props.errors.account_number} />
                            )}
                        </div>
                        <div className="form-group mb-4">
                            <input type="number" 
                                maxLength={23}
                                minLength="23"
                                className="form-control input-steps-inscription-vendeur number-input-custom" 
                                placeholder="IBAN" 
                                name="iban"
                                value={this.props.userInfos.iban}
                                onChange={ this.props.handleChangeCreateShop }
                            />
                            {this.props.is_empty_iban && (
                                <Message className="alert-rsuite-custom" type="error" description={this.props.errors.iban} />
                            )}
                        </div>
                        <div className="form-group mb-4">
                            <input type="text" 
                                maxLength={30}
                                className="form-control input-steps-inscription-vendeur" 
                                placeholder="Nom du titulaire de compte" 
                                name="account_holder_name"
                                value={this.props.userInfos.account_holder_name}
                                onChange={ this.props.handleChangeCreateShop }
                            />
                            {this.props.is_empty_account_holder_name && (
                                <Message className="alert-rsuite-custom" type="error" description={this.props.errors.account_holder_name} />
                            )}
                        </div>
                        <div className="form-group mb-4">
                            <input type="number" 
                                maxLength={11}
                                className="form-control input-steps-inscription-vendeur number-input-custom" 
                                placeholder="SWIFT" 
                                name="swift"
                                value={this.props.userInfos.swift}
                                onChange={ this.props.handleChangeCreateShop }
                            />
                            {this.props.is_empty_swift && (
                                <Message className="alert-rsuite-custom" type="error" description={this.props.errors.swift} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

