import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";

export default class ProductItemSkeleton extends Component {
  render() {
    return Array(6)
      .fill()
      .map((product, i) => (
        <div
          className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 px-sm-2 px-1"
          key={i}
        >
          <div className="product-card-item">
            <div className="">
              <div className="product-card-item-half-top lh-1">
                <div className="m-b-15">
                  <Skeleton
                    className="sk-img-product-card-item"
                    variant="rect"
                  />
                </div>
                <div className="sk-product-card-item-half-bottom">
                  <Skeleton className="name-product-card-item m-b-3" />
                  <Skeleton
                    className="price-product-card-item"
                    width={`70%`}
                    height={20}
                  />
                  <Skeleton className="fs-10 m-b-8" width={`50%`} />
                </div>
              </div>
            </div>

            <form className="lh-0">
              <div className="btn-add-favoris-product-card-item">
                <Skeleton
                  className="whishlist-icon"
                  width={24}
                  height={24}
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.438)" }}
                />
              </div>
            </form>

            <form>
              <div className="sk-btn-product-card-item-add-card">
                <Skeleton
                  className="icon-btn-product-card-item-add-card"
                  width={15}
                  height={15}
                />
                <Skeleton className="" width={80} height={15} />
              </div>
            </form>
          </div>
        </div>
      ));
  }
}
