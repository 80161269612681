import React from "react";
import "./cartItem.css";
import FeatherIcon from "feather-icons-react";
import ThumbsProduct from "../../img/product1.png";
import { connect, useDispatch } from "react-redux";
import {
  INCREASE,
  DECREASE,
  TOGGLE_QTY,
  removeItem,
} from "../reduxCart/cartActions";
import baseUrl from "../../http/backend-base-url";
import {
  decrement,
  getCartCount,
  getSubTotal,
  getTotalAmount,
  increment,
  removeCartItem,
} from "../../utils/api/slice/useCartSlice";

const CartItem = ({ item }) => {
  const dispatch = useDispatch();

  const getCartItems = () => {
    dispatch(getSubTotal());
    dispatch(getCartCount());
    dispatch(getTotalAmount());
  };

  const onRemoveItem = (e) => {
    e?.preventDefault();
    dispatch(removeCartItem(item?.product));
    getCartItems();
  };

  const onToggle = (type) => {
    if (type === "inc") {
      dispatch(increment(item));
    } else {
      if (item?.qty === 1) {
        dispatch(removeCartItem(item?.product));
      } else {
        dispatch(decrement(item));
      }
    }
    getCartItems();
  };
  return (
    <div className="component-cart-item b-b-1 mb-3">
      <div className="row">
        <div className="col-xl-6 col-lg-5 col-md-12">
          <div className="row">
            <div className="col-sm-3 m-b-20">
              <img
                loading="lazy"
                className="img-thumbs-commande-item"
                src={
                  item?.product?.image?.length
                    ? baseUrl + item?.product?.image[0]?.image_medium
                    : ThumbsProduct
                }
                alt=""
              />
            </div>
            <div className="pl-lg-0 col-sm-9 m-b-20">
              <p className="text-commande-item m-b-10 fw-300 fs-14 text-dark">
                {item?.product?.name}
              </p>
              <p className="text-commande-item m-b-30 fw-300 fs-12">
                {item?.product?.description}
              </p>
              {item?.selectedPointure || item?.selectedSize ? (
                <p className="text-commande-item m-b-30 fw-400 fs-12">
                  <span className="h-theme ">
                    {item?.selectedPointure ? "Pointure:" : "Taille:"}
                  </span>{" "}
                  {item?.selectedPointure || item?.selectedSize}
                </p>
              ) : null}
            </div>
          </div>
        </div>
        <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 m-b-20 dis-flex align-content-md-center px-2">
          <div>
            <p className="h-theme fw-600 text-up mb-2">Quantité</p>
            <div className="content-qty-cart-item">
              <button
                className="amount-btn left"
                onClick={() => onToggle("desc")}
              >
                <FeatherIcon className="icon-amount-btn" icon="minus" />
              </button>
              <p className="amount">{item?.qty}</p>
              <button
                className="amount-btn right"
                disabled={item?.product?.quantity <= item?.qty}
                onClick={() => onToggle("inc")}
              >
                <FeatherIcon className="icon-amount-btn" icon="plus" />
              </button>
            </div>
          </div>
        </div>
        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 m-b-20 px-2">
          <div>
            <p className="h-theme fw-600 text-up mb-2">Prix unitaire</p>
            <p className="text-dark fs-14 ff-open fw-400">
              {Intl.NumberFormat("fr-FR").format(
                parseInt(item?.product?.price)
              )}{" "}
              FCFA
            </p>
          </div>
        </div>
        <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 m-b-20 px-2">
          <div>
            <p className="h-theme fw-600 text-up mb-1">Action</p>
            {/* <button className="btn-icon-action-commande">
            <FeatherIcon className="icon-commande-favoris" icon="heart" />
          </button> */}
            <button
              className="btn-icon-action-commande"
              onClick={(e) => onRemoveItem(e)}
            >
              <FeatherIcon className="icon-commande-delete" icon="trash-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
