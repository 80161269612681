import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import api from "../../http/global-vars";
import { QueryUrl, useLocationState } from "../utils";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { prepareHeaders } from "./user.api";

export const ProductApi = createApi({
  reducerPath: "productApi",
  tagTypes: ["productList", "productPromote", "productById", "productBySeller"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${api}`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getProductList: builder.query({
      providesTags: ["productList"],
      query: (query) => QueryUrl(`web/productbyvisitor`, query),
    }),
    getProductBest: builder.query({
      providesTags: ["productPromote"],
      query: (query) => QueryUrl(`productbybestseller`, query),
    }),
    getProductListAdmin: builder.query({
      providesTags: ["productList"],
      query: (query) => QueryUrl(`product`, query),
    }),
    productById: builder.query({
      query: (id) => `product-detail/${id}/`,
      providesTags: ["productById"],
    }),
    getProductBySeller: builder.query({
      providesTags: ["productBySeller"],
      query: ({ id, ...query }) => {
        if (id) {
          return QueryUrl(`web/productbyfournisseur/${id}/`, query);
        }
        return "";
      },
    }),
    addOrEditProduct: builder.mutation({
      invalidatesTags: ["productById", "productBySeller", "productList"],
      query: ({ id, data }) => {
        if (id) {
          return {
            url: `product/${id}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `create-product/`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteImageProduct: builder.mutation({
      query: (id) => ({
        url: `picture/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["productById", "productBySeller", "productList"],
    }),
    deleteProduct: builder.mutation({
      query: (id) => ({
        url: `picture/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["productById", "productBySeller", "productList"],
    }),
  }),
});

export const {
  useGetProductListQuery,
  useGetProductBestQuery,
  useGetProductListAdminQuery,
  useLazyProductByIdQuery,
  useAddOrEditProductMutation,
  useGetProductBySellerQuery,
  useDeleteImageProductMutation,
} = ProductApi;

export function useProductFromLocation() {
  const itemState = useLocationState(undefined);
  const [item, setItem] = useState(itemState);
  const { id } = useParams();
  const [findById, { data, isError, isLoading }] = useLazyProductByIdQuery();
  const navigate = useHistory();

  useEffect(() => {
    if (id) {
      findById(id);
    }
  }, [id]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate.goBack();
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      console.log("data", data);
      setItem(data);
    }
  }, [data]);

  return [item, isLoading, id, findById];
}
