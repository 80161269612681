import React, {Component} from 'react';
import FeatherIcon from "feather-icons-react";
import Dropzone from "react-dropzone";
import { Message  } from 'rsuite';
import baseUrl from '../../../http/backend-base-url';
import api from '../../../http/global-vars';
import axios from 'axios'

export default class updateImagesProduct extends Component {
  constructor(props) {
    super(props);
    // this.onDrop = (files) => {
    //   this.setState({ files: [...files] });
    // };
    this.state = {
      api: api,
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      baseUrl: baseUrl,
      files: [],
      imagesPreviewUrls: [],
    };
    this._handleImageChange = this._handleImageChange.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ files: this.props?.productImages });
    }, 3000);

    this.props.onRef(this);
  }

  onDrop = (files) => {
    console.log("files",files)
    this.setState({ files: [...this.state.files,...files] });
  };

  _handleImageChange = (e) => {
    e.preventDefault();
  };

  onRemoveImage = (e, i, file) => {
    let newFiles = this.state.files.filter(
      (f) => this.state.files.indexOf(f) !== i
    );
    this.setState({ files: newFiles });

    var api = this.state.api;
    var config = this.state.requestConfig;
    if (file?.id) {
      var url = `${api}picture/${file?.id}/`;

      axios
        .delete(url, config)
        .then((response) => {
          // this.onDrop()
        })

        .catch((error) => {
          console.log(error);
        });
    }
    
  };

  render() {
    const baseUrl = this.state.baseUrl;

    return (
      <div className="component-update-image-product pb-5">
        <p className="consigne-title-steps mb-5">
          Mettre à jour les images du produit
        </p>

        <Dropzone onDrop={this.onDrop}>
          {({ getRootProps, getInputProps }) => (
            <section className="mb-5">
              <div
                {...getRootProps({
                  className: "dropzone container-dropzone-import",
                })}
              >
                <input {...getInputProps()} name="image" />
                <p className="text-center">Parcourir ou deposer des images</p>
                <p className="fw-600 fs-14">Taille des images 500X500</p>
              </div>
            </section>
          )}
        </Dropzone>
        {this.props.is_empty_images && (
          <Message
            className="alert-rsuite-custom"
            type="error"
            description={this.props.errors.image}
          />
        )}

        <div className="row px-2">
          {this.state.files?.length > 0
            ? this.state.files.map((file, i) => (
                <div
                  className="col-xl-3 col-lg-4 col-md-3 col-sm-4 col-6 px-2 mb-3 dis-flex pos-relative"
                  key={i}
                >
                  <div
                    className="image-product-upload"
                    style={{
                      background: `url(${
                        file?.image_medium
                          ? baseUrl + file.image_medium
                          : URL.createObjectURL(file)
                      })`,
                    }}
                  ></div>
                  <span
                    className="btn-remove-image-product-upload"
                    onClick={(e) => this.onRemoveImage(e, i, file)}
                  >
                    <FeatherIcon
                      icon="trash-2"
                      className="icon-remove-image-product-upload"
                    />
                  </span>
                </div>
              ))
            : null}
        </div>
      </div>
    );
  }
}