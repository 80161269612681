import React, { useEffect } from 'react'
import Header from '../header/index'
import Footer from '../footer/index'

function Apropos() {
  useEffect(() => {
    window.scrollTo(10,10)
  }, [])
  return (
    <div className="component-coaching-area">
      <Header />
      <div className="bg-categorie-contain pt-5 p-b-45 fixed-after-header">
        <div className="container">
          <div className="text-center pt-4 pb-5">
            <h2 className="titre-condition-generale pb-3">À propos de nous</h2>
          </div>
          <div className="cgu-text">
            <p className="text-condition-generale pb-3">
              Bienvenue sur DIEKKES, une petite, mais dynamique entreprise
              spécialisée dans la vente en ligne de tous types de produits.
            </p>
            <p className="text-condition-generale pb-3">
              Nous sommes animés par la passion du bon plan et de l’efficacité
              du service client. C’est pourquoi nous nous engageons à vous
              apporter les deux. Notre mission est d’offrir à nos clients un
              vaste choix de produits dans plusieurs catégories. Notre objectif
              en la matière est de vous permettre d'obtenir le meilleur rapport
              qualité/prix. Et nous mettons un point d’honneur à vous proposer
              un excellent service client et assistance. Pour nos vendeurs, Nous
              souhaitons offrir une large visibilité sur le net afin de leur
              permettre d’élargir leur clientèle. De plus, nous proposons un
              ensemble de services innovants permettant de faciliter leurs
              activités.
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default Apropos
