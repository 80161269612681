import React, { Component } from "react";
import "./addProductSteps.css";
import { Loader, Steps, Modal } from "rsuite";
import InformationsProduct from "./steps/informationsProduct";
import DetailsProduct from "./steps/detailsProduct";
import TarificationProduct from "./steps/tarificationProduct";
import ImagesProduct from "./steps/imagesProduct";
import api from "../../../http/global-vars";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import ConfirmationIcon from "../../../img/icons/check-icon.png";
// import { NavLink } from "react-router-dom";
// import { values } from 'lodash';

export default class stepsAddingProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: api,
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        "Content-Type": "multipart/form-data",
      },
      step: 0,
      fields: { category: [] },
      categories: [],
      errors: {},
      productInfos: {},
      creatingProductInProgress: false,
      loading: false,
      categoryList: [],
      is_empty_category: false,
      is_empty_product_name: false,
      is_empty_quantity: false,
      is_empty_price: false,
      is_empty_images: false,
      is_empty_pointure: false,
      is_empty_taille: false,
      is_empty_pointure_val: false,
      is_empty_taille_val: false,
      isSuccessCreateProduct: false,
      isErrorCreateProduct: false,
      showConfirmation: false,
      newRecorder: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.closeConformation = this.closeConformation.bind(this);
    this.openConfirmation = this.openConfirmation.bind(this);
  }
  changePercent(nextstep) {
    const step = nextstep < 0 ? 0 : nextstep > 3 ? 3 : nextstep;
    this.setState({
      step,
    });
  }
  precedent = () => {
    this.changePercent(this.state.step - 1);
  };
  suivant = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    var step = this.state.step;
    if (step === 0) {
      if (this.onValidateStep1AddingProduct()) {
        this.changePercent(this.state.step + 1);
      }
    }
    if (step === 1) {
      // if(this.onValidateStep2CreateShop()){
      this.changePercent(this.state.step + 1);
      // }
    }
    if (step === 2) {
      if (this.onValidateStep3AddingProduct()) {
        this.changePercent(this.state.step + 1);
      }
    }
  };

  handleChangeSelector = (value) => {
    let fields = this.state.fields;

    let data = [];
    if (value) {
      value.forEach((element) => {
        if (element !== null && element) {
          data.push(element);
        }
      });
    }
    console.log("value", data);
    fields["category"] = data;
    this.setState({
      fields: fields,
    });
  };

  handleChangePointure = (value) => {
    let fields = this.state.fields;

    let data = [];
    if (value) {
      value.forEach((element) => {
        if (element !== null && element) {
          data.push(element);
        }
      });
    }
    console.log("value pointur", data);
    fields["pointures"] = data;
    this.setState({
      fields: fields,
    });
  };

  handleChangeTaille = (value) => {
    let fields = this.state.fields;

    let data = [];
    if (value) {
      value.forEach((element) => {
        if (element !== null && element) {
          data.push(element);
        }
      });
    }
    console.log("value taille", data);
    fields["tailles"] = data;
    this.setState({
      fields: fields,
    });
  };

  handleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;

    this.setState({
      fields: fields,
    });
  };

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    // console.log("shop", this.props.shopDetails);
    this.onGetCategories();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.shopDetails !== this.props.shopDetails) {
      let data = [];
      if (this.props.shopDetails?.products_main_category) {
        data.push(parseInt(this.props.shopDetails?.products_main_category));
        let fields = this.state.fields;
        fields["category"] = data;
        this.setState({
          fields: fields,
        });
      }
      console.log("shop update", data);
    }
  }

  onGetCategories = () => {
    var api = this.state.api;
    axios
      .get(api + "category/")
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.setState({
            categoryList: response.data,
            loading: false,
          });
        }
      })
      .catch((error) => {
        if (error) {
          this.setState({ loading: false });
        }
      });
  };

  onValidateStep1AddingProduct = () => {
    let fields = this.state.fields;
    let errors = this.state.errors;
    var isValidForm = true;
    console.log(fields)
    if (!fields["category"]) {
      isValidForm = false;
      this.setState({
        is_empty_category: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_category: false,
        });
      }, 5000);
      errors["category"] = "Veuillez choisir une catégorie!";
    }
    if (!fields["name"]) {
      isValidForm = false;
      this.setState({
        is_empty_product_name: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_product_name: false,
        });
      }, 5000);
      errors["name"] = "Veuillez saisir le nom du produit!";
    }

    if (!fields["pointure"]) {
      isValidForm = false;
      this.setState({
        is_empty_pointure: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_pointure: false,
        });
      }, 5000);
      errors["pointure"] = "Veuillez renseigner cet information !";
    }

    if (!fields["taille"]) {
      isValidForm = false;
      this.setState({
        is_empty_taille: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_taille: false,
        });
      }, 5000);
      errors["taille"] = "Veuillez renseigner cet information !";
    }

    if (
      fields["pointure"] &&
      fields["pointure"] === 'true' &&
      !fields["pointures"]
    ) {
      isValidForm = false;
      this.setState({
        is_empty_pointure_val: 'true',
      });
      setTimeout(() => {
        this.setState({
          is_empty_pointure_val: false,
        });
      }, 5000);
      errors["pointures"] = "Veuillez renseigner les pointures disponibles !";
    }

    if (fields["taille"] && fields["taille"] === 'true' && !fields["tailles"]) {
      isValidForm = false;
      this.setState({
        is_empty_taille_val: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_taille_val: false,
        });
      }, 5000);
      errors["tailles"] = "Veuillez renseigner les tailles disponibles !";
    }

    this.setState({
      errors,
    });

    return isValidForm;
  };

  onValidateStep3AddingProduct = () => {
    let fields = this.state.fields;
    let errors = this.state.errors;
    var isValidForm = true;

    if (!fields["price"]) {
      isValidForm = false;
      this.setState({
        is_empty_price: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_price: false,
        });
      }, 5000);
      errors["price"] = "Veuillez renseigner le prix du produit!";
    }
    if (!fields["quantity"]) {
      isValidForm = false;
      this.setState({
        is_empty_quantity: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_quantity: false,
        });
      }, 5000);
      errors["quantity"] = "Veuillez renseigner la quantité disponible!";
    }

    this.setState({
      errors,
    });

    return isValidForm;
  };

  onValidateStep4AddingProduct = () => {
    let fields = this.state.fields;
    let errors = this.state.errors;
    console.log("fields", fields);
    var isValidForm = true;
    if (!this.child.state.files?.length) {
      isValidForm = false;
      this.setState({
        is_empty_images: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_images: false,
        });
      }, 5000);
      errors["image"] = "Veuillez télécharger les images de votre produit!";
    }
    this.setState({
      errors,
    });

    return isValidForm;
  };

  callBackRecorder = (voiceRecorder) => {
    this.setState({ newRecorder: voiceRecorder });
  };

  onSubmitAddingProduct = (e) => {
    e.preventDefault();

    if (this.onValidateStep4AddingProduct() === true) {
      this.setState({
        creatingProductInProgress: true,
      });

      let { fields } = this.state;

      console.log("fields", fields);

      var fd = new FormData();

      fd.append("name", fields.name);
      fd.append("description", fields.description);
      fd.append("price", fields.price);
      fd.append("quantity", fields.quantity);
      fd.append("brand", fields.brand);
      fd.append("main_color", fields.main_color);
      fd.append("dimension", fields.dimension);
      fd.append("weight", fields.weight);
      fd.append("tax", fields.tax);
      fd.append("provider", this.props.shopDetails.id);
      fd.append("pointure", fields.pointure);
      if (fields?.pointures?.length) {
        fd.append("pointures", fields.pointures.join());
      }
      
      fd.append("taille", fields.taille);
      // fd.append("tailles", fields.tailles);
      if (fields?.tailles?.length) {
        fd.append("tailles", fields.tailles.join());
      }
      if (this.state.newRecorder !== null) {
        fd.append("description_audio", this.state.newRecorder);
      }

      if (this.child.state.files?.length) {
        this.child.state.files.forEach((item) => {
          fd.append("image", item);
        });
      }

      for (var index = 0; index < this.state.fields.category.length; index++) {
        var cat = parseInt(this.state.fields.category[index]);
        console.log("object12", cat);

        fd.append("category", Number(cat));
      }
      // fd.append("category", this.state.fields.category);
      fd.append("dd[]", fd.getAll("category").map(Number));

      var api = this.state.api;
      var config = this.state.requestConfig;
      console.log("data", this.state.fields);
      axios
        .post(api + "create-product/", fd, config)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            this.setState({
              creatingProductInProgress: false,
              productInfos: response.data,
            });
            this.openConfirmation();
            this.setState({
              isSuccessCreateProduct: true,
            });
            setTimeout(() => {
              this.setState({
                isSuccessCreateProduct: false,
              });
            }, 5000);
          } else {
            this.setState({
              creatingProductInProgress: false,
            });
            toast.error(
              "Une erreur est survenue lors de l'enregistrement du produit! Veuillez réessayer."
            );
            this.setState({
              isErrorCreateProduct: true,
            });
            setTimeout(() => {
              this.setState({
                isErrorCreateProduct: false,
              });
            }, 5000);
          }
        })
        .catch((error) => {
          console.log("error", error?.response);
          if (error?.response?.message) {
            this.setState({
              creatingProductInProgress: false,
            });
            this.setState({ loading: false });
            toast.error(error?.response?.message);
            this.setState({
              isErrorCreateProduct: true,
            });
            setTimeout(() => {
              this.setState({
                isErrorCreateProduct: false,
              });
            }, 5000);
          } else {
            this.setState({
              creatingProductInProgress: false,
            });
            this.setState({ loading: false });
            toast.error(
              "Une erreur est survenue lors de l'enregistrement du produit! Veuillez réessayer."
            );
            this.setState({
              isErrorCreateProduct: true,
            });
            setTimeout(() => {
              this.setState({
                isErrorCreateProduct: false,
              });
            }, 5000);
          }
        });
    }
  };

  closeConformation(e) {
    e.preventDefault();
    this.setState({ showConfirmation: false });
    return (window.location = "/articles");
  }
  openConfirmation(e) {
    this.setState({ showConfirmation: true });
  }

  onSubmitProductImages = async (e) => {
    e.preventDefault();
    let errors = this.state.errors;
    var api = this.state.api;
    var config = this.state.requestConfig;
    var productID = this.state.productInfos.id;
    const url = api + "picture/";
    if (this.child.state.files?.length) {
      this.setState({
        creatingProductInProgress: true,
      });
      var data = new FormData();
      for (let i = 0; i < this.child.state.files?.length; i++) {
        const element = this.child.state.files[i];
        data.append("product_id", productID);
        data.append("image", element);
        await axios
          .post(url, data, config)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              this.setState({
                isSuccessCreateProduct: true,
              });
              setTimeout(() => {
                this.setState({
                  isSuccessCreateProduct: false,
                  creatingProductInProgress: false,
                });
                //this.closeConformation()
                this.openConfirmation();
              }, 2000);
            } else {
              toast.error(
                "Une erreur est survenue lors de l'enregistrement du produit! Veuillez réessayer."
              );
              this.setState({
                isErrorCreateProduct: true,
              });
              setTimeout(() => {
                this.setState({
                  isErrorCreateProduct: false,
                  creatingProductInProgress: false,
                });
              }, 2000);
            }
          })
          .catch((e) => {
            this.setState({
              creatingProductInProgress: false,
            });
            this.setState({
              isErrorCreateProduct: false,
            });
          });
      }
    } else {
      this.setState({
        is_empty_images: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_images: false,
        });
      }, 5000);
      errors["image"] = "Veuillez télécharger les images de votre produit!";
    }
    this.setState({
      errors,
    });
  };

  render() {
    const step = this.state.step;
    console.log("step", step);
    return (
      <div className="px-xl-5 px-lg-3 px-md-4  pt-3">
        <Steps current={step} className="m-b-40">
          <Steps.Item
            className="step-horizontal"
            title=""
            description="Détails du produit"
          />
          <Steps.Item
            className="step-horizontal"
            title=""
            description="Plus de détails"
          />
          <Steps.Item
            className="step-horizontal"
            title=""
            description="Tarifications"
          />
          <Steps.Item
            className="step-horizontal"
            title=""
            description="Images"
          />
        </Steps>

        <div>
          <form onSubmit={(e) => this.onSubmitAddingProduct(e)}>
            {step === 0 ? (
              <InformationsProduct
                handleChange={this.handleChange}
                handleChangeSelector={this.handleChangeSelector}
                handleChangePointure={this.handleChangePointure}
                handleChangeTaille={this.handleChangeTaille}
                categoryList={this.state.categoryList}
                errors={this.state.errors}
                fields={this.state.fields}
                shopDetails={this.props.shopDetails}
                is_empty_category={this.state.is_empty_category}
                is_empty_product_name={this.state.is_empty_product_name}
                is_empty_pointure={this.state.is_empty_pointure}
                is_empty_pointure_val={this.state.is_empty_pointure_val}
                is_empty_taille={this.state.is_empty_taille}
                is_empty_taille_val={this.state.is_empty_taille_val}
              />
            ) : step === 1 ? (
              <DetailsProduct
                handleChange={this.handleChange}
                callBackRecorder={this.callBackRecorder}
                fields={this.state.fields}
              />
            ) : step === 2 ? (
              <TarificationProduct
                handleChange={this.handleChange}
                is_empty_price={this.state.is_empty_price}
                is_empty_quantity={this.state.is_empty_quantity}
                errors={this.state.errors}
                fields={this.state.fields}
              />
            ) : step === 3 ? (
              <ImagesProduct
                onRef={(ref) => (this.child = ref)}
                handleChange={this.handleChange}
                is_empty_images={this.state.is_empty_images}
                errors={this.state.errors}
              />
            ) : (
              <></>
            )}

            <div className="row m-t-50">
              <div className="col-lg-3 col-md-3 pr-md-0 mb-3 col-sm-5 pr-sm-2">
                <p
                  className={
                    step === 0 || step === 3
                      ? "dis-none"
                      : "btn-theme-light text-up py-3 w-full text-center"
                  }
                  onClick={this.precedent}
                  disabled={step === 0}
                >
                  Retour
                </p>
              </div>
              <div
                className={
                  step === 2 || step === 3
                    ? "dis-none"
                    : "col-lg-3 pl-lg-2 pl-md-1 col-md-4 mb-3 col-sm-7 ml-auto"
                }
              >
                <p
                  className="btn-theme-light dis-flex justify-content-center text-up py-3 w-full hov-pointer"
                  onClick={this.suivant}
                >
                  Passer l’étape
                </p>
              </div>

              {this.state.creatingProductInProgress === true ? (
                <div
                  className={
                    step === 0 || step === 1 || step === 3
                      ? "dis-none"
                      : "col-lg-4 pl-lg-2 pl-md-1 col-md-5 mb-3 col-sm-7 pl-sm-2 ml-auto"
                  }
                >
                  <button
                    disabled
                    type="submit"
                    className="btn-theme creating-shop"
                  >
                    Ajout en cours&nbsp; <Loader />
                  </button>
                </div>
              ) : (
                <div
                  className={
                    step === 0 || step === 1 || step === 3
                      ? "dis-none"
                      : "col-lg-4 pl-lg-2 pl-md-1 col-md-5 mb-3 col-sm-7 pl-sm-2 ml-auto"
                  }
                >
                  <button
                    type="button"
                    onClick={this.suivant}
                    className="btn-theme dis-flex justify-content-center py-3 w-full hov-pointer"
                  >
                    Ajouter des images
                  </button>
                </div>
              )}
              <div
                className={
                  step === 0 || step === 1 || step === 2
                    ? "dis-none"
                    : "col-lg-4 pl-lg-2 pl-md-1 col-md-5 mb-3 col-sm-7 pl-sm-2 ml-auto"
                }
              >
                {this.state.creatingProductInProgress ? (
                  <button
                    type="submit"
                    disabled
                    className="btn-form-dark in-progress-btn"
                  >
                    <Loader content="Ajout de produit en cours..." />
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn-theme dis-flex justify-content-center py-3 w-full hov-pointer"
                  >
                    Enregistrer le produit
                  </button>
                )}
              </div>
            </div>
          </form>

          {/* <form onSubmit={(e) => this.onSubmitProductImages(e)}>
            {step === 3 ? (
              <ImagesProduct
                onRef={(ref) => (this.child = ref)}
                handleChange={this.handleChange}
                is_empty_images={this.state.is_empty_images}
                errors={this.state.errors}
              />
            ) : (
              <></>
            )}
            <div className="row m-t-50">
              <div
                className={
                  step === 0 || step === 1 || step === 2
                    ? "dis-none"
                    : "col-lg-4 pl-lg-2 pl-md-1 col-md-5 mb-3 col-sm-7 pl-sm-2 ml-auto"
                }
              >
                {this.state.creatingProductInProgress ? (
                  <button
                    type="submit"
                    disabled
                    className="btn-form-dark in-progress-btn"
                  >
                    <Loader content="Ajout de produit en cours..." />
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn-theme dis-flex justify-content-center py-3 w-full hov-pointer"
                  >
                    Enregistrer le produit
                  </button>
                )}
              </div>
            </div>
          </form> */}
        </div>

        {this.state.isSuccessCreateProduct && (
          <ToastContainer className="fs-13" />
        )}
        {this.state.isErrorCreateProduct && (
          <ToastContainer className="fs-13" />
        )}

        {/* Modal ajout article  */}
        <div className="modal-container">
          <Modal
            size="xs"
            show={this.state.showConfirmation}
            onHide={this.closeConformation}
            className="rsuite-content-modal-custom"
          >
            <form>
              <Modal.Header>
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <div className="my-5 flex-col itm-center">
                <img loading="lazy"
                  src={ConfirmationIcon}
                  alt=""
                  className="icon-confirmation-modal mb-4"
                />
                <p className="title-confirmation-modal my-4">
                  Article soumis avec succès
                </p>
              </div>
              <Modal.Footer className="text-center">
                <div className="row px-5">
                  <div className="col-12 mb-3">
                    <button
                      onClick={this.closeConformation}
                      className="btn-change-password"
                    >
                      Fermer
                    </button>
                  </div>
                </div>
              </Modal.Footer>
            </form>
          </Modal>
        </div>
      </div>
    );
  }
}
