import React, {Component} from 'react';
import './error404.css';
import { NavLink } from 'react-router-dom';

export default class error404 extends Component {
  constructor(props) {
      super(props);
      this.state = {};
  }
  render() {
    return <div className="component-error404">
      <div className="text-center my-auto">
        <h1 className="mb-5">404</h1>
        <p className="mb-5">Oupsss!!! La page demandé est introuvable!</p>
        <NavLink className="btn-change-password no-decoration" to='/'>Aller à l'accueil</NavLink>
      </div>
    </div>;
  }
}
