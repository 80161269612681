import React, {Component} from 'react';
import './tableCommande.css';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ClearIcon from '@material-ui/icons/Clear';
import Moment from 'react-moment'
import api from '../../../http/global-vars';
import axios from 'axios';
import { Loader } from 'rsuite'

export default class tableCommandePlaced extends Component {
  constructor(props) {
      super(props);
      this.state = {
        token: window.localStorage.getItem('userToken'),
        requestConfig: {
          headers: { Authorization: `Bearer ${window.localStorage.getItem('userToken')}` }
        },
        api: api,
        fields: {},
        loading: false,
        orderOnConfirmation: false,
        orderOnCancel: false,
      };
  }
  
  orderCreated(cell, row) {
    return (
      <Moment format="DD/MM/YYYY">
          {row.order_date}
      </Moment>
    );
  }
 
  paymentMode = (cell, row) => {
    return row.payment_mode.name;
  }
  quantity = (cell, row) => {
    return row.cart.number_of_items;
  }
  shippingMode = (cell, row) => {
    return row.delivery.name;
  }
  priceOrder = (cell, row) => {
    return Intl.NumberFormat('fr-FR').format(parseInt(row.order_total)) + " FCFA";
  }
  orderStatus = (cell, row) => {
    if (row.status === "placed") {
      let status = "En cours de préparation"
      return status
    }
    if (row.status === "ready") {
      let status = "Prêt à être livrer"
      return status
    }
    if (row.status === "paid") {
      let status = "Payée"
      return status
    }
    if (row.status === "shipped") {
      let status = "En cours de livraison"
      return status
    }
    if (row.status === "delivered") {
      let status = "Livrée"
      return status
    }
    if (row.status === "canceled") {
      let status = "Annulée"
      return status
    }
    if (row.status === "returned") {
      let status = "Retournée"
      return status
    }
    if (row.status === "failed") {
      let status = "Echec de livraison"
      return status
    }
  }
  
  actionOrderPlaced(cell, row) {
    return (
      <div className="dis-flex justify-content-center">
        <button onClick={() => this.onConfirmOrder(row)} className="btn-action-table-grey check" key={row}>
          {this.state.orderOnConfirmation ?
          <Loader  className="loading-theme" size="xs" />
          :
          <CheckBoxIcon className="icon-action-table-grey" />
          }
        </button>
        
        <button onClick={() => this.onCancelOrder(row)} className="btn-action-table-grey clear">
          {this.state.onCancelOrder ?
          <Loader className="loading-theme" size="xs" />
          :
          <ClearIcon className="icon-action-table-grey" />
          }
        </button>
      </div>
    );
  }
  
  onConfirmOrder = (commande) => {
    this.setState({ orderOnConfirmation: true })
    if (window.confirm('Vous allez confirmé cette commande!')) {
      var api = this.state.api
      var config = this.state.requestConfig
      var orderID = commande.id
      
      let fields = this.state.fields
      fields["status"] = "ready"
      this.setState(fields);
      
      axios.put(`${api}order/${orderID}/`, fields, config)
        .then(response => {
          this.setState({ orderOnConfirmation: false })
          if (response.status === 200 || response.status === 201) {
            this.setState({ loading: false })
            // this.props.onGetOrdersByShop()
            window.location.reload();
          }
        })
        .catch(error => {
          if (error) {
            this.setState({ loading: false })
          }
        })
    }
    
  }
  
  onCancelOrder = (order) => {
    this.setState({ orderOnCancel: true })
    if (window.confirm('Vous allez annulé cette commande!')) {
      var api = this.state.api
      var config = this.state.requestConfig
      var orderID = order.id
      
      let fields = this.state.fields
      fields["status"] = "canceled"
      this.setState(fields);
      
      axios.put(`${api}order/${orderID}/`, fields, config)
        .then(response => {
          this.setState({ orderOnCancel: false })
          if (response.status === 200 || response.status === 201) {
            this.setState({ loading: false })
            // this.props.onGetOrdersByShop()
            window.location.reload();
          }
        })
        .catch(error => {
          if (error) {
            this.setState({ loading: false })
          }
        })
    }
  }
  
  render() {
    const options = {
      noDataText: 'Aucune commande disponible',
    }

    return <div className="table-container">
      <BootstrapTable className="head-table-custom" 
        data={this.props.commandes} search={ false } 
        multiColumnSearch={ true } striped hover 
        options={options}
        multiColumnSort={2} pagination version='4'>
        <TableHeaderColumn 
          isKey dataField='order_code'
          width="150"
          tdStyle={{ fontWeight: 400, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', borderTop: 'none', borderBottom: 'none', padding: '1rem', textAlign: 'center' }} 
          thStyle={{ textAlign: 'center', whiteSpace: 'normal' }}
          className="th-table-custom"
        >N ° de commande</TableHeaderColumn>
        <TableHeaderColumn 
          dataField='order_date' className="th-table-custom" 
          width="150" dataFormat={this.orderCreated}
          thStyle={{ textAlign: 'center' }}
          tdStyle={{ fontWeight: 400, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', borderTop: 'none', borderBottom: 'none', padding: '1rem', textAlign: 'center' }} 
        >Date</TableHeaderColumn>
        <TableHeaderColumn 
          dataField='order_total' className="th-table-custom"
          dataFormat={this.priceOrder}
          width="200"
          thStyle={{ textAlign: 'center' }}
          tdStyle={{ fontWeight: 400, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', borderTop: 'none', borderBottom: 'none', padding: '1rem', textAlign: 'center' }} 
        >Prix</TableHeaderColumn>
        <TableHeaderColumn 
          dataField='payment_mode' className="th-table-custom"
          dataFormat={this.paymentMode}
          width="200"
          thStyle={{ textAlign: 'center' }}
          tdStyle={{  fontWeight: 400, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', borderTop: 'none', borderBottom: 'none', padding: '1rem', 
                      textAlign: 'center', whiteSpace: 'normal', textTransform: 'capitalize' }} 
        >Mode paiement</TableHeaderColumn>
        <TableHeaderColumn 
          dataField='number_of_items' className="th-table-custom"
          dataFormat={this.quantity}
          width="130"
          thStyle={{ textAlign: 'center' }}
          tdStyle={{ fontWeight: 400, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', borderTop: 'none', borderBottom: 'none', padding: '1rem', textAlign: 'center' }} 
        >Quantité</TableHeaderColumn>
        <TableHeaderColumn 
          dataField='mode_livraison' className="th-table-custom"
          dataFormat={this.shippingMode}
          width="250"
          thStyle={{ textAlign: 'center' }}
          tdStyle={{ fontWeight: 400, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', borderTop: 'none', borderBottom: 'none', padding: '1rem', textAlign: 'center', whiteSpace: 'normal' }} 
        >Mode de livraison</TableHeaderColumn>
        <TableHeaderColumn 
          dataField='status' className="th-table-custom"
          dataFormat={this.orderStatus}
          width="230"
          thStyle={{ textAlign: 'center' }}
          tdStyle={{ fontWeight: 400, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', borderTop: 'none', borderBottom: 'none', padding: '1rem', textAlign: 'center' }} 
        >Statut</TableHeaderColumn>
        <TableHeaderColumn 
          dataField='id' className="th-table-custom"
          dataFormat={ this.actionOrderPlaced.bind(this) }
          width="150"
          thStyle={{ textAlign: 'center' }}
          tdStyle={{ fontWeight: 400, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', borderTop: 'none', borderBottom: 'none', padding: '1rem', textAlign: 'center' }} 
        >Actions</TableHeaderColumn>
      </BootstrapTable>
    </div>;
  }
}