import axios from "axios";
import React, { Component } from "react";
import PhoneInput from "react-phone-input-2";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { Icon, Loader, Message, Modal } from "rsuite";
import api from "../../http/global-vars";

export default class ResetCodeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        phone: "",
      },
      isLoading: false,
      error: {},
      is_empty_phone: false,
      is_empty_code: false,
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      userID: window.localStorage.getItem("userID"),
      api: api,
      phone: "",
      isSend: false,
    };
  }

  handleChange = (phone) => {
    let fields = this.state.fields;
    fields["phone"] = "+" + phone;
    console.log("+" + phone);
    this.setState({
      fields: fields,
    });
  };

  handleChangeCode = (e) => {
    let fields = this.state.fields;
    fields["code"] = e.target.value;
    this.setState({
      fields: fields,
    });
  };

  handleReset = (e) => {
    this.handleClose(e);
  };

  onValidateFormData = () => {
    let fields = this.state.fields;
    let errors = this.state.error;
    var isValidForm = true;

    if (!fields["phone"]) {
      isValidForm = false;
      this.setState({
        is_empty_phone: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_phone: false,
        });
      }, 5000);

      errors["phone"] = "Le numéro de téléphone est requis.";
    }

    if (this.state.isSend) {
      if (!fields["code"]) {
        isValidForm = false;
        this.setState({
          is_empty_code: true,
        });
        setTimeout(() => {
          this.setState({
            is_empty_code: false,
          });
        }, 5000);

        errors["code"] = "Le code est requis.";
      }
    }
    this.setState({
      error: errors,
    });

    return isValidForm;
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (this.onValidateFormData()) {
      this.setState({
        isLoading: true,
      });

      var api = this.state.api;
      var data = this.state.fields;

      axios
        .post(api + "auth/request-forget-code/", data)
        .then((response) => {
          console.log("la reponse", response);
          if (response.status === 200) {
            toast.success(
              "Envoyée avec succès. Veuillez vérifier vos messages, nous venons de vous envoyer un nouveau code pour finaliser votre connexion."
            );
            this.setState({
              isLoading: false,
              isSend: true,
            });
          }
          //   toast.success("Administrateur modifié avec succés.");
        })
        .catch((error) => {
          console.log(
            "erreur",
            error?.response?.data?.message?.includes(
              "Un code vous a déja été envoyé."
            )
          );
          if (
            error?.response?.data?.message &&
            error?.response?.data?.message?.includes(
              "Un code vous a déja été envoyé."
            )
          ) {
            this.setState({
              isLoading: false,
              isSend: true,
            });
          } else if (
            error?.response?.data?.message &&
            !error?.response?.data?.message?.includes(
              "Un code vous a déja été envoyé."
            )
          ) {
            toast.error(error.response.data.message);
          }
          this.setState({
            isLoading: false,
          });
        });
    }
  };

  onLoginSubmit = (e) => {
    if (this.onValidateFormData()) {
      this.setState({ isLoading: true });
      var api = this.state.api;
      var data = this.state.fields;

      axios
        .post(api + "auth/login-phone/", data)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            window.localStorage.setItem("userToken", response.data.token);
            var token = response.data.token;

            let requestConfig = {
              headers: { Authorization: `Bearer ${token}` },
            };
            this.props.onGetConnectedUserInfos(requestConfig);
            setTimeout(() => {
              this.setState({
                isLoading: false,
                isSend: false,
                fields: {},
                error: {},
              });
            }, 5000);
          } else {
            toast.error(
              "Une erreur est survenue lors de votre connexion! Veuillez réessayer."
            );
            // this.setState({
            //   isErrorLogin: true,
            // });
            // setTimeout(() => {
            //   this.setState({
            //     isErrorLogin: false,
            //   });
            // }, 5000);
          }
        })
        .catch((error) => {
          console.log("error", error?.response);
          this.setState({
            isLoading: false,
          });
          if (error?.response?.data?.message) {
            toast.error(error.response.data.message);
          }
        });
    }
  };

  handleClose = (e) => {
    let fields = this.state.fields;
    fields["phone"] = "+221";
    fields["code"] = "";
    this.setState({ fields: fields, isSend: false });
    this.props.handleCloseReset(e);
  };
  render() {
    return (
      <div className="modal-container">
        <Modal
          size="xs"
          show={this.props.show}
          onHide={this.handleClose}
          backdrop={"static"}
          className="rsuite-content-modal-custom"
        >
          <form>
            <Modal.Header>
              <Modal.Title>
                {" "}
                {this.state.isSend ? "Connexion" : "Reinitialiser le code"}{" "}
              </Modal.Title>
            </Modal.Header>
            <div className="my-5 flex-col itm-center">
              <div className="add-family-form-container w-100">
                <form className="crud-form" onSubmit={(e) => this.onSubmit(e)}>
                  <div className="row crud-row pb-1">
                    <div className="col-md-12 crud-col-item">
                      <div className="form-group crud-form-group required">
                        <label for="phone">Téléphone</label>

                        <PhoneInput
                          defaultMask={".. ... .. .."}
                          inputClass="form-control crud-form-control"
                          country={"sn"}
                          onlyCountries={["sn"]}
                          inputProps={{
                            name: "telephone",
                            required: true,
                            autoFocus: true,
                          }}
                          disableDropdown={true}
                          enableAreaCodes={true}
                          prefix="+"
                          value={this.state.fields.phone}
                          onChange={(phone) => this.handleChange(phone)}
                          countryCodeEditable={false}
                          inputStyle={{
                            width: "100%",
                            paddingBottom: "22px",
                            borderRadius: "10px",
                          }}
                        />
                        {this.state.is_empty_phone === true && (
                          <Message
                            className="alert-rsuite-custom"
                            type="error"
                            description={this.state.error.phone}
                          />
                        )}
                      </div>
                    </div>
                    {this.state.isSend && (
                      <div className="col-md-12 crud-col-item">
                        <div className="form-group crud-form-group required">
                          <label for="code">Code</label>
                          <input
                            type="number"
                            name="code"
                            id="code"
                            onChange={(e) => this.handleChangeCode(e)}
                            className="form-control crud-form-control"
                          />

                          {this.state.is_empty_code === true && (
                            <Message
                              className="alert-rsuite-custom"
                              type="error"
                              description={this.state.error.code}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  {/* <div className="form-group form-group-custom-form d-flex justify-content-end align-items-center mb-1">
                    <NavLink
                      to="#"
                      className="link-form-signup-login"
                      onClick={(e) => this.handleReset(e)}
                    >
                      Code oublié ?
                    </NavLink>
                  </div> */}
                </form>
              </div>
            </div>
            <Modal.Footer className="text-center">
              <div className="row px-5">
                <div className="col-6 mb-2">
                  <button
                    onClick={(e) => this.handleClose(e)}
                    className="btn-theme"
                  >
                    Annuler
                  </button>
                </div>
                <div className="col-6 mb-2">
                  {!this.state.isLoading && (
                    <button
                      className="btn-light-theme"
                      onClick={(e) =>
                        !this.state.isSend
                          ? this.onSubmit(e)
                          : this.onLoginSubmit(e)
                      }
                    >
                      {this.state.isSend ? "Connecter" : "Envoyer"}
                    </button>
                  )}

                  {this.state.isLoading && (
                    <button className="btn-light-theme">
                      En cours&nbsp;
                      <Loader />
                    </button>
                  )}
                </div>
              </div>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    );
  }
}
