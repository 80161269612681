import React, { Component, useEffect, useState } from "react";
import "./parametresDashboard.css";
import HeaderDashboard from "../headerDashboard/index";
import NavVerticalDashboard from "../navVerticalDashboard/index";
import AddBannerShopModal from "../addBannerShopModal/index";
import AddLogoShopModal from "../addLogoShopModal/index";
import api from "../../../http/global-vars";
import axios from "axios";
import baseUrl from "../../../http/backend-base-url";
import { Message, Loader } from "rsuite";
import { ToastContainer, toast } from "react-toastify";
import BannerShop from "../../../img/bannerBoutique.png";
import LogoShop from "../../../img/logo-item.png";
import { useSelector } from "react-redux";
import { getBanner, getImage } from "../../../utils/utils";
import UseEditUserInfos from "../../userInformations/form/UseEditUserInfos";
import UseAditShopInfos from "./form/UseAditShopInfos";

// export default class parametresDashboard extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       token: window.localStorage.getItem('userToken'),
//       requestConfig: {
//           headers: { Authorization: `Bearer ${window.localStorage.getItem('userToken')}` }
//       },
//       userID: window.localStorage.getItem('userID'),
//       api: api,
//       baseUrl: baseUrl,
//       loading: false,
//       shopDetails: [],
//       userInfos: {},
//       successBanner: false,
//       newSuccessBanner: false,
//       errorBanner: false,
//       newErrorBanner: false,
//       successLogo: false,
//       newSuccessLogo: false,
//       errorLogo: false,
//       newErrorLogo: false,
//       categoryList: [],
//       errors: {},
//       shopParameters: {},
//       old_email: '',
//       is_empty_email: false,
//       is_invalid_email: false,
//       is_empty_email_confirmation: false,
//       not_same_email: false,
//       is_empty_first_name: false,
//       is_empty_last_name: false,
//       is_empty_phone: false,
//       is_empty_alternative_phone: false,
//       is_empty_shop_name: false,
//       is_empty_option: false,
//       is_empty_ninea: false,
//       is_empty_legal_form: false,
//       is_empty_adresse: false,
//       is_empty_country: false,
//       is_empty_city: false,
//       is_empty_code_postal: false,
//       is_empty_person_in_charge: false,
//       is_empty_products_main_category: false,
//       is_empty_company_registration_number: false,
//       is_empty_bank_name: false,
//       is_empty_account_number: false,
//       is_empty_iban: false,
//       is_empty_account_holder_name: false,
//       is_empty_swift: false,
//       same_number_phone: false,
//       is_update_email: false,
//       isSuccessUpdateParameters: false,
//       isErrorUpdateParameters: false,
//       updateShopParametersInProgress: false
//     };
//     this.handleChangeShopParameters = this.handleChangeShopParameters.bind(this);
//   }

//   componentDidMount() {
//     window.scrollTo({top: 0, behavior: 'smooth'});
//     var config = this.state.requestConfig
//     this.onGetShopInfos(config)
//     this.onGetCategories()
//   }

//   onGetCategories = () => {
//     var api = this.state.api
//     axios.get(api + 'category/')
//       .then(response => {
//         if (response.status === 200 || response.status === 201) {
//           this.setState({
//             categoryList: response.data,
//             loading: false
//           })
//         }
//       })
//       .catch(error => {
//         if(error){
//           this.setState({ loading: false })
//         }
//       })
//   }

//   onGetShopInfos = (config) =>{
//     this.setState({
//       loading: true
//     })
//     var api = this.state.api
//     var userID = this.state.userID
//     axios.get(api + `fournisseurbyuser/${userID}/`, config)
//       .then(response => {
//         if (response.status === 200 || response.status === 201) {
//           this.setState({
//             shopDetails: response.data[0],
//             userInfos: response.data[0].user,
//             old_email: response.data[0].user.email,
//           })
//           setTimeout(() => {
//             this.setState({
//                 loading: false
//             })
//           }, 5000);

//         }
//       })
//       .catch(error => {
//           if(error){
//               this.setState({
//                   loading: false
//               })
//           }
//       })
//   }

//   callBackSuccessBanner = (successBanner) => {
//     this.setState({ newSuccessBanner: successBanner})

//     if(this.state.newSuccessBanner === true){
//       toast.success('La bannière de votre boutique à été modifié avec succès!')
//       setTimeout(() => {
//         this.setState({
//           newSuccessBanner: false
//         })
//       }, 4000);
//     }
//   }
//   callBackErrorBanner = (errorBanner) => {
//     this.setState({ newErrorBanner: errorBanner })
//     if(this.state.newErrorBanner === true){
//       toast.error('Oupsss! Une erreur est survenue lors de la modification de la banière de votre boutique!')
//       setTimeout(() => {
//         this.setState({
//           newErrorBanner: false
//         })
//       }, 4000);
//     }

//   }

//   callBackSuccessLogo = (successLogo) => {
//     this.setState({ newSuccessLogo: successLogo})
//     if(this.state.newSuccessLogo === true){
//       toast.success('Le logo de votre boutique à été modifié avec succès!')
//       setTimeout(() => {
//         this.setState({
//           newSuccessLogo: false
//         })
//       }, 4000);
//     }
//   }
//   callBackErrorLogo = (errorLogo) => {
//     this.setState({ newErrorLogo: errorLogo })
//     if(this.state.newErrorLogo === true){
//       toast.error('Oupsss! Une erreur est survenue lors de la modification du logo de votre boutique!')
//       setTimeout(() => {
//         this.setState({
//           newErrorLogo: false
//         })
//       }, 4000);
//     }
//   }

//   handleChangeShopParameters = (e) => {
//     e.persist();

//     this.setState(prevState => ({
//       userInfos: { ...prevState.userInfos, [e.target.name]: e.target.value },
//       shopDetails: { ...prevState.shopDetails, [e.target.name]: e.target.value }
//     }))
//   }

//   scrollToInfosVendeur = () => { window.scrollTo({ top: 500, behavior: 'smooth' }); }
//   scrollToInfosAffaire = () => { window.scrollTo({ top: 850, behavior: 'smooth' }); }
//   scrollToInfosBancaire = () => { window.scrollTo({ top: 1200, behavior: 'smooth' }); }

//   onValidateParameters = () => {
//     let userInfosChange = this.state.userInfos
//     let shopDetailsChange = this.state.shopDetails
//     let errors = this.state.errors
//     var isValidForm = true

//     if(!userInfosChange['first_name']){
//       isValidForm = false
//       this.setState({
//         is_empty_first_name: true
//       })
//       this.scrollToInfosVendeur()
//       setTimeout(() => {
//         this.setState({
//           is_empty_first_name: false
//         })
//       }, 5000);
//       errors['first_name'] = 'Le prénom est obligatoire!'
//     }
//     if(!userInfosChange['last_name']){
//       isValidForm = false
//       this.setState({
//         is_empty_last_name: true
//       })
//       this.scrollToInfosVendeur()
//       setTimeout(() => {
//         this.setState({
//           is_empty_last_name: false
//         })
//       }, 5000);
//       errors['last_name'] = 'Le nom de famille est obligatoire!'
//     }
//     if(!userInfosChange['email']){
//       isValidForm = false
//       this.setState({
//         is_empty_email: true
//       })
//       this.scrollToInfosVendeur()
//       setTimeout(() => {
//         this.setState({
//           is_empty_email: false
//         })
//       }, 5000);
//       errors['email'] = "L adresse e-mail est obligatoire!"
//     }
//     if (typeof userInfosChange["email"] !== "undefined") {
//       var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
//       if (!pattern.test(userInfosChange["email"])) {
//           isValidForm = false
//           this.setState({
//               is_invalid_email: true
//           })
//           this.scrollToInfosVendeur()
//           setTimeout(() => {
//               this.setState({
//                   is_invalid_email: false
//               })
//           }, 5000);

//           errors['invalid_email'] = 'Votre adresse email n\'est pas valide!'
//       }
//     }
//     if(userInfosChange['phone'] === shopDetailsChange['alternative_phone']){
//         isValidForm = false
//         this.setState({
//           same_number_phone: true
//         })
//         this.scrollToInfosVendeur()
//         setTimeout(() => {
//             this.setState({
//                 same_number_phone: false
//             })
//         }, 5000);
//         errors['alternative_phone'] = 'Le numéro alternatif doit être different du numero par défaut!'
//     }
//     if(!userInfosChange['phone']){
//         isValidForm = false
//         this.setState({
//           is_empty_phone: true
//         })
//         this.scrollToInfosVendeur()
//         setTimeout(() => {
//             this.setState({
//                 is_empty_phone: false
//             })
//         }, 5000);
//         errors['phone'] = 'Le numéro de téléphone est obligatoire!'
//     }
//     if(!shopDetailsChange['shop_name']){
//         isValidForm = false
//         this.setState({
//             is_empty_shop_name: true
//         })
//         this.scrollToInfosAffaire()
//         setTimeout(() => {
//             this.setState({
//                 is_empty_shop_name: false
//             })
//         }, 5000);
//         errors['shop_name'] = 'Le nom de la boutique est obligatoire!'
//     }
//     if(!shopDetailsChange['option']){
//       isValidForm = false
//       this.setState({
//           is_empty_option: true
//       })
//       this.scrollToInfosAffaire()
//       setTimeout(() => {
//           this.setState({
//               is_empty_option: false
//           })
//       }, 5000);
//       errors['option'] = 'Veuillez sélectionner une des 2 options'
//     }
//     if(!shopDetailsChange['ninea']){
//         isValidForm = false
//         this.setState({
//             is_empty_ninea: true
//         })
//         this.scrollToInfosAffaire()
//         setTimeout(() => {
//             this.setState({
//                 is_empty_ninea: false
//             })
//         }, 5000);
//         errors['ninea'] = 'Le ninea est obligatoire!'
//     }
//     if(!shopDetailsChange['legal_form']){
//         isValidForm = false
//         this.setState({
//             is_empty_legal_form: true
//         })
//         this.scrollToInfosAffaire()
//         setTimeout(() => {
//             this.setState({
//                 is_empty_legal_form: false
//             })
//         }, 5000);
//         errors['legal_form'] = 'La forme juridique est obligatoire!'
//     }
//     if(!userInfosChange['adresse']){
//         isValidForm = false
//         this.setState({
//             is_empty_adresse: true
//         })
//         this.scrollToInfosAffaire()
//         setTimeout(() => {
//             this.setState({
//                 is_empty_adresse: false
//             })
//         }, 5000);
//         errors['adresse'] = 'L\'adresse de la boutique est obligatoire!'
//     }
//     if(!shopDetailsChange['country']){
//         isValidForm = false
//         this.setState({
//             is_empty_country: true
//         })
//         this.scrollToInfosAffaire()
//         setTimeout(() => {
//             this.setState({
//                 is_empty_country: false
//             })
//         }, 5000);
//         errors['country'] = 'Le pays est obligatoire!'
//     }
//     if(!shopDetailsChange['city']){
//         isValidForm = false
//         this.setState({
//             is_empty_city: true
//         })
//         this.scrollToInfosAffaire()
//         setTimeout(() => {
//             this.setState({
//                 is_empty_city: false
//             })
//         }, 5000);
//         errors['city'] = 'La ville est obligatoire!'
//     }
//     if(!shopDetailsChange['code_postal']){
//         isValidForm = false
//         this.setState({
//             is_empty_code_postal: true
//         })
//         this.scrollToInfosAffaire()
//         setTimeout(() => {
//             this.setState({
//                 is_empty_code_postal: false
//             })
//         }, 5000);
//         errors['code_postal'] = 'Le code postal est obligatoire!'
//     }
//     if(!shopDetailsChange['person_in_charge']){
//         isValidForm = false
//         this.setState({
//             is_empty_person_in_charge: true
//         })
//         this.scrollToInfosAffaire()
//         setTimeout(() => {
//             this.setState({
//                 is_empty_person_in_charge: false
//             })
//         }, 5000);
//         errors['person_in_charge'] = 'Ce champ est obligatoire!'
//     }
//     if(!shopDetailsChange['products_main_category']){
//         isValidForm = false
//         this.setState({
//             is_empty_products_main_category: true
//         })
//         this.scrollToInfosAffaire()
//         setTimeout(() => {
//             this.setState({
//                 is_empty_products_main_category: false
//             })
//         }, 5000);
//         errors['products_main_category'] = 'Veuillez sélectionner la catégorie principale de vos produits'
//     }
//     if(!shopDetailsChange['company_registration_number']){
//         isValidForm = false
//         this.setState({
//             is_empty_company_registration_number: true
//         })
//         this.scrollToInfosAffaire()
//         setTimeout(() => {
//             this.setState({
//                 is_empty_company_registration_number: false
//             })
//         }, 5000);
//         errors['company_registration_number'] = 'Ce champ est obligatoire!'
//     }
//     if(!shopDetailsChange['bank_name']){
//       isValidForm = false
//       this.setState({
//           is_empty_bank_name: true
//       })
//       this.scrollToInfosBancaire()
//       setTimeout(() => {
//           this.setState({
//               is_empty_bank_name: false
//           })
//       }, 5000);
//       errors['bank_name'] = 'Le nom de la banque est obligatoire!'
//     }
//     if(!shopDetailsChange['account_number']){
//         isValidForm = false
//         this.setState({
//             is_empty_account_number: true
//         })
//         this.scrollToInfosBancaire()
//         setTimeout(() => {
//             this.setState({
//                 is_empty_account_number: false
//             })
//         }, 5000);
//         errors['account_number'] = 'Le numéro de compte est obligatoire!'
//     }
//     if(!shopDetailsChange['iban']){
//         isValidForm = false
//         this.setState({
//             is_empty_iban: true
//         })
//         this.scrollToInfosBancaire()
//         setTimeout(() => {
//             this.setState({
//                 is_empty_iban: false
//             })
//         }, 5000);
//         errors['iban'] = 'L\'iban est obligatoire!'
//     }
//     if(!shopDetailsChange['account_holder_name']){
//         isValidForm = false
//         this.setState({
//             is_empty_account_holder_name: true
//         })
//         this.scrollToInfosBancaire()
//         setTimeout(() => {
//             this.setState({
//                 is_empty_account_holder_name: false
//             })
//         }, 5000);
//         errors['account_holder_name'] = 'Le nom du titulaire de compte est obligatoire!'
//     }
//     if(!shopDetailsChange['swift']){
//         isValidForm = false
//         this.setState({
//             is_empty_swift: true
//         })
//         this.scrollToInfosBancaire()
//         setTimeout(() => {
//             this.setState({
//                 is_empty_swift: false
//             })
//         }, 5000);
//         errors['swift'] = 'Veuillez renseigner le swift!'
//     }

//     this.setState({
//       errors
//     })

//     return isValidForm
//   }

//   onLogoutUser = () => {
//     toast.success('Les nouvelles informations de votre boutique ont été bien prise en compte!', { autoClose: 4000 })
//     toast.warning('Votre e-mail à été modifié également! Vous serrez déconnecté dans quelques secondes...')
//     window.localStorage.removeItem('userToken')

//     this.setState({
//         is_update_email: true
//     })
//     setTimeout(() => {
//       this.setState({
//         is_update_email: false
//       })
//       window.location = '/connexion'
//     }, 6000);
//   }

//   onSaveUserInfosParameters = () => {
//     var api = this.state.api
//     var config = this.state.requestConfig
//     var dataUser = new FormData()
//     var userID = this.state.userID
//     var userEmail = this.state.old_email

//     dataUser.append('first_name', this.state.userInfos.first_name)
//     dataUser.append('last_name', this.state.userInfos.last_name)
//     dataUser.append('email', this.state.userInfos.email)
//     dataUser.append('phone', this.state.userInfos.phone)
//     // dataUser.append('adresse', this.state.userInfos.adresse)

//     axios.put(`${api}user/${userID}/`, dataUser, config)
//       .then(response => {
//         if(response.status === 200 || response.status === 201){
//           this.setState({ updateShopParametersInProgress: false })

//           if(userEmail !== this.state.userInfos.email){
//             this.onLogoutUser()
//           }
//           else{
//             window.localStorage.setItem('userFirstName', dataUser.first_name)
//             window.localStorage.setItem('userLastName', dataUser.last_name)
//             window.localStorage.setItem('userFullname', dataUser.first_name + ' ' + dataUser.last_name)
//             // toast.success('Les nouvelles informations de votre boutique ont été bien prise en compte!')
//             this.setState({
//                 isSuccessUpdateParameters: true
//             })
//             setTimeout(() => {
//                 this.setState({
//                   isSuccessUpdateParameters: false
//                 })
//                 window.location.reload()
//             }, 4000);
//           }
//         }
//         else{
//             toast.error('Oupsss! Une erreur est survenue lors de la mise à jour des informations de votre boutique')
//             this.setState({
//                 isErrorUpdateParameters: true
//             })
//             setTimeout(() => {
//                 this.setState({
//                   isErrorUpdateParameters: false
//                 })
//             }, 5000);
//         }

//       })
//       .catch(error => {
//         toast.error('Oupsss! Une erreur est survenue lors de la modification de votre profil')
//         this.setState({
//             isErrorUpdateParameters: true
//         })
//         setTimeout(() => {
//             this.setState({
//               isErrorUpdateParameters: false
//             })
//         }, 5000);
//       })

//   }

//   onSaveShopDetailsParameters = () => {
//     var api = this.state.api
//     var config = this.state.requestConfig
//     var data = new FormData()
//     var shopID = this.state.shopDetails.id

//     data.append('user', this.state.userInfos.id)
//     data.append('shop_name', this.state.shopDetails.shop_name)
//     data.append("adresse", this.state.shopDetails.adresse);
//     data.append('option', this.state.shopDetails.option)
//     data.append('ninea', this.state.shopDetails.ninea)
//     data.append('legal_form', this.state.shopDetails.legal_form)
//     data.append('country', this.state.shopDetails.country)
//     data.append('city', this.state.shopDetails.city)
//     data.append('code_postal', this.state.shopDetails.code_postal)
//     data.append('person_in_charge', this.state.shopDetails.person_in_charge)
//     data.append('products_main_category', this.state.shopDetails.products_main_category)
//     data.append('company_registration_number', this.state.shopDetails.company_registration_number)
//     data.append('bank_name', this.state.shopDetails.bank_name)
//     data.append('account_number', this.state.shopDetails.account_number)
//     data.append('iban', this.state.shopDetails.iban)
//     data.append('account_holder_name', this.state.shopDetails.account_holder_name)
//     data.append('swift', this.state.shopDetails.swift)
//     data.append('alternative_phone', this.state.shopDetails.alternative_phone)

//     axios.put(`${api}fournisseur/${shopID}/`, data, config)
//       .then(response => {
//         if(response.status === 200){
//           this.setState({ updateShopParametersInProgress: false })
//           toast.success('Les nouvelles informations de votre boutique ont été bien prise en compte!')
//           this.setState({
//               isSuccessUpdateParameters: true
//           })
//           setTimeout(() => {
//               this.setState({
//                 isSuccessUpdateParameters: false
//               })
//               window.location.reload()
//           }, 4000);
//         }
//         else{
//           toast.error('Oupsss! Une erreur est survenue lors de la mise à jour des informations de votre boutique')
//           this.setState({
//               isErrorUpdateParameters: true
//           })
//           setTimeout(() => {
//               this.setState({
//                 isErrorUpdateParameters: false
//               })
//           }, 5000);
//         }

//       })
//       .catch(error => {
//         // toast.error('Oupsss! Une erreur est survenue lors de la mise à jour des informations de votre boutique ')
//         this.setState({
//             isErrorUpdateParameters: true
//         })
//         setTimeout(() => {
//             this.setState({
//               isErrorUpdateParameters: false
//             })
//         }, 5000);
//       })

//   }

//   onSubmitParameters = (e) => {
//     e.preventDefault()

//     if(this.onValidateParameters() === true ){
//       this.setState({
//           updateShopParametersInProgress: true
//       })
//       this.onSaveUserInfosParameters()
//       this.onSaveShopDetailsParameters()
//     }
//   }

//   render() {
//     var { baseUrl, shopDetails, userInfos} = this.state
//     console.log('shop first ', shopDetails)
//     return (

//     );
//   }
// }

function ParametresDashboard() {
  const { user } = useSelector((s) => s?.user);
  const [shopDetails, setShopDetails] = useState(user?.provider);

  useEffect(() => {
    if (user?.id) {
      setShopDetails(user?.provider);
    }
  }, [user]);
  return (
    <div className="p-b-200">
      <HeaderDashboard />
      <div className="fixed-after-header-dashboard container-fluid">
        <div className="row">
          <div className="col-xl-2 col-lg-3 no-view-tablette">
            <NavVerticalDashboard />
          </div>
          <div className="col-xl-10 col-lg-9 px-xl-3 py-4 pr-lg-3 pl-lg-1">
            <div className="content-view-dashboard">
              <p className="title-filter-dashboard mb-5">Paramètres</p>

              <p className="legende-banner-logo">Bannière de la boutique</p>

              <div className="container-banner-shop">
                <div
                  className="image banner-shop-dashboard"
                  style={{
                    background: `url(${getBanner(shopDetails?.banner)})`,
                  }}
                ></div>
                <div className="middle">
                  <AddBannerShopModal
                    shopDetails={shopDetails}
                    // config={requestConfig}
                    userID={user?.id}
                    // callBackSuccessBanner={this.callBackSuccessBanner}
                    // callBackErrorBanner={this.callBackErrorBanner}
                  />
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-xl-3 col-lg-4 col-md-4 col-sm-5">
                  <p className="legende-banner-logo">Logo de la boutique</p>
                  <div className="container-banner-shop">
                    <img
                      loading="lazy"
                      className="logo-shop-dashboard"
                      src={getImage(shopDetails?.logo)}
                      alt={shopDetails?.name}
                    />
                    <div className="middle">
                      <AddLogoShopModal
                        shopDetails={shopDetails}
                        // config={requestConfig}
                        userID={user?.id}
                        // callBackSuccessLogo={this.callBackSuccessLogo}
                        // callBackErrorLogo={this.callBackErrorLogo}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <UserInfos />

                <SellerInfos />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ParametresDashboard;

const UserInfos = () => {
  const { onSubmit, register, errors, isLoading } = UseEditUserInfos();
  return (
    <form onSubmit={onSubmit}>
      <div className="my-5">
        <p className="consigne-title-steps parametres">
          Informations du compte du vendeur
        </p>
        <div className="row">
          <div className="col-md-6 pr-xl-5 pr-lg-0 pr-md-4">
            <div className="form-group pos-relative mb-4">
              <input
                type="text"
                name="first_name"
                className="form-control input-steps-inscription-vendeur dashboard-parametre-input"
                {...register("first_name")}
                placeholder="Prénom *"
              />
              {errors?.first_name && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={errors?.first_name?.message}
                />
              )}
            </div>
            <div className="form-group pos-relative mb-4">
              <input
                type="text"
                
                className="form-control input-steps-inscription-vendeur dashboard-parametre-input"
                {...register("last_name")}
                placeholder="Nom *"
              />
              {errors?.last_name && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={errors?.last_name?.message}
                />
              )}
            </div>
            <div className="form-group pos-relative mb-4">
              <input
                type="tel"
                
                className="form-control input-steps-inscription-vendeur dashboard-parametre-input number-input-custom"
                {...register("phone")}
                placeholder="N° téléphone *"
              />
              {errors?.phone && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={errors?.phone?.message}
                />
              )}
            </div>
          </div>
          <div className="col-md-6 pl-lg-5 pl-md-4">
            <div className="form-group pos-relative mb-4">
              <input
                type="tel"
                className="form-control input-steps-inscription-vendeur dashboard-parametre-input number-input-custom"
                {...register("alternative_phone")}
                placeholder="Whatsapp / Numéro de téléphone alternatif"
              />
              {errors?.alternative_phone && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={errors?.alternative_phone?.message}
                />
              )}
            </div>
            <div className="form-group pos-relative mb-4">
              <input
                type="email"
                className="form-control input-steps-inscription-vendeur dashboard-parametre-input"
                placeholder="Adresse mail *"
                {...register("email")}
              />
              {errors?.email && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={errors?.email?.message}
                />
              )}
            </div>
            <div className="form-group pos-relative mb-4">
              <input
                type="text"
                className="form-control input-steps-inscription-vendeur dashboard-parametre-input"
                placeholder="Adresse de livraison *"
                {...register("adresse")}
              />
              {errors?.adresse && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={errors?.adresse?.message}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row dis-flex">
        <div className="ml-auto col-lg-6 col-md-7 pl-lg-5 pl-md-4">
          {!isLoading ? (
            <button type="submit" className="btn-user-info-form text-up">
              ENREGISTRER
            </button>
          ) : (
            <button
              type="submit"
              disabled
              className="btn-form-dark in-progress-btn"
            >
              ENREGISTREMENT EN COURS &nbsp;
              <Loader />
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

const SellerInfos = () => {
  const {register,errors,onSubmit,isLoading,categoryList} = UseAditShopInfos()
  return (
    <form onSubmit={onSubmit}>
      <div className="my-5">
        <p className="consigne-title-steps parametres">
          Informations d'affaires
        </p>
        <div className="row">
          <div className="col-md-6 pr-xl-5 pr-lg-0 pr-md-4">
            <div className="form-group pos-relative mb-4">
              <select
                {...register("option")}
                className="form-control select-steps-inscription-vendeur dashboard-parametre-input"
              >
                <option disabled>Choisir une option</option>
                <option value="Entité commerciale">Personne physique</option>
                <option value="Entreprise">
                  Entité commerciale/Entreprise
                </option>
              </select>
              {errors?.option && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={errors?.option?.message}
                />
              )}
            </div>

            <div className="form-group pos-relative mb-4">
              <input
                type="text"
                
                className="form-control input-steps-inscription-vendeur dashboard-parametre-input"
                {...register("shop_name")}
                placeholder="Nom de la boutique"
              />
              {errors?.shop_name && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={errors?.shop_name?.message}
                />
              )}
            </div>
            <div className="form-group pos-relative mb-4">
              <input
                type="text"
                
                className="form-control input-steps-inscription-vendeur dashboard-parametre-input"
                {...register("ninea")}
                placeholder="NINEA"
              />
              {errors?.ninea && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={errors?.ninea?.message}
                />
              )}
            </div>
            <div className="form-group pos-relative mb-4">
              <input
                type="text"
                {...register("legal_form")}
                className="form-control input-steps-inscription-vendeur dashboard-parametre-input"
                placeholder="Forme juridique"
              />
              {errors?.legal_form && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={errors?.legal_form?.message}
                />
              )}
            </div>
            <div className="form-group pos-relative mb-4">
              <input
                type="text"
                {...register("adresse")}
                className="form-control input-steps-inscription-vendeur dashboard-parametre-input"
                placeholder="Adresse"
              />
              {errors?.adresse && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={errors?.adresse?.message}
                />
              )}
            </div>
            <div className="form-group pos-relative mb-4">
              <input
                type="text"
                {...register("country")}
                className="form-control input-steps-inscription-vendeur dashboard-parametre-input"
                placeholder="Pays"
              />
              {errors?.country && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={errors?.country?.message}
                />
              )}
            </div>
          </div>
          <div className="col-md-6 pl-lg-5 pl-md-4">
            <div className="form-group pos-relative mb-4">
              <input
                type="text"
                {...register("city")}
                className="form-control input-steps-inscription-vendeur dashboard-parametre-input"
                placeholder="Commune / Ville *"
              />
              {errors?.city && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={errors?.city?.message}
                />
              )}
            </div>
            <div className="form-group pos-relative mb-4">
              <input
                type="number"
                className="form-control input-steps-inscription-vendeur dashboard-parametre-input number-input-custom"
                {...register("code_postal")}
                placeholder="Code postal"
              />
              {errors?.code_postal && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={errors?.code_postal?.message}
                />
              )}
            </div>
            <div className="form-group pos-relative mb-4">
              <input
                type="text"
                {...register("person_in_charge")}
                className="form-control input-steps-inscription-vendeur dashboard-parametre-input"
                placeholder="Personne responsable"
              />
              {errors?.person_in_charge && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={errors?.person_in_charge?.message}
                />
              )}
            </div>
            <div className="form-group pos-relative mb-4">
              <select
                className="form-control select-steps-inscription-vendeur"
                {...register("products_main_category")}
              >
                <option disabled>Catégorie Principale de vos produits</option>
                {categoryList.map((category) => (
                  <option value={category.id}>{category.name}</option>
                ))}
              </select>
              {errors?.products_main_category && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={errors?.products_main_category?.message}
                />
              )}
            </div>
            
            <div className="form-group pos-relative mb-4">
              <input
                type="text"
                {...register("company_registration_number")}
                className="form-control input-steps-inscription-vendeur dashboard-parametre-input"
                placeholder="N° d'Enregistrement des Entreprises"
              />
              {errors?.company_registration_number && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={errors?.company_registration_number?.message}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="my-5">
        <p className="consigne-title-steps parametres">Compte bancaire</p>
        <div className="row">
          <div className="col-md-6 pr-xl-5 pr-lg-0 pr-md-4">
            <div className="form-group mb-4">
              <input
                type="text"
                {...register("bank_name")}
                className="form-control input-steps-inscription-vendeur dashboard-parametre-input"
                placeholder="Nom de votre banque"
              />
              {errors?.bank_name && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={errors?.bank_name?.message}
                />
              )}
            </div>
            <div className="form-group mb-4">
              <input
                type="number"
                {...register("account_number")}
                className="form-control input-steps-inscription-vendeur dashboard-parametre-input number-input-custom"
                placeholder="Numéro de compte"
              />
              {errors?.account_number && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={errors?.account_number?.message}
                />
              )}
            </div>
            <div className="form-group mb-4">
              <input
                type="number"
                className="form-control input-steps-inscription-vendeur dashboard-parametre-input number-input-custom"
                placeholder="IBAN"
                {...register("iban")}
              />
              {errors?.iban && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={errors?.iban?.message}
                />
              )}
            </div>
          </div>
          <div className="col-md-6 pl-lg-5 pl-md-4">
            <div className="form-group mb-4">
              <input
                type="text"
                {...register("account_holder_name")}
                className="form-control input-steps-inscription-vendeur dashboard-parametre-input"
                placeholder="Nom du titulaire de compte"
              />
              {errors?.account_holder_name && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={errors?.account_holder_name?.message}
                />
              )}
            </div>
            <div className="form-group mb-4">
              <input
                type="number"
                {...register("swift")}
                className="form-control input-steps-inscription-vendeur dashboard-parametre-input number-input-custom"
                placeholder="SWIFT"
              />
              {errors?.swift && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={errors?.swift?.message}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row dis-flex">
        <div className="ml-auto col-lg-6 col-md-7 pl-lg-5 pl-md-4">
          {!isLoading ? (
            <button type="submit" className="btn-user-info-form text-up">
              ENREGISTRER LES MODIFICATIONS
            </button>
          ) : (
            <button
              type="submit"
              disabled
              className="btn-form-dark in-progress-btn"
            >
              ENREGISTREMENT EN COURS &nbsp;
              <Loader />
            </button>
          )}
        </div>
      </div>
    </form>
  );
};
