import React, { Component } from "react";
import "./socialLogin.css";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import FeatherIcon from "feather-icons-react";
import api from "../../http/global-vars";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoginPhoneModal from "../LoginPhoneModal/LoginPhoneModal";
import ResetCodeModal from "../LoginPhoneModal/ResetCodeModal";
import InscriptionPhoneModal from "../LoginPhoneModal/InscriptionPhoneModal";
import UseSocialLogin from "../connexion/form/UseSocialLogin";

// export default class socialLogin extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       token: window.localStorage.getItem("userToken"),
//       requestConfig: {
//         headers: {
//           Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
//         },
//       },
//       userID: window.localStorage.getItem("userID"),
//       api: api,
//       fields: {},
//       socialLoginInProgress: false,
//       isSuccessRegister: false,
//       isErrorRegister: false,
//       isExistUser: false,
//       show: false,
//       showReset: false,
//       showRegister: false
//     };
//   }

//   componentClicked = (data) => {};

//   responseFacebook = async (response) => {
//     this.setState({
//       socialLoginInProgress: true,
//     });

//     let fields = this.state.fields;
//     fields["email"] = response.email;
//     fields["first_name"] = response.first_name;
//     fields["last_name"] = response.last_name;

//     var api = this.state.api;
//     var data = this.state.fields;

//     await axios
//       .post(api + "auth/registersocial/", data)
//       .then(async (response) => {
//         if (response.status === 200 || response.status === 201) {
//           window.localStorage.setItem("userToken", response.data.token);
//           var token = response.data.token;

//           let requestConfig = {
//             headers: { Authorization: `Bearer ${token}` },
//           };
//           await this.props.onGetConnectedUserInfos(requestConfig);
//         } else {
//           toast.error(
//             "Une erreur est survenue lors de votre connexion! Veuillez réessayer."
//           );
//           this.setState({
//             isErrorLogin: true,
//           });
//           setTimeout(() => {
//             this.setState({
//               isErrorLogin: false,
//             });
//           }, 5000);
//         }
//       })
//       .catch((error) => {
//         if (
//           error?.response?.data?.error?.email[0] &&
//           error?.response?.data?.error?.email[0] ===
//             "user with this email already exists."
//         ) {
//           this.setState({
//             isExistUser: true,
//           });
//           setTimeout(() => {
//             this.setState({
//               isExistUser: false,
//             });
//           }, 6000);
//         }
//       });
//   };

//   responseLoginGoogle = async (response) => {
//     this.setState({
//       socialLoginInProgress: true,
//     });

//     console.log(response);
//     let fields = this.state.fields;

//     fields["email"] = response?.profileObj?.email;
//     fields["first_name"] = response?.profileObj?.givenName;
//     fields["last_name"] = response?.profileObj?.familyName;
//     fields["phone"] = null;
//     this.setState(fields);

//     var api = this.state.api;
//     var data = this.state.fields;

//     await axios
//       .post(api + "auth/registersocial/", data)
//       .then(async (response) => {
//         if (response.status === 200 || response.status === 201) {
//           if (response?.data?.message && !response.data.token) {
//             toast.error(response?.data?.message);
//           } else {
//             window.localStorage.setItem("userToken", response.data.token);
//             var token = response.data.token;
//             console.log("responsegoo", response);

//             let requestConfig = {
//               headers: { Authorization: `Bearer ${token}` },
//             };
//             await this.props.onGetConnectedUserInfos(requestConfig);
//           }
//         } else {
//           toast.error(
//             "Une erreur est survenue lors de votre connexion! Veuillez réessayer."
//           );
//           this.setState({
//             isErrorLogin: true,
//           });
//           setTimeout(() => {
//             this.setState({
//               isErrorLogin: false,
//             });
//           }, 5000);
//         }
//       })
//       .catch((error) => {
//         console.log("error", error?.response);
//         if (
//           error?.response?.data?.error?.email[0] &&
//           error?.response?.data?.error?.email[0] ===
//             "user with this email already exists."
//         ) {
//           this.setState({
//             isExistUser: true,
//           });
//           setTimeout(() => {
//             this.setState({
//               isExistUser: false,
//             });
//           }, 10000);
//         }
//       });
//   };

//   handleClose = (e) => {
//     e.preventDefault();
//     this.setState({ show: false, showRegister: false });
//   };
//   handleOpen = () => {
//     if (this.props.from !== "register") {
//       this.setState({ show: true });
//     } else {
//       this.setState({ showRegister: true });
//     }

//   };

//   handleCloseReset = (e) => {
//     e.preventDefault();
//     this.setState({ showReset: false });
//   };
//   handleOpenReset = () => {
//     this.setState({ showReset: true });
//   };

//   render() {
//     const { from } = this.props;
//     return (

//     );
//   }
// }

function SocialLogin({ from }) {
  const { onSubmit, isLoading } = UseSocialLogin();
  const responseFacebook = async (response) => {
  

    let fields = {};
    fields["email"] = response.email;
    fields["first_name"] = response.first_name;
    fields["last_name"] = response.last_name;

    await onSubmit(fields);
  };

  const responseLoginGoogle = async (response) => {
   

    console.log(response);
    let fields = {}

    fields["email"] = response?.profileObj?.email;
    fields["first_name"] = response?.profileObj?.givenName;
    fields["last_name"] = response?.profileObj?.familyName;
    fields["phone"] = null;

    await await onSubmit(fields);
  };
  return (
    <div className=" m-b-80">
      <div className="row">
        <div className="col-md-12 itm-center">
          <p className="fs-12 text-black">
            {from === "register" ? "Inscrivez-vous" : "Connectez-vous"} avec{" "}
          </p>
          {from !== "register" && (
            <>
              <GoogleLogin
                clientId="419213678083-i6uafbahfe71l0op3gtrvtq6ulcd4mvf.apps.googleusercontent.com"
                onSuccess={responseLoginGoogle}
                onFailure={responseLoginGoogle}
                className="login-with-button"
              />

              <FacebookLogin
                appId="430829579169608"
                autoLoad={false}
                fields="first_name,last_name,email"
                // onClick={componentClicked}
                callback={responseFacebook}
                textButton="f"
              />
            </>
          )}
        </div>
      </div>

      {/* {this.state.isExistUser === true && (
      <div className="row exist-user-alert-row mt-3">
        <div className="col-md-12 exist-user-alert-col">
          <div className="alert alert-danger exist-user-alert-danger">
            Votre e-mail est déjà associé à un compte! <br />
            Veuillez utiliser une autre adresse e-mail pour créer un compte
            ou vous connecter.
          </div>
        </div>
      </div>
    )} */}
    </div>
  );
}

export default SocialLogin;
