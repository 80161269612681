import React, { Component } from 'react';
import './checkout.css';
import { NavLink } from 'react-router-dom';
import Header from '../header/index';
import Footer from '../footer/index';
import FeatherIcon from 'feather-icons-react';
import CheckoutContainer from '../checkoutContainer';
import Skeleton from 'react-loading-skeleton';

export default class checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }
  componentDidMount() {
    window.scrollTo({top: 0, behavior: 'smooth'});
    this.setState({ loading: true });
    const timer = setTimeout(() => {
      this.setState({ loading: false });
    }, 3000);
    // Cancel the timer while unmounting
    return () => clearTimeout(timer);
  }

  render() {
    return <div>
      <Header />
      <div className="body-theme p-t-20 p-b-45 fixed-after-header">
        <div className="container">
          <div className="pos-relative">

            {this.state.loading ?
              (
                <>
                <Skeleton className="link-previous-ariane" width={15}/>
                <Skeleton className="h-previous-ariane m-b-25" width={500} />
                </>
              ) :
              (
                <>
                  <NavLink to="" className="">
                    <FeatherIcon className="link-previous-ariane" icon="arrow-left" />
                  </NavLink>
                  <h4 className="h-previous-ariane m-b-25">Finalisation de la commande</h4>
                </>
              )}

          </div>
          <div className="content">
            <div>
              <CheckoutContainer />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>;
  }
}
