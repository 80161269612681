import { ORDERS_BY_SHOP, ORDERS } from '../types'
import api from '../http/global-vars'

export const fetchOrdersByShop = (id) => {
  return async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
      },
    }
    const url = api + 'orderbyfournisseur/' + id + '/'
    
    await fetch(url, config)
      .then(async (res) => {
        const data = await res.json();
        let values = [];
        data.forEach((element) => {
          if (
            element?.cartitems?.filter(
              (item) => item?.item?.provider?.id === id
            )?.length
          ) {
            let total = 0;
            element?.cartitems
              ?.filter((item) => item?.item?.provider?.id === id)
              .forEach((item) => {
                total += (item?.price || item?.item?.price) * item?.quantity;
              });
            element.cart.total = total;
            element.cartitems = element?.cartitems?.filter(
              (item) => item?.item?.provider?.id === id
            );
            element.number_of_items = element?.cartitems?.filter(
              (item) => item?.item?.provider?.id === id
            )?.length;
            element.cart.number_of_items = element?.cartitems?.filter(
              (item) => item?.item?.provider?.id === id
            )?.length;
            values.push(element);
          }
        });
        // console.log("cool here", values);
        dispatch({
          type: ORDERS_BY_SHOP,
          payload: data?.sort(
            (a, b) => new Date(a?.order_date) - new Date(b?.order_date)
          ),
        });
      })
      .catch((error) => console.log("error", error));
  }
}

export const fetchOrders = () => {
  return async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
      },
    }
    const url = api + 'order/'
    const res = await fetch(url, config)
    const data = await res.json()
    
    dispatch({
      type: ORDERS,
      payload: data,
    })
  }
}
