import React, { Component } from "react";
import HeaderDashboard from "../headerDashboard/index";
import NavVerticalDashboardAdmin from "../navVerticalDashboardAdmin/index";
import FeatherIcon from "feather-icons-react";
import TableCategories from "../TableCatgories/index";
import TableSubCategory from "../TableSubCategory/TableSubCategory";
import axios from "axios";
import api from "../../../http/global-vars";

class CategoriesDashboardAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
    };
  }

  componentDidMount() {
    this.getCategories();
  }
  getCategories = () => {
    axios.get(`${api}category/`, this.state.requestConfig).then((response) => {
      console.log("response", response);
      this.setState({
        categories: response?.data?.sort((a, b) => b?.id - a?.id),
      });
    });
  };
  render() {
    return (
      <div className="component-articles-dashboard-admin">
        <HeaderDashboard />
        <div className="fixed-after-header-dashboard container-fluid">
          <div className="row">
            <div className="col-xl-2 col-lg-3 no-view-tablette">
              <NavVerticalDashboardAdmin />
            </div>
            <div className="col-xl-10 col-lg-9 px-xl-3 py-4 pr-lg-3 pl-lg-1">
              <div className="card-dashboard-content-table">
                <div className="head-card-dashboard-content-table">
                  <div className="row mb-5">
                    <div className="col-md-9 col-sm-8 mb-sm-0 mb-3">
                      <p className="title-filter-dashboard">
                        Tous les catégories
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xl-9">
                      <nav>
                        <div
                          className="nav nav-tabs nav-tabs-horizontal-commandes"
                          id="nav-tab"
                          role="tablist"
                        >
                          <a
                            className="nav-link nav-horizontal-commandes active"
                            id="nav-tout-tab"
                            data-toggle="tab"
                            href="#nav-tout"
                            role="tab"
                            aria-controls="nav-tout"
                            aria-selected="true"
                          >
                            Catégories
                          </a>
                          <a
                            className="nav-link nav-horizontal-commandes"
                            id="nav-active-tab"
                            data-toggle="tab"
                            href="#nav-active"
                            role="tab"
                            aria-controls="nav-active"
                            aria-selected="false"
                          >
                            Sous Catégories
                          </a>
                        </div>
                      </nav>
                    </div>
                    <div className="col-xl-3"></div>
                  </div>
                </div>
                <div className="content-card-dashboard-content-table">
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="nav-tout"
                      role="tabpanel"
                      aria-labelledby="nav-tout-tab"
                    >
                      <TableCategories
                        getCategories={this.getCategories}
                        categories={this.state.categories}
                      />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-active"
                      role="tabpanel"
                      aria-labelledby="nav-active-tab"
                    >
                      <TableSubCategory
                        getCategories={this.getCategories}
                        categories={this.state.categories}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CategoriesDashboardAdmin;
