import React from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

function ListCategoriesFooter() {
  const categoryList = useSelector((s) => s?.categories);
  return (
    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 m-b-70">
      <h5 className="text-white m-b-20">Toutes les catégories</h5>
      <ul className="ul-footer">
        {categoryList?.isLoading
          ? Array(8)
              .fill()
              .map((category, i) => {
                return (
                  <Skeleton key={i} height={20} className="li-footer m-b-11" />
                );
              })
          : categoryList?.data
              ?.sort((a, b) => a?.position - b?.position)
              .filter((item) => !item?.category_id)
              .map((category) => (
                <li className="li-footer" key={category.id}>
                  <a
                    href={`/tous-les-produits?category=${category.id}`}
                    className="link-footer"
                  >
                    {category?.name}
                  </a>
                </li>
              ))}
      </ul>
    </div>
  );
}

export default ListCategoriesFooter;
