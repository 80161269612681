import React, { Component } from "react";
import "./filterAllProduct.css";
import FilterByCategory from "../filterByCategory/filterByCategory";
import FilterByRate from "../filterByRate/filterByRate";
import FilterByPrice from "../filterByPrice/filterByPrice";
import FilterBySeller from "../filterBySeller/filterBySeller";

export default class filterAllProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="card-filter">
        <p className="titre-filter-component fw-500">Filtrer par</p>
        {/* <p className="nom-categorie-filter">Mode</p> */}

        {/* <div className="content-type-filter">
                <FilterByCategory categoryId={this.props.categoryId} />
            </div> */}

        {this.props.categoryId === undefined ? (
          <div className="content-type-filter">
            <FilterByCategory categoryId={this.props.categoryId} />
          </div>
        ) : (
          <div className="content-type-filter">
            <ul className="ul-categories">
              <FilterByCategory categoryId={this.props.categoryId} />
            </ul>
          </div>
        )}

        {this.props.shopId === undefined ? (
          <div className="content-type-filter">
            <FilterBySeller shopId={this.props.shopId} />
          </div>
        ) : (
          <div className="content-type-filter">
            <ul className="ul-categories">
              <FilterBySeller shopId={this.props.shopId} />
            </ul>
          </div>
        )}
        <div className="content-type-filter">
          <FilterByRate />
        </div>

        <div className="content-type-filter">
          <FilterByPrice />
        </div>
      </div>
    );
  }
}
