import React, {Component} from 'react';
import './tableTransactions.css';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import Moment from 'react-moment'

export default class tableTransactions extends Component {
  constructor(props) {
      super(props);
      this.state = {};
  }
  
  clientName(cell, row){
    return row.user.first_name + " " + row.user.last_name
  }
  dateOrder(cell, row){
    return <Moment format="DD/MM/YYYY">{row.order.order_date}</Moment>
  }
  orderNumber(cell, row){
    return row.order.order_code
  }
  orderPayement(cell, row){
    return <span className="text-cap">{row.order.payment_mode.name}</span>
  }
  orderTotal(cell, row){
    return <span>{Intl.NumberFormat('fr-FR').format(parseInt(row.order.order_total)+parseInt(row.order.shipping))} FCFA</span>
  }
  
  render() {
    const options = {
      noDataText: 'Aucune transaction enregistré',
    }

    return <div className="component-table-transactions">
      <BootstrapTable className="head-table-custom" options={options} 
          data={this.props.transactions} search={ false } multiColumnSearch={ true } striped 
          hover multiColumnSort={2} pagination version='4'>
        <TableHeaderColumn 
          isKey dataField='user' dataFormat={this.clientName}
          width="230"
          tdStyle={{ fontWeight: 400, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', borderTop: 'none', borderBottom: 'none', padding: '1rem',  whiteSpace: 'normal', lineHeight: 1, textAlign: 'center' }} 
          thStyle={{ textAlign: 'center' }}
          className="th-table-custom"
        >Nom du client</TableHeaderColumn>
        <TableHeaderColumn 
          className="th-table-custom"
          dataField="date" dataFormat={this.dateOrder}
          width="150"
          thStyle={{ textAlign: 'center' }}
          tdStyle={{ fontWeight: 400, whiteSpace: 'normal', fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', borderTop: 'none', borderBottom: 'none', padding: '1rem', textAlign: 'center' }} 
        >Date</TableHeaderColumn>
        <TableHeaderColumn 
          dataField='order' className="th-table-custom" dataFormat={this.orderNumber}
          width="200"
          thStyle={{ textAlign: 'center' }}
          tdStyle={{ fontWeight: 400, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', borderTop: 'none', borderBottom: 'none', padding: '1rem', textAlign: 'center' }} 
          >N° de la commande</TableHeaderColumn>
        <TableHeaderColumn 
          dataField='payement' className="th-table-custom" dataFormat={this.orderPayement}
          width="200"
          thStyle={{ textAlign: 'center' }}
          tdStyle={{ fontWeight: 400, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', borderTop: 'none', borderBottom: 'none', padding: '1rem', textAlign: 'center' }} 
          >Mode de paiement</TableHeaderColumn>
        <TableHeaderColumn 
          dataField='montant' className="th-table-custom" dataFormat={this.orderTotal}
          width="200"
          thStyle={{ textAlign: 'center' }} 
          tdStyle={{ fontWeight: 400, whiteSpace: 'normal', fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', borderTop: 'none', borderBottom: 'none', padding: '1rem', textAlign: 'center' }} 
          >Montant</TableHeaderColumn>
      </BootstrapTable>
    </div>;
  }
}
