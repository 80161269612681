import React, { useEffect, useMemo, useState } from "react";
import "./allProductPage.css";
import Header from "../header/index";
import Footer from "../footer/index";
import { useLocation } from "react-router-dom";
import SortBy from "../sortBy";
import { useGetProductListQuery } from "../../utils/api/product.api";
import AllProductItemPage from "./AllProductItemPage";
import FilterItems from "./FilterItems";

function AllProductPage() {
  const { search } = useLocation();
  const [page, setPage] = useState(1);
  
  const [items, setItems] = React.useState([]);
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const [query, setQuery] = React.useState({
    category: searchParams?.getAll("category")
      ? searchParams?.getAll("category")
      : "",
    price_min: searchParams?.get("price_min") || "",
    price_max: searchParams?.get("price_max") || "",
    boutique: searchParams?.getAll("boutique")
      ? searchParams?.getAll("boutique")
      : "",
    note: "",
  });
  const {
    data = { results: [], count: 0 },
    isLoading,
    refetch,
    isFetching,
  } = useGetProductListQuery({ ...query, page, limit: 12 });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (!isLoading) refetch();
  }, []);

  React.useEffect(() => {
    if (data?.results) {
      setItems((prevState) => {
        if (page === 1) {
          return data?.results;
        }
        return [...prevState, ...data?.results];
      });
    }
  }, [data]);
  return (
    <div>
      <Header />
      <div className="body-theme p-t-15 p-b-45 fixed-after-header">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-3">
              <FilterItems
                searchParams={searchParams}
                query={query}
                setQuery={setQuery}
                setPage={setPage}
              />
            </div>
            <div className="col-xl-9 col-lg-9 pl-lg-0 dis-flex">
              <div className="content-item-card w-full">
                <div className="content-item-card-head">
                  <div className="row">
                    <div className="col-xl-4 col-lg-5 col-md-6 col-10">
                      <p className="fs-14 fw-600 text-black-50">
                        Tous les produits{" "}
                        <span className="fw-300 m-l-10">
                          ( {data?.count} résultats)
                        </span>
                      </p>
                    </div>
                    <div className="col-xl-3 col-lg-4 pl-lg-4 col-md-4 col-2 ml-auto">
                      <SortBy />
                    </div>
                  </div>
                </div>
                <div className="content-item-card-body">
                  {/* <ProductItem /> */}
                  <AllProductItemPage
                    items={items}
                    data={data}
                    refetch={refetch}
                    isLoading={isLoading}
                    isFetching={isFetching}
                    setPage={setPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AllProductPage;
