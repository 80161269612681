import React, {Component} from 'react';
import './sortBy.css';
import Skeleton from 'react-loading-skeleton';
// import FeatherIcon from 'feather-icons-react';
// import { NavLink } from 'react-router-dom';

export default class sortBy extends Component {
  constructor(props) {
      super(props);
      this.state = {
        loading: false,
      };
  }
  componentDidMount(){
    this.setState({ loading: true });
    const timer = setTimeout(() => {
        this.setState({ loading: false });
    }, 1000);

    return () => clearTimeout(timer);
  }

  render() {
    if(this.state.loading){
      return <Skeleton height={10} className="fs-10 fw-600 text-black-50 no-view-mobile lh-2" />
    }
    else{
      return <div className="component-sort-by">
        {/* <div className="dis-flex justify-content-end itm-center m-t-3">
          <p className="fs-10 fw-600 text-black-50 no-viewIn-mobile">
            Trier par : 
          </p>
          <div className="dropdown dropdown-sort">
            <button className="dropbtn dropbtn-sort no-viewIn-mobile">
              Les plus demandés
              <FeatherIcon className="icon-chevron-dropdown" icon="chevron-down" />
            </button>
            <button className="dropbtn dropbtn-sort no-viewIn-tablette">
              <FeatherIcon className="icon-filter-mobile-view" icon="sliders" />
            </button>
            <div className="dropdown-content dropdown-content-sort">
              <NavLink to="#">Link 1</NavLink>
              <NavLink to="#">Link 2</NavLink>
              <NavLink to="#">Link 3</NavLink>
            </div>
          </div>
        </div> */}
      </div>;
    }
    
  }
}
