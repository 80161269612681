import { formatMoney } from "../../../utils/utils";
import React, { useEffect, useState } from "react";
import HeaderDashboard from "../headerDashboard/index";
import NavVerticalDashboard from "../navVerticalDashboard/index";
import * as IconlyPack from "react-iconly";
import { NavLink, useLocation } from "react-router-dom";
import baseUrl from "../../../http/backend-base-url";
import { useSelector } from "react-redux";

function CommandeDetails() {
  const [order, setOrder] = useState({});
  let user = useSelector((state) => state?.user?.user);
  const location = useLocation();
  useEffect(() => {
    if (location?.state?.order?.id) {
      // let items = location.state.order;
      setOrder(location.state.order);

      console.log("first", location.state.order);
    }
    return () => {
      setOrder({});
    };
  }, [location, user]);
  return (
    <div className="p-b-200">
      <HeaderDashboard />
      <div className="fixed-after-header-dashboard container-fluid">
        <div className="row">
          <div className="col-xl-2 col-lg-3 no-view-tablette">
            <NavVerticalDashboard />
          </div>
          <div className="col-xl-10 col-lg-9 px-xl-3 py-4 pr-lg-3 pl-lg-1">
            <div className="card-dashboard-content-table">
              <div className="">
                <p className="title-filter-dashboard mb-5">
                  <NavLink
                    to={"/commandes"}
                    className="action-btn btn edit-btn"
                    title="Retour"
                  >
                    <IconlyPack.ArrowLeft
                      set="bold"
                      primaryColor="#000"
                      className="action-btn-icon action-edit-icon"
                      // onClick={() => onDesarchive(row?.id)}
                    />
                  </NavLink>
                  Commande Details
                </p>
              </div>
              <div className="content-view-dashboard">
                {/* Commande */}
                <div>
                  <div className="">
                    {/* <p className="text-commande-item m-b-30 fw-600 fs-14">
                        Détails de la livraison
                      </p> */}
                    {order?.cartitems?.map((item) => {
                      return (
                        <div className="row m-b-20" key={item.id}>
                          <div className="col-xl-7 col-lg-7 col-md-12">
                            <div className="row">
                              <div className="col-sm-3 m-b-20">
                                <img loading="lazy"
                                  className="img-thumbs-commande-item"
                                  src={baseUrl + item?.item?.image[0]?.image}
                                  alt=""
                                />
                              </div>
                              <div className="col-sm-9 m-b-20">
                                <p className="text-commande-item m-b-10 fw-300 fs-14">
                                  {item?.item?.name}
                                </p>
                                <p
                                  className="text-commande-item m-b-30 fw-300 fs-12"
                                  dangerouslySetInnerHTML={{
                                    __html: item?.item?.description,
                                  }}
                                />
                                {item?.pointure || item?.taille ? (
                                  <p className="text-commande-item m-b-30 fw-400 fs-12">
                                    <span className="h-theme ">
                                      {item?.pointure ? "Pointure:" : "Taille:"}
                                    </span>{" "}
                                    {item?.pointure || item?.taille}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 m-b-20">
                            <div>
                              <p className="h-theme fw-600 text-up">Quantité</p>
                              <p
                                className="text-dark fs-14 ff-open fw-400 text-center"
                                id="inputRegion"
                              >
                                {item?.quantity}
                              </p>
                            </div>
                          </div>
                          <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 m-b-20">
                            <div>
                              <p className="h-theme fw-600 text-up">
                                Prix unitaire
                              </p>
                              <p className="text-dark fs-14 ff-open fw-400 text-center">
                                {formatMoney(
                                  item?.price > 0
                                    ? item?.price
                                    : item?.item?.price,
                                  order?.currency
                                )}
                                {/* {format(item?.item?.price)} */}
                              </p>
                            </div>
                          </div>
                          <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 m-b-20">
                            {/* <div>
                              <p className="h-theme fw-600 text-up">Action</p>
                              <button className="btn-icon-action-commande">
                                <FeatherIcon
                                  className="icon-commande-favoris"
                                  icon="heart"
                                />
                              </button>
                              <button className="btn-icon-action-commande">
                                <FeatherIcon
                                  className="icon-commande-delete"
                                  icon="trash-2"
                                />
                              </button>
                            </div> */}
                          </div>
                        </div>
                      );
                    })}

                    <div className="row">
                      <div
                        className="col-lg-8 dis-flex align-items-start "
                        style={{ flexDirection: "column" }}
                      >
                        <div className="mb-3">
                          <p className="resume-item fw-300 fs-14">
                            Prénom et Nom client
                          </p>
                          <p className="resume-item fw-600 fs-14 ">
                            {order?.user?.first_name +
                              " " +
                              order?.user?.last_name}
                          </p>
                        </div>
                        <div className="mb-3">
                          <p className="resume-item fw-300 fs-14">
                            Email client
                          </p>
                          <p className="resume-item fw-600 fs-14 ">
                            {order?.user?.email}
                          </p>
                        </div>
                        <div className="mb-3">
                          <p className="resume-item fw-300 fs-14">
                            Téléphone client
                          </p>
                          <p className="resume-item fw-600 fs-14 ">
                            {order?.user?.phone}
                          </p>
                        </div>
                        <div className="mb-3">
                          <p className="resume-item fw-300 fs-14">
                            Adresse de livraison
                          </p>
                          <p className="resume-item fw-600 fs-14 ">
                            {order?.shipping_address}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <p className="resume-item fw-400 fs-14 m-b-20">
                          Prix ​​sous-total :{" "}
                          <span className="value-resume-item fs-14 fw-700">
                            {formatMoney(order?.cart?.total, order?.currency)}
                          </span>
                        </p>
                        <p className="resume-item fw-400 fs-14 m-b-20">
                          Frais de livraison (+) :{" "}
                          <span className="value-resume-item fs-14 fw-700">
                            {order?.shipping > 0
                              ? formatMoney(order?.shipping, order?.currency)
                              : "Livraison offerte"}
                            {/* {formatMoney(order?.shipping, order?.currency)} */}
                          </span>
                        </p>
                        <p className="resume-item fw-400 fs-14 m-b-20">
                          Remise (-) :{" "}
                          <span className="value-resume-item fs-14 fw-700">
                            {formatMoney(0, order?.currency)}
                          </span>
                        </p>
                        <p className="resume-item fw-700">
                          Total à payer :{" "}
                          <span className="value-resume-item fw-700 fs-17 lh-1-4">
                            {formatMoney(
                              parseFloat(order?.cart?.total),
                              order?.currency
                            )}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommandeDetails;
