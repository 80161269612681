import React, { Component, useEffect, useState } from "react";
import {
  registerLocale,
  // setDefaultLocale
} from "react-datepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import "./dashboardSeller.css";
import HeaderDashboard from "../headerDashboard/index";
import NavVerticalDashboard from "../navVerticalDashboard/index";
import IconActivity from "../../../img/icons/Activity.png";
import IconBag from "../../../img/icons/Bag.png";
import IconCart from "../../../img/icons/Buy.png";
import IconCalendar from "../../../img/icons/Calendar.png";
import IconAdd from "../../../img/icons/Plus.png";
import ChartCommandes from "../chartCommandes/index";
import ChartVentes from "../chartVentes/index";
import api from "../../../http/global-vars";
import axios from "axios";
import CountUp from "react-countup";
import moment from "moment";
import _ from "lodash";
import { NavLink } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { useGetDashboardSellerQuery } from "../../../utils/api/dashboard.api";

registerLocale("fr", fr);


function DashboardSeller() {
  const [date, setDate] = useState(new Date());
  const { user } = useSelector((s) => s?.user);
  const {
    data = {
      orderGraph: [],
      venteGraph: [],
      boutique_count: 0,
      commandes_by_week_count: 0,
      produits_count: 0,
    },
    isLoading,
    refetch,
  } = useGetDashboardSellerQuery({ date: moment(date).format("DD-MM-YYYY"),id: user?.provider?.id, });

  useEffect(() => {
    if (!isLoading) {
      refetch();
    }
  }, []);
  return (
    <div className="p-b-200">
      <HeaderDashboard />
      <div className="fixed-after-header-dashboard container-fluid">
        <div className="row">
          <div className="col-xl-2 col-lg-3 no-view-tablette">
            <NavVerticalDashboard />
          </div>
          <div className="col-xl-10 col-lg-9 px-xl-3 py-4 pr-lg-3 pl-lg-1">
            <div className="row px-md-2">
              <div className="col-md-4 mb-3 dis-flex px-md-2">
                <div className="item-stat-dashboad-view">
                  <img
                    loading="lazy"
                    className="icon-item-stat-dashboad-view"
                    src={IconActivity}
                    alt=""
                  />
                  <div className="content-item-stat-dashboad-view">
                    <p className="titre-item-stat-dashboad-view">Revenus</p>
                    <p className="chiffre-item-stat-dashboad-view">
                      <span className="currency">FCFA</span>{" "}
                      {/* <CountUp end={revenus} start={0} duration={1.5} /> */}
                      {data?.revenus}
                    </p>
                  </div>
                  <div className="footer-item-stat-dashboad-view">
                    <p className="text-footer-item-stat-dashboad-view">
                      <img
                        loading="lazy"
                        className="icon-footer-item-stat-dashboad-view"
                        src={IconCalendar}
                        alt=""
                      />
                      Cette semaine
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-3 dis-flex px-md-2">
                <div className="item-stat-dashboad-view">
                  <img
                    loading="lazy"
                    className="icon-item-stat-dashboad-view"
                    src={IconBag}
                    alt=""
                  />
                  <div className="content-item-stat-dashboad-view">
                    <p className="titre-item-stat-dashboad-view">Commandes</p>
                    <CountUp
                      className="chiffre-item-stat-dashboad-view"
                      end={data?.commandes_by_week_count}
                      duration={0.5}
                    />
                  </div>
                  <div className="footer-item-stat-dashboad-view">
                    <p className="text-footer-item-stat-dashboad-view">
                      <img
                        loading="lazy"
                        className="icon-footer-item-stat-dashboad-view"
                        src={IconCalendar}
                        alt=""
                      />
                      Cette semaine
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mb-3 dis-flex px-md-2">
                <div className="item-stat-dashboad-view">
                  <img
                    loading="lazy"
                    className="icon-item-stat-dashboad-view"
                    src={IconCart}
                    alt=""
                  />
                  <div className="content-item-stat-dashboad-view">
                    <p className="titre-item-stat-dashboad-view">Produits</p>
                    <CountUp
                      className="chiffre-item-stat-dashboad-view"
                      end={data?.produits_count}
                      duration={1.5}
                    />
                  </div>
                  <div className="footer-item-stat-dashboad-view">
                    <NavLink
                      to="/ajouter-un-produit"
                      className="text-footer-item-stat-dashboad-view link"
                    >
                      <img
                        loading="lazy"
                        className="icon-footer-item-stat-dashboad-view"
                        src={IconAdd}
                        alt=""
                      />
                      Ajouter un produit
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>

            {/* Chart Commande */}
            <div className="card-dashboard mb-3">
              <div className="row itm-center mb-5">
                <div className="col-xl-8 col-lg-7 col-md-7 mb-3">
                  <p className="title-filter-dashboard">
                    Nombre Total de Commandes
                  </p>
                </div>
                <div className="col-xl-4 col-lg-5 col-md-5 col-sm-7 mb-3 pl-xl-5 pl-lg-4 pl-md-4 pr-md-3 pr-sm-5 d-md-flex justify-content-md-end">
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    className="form-control select-filter-dashboard"
                    selected={date}
                    onChange={(date) => setDate(date)}
                    locale="fr"
                  />
                </div>
              </div>
              {isLoading ? (
                <div className="chart-dashboard-marketplace">
                  <Skeleton height={365} />
                </div>
              ) : (
                <ChartCommandes
                data={data?.orderGraph}
                />
              )}
            </div>

            {/* Chart vente */}
            <div className="card-dashboard mb-3">
              <div className="row itm-center mb-5">
                <div className="col-xl-8 col-lg-7 col-md-7 mb-3">
                  <p className="title-filter-dashboard">Ventes</p>
                </div>
                <div className="col-xl-4 col-lg-5 col-md-5 col-sm-7 mb-3 pl-xl-5 pl-lg-4 pl-md-4 pr-md-3 pr-sm-5 d-md-flex justify-content-md-end">
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    className="form-control select-filter-dashboard"
                    selected={date}
                    onChange={(date) => setDate(date)}
                    locale="fr"
                  />
                </div>
              </div>
              {isLoading ? (
                <div className="chart-dashboard-marketplace">
                  <Skeleton height={365} />
                </div>
              ) : (
                <ChartVentes
                  data={data?.venteGraph}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardSeller;
