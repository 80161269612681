import React, { Component } from "react";
// import './tableArticles.css';
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import FeatherIcon from "feather-icons-react";
import api from "../../../http/global-vars";
import axios from "axios";
import { Alert } from "rsuite";
import Moment from "react-moment";
import { toast } from "react-toastify";
import AddAdminModal from "./AddAdminModal";
import EditAdminModal from "./EditAdminModal";
import DeleteAdminModal from "./DeleteAdminModal";

export default class AdminsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      admins: [],
      admin: {},
      checked: true,
      productActivToggle: false,
      api: api,
      fields: {},
      onArchiveProduct: true,
      loading: false,
      archivageProductInProgress: false,
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      show: false,
      archivageInProgress: false,
    };
  }

  componentDidMount() {
    this.getAdmins();
  }

  getAdmins = () => {
    axios.get(`${api}adminuser/`, this.state.requestConfig).then((response) => {
      console.log("response", response);
      this.setState({
        admins: response?.data?.results.sort((a, b) => b?.id - a?.id),
      });
    });
  };

  handleClose = (e) => {
    e.preventDefault();
    this.setState({ show: false });
    this.setState({
      admin: {},
      archivageInProgress: false,
    });
  };
  handleOpen = (row) => {
    this.setState({
      admin: row,
    });
    this.setState({ show: true });
  };

  onDeleteAdmin = (e) => {
    e.preventDefault();
    this.setState({ archivageInProgress: true });
    let { admin } = this.state;

    axios
      .delete(`${api}user/${admin?.id}/`, this.state.requestConfig)
      .then((response) => {
        console.log(response);
        // this.setState({ archivageInProgress: false });
        if (response) {
          // Alert.success("Le produit à été supprimé.");
          setTimeout(() => {
            // this.props.onGetProductShop();
          }, 3000);
          this.setState({
            admin: {},
            archivageInProgress: false,
            show: false,
          });
          this.getAdmins();
          toast.success("Administrateur supprimé avec succés.");
        }
      })
      .catch((error) => {
        if (error) {
          this.setState({ archivageInProgress: false });
        }
      });
  };

  actionFormatter = (cell, row) => {
    return (
      <div className="dis-flex justify-content-center">
        <button
          // onClick={() =>
          //   this.setState({
          //     category: row,
          //   })
          // }
          data-toggle="modal"
          data-target={`.edit-admin-modal${row?.id}`}
          className="btn-action-table-grey clear"
        >
          <FeatherIcon icon="edit" className="icon-action-table-grey" />
        </button>
        <button
          onClick={() => this.handleOpen(row)}
          className="btn-action-table-grey clear"
        >
          <FeatherIcon icon="trash-2" className="icon-action-table-grey" />
        </button>

        {/* MOdal Edit */}
        <div
          className={`modal fade easypm-modal edit-admin-modal${row?.id}`}
          id="AddAdminModal"
          tabIndex="-1"
          data-backdrop="static"
          aria-labelledby="AddAdminModalLabel"
          aria-hidden="true"
        >
          <EditAdminModal admin={row} getAdmins={this.getAdmins} />
        </div>
      </div>
    );
  };

  render() {
    const options = {
      noDataText: "Aucune donnée disponible",
    };
    return (
      <div>
        <div className="table-container">
          <BootstrapTable
            className="head-table-custom"
            options={options}
            data={this.state.admins}
            search={true}
            multiColumnSearch={true}
            striped
            hover
            multiColumnSort={2}
            pagination
            version="4"
          >
            <TableHeaderColumn
              isKey
              dataField="id"
              hidden={true}
              tdStyle={{
                fontWeight: 400,
                fontSize: 14,
                color: "rgba(0, 0, 0, 0.7)",
                borderTop: "none",
                borderBottom: "none",
                padding: "1rem",
                whiteSpace: "normal",
                lineHeight: 1,
              }}
              thStyle={{ textAlign: "center" }}
              className="th-table-custom"
            >
              N°
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="first_name"
              //   width="230"
              tdStyle={{
                fontWeight: 400,
                fontSize: 14,
                color: "rgba(0, 0, 0, 0.7)",
                borderTop: "none",
                borderBottom: "none",
                padding: "1rem",
                whiteSpace: "normal",
                lineHeight: 1,
              }}
              dataAlign="center"
              // thStyle={{ textAlign: "center" }}
              className="th-table-custom"
            >
              Prénom
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="last_name"
              //   width="230"
              tdStyle={{
                fontWeight: 400,
                fontSize: 14,
                color: "rgba(0, 0, 0, 0.7)",
                borderTop: "none",
                borderBottom: "none",
                padding: "1rem",
                whiteSpace: "normal",
                lineHeight: 1,
              }}
              dataAlign="center"
              // thStyle={{ textAlign: "center" }}
              className="th-table-custom"
            >
              Nom
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="email"
              className="th-table-custom"
              //   width="150"
              // thStyle={{ textAlign: "center" }}
              dataAlign="center"
              tdStyle={{
                fontWeight: 400,
                fontSize: 14,
                color: "rgba(0, 0, 0, 0.7)",
                borderTop: "none",
                borderBottom: "none",
                padding: "1rem",
                // textAlign: "center",
              }}
            >
              Email
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="phone"
              className="th-table-custom"
              //   width="150"
              // thStyle={{ textAlign: "center" }}
              dataAlign="center"
              tdStyle={{
                fontWeight: 400,
                fontSize: 14,
                color: "rgba(0, 0, 0, 0.7)",
                borderTop: "none",
                borderBottom: "none",
                padding: "1rem",
                // textAlign: "center",
              }}
            >
              Téléphone
            </TableHeaderColumn>
            <TableHeaderColumn
              className="th-table-custom"
              dataFormat={this.actionFormatter}
              dataField="id"
              width="150"
              dataAlign="center"
              // thStyle={{ textAlign: "center" }}
              tdStyle={{
                fontWeight: 400,
                fontSize: 14,
                color: "rgba(0, 0, 0, 0.7)",
                borderTop: "none",
                borderBottom: "none",
                padding: "1rem",
                // textAlign: "center",
              }}
            >
              Actions
            </TableHeaderColumn>
          </BootstrapTable>
        </div>
        {/* MOdal Add */}
        <div
          className="modal fade easypm-modal add-admin-modal"
          id="AddAdminModal"
          tabIndex="-1"
          data-backdrop="static"
          aria-labelledby="AddAdminModalLabel"
          aria-hidden="true"
        >
          <AddAdminModal getAdmins={this.getAdmins} />
        </div>
        <DeleteAdminModal
          handleClose={this.handleClose}
          show={this.state.show}
          onDeleteAdmin={this.onDeleteAdmin}
          archivageInProgress={this.state.archivageInProgress}
        />
      </div>
    );
  }
}
