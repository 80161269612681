import React, {Component} from 'react';
import './bannerShop.css';
import baseUrl from '../../http/backend-base-url';
import Skeleton from 'react-loading-skeleton';
import BannerShop from '../../img/bannerBoutique.png';

export default class bannerShop extends Component {
  constructor(props) {
      super(props);
      this.state = {
        baseUrl: baseUrl,
      };
  }
  render() {
    const baseUrl = this.state.baseUrl;
    const shopDetails = this.props.shopDetails
    const loading = this.props.loading

    if(loading){
      return <Skeleton className="w-full m-b-12 banner-shop-details lh-2" />
    }
    else{
      return this.props.shopDetails.banner === "/uploads/bannier.png" ||
        this.props.shopDetails.banner === "/mediafiles/bannier.png" ? (
        <img loading="lazy"
          className="w-full m-b-12 banner-shop-details"
          src={BannerShop}
          alt={shopDetails.shop_name}
        />
      ) : (
        <div
          className="m-b-12 banner-shop-details"
          style={{ background: `url(${baseUrl + shopDetails.banner})` }}
        ></div>
      );
    }
    
  }
}