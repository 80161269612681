import React, { Component } from "react";
import { Message, CheckPicker } from "rsuite";

export const optionsTaille = [
  {
    value: "XS",
    label: "XS",
  },
  {
    value: "S",
    label: "S",
  },
  {
    value: "M",
    label: "M",
  },
  {
    value: "M-L",
    label: "M-L",
  },
  {
    value: "L",
    label: "L",
  },
  {
    value: "L-XL",
    label: "L-XL",
  },
  {
    value: "XL",
    label: "XL",
  },
  {
    value: "XXL",
    label: "XXL",
  },
];

export const optionSize = [
  {
    value: "26",
    label: "26",
  },
  {
    value: "28",
    label: "28",
  },
  {
    value: "30",
    label: "30",
  },
  {
    value: "32",
    label: "32",
  },
  {
    value: "34",
    label: "34",
  },
  {
    value: "36",
    label: "36",
  },
  {
    value: "38",
    label: "38",
  },
  {
    value: "40",
    label: "40",
  },
  {
    value: "42",
    label: "42",
  },
  {
    value: "44",
    label: "44",
  },
  {
    value: "46",
    label: "46",
  },
  {
    value: "48",
    label: "48",
  },
];


function InformationsProduct({register,errors,step,options,option,handleSelectCategory,taille,
  pointure,
  handleSelectPointure,
  handleSelectTaille}) {
  return (
    <div className="mt-3">
        <p className="consigne-title-steps mb-5">
          Ajouter les informations du produit
        </p>

        <div className="row mb-4">
          <div className="col-md-6 pr-lg-4">
            <CheckPicker
              // value={categoryList}
              onChange={handleSelectCategory}
              data={options}
              labelKey="label"
              valueKey="value"
              value={option}
              style={{ width: "100%" }}
              className="check-picker-custom-marketplace"
              placeholder="Choisir une catégorie"
            />
            {errors?.category && (
              <Message
                className="alert-rsuite-custom"
                type="error"
                description={errors?.category?.message}
              />
            )}
            {/* <div className="row">
              <div className="col-xl-6 col-lg-12 col-md-5 mb-4">
                
              </div>
            </div> */}
          </div>
          <div className="col-md-6 pl-lg-5"></div>
        </div>
        <div className="row">
          <div className="col-md-6 pr-lg-4">
            <div className="form-group mb-4">
              <input
                type="text"
                {...register("name")}
                className="form-control input-steps-inscription-vendeur"
                placeholder="Nom du produit"
                
              />
              {errors?.name && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={errors?.name?.message}
                />
              )}
            </div>
            <div className="form-group mb-4">
              <input
                type="text"
                {...register("brand")}
                
                className="form-control input-steps-inscription-vendeur"
                placeholder="Marque"
              />
              {errors?.brand && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={errors?.brand?.message}
                />
              )}
            </div>
            <div class="form-group mb-4">
              <select
                {...register("pointure")}
                id="pointure"
                className="form-control input-steps-inscription-vendeur"
              >
                <option value=""> Ce produit dispose-t-il des pointures ? </option>
                <option value={true}> Oui </option>
                <option value={false}>Non</option>
                {/* {options?.map((option) => (
                  <option value={option?.value}> {option?.label} </option>
                ))} */}
              </select>
              {errors?.pointure && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={errors?.pointure?.message}
                />
              )}
            </div>
            <div class="form-group mb-4">
              <CheckPicker
                // value={categoryList}
                onChange={handleSelectPointure}
                data={optionSize}
                labelKey="label"
                valueKey="value"
                value={pointure}
                style={{ width: "100%" }}
                className="check-picker-custom-marketplace"
                placeholder="Choisir les pointures"
              />

              {errors?.pointures && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={errors?.pointures?.message}
                />
              )}
            </div>
          </div>
          <div className="col-md-6 pl-lg-5">
            <div className="form-group mb-4">
              <input
                type="text"
                {...register("model")}
                className="form-control input-steps-inscription-vendeur"
                placeholder="Modèle"
              />
              {errors?.model && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={errors?.model?.message}
                />
              )}
            </div>
            <div className="form-group mb-4">
              <input
                type="color"
                {...register("main_color")}
                className="form-control input-steps-inscription-vendeur"
                placeholder="Couleur principale"
              />
              {errors?.main_color && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={errors?.main_color?.message}
                />
              )}
            </div>
            <div class="form-group mb-4">
              <select
                {...register("taille")}
                id="taille"
                className="form-control input-steps-inscription-vendeur"
              >
                <option value=""> Ce produit dispose-t-il de différentes tailles ? </option>
                <option value={true}> Oui </option>
                <option value={false}>Non</option>
                
              </select>
              {errors?.taille && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={errors?.taille?.message}
                />
              )}
            </div>
            <div class="form-group mb-4">
              <CheckPicker
                // value={categoryList}
                onChange={handleSelectTaille}
                data={optionsTaille}
                labelKey="label"
                valueKey="value"
                value={taille}
                style={{ width: "100%" }}
                className="check-picker-custom-marketplace"
                placeholder="Choisir les tailles"
              />

              {errors?.tailles && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={errors?.tailles?.message}
                />
              )}
            </div>
          </div>
        </div>
      </div>
  )
}

export default InformationsProduct