import React, { Component } from "react";
import "./updateProduct.css";
import HeaderDashboard from "../headerDashboard/index";
import NavVerticalDashboard from "../navVerticalDashboard/index";
import api from "../../../http/global-vars";
import axios from "axios";
import { Message, CheckPicker, Loader } from "rsuite";
import UpdateImagesProduct from "./updateImagesProduct";
import { ToastContainer, toast } from "react-toastify";
import FeatherIcon from "feather-icons-react";
import MicRecorder from "mic-recorder-to-mp3";
import baseUrl from "../../../http/backend-base-url";
import { withRouter } from "react-router-dom";
import {
  optionSize,
  optionsTaille,
} from "../addProductSteps/steps/informationsProduct";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

class updateProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: api,
      baseUrl: baseUrl,
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        "Content-Type": "multipart/form-data",
      },
      productID: this.props.match.params.id,
      productInfos: {},
      errors: {},
      categoryList: [],
      loading: false,
      is_empty_category: false,
      is_empty_product_name: false,
      is_empty_quantity: false,
      is_empty_price: false,
      is_empty_images: false,
      is_empty_pointure: false,
      is_empty_taille: false,
      is_empty_pointure_val: false,
      is_empty_taille_val: false,
      updateProductInProgress: false,
      isSuccessUpdateImage: false,
      updateImagesProductInProgress: false,
      isErrorUpdateImage: false,
      isRecording: false,
      blobURL: "",
      isBlocked: false,
      audioDisplay: false,
      onRecording: false,
      isDescAudioExist: false,
      newFileRecorder: null,
    };
    this.handleChangeUpdateProduct = this.handleChangeUpdateProduct.bind(this);
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.setState({ loading: true });
    this.onGetProductById();
    this.onGetCategories();

    this.setState({ audioDisplay: false });

    navigator.getUserMedia(
      { audio: true },
      () => {
        this.setState({ isBlocked: false });
      },
      () => {
        this.setState({ isBlocked: true });
      }
    );
  }

  start = () => {
    if (this.state.isBlocked) {
    } else {
      Mp3Recorder.start()
        .then(() => {
          this.setState({
            isRecording: true,
            onRecording: true,
            isDescAudioExist: false,
          });
        })
        .catch((e) => console.error(e));
    }
  };
  stop = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const file = new File(buffer, "product_description.mp3", {
          type: blob.type,
          lastModified: Date.now(),
        });

        const blobURL = URL.createObjectURL(blob);
        this.setState({
          blobURL,
          isRecording: false,
          audioDisplay: true,
          onRecording: false,
          newFileRecorder: file,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  handleChangeUpdateProduct = (e) => {
    e.persist();

    this.setState((prevState) => ({
      productInfos: {
        ...prevState.productInfos,
        [e.target.name]: e.target.value,
      },
    }));
  };

  handleChangeSelector = (value) => {
    let productInfos = this.state.productInfos;

    productInfos["category"] = value;

    this.setState({
      productInfos: productInfos,
    });
  };

  handleChangePointure = (value) => {
    let fields = this.state.productInfos;

    let data = [];
    if (value) {
      value.forEach((element) => {
        if (element !== null && element) {
          data.push(element);
        }
      });
    }
    console.log("value pointur", data);
    fields["pointures"] = data;
    this.setState({
      productInfos: fields,
    });
  };

  handleChangeTaille = (value) => {
    let fields = this.state.productInfos;

    let data = [];
    if (value) {
      value.forEach((element) => {
        if (element !== null && element) {
          data.push(element);
        }
      });
    }
    console.log("value taille", data);
    fields["tailles"] = data;
    this.setState({
      productInfos: fields,
    });
  };

  onGetProductById = () => {
    var api = this.state.api;
    var productID = this.state.productID;

    axios
      .get(`${api}product-detail/${productID}/`)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          let data = response.data;
          data["pointures"] = data?.pointures
            ? data?.pointures?.split(",")
            : [];
          data["tailles"] = data?.tailes ? data?.tailes?.split(",") : [];
          this.setState({
            productInfos: data,
            loading: false,
          });

          if (response.data.description_audio !== null) {
            this.setState({ isDescAudioExist: true });
          }
        }
      })
      .catch((error) => {
        if (error) {
          this.setState({ loading: false });
        }
      });
  };

  onGetCategories = () => {
    var api = this.state.api;
    axios
      .get(api + "category/")
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.setState({
            categoryList: response.data,
            loading: false,
          });
        }
      })
      .catch((error) => {
        if (error) {
          this.setState({ loading: false });
        }
      });
  };

  onValidateProductUpdate = () => {
    let fields = this.state.productInfos;
    let errors = this.state.errors;
    var isValidForm = true;

    if (!fields["category"]) {
      isValidForm = false;
      this.setState({
        is_empty_category: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_category: false,
        });
      }, 5000);
      errors["category"] = "Veuillez choisir une catégorie!";
    }
    if (!fields["name"]) {
      isValidForm = false;
      this.setState({
        is_empty_product_name: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_product_name: false,
        });
      }, 5000);
      errors["name"] = "Veuillez saisir le nom du produit!";
    }

    // if (!fields["pointure"]) {
    //   isValidForm = false;
    //   this.setState({
    //     is_empty_pointure: true,
    //   });
    //   setTimeout(() => {
    //     this.setState({
    //       is_empty_pointure: false,
    //     });
    //   }, 5000);
    //   errors["pointure"] = "Veuillez renseigner cet information !";
    // }

    // if (!fields["taille"]) {
    //   isValidForm = false;
    //   this.setState({
    //     is_empty_taille: true,
    //   });
    //   setTimeout(() => {
    //     this.setState({
    //       is_empty_taille: false,
    //     });
    //   }, 5000);
    //   errors["taille"] = "Veuillez renseigner cet information !";
    // }

    if (
      fields["pointure"] &&
      (fields["pointure"] === "true" || fields["pointure"] === true) &&
      !fields["pointures"]
    ) {
      isValidForm = false;
      this.setState({
        is_empty_pointure_val: "true",
      });
      setTimeout(() => {
        this.setState({
          is_empty_pointure_val: false,
        });
      }, 5000);
      errors["pointures"] = "Veuillez renseigner les pointures disponibles !";
    }

    if (
      fields["taille"] &&
      (fields["taille"] === true || fields["taille"] === "true") &&
      !fields["tailles"]
    ) {
      isValidForm = false;
      this.setState({
        is_empty_taille_val: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_taille_val: false,
        });
      }, 5000);
      errors["tailles"] = "Veuillez renseigner les tailles disponibles !";
    }
    if (!fields["price"]) {
      isValidForm = false;
      this.setState({
        is_empty_price: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_price: false,
        });
      }, 5000);
      errors["price"] = "Veuillez renseigner le prix du produit!";
    }
    if (!fields["quantity"]) {
      if (fields["quantity"] === 0) {
        isValidForm = true;
      } else {
        isValidForm = false;
        this.setState({
          is_empty_quantity: true,
        });
        setTimeout(() => {
          this.setState({
            is_empty_quantity: false,
          });
        }, 5000);
        errors["quantity"] = "Veuillez renseigner la quantité disponible!";
      }
    }
    console.log(errors, fields);
    this.setState({
      errors,
    });

    return isValidForm;
  };

  onSubmitProductUpdate = async(e) => {
    e.preventDefault();

    if (this.onValidateProductUpdate()) {
      console.log("data", this.state.productInfos);
      this.setState({ updateProductInProgress: true });

      var api = this.state.api;
      var config = this.state.requestConfig;
      var data = new FormData();
      var productID = this.state.productID;
      console.log(
        "img",
        this.child.state.files?.filter((el) => !el?.id)
      );
      if (this.child.state.files?.filter((el) => !el?.id)?.length) {
        this.child.state.files
          .filter((el) => !el?.id)
          .forEach((item) => {
            data.append("image", item);
          });
      }

      data.append("name", this.state.productInfos.name);
      data.append("brand", this.state.productInfos.brand);
      data.append("model", this.state.productInfos.model);
      data.append("main_color", this.state.productInfos.main_color);
      data.append("description", this.state.productInfos.description);
      if (this.state.newFileRecorder !== null) {
        data.append("description_audio", this.state.newFileRecorder);
      }
      data.append("dimension", this.state.productInfos.dimension);
      data.append("weight", this.state.productInfos.weight);
      data.append("tax", this.state.productInfos.tax);
      data.append("quantity", this.state.productInfos.quantity);
      data.append("price", this.state.productInfos.price);

      for (
        var index = 0;
        index < this.state.productInfos.category.length;
        index++
      ) {
        var cat = parseInt(this.state.productInfos.category[index]);
        console.log("object12", cat);

        data.append("category", Number(cat));
      }
      data.append("dd[]", data.getAll("category").map(Number));

      await axios
        .put(`${api}product/${productID}/`, data, config)
        .then((response) => {
          console.log("response", response);
          if (response.status === 200 || response.status === 201) {
            this.setState({
              loading: false,
              // productInfos: response.data,
              updateProductInProgress: false,
            });
            setTimeout(async () => {
              await this.onGetProductById();
              await this.props.history.push("/articles");
            }, 100);
          } else {
            toast.error(
              "Une erreur est survenue lors de l'enregistrement du produit! Veuillez réessayer."
            );
            this.setState({
              isErrorUpdateImage: true,
              updateProductInProgress: false,
            });
            setTimeout(() => {
              this.setState({
                isErrorUpdateImage: false,
                updateImagesProductInProgress: false,
              });
            }, 5000);
          }
        })
        .catch((error) => {
          this.setState({ updateProductInProgress: false });
          if (error?.response?.message) {
            this.setState({ loading: false });
            toast.error(error?.response?.message);
            this.setState({
              isErrorUpdateImage: true,
            });
            setTimeout(() => {
              this.setState({
                isErrorUpdateImage: false,
              });
            }, 5000);
          } else {
            this.setState({ loading: false });
            toast.error(
              "Une erreur est survenue lors de l'enregistrement du produit! Veuillez réessayer."
            );
            this.setState({
              isErrorUpdateImage: true,
            });
            setTimeout(() => {
              this.setState({
                isErrorUpdateImage: false,
              });
            }, 5000);
          }
        });
    }
  };

  onUpdateProductImages = async (e) => {
    e.preventDefault();

    let errors = this.state.errors;
    var api = this.state.api;
    var config = this.state.requestConfig;
    var productID = this.state.productInfos.id;
    const url = api + "picture/";

    if (this.child.state.files?.length) {
      this.setState({
        updateImagesProductInProgress: true,
      });
      var data = new FormData();
      for (let i = 0; i < this.child.state.files?.length; i++) {
        const element = this.child.state.files[i];
        data.append("product_id", productID);
        data.append("image", element);
        await axios
          .post(url, data, config)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              this.setState({
                isSuccessUpdateImage: true,
              });
              setTimeout(() => {
                this.setState({
                  isSuccessUpdateImage: false,
                  updateImagesProductInProgress: false,
                });
              }, 5000);
            } else {
              toast.error(
                "Une erreur est survenue lors de l'enregistrement du produit! Veuillez réessayer."
              );
              this.setState({
                isErrorUpdateImage: true,
              });
              setTimeout(() => {
                this.setState({
                  isErrorUpdateImage: false,
                  updateImagesProductInProgress: false,
                });
              }, 5000);
            }
          })
          .catch((error) => {
            this.setState({
              isErrorUpdateImage: true,
            });
            setTimeout(() => {
              this.setState({
                isErrorUpdateImage: false,
                updateImagesProductInProgress: false,
              });
            }, 5000);
          });
      }
    } else {
      this.setState({
        is_empty_images: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_images: false,
        });
      }, 5000);
      errors["image"] = "Veuillez télécharger les images de votre produit!";
    }
    this.setState({
      errors,
    });
  };

  render() {
    var product = this.state.productInfos;
    var baseUrl = this.state.baseUrl;

    return (
      <div className="component-update-product">
        <HeaderDashboard />
        <div className="fixed-after-header-dashboard container-fluid">
          <div className="row">
            <div className="col-xl-2 col-lg-3 no-view-tablette">
              <NavVerticalDashboard />
            </div>
            <div className="col-xl-10 col-lg-9 px-xl-3 py-4 pr-lg-3 pl-lg-1">
              <div className="card-dashboard">
                <h3
                  className="h-theme fw-600 ff-raleway"
                  style={{ height: 50 }}
                >
                  {product?.name}
                </h3>
                <form onSubmit={(e) => this.onSubmitProductUpdate(e)}>
                  <div className="py-5">
                    <div className="row">
                      <div className="col-md-6 pr-lg-4">
                        <p className="consigne-title-steps mb-5">
                          Mettre à jour les informations du produit
                        </p>
                        <div className="row mb-4">
                          {/* <div className="col-xl-6 col-lg-12 col-md-5 mb-4 pos-relative">
                            
                          </div> */}
                          <span className="label-steps-inscription-vendeur fw-500">
                            Catégorie
                          </span>
                          <CheckPicker
                            value={product?.category}
                            onChange={this.handleChangeSelector}
                            data={this.state.categoryList}
                            labelKey="name"
                            valueKey="id"
                            name="category"
                            style={{ width: "100%" }}
                            className="check-picker-custom-marketplace"
                            placeholder="Choisir une catégorie"
                          />
                          {this.state.is_empty_category && (
                            <Message
                              className="alert-rsuite-custom"
                              type="error"
                              description={this.state.errors.category}
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 pl-lg-5"></div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 pr-lg-4">
                        <div className="form-group mb-4 pos-relative">
                          <span className="label-steps-inscription-vendeur fw-500">
                            Nom du produit
                          </span>
                          <input
                            type="text"
                            onChange={this.handleChangeUpdateProduct}
                            value={product?.name}
                            maxLength={30}
                            className="form-control input-steps-inscription-vendeur"
                            placeholder="Nom du produit"
                            name="name"
                          />
                          {this.state.is_empty_product_name && (
                            <Message
                              className="alert-rsuite-custom"
                              type="error"
                              description={this.state.errors.name}
                            />
                          )}
                        </div>
                        <div className="form-group mb-4 pos-relative">
                          <span className="label-steps-inscription-vendeur fw-500">
                            Marque
                          </span>
                          <input
                            type="text"
                            onChange={this.handleChangeUpdateProduct}
                            value={product?.brand}
                            maxLength={30}
                            className="form-control input-steps-inscription-vendeur"
                            placeholder="Marque"
                            name="brand"
                          />
                        </div>
                        <div className="form-group mb-4 pos-relative">
                          <span className="label-steps-inscription-vendeur fw-500">
                            Ce produit dispose-t-il des pointures ?
                          </span>
                          <select
                            name="pointure"
                            onChange={this.handleChangeUpdateProduct}
                            value={product?.pointure}
                            id="pointure"
                            className="form-control input-steps-inscription-vendeur"
                          >
                            <option value="">
                              {" "}
                              Ce produit dispose-t-il des pointures ?{" "}
                            </option>
                            <option value={true}> Oui </option>
                            <option value={false}>Non</option>
                          </select>
                          {this.state.is_empty_pointure && (
                            <Message
                              className="alert-rsuite-custom"
                              type="error"
                              description={this.state.errors.pointure}
                            />
                          )}
                        </div>
                        <div className="form-group mb-4 pos-relative">
                          <span className="label-steps-inscription-vendeur fw-500">
                            Pointures
                          </span>
                          <CheckPicker
                            // value={this.props.categoryList}
                            onChange={this.handleChangePointure}
                            data={optionSize}
                            labelKey="label"
                            valueKey="value"
                            value={
                              product.pointures
                            }
                            style={{ width: "100%" }}
                            className="check-picker-custom-marketplace"
                            placeholder="Choisir les pointures"
                          />

                          {this.state.is_empty_pointure_val && (
                            <Message
                              className="alert-rsuite-custom"
                              type="error"
                              description={this.state.errors.pointures}
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 pl-lg-5">
                        <div className="form-group mb-4 pos-relative">
                          <span className="label-steps-inscription-vendeur fw-500">
                            Modèle
                          </span>
                          <input
                            type="text"
                            onChange={this.handleChangeUpdateProduct}
                            value={product?.model}
                            maxLength={5}
                            className="form-control input-steps-inscription-vendeur"
                            placeholder="Modèle"
                            name="model"
                          />
                        </div>
                        <div className="form-group mb-4 pos-relative">
                          <span className="label-steps-inscription-vendeur fw-500">
                            Couleur principale
                          </span>
                          <input
                            type="text"
                            onChange={this.handleChangeUpdateProduct}
                            value={product?.main_color}
                            maxLength={30}
                            className="form-control input-steps-inscription-vendeur"
                            placeholder="Couleur principale"
                            name="main_color"
                          />
                        </div>
                        <div className="form-group mb-4 pos-relative">
                          <span className="label-steps-inscription-vendeur fw-500">
                            Ce produit dispose-t-il de différentes tailles ?
                          </span>
                          <select
                            name="taille"
                            onChange={this.handleChangeUpdateProduct}
                            value={product?.taille}
                            id="taille"
                            className="form-control input-steps-inscription-vendeur"
                          >
                            <option value="">
                              {" "}
                              Ce produit dispose-t-il de différentes tailles ?{" "}
                            </option>
                            <option value={true}> Oui </option>
                            <option value={false}>Non</option>
                          </select>
                          {this.state.is_empty_taille && (
                            <Message
                              className="alert-rsuite-custom"
                              type="error"
                              description={this.state.errors.taille}
                            />
                          )}
                        </div>
                        <div className="form-group mb-4 pos-relative">
                          <span className="label-steps-inscription-vendeur fw-500">
                            Tailles
                          </span>
                          <CheckPicker
                            // value={this.props.categoryList}
                            onChange={this.handleChangeTaille}
                            data={optionsTaille}
                            labelKey="label"
                            valueKey="value"
                            value={
                              product.tailles
                            }
                            style={{ width: "100%" }}
                            className="check-picker-custom-marketplace"
                            placeholder="Choisir les tailles"
                          />

                          {this.state.is_empty_taille_val && (
                            <Message
                              className="alert-rsuite-custom"
                              type="error"
                              description={this.state.errors.tailles}
                            />
                          )}
                        </div>
                      </div>

                      {/* <div className="col-md-6 pl-lg-5">
                        
                      </div> */}
                    </div>
                  </div>
                  <div className="py-5">
                    <p className="consigne-title-steps mb-5">
                      Mettre à jour les details du produit
                    </p>
                    <div className="row">
                      <div className="col-md-6 pr-lg-5">
                        <div className="form-group mb-4 pos-relative">
                          <span className="label-steps-inscription-vendeur fw-500 mb-3 d-block">
                            Description du produit
                          </span>
                          <textarea
                            className="textarea-theme-marketplace"
                            rows="12"
                            placeholder="Description du produit"
                            value={product?.description}
                            onChange={this.handleChangeUpdateProduct}
                            name="description"
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-md-6 pl-lg-5 pt-4">
                        <div className="mb-4 mt-1 d-flex align-items-center">
                          {this.state.isDescAudioExist === true ? (
                            <audio
                              className="w-80"
                              src={baseUrl + product?.description_audio}
                              controls="controls"
                            />
                          ) : this.state.audioDisplay === false ? (
                            this.state.onRecording === true ? (
                              <p
                                className="w-80 h-theme-dark d-flex align-items-center"
                                style={{ height: 54 }}
                              >
                                Enregistrement en cours ...{" "}
                              </p>
                            ) : (
                              <p
                                className="w-80 h-theme-dark d-flex align-items-center"
                                style={{ height: 54 }}
                              >
                                Cliquez sur le micro pour enregistrer votre
                                description
                              </p>
                            )
                          ) : (
                            <audio
                              className="w-80"
                              src={this.state.blobURL}
                              controls="controls"
                            />
                          )}

                          {this.state.isRecording === false ? (
                            <span
                              className="btn-start-recorder ml-auto hov-pointer"
                              onClick={this.start}
                            >
                              <FeatherIcon icon="mic" />
                            </span>
                          ) : (
                            <></>
                          )}
                          {this.state.isRecording === true ? (
                            <span
                              className="btn-finish-recorder ml-auto hov-pointer"
                              onClick={this.stop}
                            >
                              <FeatherIcon icon="stop-circle" />
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="form-group mb-4 pos-relative">
                          <span className="label-steps-inscription-vendeur fw-500">
                            Dimensions
                          </span>
                          <input
                            type="text"
                            maxLength={5}
                            className="form-control input-steps-inscription-vendeur"
                            placeholder="Dimensions"
                            value={product?.dimension}
                            onChange={this.handleChangeUpdateProduct}
                            name="dimension"
                          />
                        </div>
                        <div className="form-group mb-4 pos-relative">
                          <span className="label-steps-inscription-vendeur fw-500">
                            Poids
                          </span>
                          <input
                            type="number"
                            maxLength={30}
                            min="0"
                            className="form-control input-steps-inscription-vendeur"
                            placeholder="Poids"
                            value={product?.weight}
                            onChange={this.handleChangeUpdateProduct}
                            name="weight"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="py-5">
                    <p className="consigne-title-steps mb-5">
                      Mettre à jour la tarification
                    </p>

                    <div className="row">
                      <div className="col-md-6 pr-lg-5">
                        <div className="form-group mb-4 pos-relative">
                          <span className="label-steps-inscription-vendeur fw-500">
                            Taxes
                          </span>
                          <input
                            type="text"
                            onChange={this.handleChangeUpdateProduct}
                            value={product?.tax}
                            maxLength={30}
                            className="form-control input-steps-inscription-vendeur"
                            placeholder="Taxes"
                            name="tax"
                          />
                        </div>
                        <div className="form-group mb-4 pos-relative">
                          <span className="label-steps-inscription-vendeur fw-500">
                            Quantité
                          </span>
                          <input
                            type="number"
                            onChange={this.handleChangeUpdateProduct}
                            value={product?.quantity}
                            maxLength={30}
                            min="0"
                            className="form-control input-steps-inscription-vendeur"
                            placeholder="Quantité"
                            name="quantity"
                          />
                          {this.state.is_empty_quantity && (
                            <Message
                              className="alert-rsuite-custom"
                              type="error"
                              description={this.state.errors.quantity}
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 pl-lg-5">
                        <div className="form-group mb-4 pos-relative">
                          <span className="label-steps-inscription-vendeur fw-500">
                            Prix
                          </span>
                          <input
                            type="number"
                            onChange={this.handleChangeUpdateProduct}
                            value={parseInt(product?.price)}
                            maxlength={7}
                            className="form-control input-steps-inscription-vendeur"
                            placeholder="Prix"
                            name="price"
                          />
                          <span className="input-result-parametres-dashboard fw-400">
                            FCFA
                          </span>
                          {this.state.is_empty_price && (
                            <Message
                              className="alert-rsuite-custom"
                              type="error"
                              description={this.state.errors.price}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <UpdateImagesProduct
                    productImages={this.state.productInfos.image}
                    onRef={(ref) => (this.child = ref)}
                    handleChange={this.handleChange}
                    is_empty_images={this.state.is_empty_images}
                    errors={this.state.errors}
                  />

                  {/* <div className="row d-flex justify-content-center py-5">
                    <div className="col-lg-6">
                      {this.state.updateImagesProductInProgress === false ? (
                        <button className="btn-user-info-form text-up">
                          Mettre à jour les images du produits
                        </button>
                      ) : (
                        <button
                          disabled
                          type="submit"
                          className="btn-user-info-form btn-form-dark in-progress-btn disabled"
                        >
                          Mise à jour des images en cours &nbsp;
                          <Loader />
                        </button>
                      )}
                    </div>
                  </div> */}
                  <div className="row d-flex justify-content-center py-5">
                    <div className="col-lg-6">
                      {this.state.updateProductInProgress === false ? (
                        <button className="btn-user-info-form text-up">
                          Mettre à jour le produit
                        </button>
                      ) : (
                        <button
                          disabled
                          type="submit"
                          className="btn-user-info-form btn-form-dark in-progress-btn"
                        >
                          Mise à jour en cours &nbsp;
                          <Loader />
                        </button>
                      )}
                    </div>
                  </div>
                </form>
                {/* <form
                  className="py-5"
                  onSubmit={(e) => this.onUpdateProductImages(e)}
                >
                  <UpdateImagesProduct
                    productImages={this.state.productInfos.image}
                    onRef={(ref) => (this.child = ref)}
                    handleChange={this.handleChange}
                    is_empty_images={this.state.is_empty_images}
                    errors={this.state.errors}
                  />

                  <div className="row d-flex justify-content-center py-5">
                    <div className="col-lg-6">
                      {this.state.updateImagesProductInProgress === false ? (
                        <button className="btn-user-info-form text-up">
                          Mettre à jour les images du produits
                        </button>
                      ) : (
                        <button
                          disabled
                          type="submit"
                          className="btn-user-info-form btn-form-dark in-progress-btn disabled"
                        >
                          Mise à jour des images en cours &nbsp;
                          <Loader />
                        </button>
                      )}
                    </div>
                  </div>
                </form> */}
              </div>
            </div>
          </div>
        </div>
        {this.state.isSuccessUpdateImage && (
          <ToastContainer className="fs-13" />
        )}
        {this.state.isErrorUpdateImage && <ToastContainer className="fs-13" />}
      </div>
    );
  }
}

export default withRouter(updateProduct);
