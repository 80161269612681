import axios from 'axios'
import React, { useEffect } from 'react'
import api from '../../../http/global-vars'
import HeaderDashboard from '../headerDashboard/index'
import NavVerticalDashboardAdmin from '../navVerticalDashboardAdmin/index'
import CguAdmin from './CguAdmin'
import PcAdmin from './PcAdmin'

function GestionDesConditions() {
  const [conditions, setConditions] = React.useState([])
  let config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
    },
  }

  useEffect(() => {
    getConditions()
  }, [])
  const getConditions = async () => {
    let url = `${api}condition/`
    await axios
      .get(url, config)
      .then((response) => {
        setConditions(response?.data?.results)
        console.log('response', response?.data?.results)
      })
      .catch((err) => {
        console.log('error', err?.response)
      })
  }
  return (
    <div className="component-articles-dashboard-admin">
      <HeaderDashboard />
      <div className="fixed-after-header-dashboard container-fluid">
        <div className="row">
          <div className="col-xl-2 col-lg-3 no-view-tablette">
            <NavVerticalDashboardAdmin />
          </div>
          <div className="col-xl-10 col-lg-9 px-xl-3 py-4 pr-lg-3 pl-lg-1">
            <div className="card-dashboard-content-table">
              <div className="head-card-dashboard-content-table">
                <div className="row mb-5">
                  <div className="col-md-9 col-sm-8 mb-sm-0 mb-3">
                    <p className="title-filter-dashboard">
                      Gestion des conditions
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-9">
                    <nav>
                      <div
                        className="nav nav-tabs nav-tabs-horizontal-commandes"
                        id="nav-tab"
                        role="tablist"
                      >
                        <a
                          className="nav-link nav-horizontal-commandes active"
                          id="nav-tout-tab"
                          data-toggle="tab"
                          href="#nav-tout"
                          role="tab"
                          aria-controls="nav-tout"
                          aria-selected="true"
                        >
                          Conditions générales d'utilisation
                        </a>
                        <a
                          className="nav-link nav-horizontal-commandes"
                          id="nav-active-tab"
                          data-toggle="tab"
                          href="#nav-active"
                          role="tab"
                          aria-controls="nav-active"
                          aria-selected="false"
                        >
                          Politique de confidentialité
                        </a>
                      </div>
                    </nav>
                  </div>
                  <div className="col-xl-3"></div>
                </div>
              </div>
              <div className="content-card-dashboard-content-table px-3">
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="nav-tout"
                    role="tabpanel"
                    aria-labelledby="nav-tout-tab"
                  >
                    <CguAdmin
                      cgu={
                        conditions?.filter((item) => item?.type === 'cgu')[0]
                      }
                      getConditions={getConditions}
                    />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-active"
                    role="tabpanel"
                    aria-labelledby="nav-active-tab"
                  >
                    <PcAdmin
                      pc={conditions?.filter((item) => item?.type === 'pc')[0]}
                      getConditions={getConditions}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GestionDesConditions
