import React, { Component } from "react";
import HeaderDashboard from "../headerDashboard/index";
import NavVerticalDashboardAdmin from "../navVerticalDashboardAdmin/index";
import PaymentTable from "../PaymentTable/PaymentTable";
import TableDelivery from "../TableDelivery/TableDelivery";

class DeliveryDashboardAdmin extends Component {
  render() {
    return (
      <div className="component-articles-dashboard-admin">
        <HeaderDashboard />
        <div className="fixed-after-header-dashboard container-fluid">
          <div className="row">
            <div className="col-xl-2 col-lg-3 no-view-tablette">
              <NavVerticalDashboardAdmin />
            </div>
            <div className="col-xl-10 col-lg-9 px-xl-3 py-4 pr-lg-3 pl-lg-1">
              <div className="card-dashboard-content-table">
                <div className="head-card-dashboard-content-table">
                  <div className="row mb-5">
                    <div className="col-md-9 col-sm-8 mb-sm-0 mb-3">
                      <p className="title-filter-dashboard">
                        Mode de livraisons et de paiements
                      </p>
                    </div>
                    <div className="col-md-3 col-sm-4 pl-sm-0"></div>
                  </div>

                  <div className="row">
                    <div className="col-xl-9">
                      <nav>
                        <div
                          className="nav nav-tabs nav-tabs-horizontal-commandes"
                          id="nav-tab"
                          role="tablist"
                        >
                          <a
                            className="nav-link nav-horizontal-commandes active"
                            id="nav-tout-tab"
                            data-toggle="tab"
                            href="#nav-tout"
                            role="tab"
                            aria-controls="nav-tout"
                            aria-selected="true"
                          >
                            Modes de livraison
                          </a>
                          <a
                            className="nav-link nav-horizontal-commandes"
                            id="nav-active-tab"
                            data-toggle="tab"
                            href="#nav-active"
                            role="tab"
                            aria-controls="nav-active"
                            aria-selected="false"
                          >
                            Moyens de paiements
                          </a>
                        </div>
                      </nav>
                    </div>
                    <div className="col-xl-3"></div>
                  </div>
                </div>
                <div className="content-card-dashboard-content-table">
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="nav-tout"
                      role="tabpanel"
                      aria-labelledby="nav-tout-tab"
                    >
                      <TableDelivery />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-active"
                      role="tabpanel"
                      aria-labelledby="nav-active-tab"
                    >
                      <PaymentTable />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DeliveryDashboardAdmin;
