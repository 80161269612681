import React, { Component } from "react";
import "./inscription.css";
import "../connexion/connexion.css";
import Header from "../header/index";
import Footer from "../footer/index";
import { NavLink } from "react-router-dom";
import api from "../../http/global-vars";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loader, Message } from "rsuite";
import SocialLogin from "../socialLogin/index";
import Swal from "sweetalert2";

export default class inscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: api,
      fields: {},
      errors: {},
      registerInProgress: false,
      isSuccessRegister: false,
      isErrorRegister: false,
      isExistUser: false,
      is_empty_first_name: false,
      is_empty_last_name: false,
      is_empty_adress: false,
      is_empty_phone: false,
      is_empty_email: false,
      is_invalid_email: false,
      is_empty_code: false,
      is_empty_code_confirmation: false,
      not_same_code: false,
      isExistPhone: false,
      phone: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.onSubmitInscription = this.onSubmitInscription.bind(this);
  }

  componentDidMount() {
    console.log(this.props);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  handleChange = (e) => {
    let fields = this.state.fields;

    if (e.target.name === "phone" && !e.target.value.includes("+221")) {
      fields[e.target.name] = "+221" + e.target.value;
    } else {
      fields[e.target.name] = e.target.value;
    }

    this.setState({
      fields: fields,
    });
  };

  handleChangePhone = (phone, country) => {
    let fields = this.state.fields;
    console.log("+" + phone, country);
    this.setState({ phone: phone });
    if (country?.format?.match(/\./g)?.length === phone?.length) {
      fields["phone"] = "+" + phone;
    }

    this.setState({
      fields: fields,
    });
  };

  onValidateFormData = () => {
    let fields = this.state.fields;
    let errors = this.state.errors;
    var isValidForm = true;

    if (!fields["first_name"]) {
      isValidForm = false;
      this.setState({
        is_empty_first_name: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_first_name: false,
        });
      }, 5000);
      errors["first_name"] = "Veuillez saisir votre prénom!";
    }
    if (!fields["last_name"]) {
      isValidForm = false;
      this.setState({
        is_empty_last_name: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_last_name: false,
        });
      }, 5000);
      errors["last_name"] = "Veuillez saisir votre nom de famille!";
    }
    if (!fields["adresse"]) {
      isValidForm = false;
      this.setState({
        is_empty_adress: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_adress: false,
        });
      }, 5000);
      errors["adresse"] = "Ce champ est obligatoire!";
    }
    if (!fields["phone"] && !fields["email"]) {
      isValidForm = false;
      this.setState({
        is_empty_phone: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_phone: false,
        });
      }, 5000);
      errors["phone"] = "Ce champ est obligatoire!";
    }
    // if (!fields["email"] && !fields["phone"]) {
    //   isValidForm = false;
    //   this.setState({
    //     is_empty_email: true,
    //   });
    //   setTimeout(() => {
    //     this.setState({
    //       is_empty_email: false,
    //     });
    //   }, 5000);

    //   errors["email"] = "Veuillez renseigner votre adresse e-mail!";
    // }
    if (fields["email"] && typeof fields["email"] !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields["email"])) {
        isValidForm = false;
        this.setState({
          is_invalid_email: true,
        });
        setTimeout(() => {
          this.setState({
            is_invalid_email: false,
          });
        }, 5000);

        errors["invalid_email"] = "Votre adresse email n'est pas valide";
      }
    }
    if (!fields["code"]) {
      isValidForm = false;
      this.setState({
        is_empty_code: true,
      });
      errors["code"] = "Veuillez renseigner un mot de passe!";
      setTimeout(() => {
        this.setState({
          is_empty_code: false,
        });
      }, 5000);
    }
    if (!fields["code_confirmation"]) {
      isValidForm = false;
      this.setState({
        is_empty_code_confirmation: true,
      });
      errors["code_confirmation"] = "Veuillez confirmer votre mot de passe!";
      setTimeout(() => {
        this.setState({
          is_empty_code_confirmation: false,
        });
      }, 5000);
    }
    if (typeof fields["code"] !== "undefined") {
      if (fields["code"] !== fields["code_confirmation"]) {
        isValidForm = false;
        this.setState({
          not_same_code: true,
        });
        errors["not_same_code"] =
          "Les deux mots de passe ne sont pas identiques";
        setTimeout(() => {
          this.setState({
            not_same_code: false,
          });
        }, 5000);
      }
    }

    this.setState({
      errors: errors,
    });

    return isValidForm;
  };

  onSubmitInscription = async (e) => {
    e.preventDefault();

    if (this.onValidateFormData()) {
      this.setState({
        registerInProgress: true,
      });

      var api = this.state.api;
      var data = this.state.fields;

      await axios
        .post(api + "auth/register-phone/", data)
        .then((response) => {
          this.setState({
            registerInProgress: false,
          });
          console.log(response);
          if (
            (response.status === 200 || response.status === 201) &&
            response?.data?.status === "success"
          ) {
            // toast.success("Inscription validée avec succès !");
            Swal.fire({
              icon: "success",
              title: "Inscription réussie!",
              text: "Veuillez maintenant confirmer votre numéro de téléphone pour pouvoir vous connecter",
              iconColor: "#c501ff",
              showConfirmButton: true,
              confirmButtonText: "Confirmer",
              confirmButtonColor: "#c501ff",
              // timer: 1500,
            }).then((results) => {
              if (results?.isConfirmed) {
                // window.location = "confirm-phone";
                // history.push("/confirm-phone");
                this.props.history.replace({
                  pathname: "/confirm-phone",
                  // search: searchParams.toString(),
                  state: data,
                });
              }
            });
            // this.setState({
            //   isSuccessRegister: true,
            // });
            // setTimeout(() => {
            //   this.setState({
            //     isSuccessRegister: false,
            //   });
            // }, 5000);
          } else {
            this.setState({
              registerInProgress: false,
            });
            if (response?.data?.message) {
              toast.error(response?.data?.message);
            } else {
              toast.error(
                "Une erreur est survenue lors de votre inscription! Veuillez réessayer."
              );
            }

            this.setState({
              isErrorRegister: true,
            });
            setTimeout(() => {
              this.setState({
                isErrorRegister: false,
              });
            }, 5000);
          }
        })
        .catch((error) => {
          this.setState({
            registerInProgress: false,
          });
          if (
            error?.response?.data?.error?.email?.length &&
            error?.response?.data?.error?.email[0] ===
              "user with this email already exists."
          ) {
            this.setState({
              isExistUser: true,
            });
            setTimeout(() => {
              this.setState({
                isExistUser: false,
              });
            }, 10000);
          } else if (
            error?.response?.data?.error?.phone?.length &&
            error?.response?.data?.error?.phone[0] ===
              "user with this phone already exists."
          ) {
            this.setState({
              isExistPhone: true,
            });
            setTimeout(() => {
              this.setState({
                isExistPhone: false,
              });
            }, 10000);
          } else if (error?.response?.data?.message) {
            toast.error(error?.response?.data?.message);
          } else {
            toast.error(
              "Une erreur est survenue lors de votre inscription! Veuillez réessayer."
            );
          }
        });
    }
  };

  render() {
    const { phone } = this.state;
    return (
      <div>
        <Header />
        <div className="p-t-40 p-b-150 fixed-after-header">
          <div className="container">
            <p className="m-b-40">
              <NavLink to="" className="routing-link">
                Accueil
              </NavLink>{" "}
              / <span className="routing-link">Inscription</span>
            </p>
            <div className="col">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-5 form-left">
                  <h2 className="text-up text-white fw-600 text-center lh-1 m-b-0">
                    INSCRIPTION
                  </h2>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-7 form-right">
                  <h2 className="fw-600 text-black m-b-40">Créer un compte</h2>

                  <form onSubmit={(e) => this.onSubmitInscription(e)}>
                    <div className="form-group form-group-custom-form">
                      <input
                        type="text"
                        maxLength={30}
                        name="first_name"
                        onChange={this.handleChange}
                        className="form-control form-control-custom-form"
                        placeholder="Prénom *"
                      />
                      {this.state.is_empty_first_name === true && (
                        <Message
                          className="alert-rsuite-custom"
                          type="error"
                          description={this.state.errors.first_name}
                        />
                      )}
                    </div>

                    <div className="form-group form-group-custom-form">
                      <input
                        type="text"
                        maxLength={30}
                        name="last_name"
                        onChange={this.handleChange}
                        className="form-control form-control-custom-form"
                        placeholder="Nom *"
                      />
                      {this.state.is_empty_last_name === true && (
                        <Message
                          className="alert-rsuite-custom"
                          type="error"
                          description={this.state.errors.last_name}
                        />
                      )}
                    </div>

                    <div className="form-group form-group-custom-form">
                      <input
                        type="text"
                        maxLength={30}
                        name="adresse"
                        onChange={this.handleChange}
                        className="form-control form-control-custom-form"
                        placeholder="Adresse *"
                      />
                      {this.state.is_empty_adress === true && (
                        <Message
                          className="alert-rsuite-custom"
                          type="error"
                          description={this.state.errors.adresse}
                        />
                      )}
                    </div>

                    <div className="form-group form-group-custom-form">
                      {/* <input
                        type="text"
                        maxLength={30}
                        name="phone"
                        onChange={this.handleChange}
                        className="form-control form-control-custom-form"
                        placeholder="N° téléphone"
                      /> */}
                      <PhoneInput
                        defaultMask={".. ... .. .."}
                        inputClass="form-control form-control-custom-form"
                        containerClass="phone-input"
                        country={"sn"}
                        // onlyCountries={["sn"]}
                        inputProps={{
                          name: "telephone",
                          required: true,
                          autoFocus: true,
                        }}
                        // disableDropdown={true}
                        enableAreaCodes={true}
                        prefix="+"
                        value={phone}
                        onChange={(phone, country) =>
                          this.handleChangePhone(phone, country)
                        }
                        countryCodeEditable={false}
                        inputStyle={{
                          width: "100%",
                          borderRadius: "10px",
                        }}
                      />
                      {this.state.is_empty_phone === true && (
                        <Message
                          className="alert-rsuite-custom"
                          type="error"
                          description={this.state.errors.phone}
                        />
                      )}
                      {this.state.isExistPhone === true && (
                        <div className="alert alert-danger form-alert-danger fs-12">
                          Votre numéro de téléphone est déjà associé à un
                          compte! <br />
                          Veuillez utiliser un autre numéro de téléphone pour
                          créer un compte ou vous connecter.
                        </div>
                      )}
                    </div>

                    <div className="form-group form-group-custom-form">
                      <input
                        type="email"
                        maxLength={50}
                        name="email"
                        onChange={this.handleChange}
                        className="form-control form-control-custom-form"
                        placeholder="Adresse mail"
                      />
                      {this.state.is_empty_email === true && (
                        <Message
                          className="alert-rsuite-custom"
                          type="error"
                          description={this.state.errors.email}
                        />
                      )}
                      {this.state.is_invalid_email === true && (
                        <Message
                          className="alert-rsuite-custom"
                          type="error"
                          description={this.state.errors.invalid_email}
                        />
                      )}
                      {this.state.isExistUser === true && (
                        <div className="alert alert-danger form-alert-danger fs-12">
                          Votre e-mail est déjà associé à un compte! <br />
                          Veuillez utiliser une autre adresse e-mail pour créer
                          un compte ou vous connecter.
                        </div>
                      )}
                    </div>

                    <div className="form-group form-group-custom-form">
                      <input
                        type="password"
                        name="code"
                        onChange={this.handleChange}
                        className="form-control form-control-custom-form"
                        placeholder="Mot de passe *"
                      />
                      {this.state.is_empty_code === true && (
                        <Message
                          className="alert-rsuite-custom"
                          type="error"
                          description={this.state.errors.code}
                        />
                      )}
                    </div>

                    <div className="form-group form-group-custom-form">
                      <input
                        type="password"
                        name="code_confirmation"
                        onChange={this.handleChange}
                        className="form-control form-control-custom-form"
                        placeholder="Confirmer le mot de passe *"
                      />
                      {this.state.is_empty_code_confirmation === true && (
                        <Message
                          className="alert-rsuite-custom"
                          type="error"
                          description={this.state.errors.code_confirmation}
                        />
                      )}
                      {this.state.not_same_code === true && (
                        <Message
                          className="alert-rsuite-custom"
                          type="error"
                          description={this.state.errors.not_same_code}
                        />
                      )}
                    </div>

                    <div className="custom-control custom-checkbox m-t-40 m-b-40">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck"
                        name="example1"
                      />
                      <label
                        className="custom-control-label custom-control-label-form-signup"
                        htmlFor="customCheck"
                      >
                        Oui, je veux recevoir par e-mail des infos sur les
                        dernières tendances. Désinscription possible à tout
                        moment. (facultatif)
                      </label>
                    </div>

                    {this.state.registerInProgress === false ? (
                      <button type="submit" className="btn-form-dark">
                        INSCRIPTION
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn-form-dark in-progress-btn"
                      >
                        Inscription en cours &nbsp;
                        <Loader />
                      </button>
                    )}
                  </form>
                  <p className="fs-12 text-black-50 lh-1 m-b-25">
                    En créant un compte, vous acceptez les Conditions générales
                    et{" "}
                    <NavLink to="" className="link-terms-register">
                      Politique de confidentialité
                    </NavLink>{" "}
                    de DIEKKES
                  </p>
                  {/* <SocialLogin from="register" /> */}

                  <p className="text-center fs-14 fw-300">
                    Déjà inscrit ?{" "}
                    <NavLink to="/connexion" className="link-form-signup-login">
                      Se connecter
                    </NavLink>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />

        {this.state.isSuccessRegister && <ToastContainer className="fs-13" />}
        {this.state.isErrorRegister && <ToastContainer className="fs-13" />}
      </div>
    );
  }
}
// export default connect(
//     ({ inscription }) => ({ ...inscription }),
//     dispatch => bindActionCreators({ ...inscriptionActions }, dispatch)
//   )( inscription );
