import React, { useState } from "react";
import "./shopCard.css";
import { NavLink } from "react-router-dom";
import ShopCardSkeleton from "../skeleton/shopCardSkeleton/shopCardSkeleton";
import { useGetshopVisitorQuery } from "../../utils/api/shop.api";
import { getImage } from "../../utils/utils";
import { CustomPagination } from "../../modules/Pagination/CustomPagination";

function ShopCard({ tri }) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);
  const { data = { results: [] }, isLoading } = useGetshopVisitorQuery({
    order: tri,
    page,
    limit,
  });

  return (
    <>
      {!!isLoading && <ShopCardSkeleton />}
      {!isLoading &&
        data?.results?.map((shop) => (
          <div
            className="col-xl-3 col-lg-3 col-md-6 px-2 col-sm-6 m-b-15"
            key={shop?.id}
          >
            <div className="card-boutique-item">
              <div className="image-card-boutique">
                <img loading="lazy"
                  className="w-full h-full"
                  src={getImage(shop?.logo)}
                  alt={shop.shop_name}
                />
              </div>

              <div className="content-card-boutique-item">
                <p className="marque-card-boutique">{shop.shop_name}</p>
                <NavLink
                  className="link-visit-boutique"
                  to={`/boutique/${shop.id}`}
                >
                  visiter
                </NavLink>
              </div>
            </div>
          </div>
        ))}
      <div class="col-md-12 mt-3">
        <CustomPagination
          page={page}
          perPage={limit}
          nbPages={data?.count}
          showPerpage={false}
          onChange={(page, perPage) => {
            window.scrollTo(200, 200);
            setPage(page);
            setLimit(perPage);
          }}
        />
      </div>
    </>
  );
}

export default ShopCard;
