import React, { useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import { NavLink } from "react-router-dom";
import { Message, Modal } from "rsuite";
import { useForm } from "react-hook-form";

function ReSendPhone({ show, handleClose, onSend }) {
  const {
    register,
    handleSubmit,
    formState,
    clearErrors,
    setValue,
  } = useForm();
  const [phone, setPhone] = React.useState("");

  useEffect(() => {
    register("phone", {
      required: "Le numéro de téléphone est obligatoire",
    });
  }, [register]);

  const handleChange = (phone, country) => {
    console.log("+" + phone, country);
    setPhone(phone);
    if (country?.format?.match(/\./g)?.length === phone?.length) {
      setValue("phone", "+" + phone);
    }
  };

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);
  const onSubmit = (data) => {
    onSend(data?.phone);
  };
  return (
    <div className="modal-container">
      <Modal
        size="xs"
        show={show}
        onHide={handleClose}
        backdrop={"static"}
        className="rsuite-content-modal-custom"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header>
            <Modal.Title>Renvoyer le code</Modal.Title>
          </Modal.Header>
          <div className="my-5 flex-col itm-center">
            <div className="add-family-form-container w-100">
              <div className="crud-form">
                <div className="row crud-row pb-1">
                  <div className="col-md-12 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label for="phone">Téléphone</label>

                      <PhoneInput
                        defaultMask={".. ... .. .."}
                        inputClass="form-control crud-form-control"
                        country={"sn"}
                        onlyCountries={["sn"]}
                        inputProps={{
                          name: "telephone",
                          required: true,
                          autoFocus: true,
                        }}
                        disableDropdown={true}
                        enableAreaCodes={true}
                        prefix="+"
                        value={phone}
                        onChange={(phone, country) =>
                          handleChange(phone, country)
                        }
                        countryCodeEditable={false}
                        inputStyle={{
                          width: "100%",
                          paddingBottom: "22px",
                          borderRadius: "10px",
                        }}
                      />
                      {formState.errors && formState.errors.phone && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {formState.errors.phone?.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal.Footer className="text-center">
            <div className="row px-5">
              <div className="col-6 mb-2">
                <button onClick={(e) => handleClose(e)} className="btn-theme">
                  Annuler
                </button>
              </div>
              <div className="col-6 mb-2">
                <button
                  className="btn-light-theme"
                  type="submit"
                  // onClick={(e) => }
                >
                  Envoyer
                </button>
              </div>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}

export default ReSendPhone;
