import React, { Component } from "react";
import "./footer.css";
import { NavLink } from "react-router-dom";
import AndroidIcon from "../../img/icons/android-icon.png";
import IosIcon from "../../img/icons/apple-icon.png";
import SocialLink from "../socialLink/index";
import ContactUs from "../contactUs/index";
import api from "../../http/global-vars";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import ListCategoriesFooter from "./ListCategoriesFooter";

export default class footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: api,
      categoryList: [],
      loading: false,
      isLoading: true,
      infos: {
        email: "diekkesells@gmail.com",
        telephone: "(+221) 77 168 30 05",
        facebook: "https://www.facebook.com/profile.php?id=100089923730968",
        instagram: "https://www.instagram.com/diekkes.sn/",
        twitter: "",
        youtube: "",
        linkedin: "",
        tiktok: "https://www.tiktok.com/@diekkes.sn",
      },
    };
  }

  componentDidMount() {
    this.onGetSocialInfos()
  }

  onGetSocialInfos = () => {
    axios
      .get(`${api}reseauxsociauxbyvisitor/`)
      .then((res) => {
        console.log("get", res);
        if (res.data.results?.length) {
          this.setState({ infos: res.data.results[0], isLoading: false });
        }
      })
      .catch((err) => {
        console.log("err", err);
        this.setState({ isLoading: false });
      });
  };
  render() {
    const {infos} = this.state
    return (
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 m-b-70">
              <h5 className="text-white m-b-20">
                Téléchargez notre application gratuite !
              </h5>
              <div className="row m-b-50">
                <div className="col-6 pr-2 m-b-10">
                  <a
                    className="download-application-item"
                    href="https://play.google.com/store/apps/details?id=com.diekkes.marketplace"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="dis-flex justify-content-center">
                      <img loading="lazy"
                        className="m-b-10 icon-application-footer"
                        src={AndroidIcon}
                        alt=""
                      />
                    </div>
                    <p className="text-download-application-item">Android</p>
                  </a>
                </div>
                <div className="col-6 pl-2 m-b-10">
                  <NavLink className="download-application-item" to="#">
                    <div className="dis-flex justify-content-center">
                      <img loading="lazy"
                        className="m-b-10 icon-application-footer"
                        src={IosIcon}
                        alt=""
                      />
                    </div>
                    <p className="text-download-application-item">IOS</p>
                  </NavLink>
                </div>
              </div>
              <h6 className="text-white fw-200 m-b-30">
                Suivez sur les réseaux sociaux
              </h6>
              <SocialLink infos={infos} />
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 m-b-70">
              <h5 className="text-white m-b-20">Qui sommes-nous ?</h5>
              <ul className="ul-footer">
                <li className="li-footer">
                  <NavLink to="/a-propos" className="link-footer">
                    À Propos
                  </NavLink>
                </li>
                <li className="li-footer">
                  <NavLink to="/condition-generale" className="link-footer">
                    Conditions Générales d'utilisation
                  </NavLink>
                </li>

                {/*  <li className="li-footer">
                  <NavLink to="" className="link-footer">
                    Propriété intellectuelle
                  </NavLink>
                </li> */}
                <li className="li-footer">
                  <NavLink
                    to="/politique-confidentialite"
                    className="link-footer"
                  >
                    Politique de Confidentialité
                  </NavLink>
                </li>
              </ul>
            </div>
            <ListCategoriesFooter/>
            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 m-b-70">
              <h5 className="text-white m-b-20">Nous contacter</h5>
              <p className="link-footer trans-0-2 d-block mb-3">
                MOBILE : {infos?.telephone}
              </p>
              <p className="link-footer trans-0-2 d-block mb-3">
                EMAIL : {infos?.email}
              </p>
              <ContactUs />
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
