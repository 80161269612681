import React, { Component } from "react";
import "./changeShippingAddressModal.css";
import { Modal, ButtonToolbar, Message } from "rsuite";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import api from "../../http/global-vars";
import { ToastContainer, toast } from "react-toastify";

export default class changeShippingAddressModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
      errors: {},
      userID: window.localStorage.getItem("userID"),
      userInfos: {
        adresse: "",
      },
      show: false,
      is_empty_adresse: false,
      isSuccessChangeShippingAddress: false,
      isErrorChangeShippingAddress: false,
      changeShippingAddressInProgress: false,
    };
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
    this.handleChangeShippingAddress =
      this.handleChangeShippingAddress.bind(this);
  }
  close() {
    this.setState({ show: false });
  }
  open() {
    this.setState({ show: true });
  }

  componentDidMount() {
    var config = this.state.requestConfig;
    this.onGetConnectedUserInfos(config);
  }

  onGetConnectedUserInfos = (config) => {
    this.setState({
      isPageLoading: true,
    });
    var api = this.state.api;
    axios
      .get(api + "me/", config)
      .then((response) => {
        this.setState({
          isPageLoading: false,
        });
        if (response.status === 200 || response.status === 201) {
          this.setState({
            userInfos: response.data.data,
          });
        }
      })
      .catch((error) => {
        this.setState({
          isPageLoading: false,
        });
      });
  };

  handleChangeShippingAddress = (e) => {
    e.persist();

    this.setState((prevState) => ({
      userInfos: { ...prevState.userInfos, [e.target.name]: e.target.value },
    }));
  };

  onValidateChangeShippingAddress = () => {
    let fields = this.state.userInfos;
    let errors = this.state.errors;
    var isValidForm = true;

    if (!fields["adresse"]) {
      isValidForm = false;
      this.setState({
        is_empty_adresse: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_adresse: false,
        });
      }, 5000);
      errors["adresse"] = "L'adresse de livraison est obligatoire!";
    }
    this.setState({
      errors,
    });

    return isValidForm;
  };

  onSubmitUpdateShippingAddress = (e) => {
    e.preventDefault();
    if (this.onValidateChangeShippingAddress()) {
      this.setState({
        changeShippingAddressInProgress: true,
      });

      var api = this.state.api;
      var config = this.state.requestConfig;
      var data = new FormData();
      var userID = this.state.userID;

      data.append("adresse", this.state.userInfos.adresse);

      axios
        .put(`${api}user/${userID}/`, data, config)
        .then((response) => {
          this.setState({
            changeShippingAddressInProgress: false,
          });

          if (response.status === 200) {
            window.localStorage.setItem("userFirstName", data.first_name);
            window.localStorage.setItem("userLastName", data.last_name);
            window.localStorage.setItem(
              "userFullname",
              data.first_name + " " + data.last_name
            );
            toast.success("Votre adresse de livraison a été bien modifié!");
            this.setState({
              isSuccessChangeShippingAddress: true,
            });
            setTimeout(() => {
              this.setState({
                isSuccessChangeShippingAddress: false,
              });
              window.location.reload();
            }, 4000);
            this.close();
          } else {
            toast.error(
              "Oupsss! Une erreur est survenue lors de la modification de votre profil"
            );
            this.setState({
              isErrorChangeShippingAddress: true,
            });
            setTimeout(() => {
              this.setState({
                isErrorChangeShippingAddress: false,
              });
            }, 5000);
          }
        })
        .catch((error) => {
          toast.error(
            "Oupsss! Une erreur est survenue lors de la modification de votre adresse de livraison"
          );
          this.setState({
            changeShippingAddressInProgress: false,
            isErrorChangeShippingAddress: true,
          });
          setTimeout(() => {
            this.setState({
              isErrorChangeShippingAddress: false,
            });
          }, 5000);
        });
    }
  };

  render() {
    const userAdresse = this.state.userInfos;
    return (
      <div className="component-change-shipping-address-modal">
        <ButtonToolbar>
          <FeatherIcon
            onClick={this.open}
            className="icon-edit-step-item-process-card"
            icon="edit"
          />
        </ButtonToolbar>

        <Modal
          size="sm"
          className="rsuite-content-modal-custom"
          show={this.state.show}
          onHide={this.close}
        >
          <form onSubmit={(e) => this.onSubmitUpdateShippingAddress(e)}>
            <Modal.Header>
              <Modal.Title>Modifier votre adresse de livraison</Modal.Title>
            </Modal.Header>
            <div>
              <div className="form-group mt-5 mb-5">
                <label
                  className="label-steps-inscription-vendeur fw-500"
                  htmlFor="changeShippingAdresse"
                >
                  Ajouter votre nouvelle adresse de livraison
                </label>
                <input
                  id="changeShippingAdresse"
                  className="form-control input-change-password"
                  type="text"
                  maxLength={30}
                  placeholder="Votre nouvelle adresse de livraison"
                  value={userAdresse.adresse}
                  onChange={this.handleChangeShippingAddress}
                  name="adresse"
                />
                {this.state.is_empty_adresse && (
                  <Message
                    className="alert-rsuite-custom mt-2"
                    type="error"
                    description={this.state.errors["adresse"]}
                  />
                )}
              </div>
            </div>
            <Modal.Footer className="text-center">
              <button type="submit" className="btn-change-password">
                Terminer
              </button>
            </Modal.Footer>
          </form>
        </Modal>
        {this.state.isSuccessChangeShippingAddress && (
          <ToastContainer className="fs-13" />
        )}
        {this.state.isErrorChangeShippingAddress && (
          <ToastContainer className="fs-13" />
        )}
      </div>
    );
  }
}
