import React, { Component } from "react";
import "./tableShop.css";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import FeatherIcon from "feather-icons-react";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import api from "../../../http/global-vars";
import axios from "axios";
import DeleteShopModal from "./DeleteShopModal";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { NavLink } from "react-router-dom";
import TableSkeleton from "../../skeleton/TableSkeleton";
import { CustomPagination } from "../../../modules/Pagination/CustomPagination";

class tableShop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      userID: window.localStorage.getItem("userID"),
      checked: true,
      shopActivToggle: false,
      api: api,
      fields: {},
      onArchiveShop: true,
      featuredShop: true,
      loading: false,
      archivageShopInProgress: false,
      activationShopInProgress: false,
      featuredShopInProgress: false,
      show: false,
      shop: {},
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (cell, id, user) => {
    let config = this.state.requestConfig;
    // console.log("data", cell, id, user);
    Swal.fire({
      title: `Êtes vous sûr de vouloir ${
        cell === true ? "activer" : "désactiver"
      } cette boutique`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      confirmButtonColor: "#c501ff",
      preConfirm: () => {
        return axios
          .put(
            `${api}fournisseur/${id}/`,
            { is_active: cell, user: user },
            config
          )
          .then(async (response) => {
            console.log("res", response);
            await this.props.fetchShops();
            return response;
          })
          .catch((error) => {
            console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("res", result);
        Swal.fire({
          icon: "success",
          title: `Boutique ${
            cell === true ? "activée" : "désactivée"
          } avec succés.`,
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        this.props.fetchShops();
      }
    });
  };

  shopAction(cell, row) {
    return (
      <div className="dis-flex justify-content-center">
        <BootstrapSwitchButton
          checked={cell}
          title="Activation"
          size="xs"
          width="55"
          onstyle="outline-success"
          offstyle="outline-danger"
          onlabel="OUI"
          offlabel="NON"
          onChange={(checked) =>
            this.handleChange(checked, row.id, row.user?.id)
          }
        />
        <div className="row w-full mx-0 dis-flex justify-content-center">
          <div class="col-3 px-0 dis-flex justify-content-center">
            <NavLink
              to={{
                pathname: `/details-boutique-admin/${row?.id}`,
                state: row,
              }}
              className="btn-action-table-grey clear m-0"
              title="Details"
            >
              <FeatherIcon icon="eye" className="icon-action-table-grey" />
            </NavLink>
          </div>
          <div className="col-3 px-0 dis-flex justify-content-center">
            <button
              // onClick={() => this.onDeleteShop(row)}
              onClick={() => this.handleOpen(row)}
              className={
                row?.is_archive
                  ? `btn-action-table-red clear m-0`
                  : `btn-action-table-grey clear m-0`
              }
              title={row?.is_archive ? `Désarchiver` : `Archiver`}
            >
              <FeatherIcon icon="trash-2" className="icon-action-table-grey" />
            </button>
          </div>

          <div className="col-3 px-0 dis-flex justify-content-center">
            {row?.banner !== null ? (
              row.mise_avant === true ? (
                <button
                  onClick={() => this.onFeaturedShop(row)}
                  className="btn-action-table-grey edit featured m-0"
                  title="Mise en avant"
                >
                  <FeatherIcon
                    icon="award"
                    className="icon-action-table-grey"
                  />
                </button>
              ) : (
                <button
                  onClick={() => this.onFeaturedShop(row)}
                  className="btn-action-table-grey edit featured-reverse m-0"
                  title="Mise en avant"
                >
                  <FeatherIcon
                    icon="award"
                    className="icon-action-table-grey"
                  />
                </button>
              )
            ) : (
              <></>
            )}
          </div>
          <div className="col-3 px-0 dis-flex justify-content-center">
            <button
              onClick={() => this.onDefinedTopShop(row)}
              className={`btn-action-table-grey edit ${
                row?.is_top ? "featured" : "featured-reverse"
              } m-0`}
              title="Top boutique"
            >
              <FeatherIcon icon="bookmark" className="icon-action-table-grey" />
            </button>
          </div>
        </div>
      </div>
    );
  }

  onDefinedTopShop = (shop) => {
    let config = this.state.requestConfig;
    var id = shop.id;
    var data = {};

    if (shop.is_top === false) {
      data["is_top"] = true;
    }
    if (shop.is_top === true) {
      data["is_top"] = false;
    }
    data["user"] = shop.user?.id;
    Swal.fire({
      title: `Êtes vous sûr de vouloir ${
        shop.is_top !== true
          ? "mettre en 'TOP'"
          : "retirer de la liste des tops "
      } cette boutique`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      confirmButtonColor: "#c501ff",
      preConfirm: () => {
        return axios
          .put(`${api}fournisseur/${id}/`, data, config)
          .then(async (response) => {
            console.log("res", response);
            await this.props.fetchShops();
            return response;
          })
          .catch((error) => {
            console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("res", result);
        Swal.fire({
          icon: "success",
          title: `${
            shop.is_top !== true
              ? "Boutique mise en 'Top'"
              : "Mise en 'TOP' retirée"
          } avec succés.`,
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        this.props.fetchShops();
      }
    });
  };

  onFeaturedShop = (shop) => {
    let config = this.state.requestConfig;
    var id = shop.id;
    var data = {};

    if (shop.mise_avant === false) {
      data["mise_avant"] = true;
    }
    if (shop.mise_avant === true) {
      data["mise_avant"] = false;
    }
    data["user"] = shop.user?.id;
    Swal.fire({
      title: `Êtes vous sûr de vouloir ${
        shop.mise_avant !== true
          ? "mettre en avant"
          : "retirer la mise en avant de "
      } cette boutique`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      confirmButtonColor: "#c501ff",
      preConfirm: () => {
        return axios
          .put(`${api}fournisseur/${id}/`, data, config)
          .then(async (response) => {
            console.log("res", response);
            await this.props.fetchShops();
            return response;
          })
          .catch((error) => {
            console.log(error);
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("res", result);
        Swal.fire({
          icon: "success",
          title: `${
            shop.mise_avant !== true
              ? "Boutique mise en avant"
              : "Mise en avant retirée"
          } avec succés.`,
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        this.props.fetchShops();
      }
    });
  };

  handleClose = (e) => {
    e.preventDefault();
    this.setState({ show: false });
    this.setState({
      shop: {},
      archivageShopInProgress: false,
    });
  };
  handleOpen = (row) => {
    this.setState({
      shop: row,
    });
    this.setState({ show: true });
  };

  onDeleteShop = (e) => {
    e.preventDefault();
    this.setState({ archivageShopInProgress: true });

    let config = this.state.requestConfig;
    var api = this.state.api;
    var shopID = this.state.shop.id;
    var data = new FormData();

    axios
      .put(
        `${api}fournisseur/${shopID}/`,
        {
          is_archive: !this.state.shop?.is_archive,
          is_active: !this.state.shop?.is_archive
            ? false
            : this.state.shop?.is_active,
          user: this.state.shop.user?.id,
        },
        config
      )
      .then((response) => {
        this.setState({ archivageShopInProgress: false });
        if (response.status === 200 || response.status === 201) {
          // Alert.success("La boutique à été supprimé!");
          this.setState({
            shop: {},
            show: false,
          });
          toast.success(
            this.state.shop?.is_archive
              ? "La boutique a été désarchivée!"
              : "La boutique a été archivée!"
          );
          this.props.fetchShops();
          // window.location.reload();
        }
      })
      .catch((error) => {
        this.setState({ archivageShopInProgress: false });
        if (error) {
          this.setState({ loading: false });
          toast.error("Une erreur est survenue!");
        }
      });
  };

  notationShop(cell, row) {
    return parseInt(row.note) + "/5";
  }

  render() {
    let { isLoading, page, limit, setLimit, setPage, count } = this.props;
    const renderPaginationPanel = (props) => {
      return (
        <div class="col-md-12">
          <CustomPagination
            page={page}
            perPage={limit}
            nbPages={count}
            onChange={(page, perPage) => {
              window.scrollTo(200, 200);
              setPage(page);
              setLimit(perPage);
            }}
          />
        </div>
      );
    };
    const options = {
      noDataText: "Aucun produit disponible",
      paginationPanel: renderPaginationPanel,
      sizePerPage: limit,
    };
    let headers = [
      { text: "Actions" },
      { text: "Nom" },
      { text: "Vendeur" },
      { text: "Type" },
      { text: "Email" },
    ];

    return (
      <div className="component-table-shop">
        {!!isLoading && <TableSkeleton headers={headers} />}
        {!isLoading && (
          <BootstrapTable
            className="head-table-custom"
            options={options}
            data={this.props.shop}
            search={false}
            multiColumnSearch={true}
            striped
            hover
            multiColumnSort={2}
            pagination
            version="4"
          >
            <TableHeaderColumn
              className="th-table-custom"
              dataField="is_active"
              dataFormat={this.shopAction.bind(this)}
              width="200"
              thStyle={{ textAlign: "center" }}
              tdStyle={{
                fontWeight: 400,
                fontSize: 14,
                color: "rgba(0, 0, 0, 0.7)",
                borderTop: "none",
                borderBottom: "none",
                padding: "1rem",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              Actions
            </TableHeaderColumn>
            <TableHeaderColumn
              isKey
              dataField="shop_name"
              width="230"
              tdStyle={{
                fontWeight: 400,
                fontSize: 14,
                color: "rgba(0, 0, 0, 0.7)",
                borderTop: "none",
                borderBottom: "none",
                padding: "1rem",
                whiteSpace: "normal",
                lineHeight: 1,
                textAlign: "center",
                verticalAlign: "middle",
              }}
              thStyle={{ textAlign: "center" }}
              className="th-table-custom"
            >
              Nom
            </TableHeaderColumn>
            <TableHeaderColumn
              className="th-table-custom"
              dataField="user"
              dataFormat={(cell, row) =>
                cell?.first_name + " " + cell?.last_name
              }
              width="200"
              thStyle={{ textAlign: "center" }}
              tdStyle={{
                fontWeight: 400,
                whiteSpace: "normal",
                fontSize: 14,
                color: "rgba(0, 0, 0, 0.7)",
                borderTop: "none",
                borderBottom: "none",
                padding: "1rem",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              Vendeur
            </TableHeaderColumn>
            {/* <TableHeaderColumn
              dataField="option"
              className="th-table-custom"
              width="200"
              thStyle={{ textAlign: "center" }}
              tdStyle={{
                fontWeight: 400,
                whiteSpace: "normal",
                fontSize: 14,
                color: "rgba(0, 0, 0, 0.7)",
                borderTop: "none",
                borderBottom: "none",
                padding: "1rem",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              Type
            </TableHeaderColumn> */}
            <TableHeaderColumn
              dataField="user"
              dataFormat={(cell, row) => cell?.email}
              className="th-table-custom"
              width="200"
              thStyle={{ textAlign: "center" }}
              tdStyle={{
                fontWeight: 400,
                whiteSpace: "normal",
                fontSize: 14,
                color: "rgba(0, 0, 0, 0.7)",
                borderTop: "none",
                borderBottom: "none",
                padding: "1rem",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              Email
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="user"
              dataFormat={(cell, row) => cell?.phone}
              className="th-table-custom"
              width="150"
              thStyle={{ textAlign: "center" }}
              tdStyle={{
                fontWeight: 400,
                whiteSpace: "normal",
                fontSize: 14,
                color: "rgba(0, 0, 0, 0.7)",
                borderTop: "none",
                borderBottom: "none",
                padding: "1rem",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              Téléphone
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="user"
              dataFormat={(cell, row) => cell?.adresse}
              className="th-table-custom"
              width="200"
              thStyle={{ textAlign: "center" }}
              tdStyle={{
                fontWeight: 400,
                whiteSpace: "normal",
                fontSize: 14,
                color: "rgba(0, 0, 0, 0.7)",
                borderTop: "none",
                borderBottom: "none",
                padding: "1rem",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              Adresse
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="city"
              className="th-table-custom"
              width="150"
              thStyle={{ textAlign: "center" }}
              tdStyle={{
                fontWeight: 400,
                whiteSpace: "normal",
                fontSize: 14,
                color: "rgba(0, 0, 0, 0.7)",
                borderTop: "none",
                borderBottom: "none",
                padding: "1rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Ville
            </TableHeaderColumn>
            <TableHeaderColumn
              className="th-table-custom"
              dataField="country"
              width="150"
              thStyle={{ textAlign: "center" }}
              tdStyle={{
                fontWeight: 400,
                fontSize: 14,
                color: "rgba(0, 0, 0, 0.7)",
                borderTop: "none",
                borderBottom: "none",
                padding: "1rem",
                textAlign: "center",
                verticalAlign: "middle",
                whiteSpace: "normal",
              }}
            >
              Pays
            </TableHeaderColumn>
            <TableHeaderColumn
              className="th-table-custom"
              dataField="sales"
              width="150"
              thStyle={{ textAlign: "center" }}
              tdStyle={{
                fontWeight: 400,
                fontSize: 14,
                color: "rgba(0, 0, 0, 0.7)",
                borderTop: "none",
                borderBottom: "none",
                padding: "1rem",
                textAlign: "center",
                verticalAlign: "middle",
                whiteSpace: "normal",
              }}
            >
              Ventes
            </TableHeaderColumn>
            <TableHeaderColumn
              className="th-table-custom"
              dataField="note"
              dataFormat={this.notationShop}
              width="150"
              thStyle={{ textAlign: "center" }}
              tdStyle={{
                fontWeight: 400,
                fontSize: 14,
                color: "rgba(0, 0, 0, 0.7)",
                borderTop: "none",
                borderBottom: "none",
                padding: "1rem",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              Notation
            </TableHeaderColumn>
            {/* <TableHeaderColumn
            className="th-table-custom"
            dataField="is_active"
            dataFormat={this.shopActivation.bind(this)}
            width="150"
            thStyle={{ textAlign: "center" }}
            tdStyle={{
              fontWeight: 400,
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.7)",
              borderTop: "none",
              borderBottom: "none",
              padding: "1rem",
              textAlign: "center",
              verticalAlign: 'middle'
            }}
          >
            Activation
          </TableHeaderColumn> */}
          </BootstrapTable>
        )}

        <DeleteShopModal
          handleClose={this.handleClose}
          show={this.state.show}
          shop={this.state.shop}
          onDeleteShop={this.onDeleteShop}
          archivageInProgress={this.state.archivageShopInProgress}
        />
      </div>
    );
  }
}

export default tableShop;
