import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import store from "./store";
import * as Yup from "yup";
import { fr } from "yup-locales";
import { Loader } from "./App";

Yup.setLocale(fr);

// const App = Loader(React.lazy(() => import("./App")));
let persistor = persistStore(store);
function noop() {}

if (process.env.NODE_ENV !== "development") {
  console.log = noop;
  console.warn = noop;
  console.error = noop;

  // Sentry.init({
  //   dsn: "https://bc6b4abe7a6a4a51bf1f627a6fe06605@app-sentry.withvolkeno.com/65",
  //   autoSessionTracking: true,
  //   integrations: [new Integrations.BrowserTracing()],

  //   // We recommend adjusting this value in production, or using tracesSampler
  //   // for finer control
  //   tracesSampleRate: 1.0,
  // });
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
