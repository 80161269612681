import React, {Component} from 'react';
import Skeleton from 'react-loading-skeleton';

export default class categorieListSkeleton extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
      return  <div className="sk-card-categorie-accueil">
                <Skeleton className="h-theme fw-400 mb-3" width={`40%`} height={20} />
                <ul className="ul-categories">
                    {Array(10).fill().map((category, i) => {
                        return (
                            <li key={i} className="li-categories">
                                <p className="sk-categories-link">
                                    <Skeleton className="sk-categories-link" />
                                    {/* <Skeleton className="sk-icon-categories-link" /> */}
                                </p>
                            </li>
                        )
                    })}
                    
                </ul>
              </div>;
    }
}

