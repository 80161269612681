import React from 'react'
import ReactQuill from 'react-quill'
import { useForm } from 'react-hook-form'
import api from '../../../http/global-vars'
import axios from 'axios'
import { useState } from 'react'
import { Loader } from 'rsuite'
import Swal from 'sweetalert2'

function CguAdmin({ cgu, getConditions }) {
  const [isLoading, setIsLoading] = React.useState(false)
  const [contenue, setContenue] = useState('')
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    setError,
    clearErrors,
  } = useForm()

  let config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
    },
  }

  React.useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key)
        })
      }, 5000)
    }
  }, [formState])
  React.useEffect(() => {
    register('text', {
      required: 'Le contenu est obligatoire.',
    })
    setValue('type', 'cgu')
    if (cgu?.id) {
      console.log('data', cgu)
      setContenue(cgu?.text)
      setValue('text', cgu?.text)
    }
  }, [cgu])
  const handleChange = (value) => {
    setContenue(value)
    setValue('text', value)
  }

  const onSubmit = async (data) => {
    setIsLoading(true)
    let url = cgu?.id ? `${api}condition/${cgu?.slug}/` : `${api}condition/`
    if (cgu?.id) {
      await axios
        .put(url, data, config)
        .then(async (response) => {
          console.log('response edit', response)
          await getConditions()
          await Swal.fire({
            icon: 'success',
            title: 'Contenu modifié avec succès',
            iconColor: '#c501ff',
            showConfirmButton: false,
            timer: 1500,
          })
          await setIsLoading(false)
        })
        .catch((err) => {
          console.log('error', err?.response)
          Swal.fire({
            icon: 'error',
            title: `${
              err?.response?.data?.message
                ? err?.response?.data?.message
                : `Une erreur de status ${
                    err?.response?.status || 'inconnue'
                  } est survenue!`
            }`,
            showConfirmButton: false,
            timer: 2000,
          })
          setIsLoading(false)
        })
    } else {
      await axios
        .post(url, data, config)
        .then(async (response) => {
          console.log('response add', response)
          await getConditions()
          await Swal.fire({
            icon: 'success',
            title: 'Contenu ajouté avec succès',
            iconColor: '#c501ff',
            showConfirmButton: false,
            timer: 1500,
          })
          await setIsLoading(false)
        })
        .catch((err) => {
          console.log('error', err?.response)
          setIsLoading(false)
          Swal.fire({
            icon: 'error',
            title: `${
              err?.response?.data?.message
                ? err?.response?.data?.message
                : `Une erreur de status ${
                    err?.response?.status || 'inconnue'
                  } est survenue!`
            }`,
            showConfirmButton: false,
            timer: 2000,
          })
        })
    }
  }
  return (
    <div>
      <div className="table-container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row mb-4">
            <div className="col-12 form-group">
              <label htmlFor="desc-mision" className="admin-form-label">
                Contenu
              </label>
              <ReactQuill
                name="text"
                value={contenue}
                onChange={(value) => handleChange(value)}
              />
              {formState?.errors && formState?.errors?.text && (
                <div className="alert alert-danger epm-alert-danger closer m-t-10">
                  {formState?.errors?.text?.message}
                </div>
              )}
            </div>
          </div>
          <div className="btn-container gap-3">
            <button
              className="admin-btn-add admin-btn-typo"
              type="submit"
              disabled={isLoading}
            >
              {!isLoading ? (
                'Enregistrer'
              ) : (
                <>
                  En cours&nbsp;
                  <Loader />
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CguAdmin
