import React, { Component, useEffect, useState } from "react";
import "./productItem4Items.css";
import FeatherIcon from "feather-icons-react";
import api from "../../http/global-vars";
import baseUrl from "../../http/backend-base-url";
import { Rate } from "rsuite";
import axios from "axios";
import MandatoryLoginProductItem from "../mandatoryLoginProductItem/index";
import MandatoryLoginFavoris from "../mandatoryLoginFavoris/index";
import { addToCart } from "../reduxCart/cartActions";
import { connect, useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ProductItem4ItemsSkeleton from "../skeleton/ProductItem4ItemsSkeleton/ProductItem4ItemsSkeleton";
import ModalConfirmationAddingOnCart from "../modalConfirmationAddingOnCart/index";
import Avatar from "react-avatar";
import { NavLink } from "react-router-dom";
import { fetchProducts } from "../../productAction/productAction";
import { useGetProductListQuery } from "../../utils/api/product.api";
import {
  useAddOrDeleteFavoriteMutation,
  useLazyGetFavoriteListQuery,
} from "../../utils/api/favoris.api";
import Swal from "sweetalert2";
import {
  addCartProduct,
  getCartCount,
  getSubTotal,
  getTotalAmount,
} from "../../utils/api/slice/useCartSlice";

export const ProductItem = ({ product, favorites = [] }) => {
  const [show, setShow] = useState(false);
  const { user, token } = useSelector((s) => s?.user);
  const dispatch = useDispatch();
  const [toggle, { isSuccess, isError, error }] =
    useAddOrDeleteFavoriteMutation();
  let item = favorites?.find((el) => parseInt(el?.product?.id) === product?.id);

  useEffect(() => {
    // window.scFade(0, 10);
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: item
          ? `Ce produit a été supprimé de vos favories avec succès!`
          : `Ce produit a été ajouté à vos favories avec succès!`,
        iconColor: "#c501ff",
        showConfirmButton: false,
        timer: 3000,
      });
    }

    if (isError) {
      Swal.fire({
        icon: "error",
        title: error?.data?.message
          ? error?.data?.message
          : `Une erreur de statut ${error?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [error, isError, isSuccess]);

  const closeConformation = () => {
    setShow(false);
  };
  const openConfirmation = () => {
    setShow(true);
  };

  const handleClick = (e, product) => {
    e.preventDefault();
    // dispatch(addToCart(product));
    dispatch(
      addCartProduct({
        product,
        qty: 1,
        selectedPointure: "",
        selectedSize: "",
      })
    );
    dispatch(getCartCount());
    dispatch(getSubTotal());
    // dispatch(calculateTax());
    dispatch(getTotalAmount());

    openConfirmation();
    setTimeout(() => {
      closeConformation();
    }, 10000);
  };

  const handleToggle = async () => {
    await toggle({ id: item?.id, product: product?.id, user: user?.id });
  };

  return (
    <div
      className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6 px-sm-2 px-1"
      key={product?.id}
    >
      <div className="product-card-item">
        <NavLink
          className="no-decoration"
          to={{
            pathname: `/details-produit/${product?.id}`,
            state: product,
          }}
        >
          <div className="product-card-item-half-top">
            <div className="content-img-product-card-item">
              {product?.image?.length ? (
                <img
                  loading="lazy"
                  className="img-product-card-item"
                  src={baseUrl + product?.image[0]?.image_medium}
                  alt={product?.name}
                />
              ) : (
                <Avatar
                  className="img-product-card-item"
                  color={Avatar.getRandomColor("sitebase", [
                    "#7b1cc357",
                    "#7b1cc357",
                    "#7b1cc357",
                  ])}
                  name={product?.name}
                />
              )}
            </div>
            <p className="name-product-card-item">{product?.name}</p>
            <p className="price-product-card-item">
              {Intl.NumberFormat("fr-FR").format(parseInt(product?.price))} FCFA
            </p>

            <Rate
              value={product?.averagestar}
              readOnly
              allowHalf
              className="rating-custom-product-item"
            />
          </div>
        </NavLink>
        {token ? (
          item ? (
            <div className="lh-0">
              <button
                className="btn-add-favoris-product-card-item"
                onClick={() => handleToggle()}
              >
                <FavoriteIcon className="whishlist-icon" />
              </button>
            </div>
          ) : (
            <div className="lh-0">
              <button
                onClick={(e) => handleToggle()}
                className="btn-add-favoris-product-card-item"
              >
                <FeatherIcon className="whishlist-icon" icon="heart" />
              </button>
            </div>
          )
        ) : (
          <MandatoryLoginFavoris />
        )}
        {token ? (
          product?.quantity === 0 ||
          parseInt(user?.id) === parseInt(product?.provider?.user?.id) ? (
            <button disabled className="btn-product-card-item-add-card">
              <FeatherIcon
                className="icon-btn-product-card-item-add-card"
                icon="shopping-cart"
              />
              Ajouter au panier
            </button>
          ) : (
            <button
              onClick={(e) => handleClick(e, product)}
              className="btn-product-card-item-add-card"
            >
              <FeatherIcon
                className="icon-btn-product-card-item-add-card"
                icon="shopping-cart"
              />
              Ajouter au panier
            </button>
          )
        ) : (
          <MandatoryLoginProductItem />
        )}
      </div>
      {/* Modal ajout panier  */}
      <ModalConfirmationAddingOnCart
        openConfirmation={openConfirmation}
        closeConformation={closeConformation}
        showConfirmation={show}
      />
    </div>
  );
};

function ProductItem4Items() {
  const { user } = useSelector((s) => s?.user);
  const [getFavorite, { data: favorites = [], isLoading: loading }] =
    useLazyGetFavoriteListQuery();
  const { data = { results: [] }, isLoading } = useGetProductListQuery({
    limit: 4,
  });
  useEffect(() => {
    if (user?.id) {
      getFavorite(user?.id);
    }
  }, [user]);

  return (
    <>
      {!!isLoading && <ProductItem4ItemsSkeleton />}
      {!isLoading &&
        (data?.results?.length ? (
          <div className="row m-b-50 px-2">
            {data?.results?.map((product) => (
              <ProductItem
                product={product}
                key={product?.id}
                favorites={favorites}
              />
            ))}
          </div>
        ) : (
          <div className="content-item-card-body px-4">
            <div className="alert alert-info marketplace-alert-info empty-badge-alert">
              <div className="row empty-alert-row">
                <div className="col-2 empty-icon-col">
                  <FeatherIcon
                    icon="alert-circle"
                    className="m-b-3 empty-alert-icon rotateIn"
                  />
                </div>
                <div className="col-10 empty-textual-col">
                  <p> Aucun produit disponible!</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      {/* Modal ajout panier  */}
      {/* <ModalConfirmationAddingOnCart
        openConfirmation={this.openConfirmation}
        closeConformation={this.closeConformation}
        showConfirmation={this.state.showConfirmation}
      /> */}
    </>
  );
}

export default ProductItem4Items;
