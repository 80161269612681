import React, { Component } from "react";
import "./similaryProduct.css";
import ProductItemSimilary from "../productItemSimilary/index";

export default class similaryProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  onGetSimilaryProduct = () => {};

  render() {
    return (
      <div className="plus-recent-card">
        <div className="row m-b-30">
          <div className="col-xl-3 col-lg-4 col-md-5 col-sm-6 col-xs-5 pr-sm-0 ">
            <h4 className="h-theme">Produits similaires</h4>
          </div>
          <div className="col-xl-9 col-lg-8 col-md-7 col-sm-6 col-xs-7 dis-flex m-b-15 pl-sm-0">
            <span className="trait-titre-component-dark"></span>
          </div>
        </div>
        <ProductItemSimilary id={this.props.id} />
      </div>
    );
  }
}
