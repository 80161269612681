import React, { Component } from "react";
import "./navVerticalDashboardAdmin.css";
import { Sidenav, Nav, Badge } from "rsuite";
import { NavLink } from "react-router-dom";
import AssessmentIcon from "@material-ui/icons/Assessment";
import SettingsIcon from "@material-ui/icons/Settings";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import Store from "@material-ui/icons/Store";
import Category from "@material-ui/icons/Category";
import {
  Build,
  Message,
  People,
  PeopleAltTwoTone,
  Book,
} from "@material-ui/icons";
import { useGetProductListAdminQuery } from "../../../utils/api/product.api";
import { useGetShopListQuery } from "../../../utils/api/shop.api";
import { useGetOrderListQuery } from "../../../utils/api/order.api";

const NavItemProduct = () => {
  const { data = { count: 0 } } = useGetProductListAdminQuery({limit:1});
  return (
    <Badge className="badge-nav-vertical-dashboard" content={data?.count}>
      Articles
    </Badge>
  );
};

const NavItemShop = () => {
  const { data = { count: 0 } } = useGetShopListQuery({limit:1});
  return (
    <Badge className="badge-nav-vertical-dashboard" content={data?.count}>
      Boutiques
    </Badge>
  );
};

const NavItemOrder = () => {
  const {
    data = {
      count: 0,
    },
  } = useGetOrderListQuery({limit:1});
  return (
    <Badge className="badge-nav-vertical-dashboard" content={data?.count}>
      Commandes
    </Badge>
  );
};

class navVerticalDashboardAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    setTimeout(() => {
      // this.props.fetchOrders();
      // this.props.fetchProducts();
      // this.props.fetchShops();
    }, 1000);
  }

  render() {

    return (
      <div className="component-nav-vertical-dashboard">
        <Sidenav activeKey="1">
          <Sidenav.Body>
            <Nav>
              <NavLink
                className="no-decoration"
                to="/dashboard-admin"
                activeClassName="actived"
              >
                <Nav.Item
                  className="link-item-sidenav-dashboard"
                  eventKey="1"
                  icon={
                    <AssessmentIcon className="icon-item-sidenav-dashboard" />
                  }
                >
                  Dashboard
                </Nav.Item>
              </NavLink>
              <NavLink
                className="no-decoration"
                to="/boutiques-admin"
                activeClassName="actived"
              >
                <Nav.Item
                  className="link-item-sidenav-dashboard"
                  eventKey="2"
                  icon={<Store className="icon-item-sidenav-dashboard" />}
                >
                  <NavItemShop />
                </Nav.Item>
              </NavLink>
              <NavLink
                className="no-decoration"
                to="/articles-admin"
                activeClassName="actived"
              >
                <Nav.Item
                  className="link-item-sidenav-dashboard"
                  eventKey="3"
                  icon={
                    <ViewModuleIcon className="icon-item-sidenav-dashboard" />
                  }
                >
                  <NavItemProduct />
                </Nav.Item>
              </NavLink>
              <NavLink
                className="no-decoration"
                to="/categories-admin"
                activeClassName="actived"
              >
                <Nav.Item
                  className="link-item-sidenav-dashboard"
                  eventKey="3"
                  icon={<Category className="icon-item-sidenav-dashboard" />}
                >
                  Catégories
                </Nav.Item>
              </NavLink>

              <NavLink
                className="no-decoration"
                to="/commandes-admin"
                activeClassName="actived"
              >
                <Nav.Item
                  className="link-item-sidenav-dashboard"
                  eventKey="5"
                  icon={
                    <ShoppingBasketIcon className="icon-item-sidenav-dashboard" />
                  }
                >
                  <NavItemOrder />
                </Nav.Item>
              </NavLink>
              <NavLink
                className="no-decoration"
                to="/transactions"
                activeClassName="actived"
              >
                <Nav.Item
                  className="link-item-sidenav-dashboard"
                  eventKey="6"
                  icon={
                    <AccountBalanceWalletIcon className="icon-item-sidenav-dashboard" />
                  }
                >
                  Transactions
                </Nav.Item>
              </NavLink>
              <NavLink
                className="no-decoration"
                to="/mode-payment-delivery-admin"
                activeClassName="actived"
              >
                <Nav.Item
                  className="link-item-sidenav-dashboard"
                  eventKey="3"
                  icon={<Build className="icon-item-sidenav-dashboard" />}
                >
                  Modes Paiement/livraison
                </Nav.Item>
              </NavLink>
              <NavLink
                className="no-decoration"
                to="/utilisateurs"
                activeClassName="actived"
              >
                <Nav.Item
                  className="link-item-sidenav-dashboard"
                  eventKey="3"
                  icon={
                    <PeopleAltTwoTone className="icon-item-sidenav-dashboard" />
                  }
                >
                  Utilisateurs
                </Nav.Item>
              </NavLink>
              <NavLink
                className="no-decoration"
                to="/administrateurs"
                activeClassName="actived"
              >
                <Nav.Item
                  className="link-item-sidenav-dashboard"
                  eventKey="3"
                  icon={<People className="icon-item-sidenav-dashboard" />}
                >
                  Administrateurs
                </Nav.Item>
              </NavLink>
              <NavLink
                className="no-decoration"
                to="/messages-contact"
                activeClassName="actived"
              >
                <Nav.Item
                  className="link-item-sidenav-dashboard"
                  eventKey="3"
                  icon={<Message className="icon-item-sidenav-dashboard" />}
                >
                  Messages
                </Nav.Item>
              </NavLink>
              <NavLink
                className="no-decoration"
                to="/gestion-des-conditions"
                activeClassName="actived"
              >
                <Nav.Item
                  className="link-item-sidenav-dashboard"
                  eventKey="3"
                  icon={<Book className="icon-item-sidenav-dashboard" />}
                >
                  Conditions
                </Nav.Item>
              </NavLink>
              <NavLink
                className="no-decoration"
                to="/parametres-admin"
                activeClassName="actived"
              >
                <Nav.Item
                  className="link-item-sidenav-dashboard"
                  eventKey="4"
                  icon={
                    <SettingsIcon className="icon-item-sidenav-dashboard" />
                  }
                >
                  Paramètres
                </Nav.Item>
              </NavLink>
            </Nav>
          </Sidenav.Body>
        </Sidenav>
      </div>
    );
  }
}

export default navVerticalDashboardAdmin;
