import React, {useEffect} from 'react'
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import FeatherIcon from "feather-icons-react";
import {useSelector, useDispatch} from 'react-redux'
import { fetchUsers } from '../../../usersRedux/usersAction';

function UtilisateursTable() {
    const users = useSelector((state) => state?.users)
    const dispatch = useDispatch()

    useEffect(() => {
      dispatch(fetchUsers());
    }, [dispatch]);
    const options = {
      noDataText: "Aucune donnée disponible",
    };
  return (
    <div>
      <div className="table-container">
        <BootstrapTable
          className="head-table-custom"
          options={options}
          data={users?.items}
          search={true}
          multiColumnSearch={true}
          striped
          hover
          multiColumnSort={2}
          pagination
          version="4"
        >
          <TableHeaderColumn
            isKey
            dataField="id"
            hidden={true}
            tdStyle={{
              fontWeight: 400,
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.7)",
              borderTop: "none",
              borderBottom: "none",
              padding: "1rem",
              whiteSpace: "normal",
              lineHeight: 1,
            }}
            thStyle={{ textAlign: "center" }}
            className="th-table-custom"
          >
            N°
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="first_name"
            width="150"
            tdStyle={{
              fontWeight: 400,
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.7)",
              borderTop: "none",
              borderBottom: "none",
              padding: "1rem",
              whiteSpace: "normal",
              lineHeight: 1,
            }}
            dataAlign="center"
            // thStyle={{ textAlign: "center" }}
            className="th-table-custom"
          >
            Prénom
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="last_name"
            width="150"
            tdStyle={{
              fontWeight: 400,
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.7)",
              borderTop: "none",
              borderBottom: "none",
              padding: "1rem",
              whiteSpace: "normal",
              lineHeight: 1,
            }}
            dataAlign="center"
            // thStyle={{ textAlign: "center" }}
            className="th-table-custom"
          >
            Nom
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="email"
            className="th-table-custom"
            width="250"
            // thStyle={{ textAlign: "center" }}
            dataAlign="center"
            tdStyle={{
              fontWeight: 400,
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.7)",
              borderTop: "none",
              borderBottom: "none",
              padding: "1rem",
              whiteSpace: "normal",
              // textAlign: "center",
            }}
          >
            Email
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="phone"
            className="th-table-custom"
            width="150"
            // thStyle={{ textAlign: "center" }}
            dataAlign="center"
            tdStyle={{
              fontWeight: 400,
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.7)",
              borderTop: "none",
              borderBottom: "none",
              padding: "1rem",
              // textAlign: "center",
            }}
          >
            Téléphone
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="adresse"
            className="th-table-custom"
            width="200"
            // thStyle={{ textAlign: "center" }}
            dataAlign="center"
            tdStyle={{
              fontWeight: 400,
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.7)",
              borderTop: "none",
              borderBottom: "none",
              padding: "1rem",
              whiteSpace: "normal",
              // textAlign: "center",
            }}
          >
            Adresse
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="user_type"
            className="th-table-custom"
            width="150"
            // thStyle={{ textAlign: "center" }}
            dataAlign="center"
            tdStyle={{
              fontWeight: 400,
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.7)",
              borderTop: "none",
              borderBottom: "none",
              padding: "1rem",
              // textAlign: "center",
            }}
            dataFormat={(cell, row) =>
              cell === "vendeur" ? "Vendeur" : "Client"
            }
          >
            Type
          </TableHeaderColumn>
          {/* <TableHeaderColumn
            className="th-table-custom"
            // dataFormat={this.actionFormatter}
            dataField="id"
            width="150"
            dataAlign="center"
            // thStyle={{ textAlign: "center" }}
            tdStyle={{
              fontWeight: 400,
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.7)",
              borderTop: "none",
              borderBottom: "none",
              padding: "1rem",
              // textAlign: "center",
            }}
          >
            Actions
          </TableHeaderColumn> */}
        </BootstrapTable>
      </div>
    </div>
  );
}

export default UtilisateursTable