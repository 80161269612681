import React, { Component } from "react";
import "./productItemSimilary.css";
import FeatherIcon from "feather-icons-react";
import api from "../../http/global-vars";
import baseUrl from "../../http/backend-base-url";
import { Rate } from "rsuite";
import axios from "axios";
import MandatoryLoginProductItem from "../mandatoryLoginProductItem/index";
import MandatoryLoginFavoris from "../mandatoryLoginFavoris/index";
import { addToCart } from "../reduxCart/cartActions";
import { connect, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ProductItem4ItemsSkeleton from "../skeleton/ProductItem4ItemsSkeleton/ProductItem4ItemsSkeleton";
import ModalConfirmationAddingOnCart from "../modalConfirmationAddingOnCart/index";
import { NavLink } from "react-router-dom";
import Avatar from "react-avatar";
import { ProductItem } from "../productItem4Items/productItem4Items";
import {
  useGetProductListQuery,
  useProductFromLocation,
} from "../../utils/api/product.api";
import { useLazyGetFavoriteListQuery } from "../../utils/api/favoris.api";

function ProductItemSimilary() {
  const [product, loading, id] = useProductFromLocation();
  const { user } = useSelector((s) => s?.user);
  const [getFavorite, { data: favorites = [] }] =
    useLazyGetFavoriteListQuery();
  const { data = { results: [] }, isLoading } = useGetProductListQuery({
    limit: 5,
    category: product?.category,
  });

  return (
    <>
      {!!isLoading && <ProductItem4ItemsSkeleton />}
      {!isLoading && (
        <div className="row m-b-50 px-2">
          {data?.results
            ?.filter((item) => item?.id !== parseInt(id))
            ?.slice(0, 4)
            ?.map((product) => (
              <ProductItem
                product={product}
                key={product?.id}
                favorites={favorites}
              />
            ))}

          {/* Modal ajout panier  */}
          {/* <ModalConfirmationAddingOnCart
      openConfirmation={this.openConfirmation}
      closeConformation={this.closeConformation}
      showConfirmation={this.state.showConfirmation}
    /> */}
        </div>
      )}
    </>
  );
}

export default ProductItemSimilary;
