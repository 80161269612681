import React, { useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import "./productItemByProvider.css";
import { useGetProductListQuery } from "../../utils/api/product.api";
import AllProductItemPage from "../allProductPage/AllProductItemPage";

function ProductItemProvider({
  query,
  setLength,
  setIsLoading,
  page,
  setPage,
}) {
  const { id } = useParams();
  const [items, setItems] = React.useState([]);
  const {
    data = { results: [], count: 0 },
    isLoading,
    refetch,
  } = useGetProductListQuery({ ...query, boutique: id, page, limit: 12 });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (!isLoading) refetch();
  }, []);

  useEffect(() => {
    setLength(data?.count);
    setIsLoading(isLoading);
  }, [data, isLoading]);


  React.useEffect(() => {
    if (data?.results) {
      setItems((prevState) => {
        if (page === 1) {
          return data?.results;
        }
        return [...prevState, ...data?.results];
      });
    }
  }, [data]);

  return (
    <div className="w-100">
      <AllProductItemPage
        data={data}
        items={items}
        refetch={refetch}
        isLoading={isLoading}
        setPage={setPage}
      />
    </div>
  );
}

export default ProductItemProvider;
