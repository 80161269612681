import React, { Component } from "react";
import { Icon, Loader, Modal } from "rsuite";

export default class DeleteCategoryModal extends Component {
  render() {
    return (
      <div className="modal-container">
        <Modal
          size="xs"
          show={this.props.show}
          onHide={this.props.handleClose}
          className="rsuite-content-modal-custom"
        >
          <form>
            <Modal.Header>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <div className="my-5 flex-col itm-center">
              <Icon
                size="5x"
                className="icon-confirmation-modal mb-4"
                icon="exclamation-circle2"
              />
              {/* <img loading="lazy"
                src={ConfirmationIcon}
                alt=""
                className="icon-confirmation-modal mb-4"
              /> */}
              <p className="title-confirmation-modal my-4">
                Êtes-vous sûr de vouloir supprimer cette{" "}
                {this.props?.from === "sub" ? "sous catégorie" : "catégorie"} ?
              </p>
            </div>
            <Modal.Footer className="text-center">
              <div className="row px-5">
                <div className="col-6 mb-2">
                  <button
                    onClick={(e) => this.props.handleClose(e)}
                    className="btn-theme"
                  >
                    Annuler
                  </button>
                </div>
                <div className="col-6 mb-2">
                  {!this.props.archivageInProgress && (
                    <button
                      onClick={(e) => this.props.onDeleteCategory(e)}
                      className="btn-light-theme"
                    >
                      Confirmer
                    </button>
                  )}

                  {this.props.archivageInProgress && (
                    <button
                      onClick={(e) => this.props.onDeleteCategory(e)}
                      className="btn-light-theme"
                    >
                      <Loader />
                    </button>
                  )}
                </div>
              </div>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    );
  }
}
