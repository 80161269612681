import React, {Component} from 'react';
import Skeleton from 'react-loading-skeleton';

export default class commentaireItem extends Component {
  constructor(props) {
      super(props);
      this.state = {};
  }

  render() {
    return  Array(3).fill().map(category => {
        return (
          <li className="item-content-commentaire-content-details-page">
            <div className="row">
              <div className="col-lg-2 col-md-3 col-sm-4 col-7 mb-3">
                <Skeleton variant="rect" height={150} className="lh-2" />
              </div>
              <div className="col-md-4 col-sm-7">
                <div className="content-titre-product-commentaire-item mb-2">
                  <Skeleton height={15} className="titre-product-commentaire-item lh-2" variant="text" />
                </div>
                <Skeleton width={80} className="rating-comment-product m-b-5" />

                <Skeleton className="commentaire-product-commentaire-item" />
                <Skeleton width={`90%`} className="auteur-product-commentaire-item" />
              </div>
            </div>
          </li>
        )
    });
  }
}