import React, { Component, useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import "./addBannerShopModal.css";
import { Modal, ButtonToolbar, Button, Message, Loader } from "rsuite";
import api from "../../../http/global-vars";
import axios from "axios";
import Swal from "sweetalert2";
import { cleannerError } from "../../../utils/utils";
import { useEditShopMutation } from "../../../utils/api/user.api";
import { useSelector } from "react-redux";

// export default class addBannerShopModal extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       api: api,
//       fields: {},
//       errors: {},
//       show: false,
//       placeholder_file: 'Importer un ficher',
//       loading: false,
//       is_empty_banner: false,
//       changeBannerShopInProgress: false,
//       isSuccessChangeBannerShop: false,
//       isErrorChangeBannerShop: false
//     };
//     this.close = this.close.bind(this);
//     this.open = this.open.bind(this);
//     this.handleChange = this.handleChange.bind(this);
//   }

//   componentDidMount(){
//     this.setState({ loading: true });
//     const timer = setTimeout(() => {
//         this.setState({ loading: false });
//         // var shopDetails = this.props.shopDetails
//     }, 1000);
//     return () => clearTimeout(timer);
//   }

//   close() {
//     this.setState({ show: false });
//   }
//   open() {
//     this.setState({ show: true });
//   }

//   handleChange = (e) => {
//     let fields = this.state.fields

//     fields[e.target.name] = e.target.files[0]

//     this.setState({
//         fields,
//         placeholder_file: e.target.files[0].name,
//     });
//   }

//   onValidateBannerShop = () => {
//     let fields = this.props.shopDetails
//     let errors = this.state.errors
//     var isValidForm = true
//     let placeholder_file = this.state.placeholder_file

//     if(placeholder_file === "Importer un ficher"){
//       isValidForm = false
//       this.setState({
//         is_empty_banner: true
//       })
//       setTimeout(() => {
//         this.setState({
//           is_empty_banner: false
//         })
//       }, 5000);
//       errors['banner'] = 'Ce champ est obligatoire!'
//     }

//     if(!fields['banner']){
//         isValidForm = false
//         this.setState({
//           is_empty_banner: true
//         })
//         setTimeout(() => {
//           this.setState({
//             is_empty_banner: false
//           })
//         }, 5000);
//         errors['banner'] = 'Ce champ est obligatoire!'
//     }
//     this.setState({
//       errors
//     })

//     return isValidForm
//   }

//   onSubmitNewBanner = (e) => {
//     e.preventDefault()
//     if(this.onValidateBannerShop()){
//       this.setState({
//         changeBannerShopInProgress: true
//       })

//       var api = this.state.api
//       var config = this.props.config
//       var data = new FormData()
//       var shopID = this.props.shopDetails.id

//       data.append('banner', this.state.fields.banner)
//       data.append('user', this.props.userID)

//       axios.put(`${api}fournisseur/${shopID}/`, data, config)
//         .then(response => {
//           if(response.status === 200 || response.status === 201){
//             window.localStorage.setItem('userFirstName', data.first_name)
//             window.localStorage.setItem('userLastName', data.last_name)
//             window.localStorage.setItem('userFullname', data.first_name + ' ' + data.last_name)

//             this.setState({
//               changeBannerShopInProgress: false,
//               isSuccessChangeBannerShop: true
//             })
//             this.props.callBackSuccessBanner(this.state.isSuccessChangeBannerShop);

//             setTimeout(() => {
//                 this.setState({
//                   isSuccessChangeBannerShop: false
//                 })
//                 window.location.reload()
//             }, 4000);
//             this.close()
//           }
//           else{
//             this.setState({
//                 isErrorChangeBannerShop: true
//             })
//             this.props.callBackErrorBanner(this.state.isErrorChangeBannerShop)

//             setTimeout(() => {
//                 this.setState({
//                     isErrorChangeBannerShop: false
//                 })
//             }, 5000);
//           }
//         })
//         .catch(error =>{
//           this.setState({
//               isErrorChangeBannerShop: true
//           })
//           this.props.callBackErrorBanner(this.state.isErrorChangeBannerShop)
//           setTimeout(() => {
//               this.setState({
//                   isErrorChangeBannerShop: false
//               })
//           }, 5000);
//         })
//     }
//   }

//   render() {
//     return (

//     );
//   }
// }

function AddBannerShopModal() {
  const validationSchema = yup.object().shape({
    banner: yup.mixed().required().label("L'image"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [placeholder, setPlaceholder] = useState("Importer un ficher");
  const [show, setShow] = useState(false);
  const [sendData, { isLoading, isSuccess, error, isError }] =
    useEditShopMutation();
  const { user } = useSelector((s) => s?.user);

  useEffect(() => {
    if (isSuccess) {
      setPlaceholder("Importer un ficher");
      close()
    }
    const err = error;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const close = () => {
    setShow(false);
  };
  const open = () => {
    setShow(true);
  };

  const handleChange = (e) => {
    let file = e.target.files[0];
    if (file) {
      setValue("banner",file);
      setPlaceholder(file?.name);
    }
  };

  const onSubmit = async (data) => {
    const fd = new FormData();

    for (let key of Object.keys(data)) {
      if (key === "logo" || key === "banner") {
        if (data[key]) {
          fd.append(key, data[key]);
        }
      } else {
        fd.append(key, data[key]);
      }
    }
    await sendData({
      id: user?.provider?.id,
      data: fd,
    });
  };
  return (
    <div className="">
      <ButtonToolbar>
        <Button className="ajouter-btn-modal" onClick={() => open()}>
          Ajouter
        </Button>
      </ButtonToolbar>

      <Modal
        size="xs"
        show={show}
        onHide={() => close()}
        className="rsuite-content-modal-custom"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header>
            <Modal.Title>Ajouter une bannière à la boutique</Modal.Title>
          </Modal.Header>
          <div>
            <div className="custom-file mt-5 mb-5">
              <input
                type="file"
                name="banner"
                className="custom-file-input input-custom-import-file"
                id="addBanner"
                onChange={(e) => handleChange(e)}
              />
              <label
                className="custom-file-label label-custom-import-file"
                htmlFor="addBanner"
              >
                {placeholder}
              </label>
              {errors?.banner && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={errors?.banner?.message}
                />
              )}
            </div>
          </div>
          <Modal.Footer className="text-center mt-4">
            <button
              type="submit"
              className="btn-change-password"
              disabled={isLoading}
            >
              {" "}
              {!!isLoading && (
                <>
                  EN COURS &nbsp;
                  <Loader />
                </>
              )}{" "}
              {!isLoading && "Terminer"}{" "}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}

export default AddBannerShopModal;
