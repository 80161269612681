import React, {Component} from 'react';
import './mandatoryLoginEvaluateProduct.css';
import { Modal, ButtonToolbar } from 'rsuite';
import StarIcon from '@material-ui/icons/Star';
import {NavLink} from 'react-router-dom';

export default class mandatoryLoginEvaluateProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
}

close = () => {
  this.setState({ show: false });
}
open = () => {
  this.setState({ show: true });
}

render() {
  return  <div className="component-mandatory-login-product-item">
      <ButtonToolbar>
        <div className="pos-relative">
          <span  onClick={this.open} className="btn-evaluate-product-modal">
            <StarIcon className="icon-btn-evaluate-product-modal" />
            évaluer le produit
          </span>
        </div>
      </ButtonToolbar>
      
      <Modal size="xs" show={this.state.show} onHide={this.close} className="theme-light-rs-modal mandatory-login">
        <div className="row dis-flex justify-content-center m-b-70">
          <div className="col text-center text-dark fs-13 ff-raleway fw-300">
            Vous avez déjà un compte? <br/>
            Connectez-vous pour effectuer vos achats
          </div>
        </div>

        <NavLink className="mandatory-login-btn btn-form-dark" to={`/connexion/?next=${window.location.pathname}`} >Connexion</NavLink>

        <p className="fs-13 lh-1 ff-raleway fw-300 text-black text-center">Première visite ? <NavLink className="link-form-signup-login h-theme fs-13" to="/inscription">Inscrivez-vous</NavLink> en quelques clics ! </p>
      </Modal>
    </div>;
  }
}