import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import "./cartCounter.css";

function CartCounter() {
  const { totalCount } = useSelector((s) => s?.cart);
  const { token } = useSelector((s) => s?.user);
  return (
    <NavLink className="link-header-item pos-relative" to="/checkout">
      <FeatherIcon className="icon-item-header" icon="shopping-cart" />
      <p className="text-item-header">Panier</p>

      {!!token && (
        <div className="amount-container">
          <p className="total-amount">{totalCount || 0}</p>
        </div>
      )}
    </NavLink>
  );
}

export default CartCounter;
