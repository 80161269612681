import React, { useState, useEffect } from "react";
import { useLocation, useHistory, NavLink } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import FeatherIcon from "feather-icons-react";
import ReactAudioPlayer from "react-audio-player";
import { Rate, Message, Modal } from "rsuite";
import ContentProductDetailsSkeleton from "../../skeleton/contentProductDetailsSkeleton/contentProductDetailsSkeleton";
import baseUrl from "../../../http/backend-base-url";
import axios from "axios";
import api from "../../../http/global-vars";

function ContentProductAdmin() {
  const [loading, setLoading] = useState(true);
  const [imagesProducts, setImagesProducts] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const [product, setProduct] = useState({});
  const userType = window.localStorage.getItem("userType");
  const userID = window.localStorage.getItem("userID");
  const [provider, setProvider] = useState({});
  const [evaluationProduct, setEvaluationProduct] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location?.state?.product?.id) {
      setImagesProducts(
        location?.state?.product?.image?.map((el) => {
          return {
            original: baseUrl + el?.image_high,
            thumbnail: baseUrl + el?.image_small,
          };
        })
      );
      setProduct(location?.state?.product);
      setProvider(location?.state?.product?.provider);
      console.log(location);
      setLoading(false);
      onGetEvaluationByProduct(location?.state?.product?.id);
    } else {
      setTimeout(() => {
        history.goBack();
      }, 100);
    }

    return () => {
      setProduct({});
    };
  }, [location]);

  const onGetEvaluationByProduct = async (id) => {
    axios
      .get(api + `commentairebyproduct/${id}/`)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          
          setEvaluationProduct(response.data)

        }
      })
      .catch((error) => {
        if (error) {
        //   this.setState({ loading: false });
        console.log(error)
        }
      });
  };
  if (loading === true) {
    return <ContentProductDetailsSkeleton />;
  }
  return (
    <div className="content-details-product p-lg-5 p-md-5 p-4 m-b-15">
      <div className="row m-b-100">
        <div className="col-xl-6 col-lg-7 col-md-6 m-b-50">
          <ImageGallery items={imagesProducts} />
        </div>
        <div className="col-xl-6 col-lg-5 col-md-6">
          <div className="row px-2 mb-3">
            <div className="col-xl-6 col-lg-7 col-md-7 col-sm-6 px-2 mb-2">
              <NavLink
                to={{
                  pathname:
                    userType === "admin"
                      ? `/details-boutique-admin/${provider?.id}`
                      : parseInt(userID) !== parseInt(provider?.user)
                      ? `/boutique/${provider?.id}`
                      : "",
                  state: provider,
                }}
                className="name-shop-content-details-product h-theme no-link"
              >
                <FeatherIcon
                  className="icon-name-shop-content-details-product"
                  icon="home"
                />
                {provider?.shop_name}
              </NavLink>
            </div>
            <div className="col-xl-3 col-lg-5 col-md-5 col-sm-3 mb-2 px-2">
              {parseInt(userID) === parseInt(provider?.user) ? null : (
                <NavLink
                  className="link-name-shop-content-details-product"
                  to={{
                    pathname:
                      userType === "admin"
                        ? `/details-boutique-admin/${provider?.id}`
                        : parseInt(userID) !== parseInt(provider?.user)
                        ? `/boutique/${provider?.id}`
                        : "",
                    state: provider,
                  }}
                >
                  Visiter la boutique
                </NavLink>
              )}
            </div>
          </div>

          <p className="name-product-content-details-product">{product.name}</p>

          <Rate
            value={product.averagestar}
            readOnly
            allowHalf
            className="rating-custom-medium"
          />

          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-6 m-b-15">
              <p className="price-content-details-product">
                {Intl.NumberFormat("fr-FR").format(parseInt(product?.price))}{" "}
                <span className="fw-300">FCFA</span>
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-8 col-lg-10 pr-md-0 col-md-11 col-sm-8">
              {product.quantity <= 30 ? (
                product.quantity === 0 ? (
                  <p className="product-quantity-message">
                    Produit indisponible!
                  </p>
                ) : (
                  <p className="product-quantity-message">
                    {product.quantity} exemplaires disponible(s)
                  </p>
                )
              ) : (
                <></>
              )}

              <h5 className="text-theme mt-5 mb-3">Description</h5>

              {product?.description_audio ? (
                <ReactAudioPlayer
                  className="audio-lecteur-content"
                  src={`${baseUrl}${product?.description_audio}`}
                  controls
                />
              ) : (
                <Message
                  type="warning"
                  className="fs-13"
                  description="Il n'y a pas de description audio pour ce produit!"
                />
              )}

              {/* <div className="m-t-40">
                {parseInt(userID) === parseInt(provider?.user) ||
                userType === "admin" ? null : this.state.is_user_connected ===
                  true ? (
                  product.quantity === 0 ? (
                    <form>
                      <button
                        disabled
                        onClick={(e) => this.onAddToCart(e, product)}
                        className="btn-theme-add-to-cart m-b-75"
                      >
                        <FeatherIcon
                          className="icon-btn-theme-add-to-cart"
                          icon="shopping-cart"
                        />
                        Acheter
                      </button>
                    </form>
                  ) : (
                    <form>
                      <button
                        onClick={(e) => this.onAddToCart(e, product)}
                        className="btn-theme-add-to-cart m-b-75"
                      >
                        <FeatherIcon
                          className="icon-btn-theme-add-to-cart"
                          icon="shopping-cart"
                        />
                        Acheter
                      </button>
                    </form>
                  )
                ) : (
                  <MandatoryLogin />
                )}
              </div> */}

              <div className="ligne-theme"></div>
              <h6 className="text-black ff-raleway fw-700 m-b-10">
                Informations sur le vendeur
              </h6>
              <div className="row">
                <div className="col-sm-7">
                  <NavLink
                    to={{
                      pathname:
                        userType === "admin"
                          ? `/details-boutique-admin/${provider?.id}`
                          : parseInt(userID) !== parseInt(provider?.user)
                          ? `/boutique/${provider?.id}`
                          : "",
                      state: provider,
                    }}
                    className="name-info-vendeur-content-details-product fs-18 lh-1 no-link"
                  >
                    {provider?.shop_name}
                  </NavLink>
                  <p className="name-info-vendeur-content-details-product fs-12 mb-3">
                    {provider?.number_of_subscribers} abonnés
                  </p>
                </div>
                <div className="col-sm-5 px-md-0 dis-flex">
                  {parseInt(userID) === parseInt(provider?.user) ? null : (
                    <NavLink
                      className="see-shop-info-vendeur-content-details-product ml-md-auto"
                      to={{
                        pathname:
                          userType === "admin"
                            ? `/details-boutique-admin/${provider?.id}`
                            : parseInt(userID) !== parseInt(provider?.user)
                            ? `/boutique/${provider?.id}`
                            : "",
                        state: provider,
                      }}
                    >
                      Voir sa boutique
                      <FeatherIcon
                        className="icon-see-shop-info-vendeur-content-details-product ml-xl-3 ml-md-1"
                        icon="arrow-right"
                      />
                    </NavLink>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-md-5">
        <div className="col-md-11">
          <h5 className="h-theme">Détails</h5>
          <p
            className="details-content-details-product"
            dangerouslySetInnerHTML={{ __html: product.description }}
          />
        </div>
      </div>

      <div className="my-5">
        <h5 className="h-theme mb-3">Avis du produit</h5>
        {!evaluationProduct?.length ? (
          <div className="pl-0 mb-3 col-xl-6 col-lg-6 col-md-6 col-sm-8">
            <div className="alert alert-info marketplace-alert-info empty-badge-alert">
              <div className="row empty-alert-row">
                <div className="col-2 empty-icon-col">
                  <FeatherIcon
                    icon="alert-circle"
                    className="m-b-3 empty-alert-icon rotateIn"
                  />
                </div>
                <div className="col-10 empty-textual-col">
                  <p> Ce produit n'a pas encore été évaluer!</p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="mb-3">
            {evaluationProduct?.map((evaluation) => (
              <div
                className="pl-0 mb-3 col-xl-4 col-lg-6 col-md-6 col-sm-8"
                key={evaluation?.id}
              >
                <p className="fullname-user-evaluation">
                  {evaluation?.user?.first_name + " " + evaluation?.user?.last_name}
                </p>
                <Rate
                  value={evaluation?.star}
                  allowHalf
                  readOnly
                  className="rating-user-evaluation"
                />
                <p className="commentaire-user-evaluation">{evaluation?.text}</p>
              </div>
            ))}
          </div>
        )}
      </div>

     
    </div>
  );
}

export default ContentProductAdmin;
