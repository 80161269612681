import React, { useState,useMemo } from "react";
import './allShopPage.css';
import Header from '../header/index';
import Footer from '../footer/index';
import ShopCard from '../shopCard/index';
import { NavLink,useLocation,useHistory } from 'react-router-dom';
import SortShop from './SortShop';
import { QueryUrl } from "../../utils/utils";

function AllShopPage() {
  const { search } = useLocation();
  const history = useHistory()

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const [query, setQuery] = React.useState({
    order: searchParams?.get("order") ? searchParams?.get("order") : "",
  });
  const [tri, setTri] = useState(searchParams?.get("order") || "");
  React.useEffect(() => {
    let params = QueryUrl("", query);
    history.replace({ search: params });
  }, [query]);
  return (
    <div>
      <Header />
      <div className="body-theme p-t-15 p-b-45 fixed-after-header">
        <div className="container">
          <p className="m-t-7 mb-3 dis-inline-block">
            <NavLink to="/" className="routing-link">
              Accueil
            </NavLink>{" "}
            /<span className="routing-link"> Toutes les boutiques</span>
          </p>
        </div>

        <div className="container-fluid px-lg-5">
          <div className="card-content mx-lg-5">
            <div className="d-flex align-items-start">
              <h4 className="h-theme ff-raleway fw-600 mb-5">
                Toutes les boutiques
              </h4>
              <div className="pl-lg-4 ml-auto">
                <SortShop setTri={setTri} tri={tri} setQuery={setQuery} query={query} />
              </div>
            </div>

            <div className="row m-b-50">
              <ShopCard tri={tri} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AllShopPage
