import React, { Component } from "react";
import "./productItemByCategory.css";
import FeatherIcon from "feather-icons-react";
import FavoriteIcon from "@material-ui/icons/Favorite";
import api from "../../http/global-vars";
import baseUrl from "../../http/backend-base-url";
import axios from "axios";
import MandatoryLoginProductItem from "../mandatoryLoginProductItem/index";
import MandatoryLoginFavoris from "../mandatoryLoginFavoris/index";
import { addToCart } from "../reduxCart/cartActions";
import { connect } from "react-redux";
import { Rate } from "rsuite";
import { ToastContainer, toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import ProductItemSkeleton from "../skeleton/ProductItemSkeleton/ProductItemSkeleton";
import {
  fetchProducts,
  FilterCategory,
} from "../../productAction/productAction";
import ModalConfirmationAddingOnCart from "../modalConfirmationAddingOnCart/index";
import Avatar from "react-avatar";
import { NavLink } from "react-router-dom";

class productItemByCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      userID: window.localStorage.getItem("userID"),
      api: api,
      productByCategory: [],
      baseUrl: baseUrl,
      is_user_connected: false,
      loading: false,
      favorisInfos: {},
      isSuccessFavoris: false,
      isErrorFavoris: false,
      productWhishlist: [],
      limit: 15,
      productFiltered: [],
      categories: [],
      subs: [],
      showConfirmation: false,
    };
    this.closeConformation = this.closeConformation.bind(this);
    this.openConfirmation = this.openConfirmation.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.props.onRef(this);

    this.props.fetchProducts();
    let cat = [];
    if (this.props.categoryId && this.props.categories?.length) {
      cat.push(parseInt(this.props.categoryId));
      let item = this.props.categories.find(
        (el) => parseInt(el?.id) === parseInt(this.props.categoryId)
      );

      if (item?.id) {
        cat = [...cat, ...item?.subcategories?.map((el) => parseInt(el?.id))];
      }
    }
    const timer = setTimeout(() => {
      this.props.FilterCategory(
        this.props.products,
        cat,
        this.props.categories
      );
      this.productFromRedux();
      this.onGetProductItemByCategory();
    }, 1500);
    // Cancel the timer while unmounting
    return () => clearTimeout(timer);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.categoryId !== this.props.categoryId ||
      this.props.categories !== prevProps?.categories
    ) {
      this.setState({ loading: true });
      let cat = [];
      this.props.fetchProducts();
      if (this.props.categoryId && this.props.categories?.length) {
        cat.push(parseInt(this.props.categoryId));
        let item = this.props.categories.find(
          (el) => parseInt(el?.id) === parseInt(this.props.categoryId)
        );

        if (item?.id) {
          cat = [...cat, ...item?.subcategories?.map((el) => parseInt(el?.id))];
        }
      }
      const timer = setTimeout(() => {
        this.props.FilterCategory(
          this.props.products,
          cat,
          this.props.categories
        );
        this.productFromRedux();
        this.onGetProductItemByCategory();
        // this.setState({ loading: false });
      }, 1500);

      // Cancel the timer while unmounting
      return () => clearTimeout(timer);
    }
  }

  onGetProductItemByCategory = () => {
    var api = this.state.api;
    var categoryID = this.props.categoryId;

    let url = api + `productbycategory/${categoryID}`;

    axios
      .get(url)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          console.log(response);
          this.setState({
            productByCategory: response.data,
          });
          this.getWishlistByUser();
          var token = this.state.token;
          if (token) {
            this.setState({
              is_user_connected: true,
            });
          } else {
            this.setState({
              is_user_connected: false,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  fetchMoreData = () => {
    if (this.props.filteredCategory?.length > this.state.limit) {
      this.setState({
        limit: this.state.limit + 6,
      });
    }
    /* setTimeout(() => {
      this.setState({
        limit: this.state.limit + 6
      });
    }, 1000); */
  };

  onAddToFavoris = (e, product) => {
    e.preventDefault();

    let favorisInfos = this.state.favorisInfos;
    favorisInfos["product"] = product.id;
    favorisInfos["user"] = this.state.userID;
    this.setState(favorisInfos);

    var api = this.state.api;
    var data = this.state.favorisInfos;
    var config = this.state.requestConfig;

    axios
      .post(api + "favoris/", data, config)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          // toast.success("Vous suivez cette boutique !")
          this.onGetProductItemByCategory();
          this.setState({
            isSuccessFavoris: true,
          });
          setTimeout(() => {
            this.setState({
              isSuccessFavoris: false,
            });
          }, 5000);
        } else {
          toast.error("Une erreur est survenue! Veuillez rééssayer plus tard.");
          this.setState({
            isErrorFavoris: true,
          });
          setTimeout(() => {
            this.setState({
              isErrorFavoris: false,
            });
          }, 5000);
        }
      })
      .catch((error) => {
        if (error) {
          this.setState({ loading: false });
        }
      });
  };

  getWishlistByUser = () => {
    let url = api + `favorisbyuser/${this.state.userID}/`;
    axios
      .get(url, this.state.requestConfig)
      .then((response) => {
        this.setState({ productWhishlist: response.data });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  onDeleteToFavoris = (e, productItem) => {
    e.preventDefault();

    var api = this.state.api;
    var productId = productItem.id;
    let favorisId = this.state.productWhishlist.filter(
      (f) => f.product?.id === productId
    )[0]?.id;
    var config = this.state.requestConfig;
    let url = api + `favoris/${favorisId}/`;
    axios
      .delete(url, config)
      .then((response) => {
        this.onGetProductItemByCategory();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleClick = (e, product) => {
    this.props.addM(product);

    this.openConfirmation();
    setTimeout(() => {
      this.closeConformation();
    }, 10000);
  };

  productFromRedux = () => {
    let categories = [parseInt(this.props.categoryId)];
    this.setState({ categories });
    let array = [];

    array.push(parseInt(this.props.categoryId));
    // this.props.FilterCategory(this.props.products, array, this.props.categories)
    if (
      this.props.filteredCategory !== undefined ||
      this.props.filteredCategory?.length
    ) {
      this.setState({ loading: false });
    }
  };

  closeConformation(e) {
    // e.preventDefault();
    this.setState({ showConfirmation: false });
  }
  openConfirmation(e) {
    this.setState({ showConfirmation: true });
  }

  render() {
    const { baseUrl, loading, userID } = this.state
    const {isLoading} = this.props

    let data = this.props.filteredCategory || [];
   

    if (isLoading) {
      return <ProductItemSkeleton />;
    } else {
      if (data?.length) {
        return (
          <InfiniteScroll
            dataLength={this.state.limit}
            next={this.fetchMoreData}
            hasMore={true}
            loader={
              data?.length > this.state.limit ? (
                <div className="row px-2">
                  <ProductItemSkeleton />
                </div>
              ) : null
            }
            style={{ overflow: "hidden" }}
          >
            <div className="row px-2">
              {data?.slice(0, this.state.limit).map((product) => (
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 px-sm-2 px-1">
                  <div key={product.id} className="product-card-item">
                    <NavLink
                      className="no-decoration"
                      to={`/details-produit/${product.id}`}
                    >
                      <div className="product-card-item-half-top">
                        <div className="content-img-product-card-item">
                          {product.image.length ? (
                            <div
                              className="img-product-card-item"
                              style={{
                                background: `url(${
                                  baseUrl + product.image[0]?.image_medium
                                })`,
                              }}
                            ></div>
                          ) : (
                            <Avatar
                              className="img-product-card-item"
                              color={Avatar.getRandomColor("sitebase", [
                                "#7b1cc357",
                                "#7b1cc357",
                                "#7b1cc357",
                              ])}
                              name={product.name}
                            />
                          )}
                        </div>
                        <p className="name-product-card-item">{product.name}</p>
                        <p className="price-product-card-item">
                          {Intl.NumberFormat("fr-FR").format(
                            parseInt(product?.price)
                          )}{" "}
                          FCFA
                        </p>

                        <Rate
                          value={product.averagestar}
                          allowHalf
                          readOnly
                          className="rating-custom-product-item"
                        />
                      </div>
                    </NavLink>
                    {this.state.token ? (
                      <form className="lh-0">
                        <button
                          onClick={(e) => this.onAddToFavoris(e, product)}
                          className="btn-add-favoris-product-card-item"
                        >
                          <FeatherIcon
                            className="whishlist-icon"
                            icon="heart"
                          />
                        </button>
                      </form>
                    ) : (
                      <MandatoryLoginFavoris />
                    )}

                    {this.state.token ? (
                      this.state.productWhishlist !== undefined &&
                      this.state.productWhishlist.length ? (
                        this.state.productWhishlist.some(
                          (v) => parseInt(v?.product?.id) === product?.id
                        ) ? (
                          <form className="lh-0">
                            <button
                              className="btn-add-favoris-product-card-item"
                              onClick={(e) =>
                                this.onDeleteToFavoris(e, product)
                              }
                            >
                              <FavoriteIcon className="whishlist-icon" />
                            </button>
                          </form>
                        ) : (
                          <form className="lh-0">
                            <button
                              onClick={(e) => this.onAddToFavoris(e, product)}
                              className="btn-add-favoris-product-card-item"
                            >
                              <FeatherIcon
                                className="whishlist-icon"
                                icon="heart"
                              />
                            </button>
                          </form>
                        )
                      ) : (
                        <form className="lh-0">
                          <button
                            onClick={(e) => this.onAddToFavoris(e, product)}
                            className="btn-add-favoris-product-card-item"
                          >
                            <FeatherIcon
                              className="whishlist-icon"
                              icon="heart"
                            />
                          </button>
                        </form>
                      )
                    ) : (
                      <MandatoryLoginFavoris />
                    )}

                    {this.state.token ? (
                      product.quantity === 0 ||
                      parseInt(userID) ===
                        parseInt(product?.provider?.user?.id) ? (
                        <button
                          disabled
                          className="btn-product-card-item-add-card"
                        >
                          <FeatherIcon
                            className="icon-btn-product-card-item-add-card"
                            icon="shopping-cart"
                          />
                          Ajouter au panier
                        </button>
                      ) : (
                        <button
                          onClick={(e) => this.handleClick(e, product)}
                          className="btn-product-card-item-add-card"
                        >
                          <FeatherIcon
                            className="icon-btn-product-card-item-add-card"
                            icon="shopping-cart"
                          />
                          Ajouter au panier
                        </button>
                      )
                    ) : (
                      <MandatoryLoginProductItem />
                    )}
                  </div>
                  {this.state.isSuccessFavoris && (
                    <ToastContainer className="fs-13" />
                  )}
                  {this.state.isErrorFavoris && (
                    <ToastContainer className="fs-13" />
                  )}
                </div>
              ))}
            </div>

            {/* Modal ajout panier  */}
            <ModalConfirmationAddingOnCart
              openConfirmation={this.openConfirmation}
              closeConformation={this.closeConformation}
              showConfirmation={this.state.showConfirmation}
            />
          </InfiniteScroll>
        );
      } else {
        window.scrollTo(10, 100);
        return (
          <div className="content-item-card-body px-4">
            <div className="alert alert-info marketplace-alert-info empty-badge-alert">
              <div className="row empty-alert-row">
                <div className="col-2 empty-icon-col">
                  <FeatherIcon
                    icon="alert-circle"
                    className="m-b-3 empty-alert-icon rotateIn"
                  />
                </div>
                <div className="col-10 empty-textual-col">
                  <p> Cette catégorie n'a pas encore de produit disponible!</p>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }
}
/* const mapDispatchToProps = dispatch => {
  return {
      addM: (product) => dispatch(addToCart(product))
  };

} */

const mapDispatchToProps = (dispatch) => {
  return {
    addM: (product) => dispatch(addToCart(product)),
    fetchProducts: () => dispatch(fetchProducts()),
    FilterCategory: (products, categoryID) =>
      dispatch(FilterCategory(products, categoryID)),
  };
};

const mapStateToProps = (state) => {
  return {
    products: state.products.items,
    filteredCategory: state?.products?.filteredItems,
    category: state.products.category,
    categories: state.categories.data,
    isLoading: state.products.isLoading
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(productItemByCategory);
