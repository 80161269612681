import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import * as IconlyPack from "react-iconly";
import "./MotdePasseOublie.css";
import Header from "../header/index";
import api from "../../http/global-vars";
import axios from "axios";
import { Loader } from "rsuite";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function MotDePassOublier() {
  const history = useHistory();
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [phone, setPhone] = React.useState("");

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    watch,
    reset,
    setValue,
  } = useForm();

  useEffect(() => {
    register("phone", {
      required: "Le numéro de téléphone est obligatoire",
    });
  }, [register]);

  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 3000);
    }
  }, [formState]);

  const handleChange = (phone, country) => {
    console.log("+" + phone, country);
    setPhone(phone);
    if (country?.format?.match(/\./g)?.length === phone?.length) {
      setValue("phone", "+" + phone);
    }
  };

  const onSubmit = async (data, e) => {
    console.log("data", data);
    setIsSuccessfullySubmitted(true);

    await axios
      .post(`${api}request-code-reset/`, data)
      .then((res) => {
        console.log(res);
        setIsSuccessfullySubmitted(false);

        Swal.fire({
          icon: "success",
          title:
            "Veuillez vérifier vos messages, nous venons de vous envoyer un code pour la rénitialisation de votre mot de passe.",
          showConfirmButton: true,
          allowOutsideClick: false,
          // timer: 1000,
        }).then((result) => {
          if (result.isConfirmed) {
            history.push({
              pathname: "/change-password",
              state: data?.phone,
            });
          }
        });
      })
      .catch((e) => {
        setIsSuccessfullySubmitted(false);
        console.log(e?.response);
        if (e?.response?.data?.status === "failure") {
          //   toast.error(error?.response?.data);
          Swal.fire({
            icon: "error",
            title: "Cet email n'existe pas dans la base de données.",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title:
              "Oupsss! Une erreur est survenue. Veuillez réessayer plus tard.",
            showConfirmButton: false,
            timer: 3000,
          });
        }
      });
  };
  return (
    <div>
      <Header />
      <div className="login-page-container p-t-40 p-b-150 fixed-after-header">
        <section className="container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row auth-page-row">
              <div className="col-md-6 offset-md-3 auth-page-col">
                <div className="row auth-form-row">
                  <h1 className="auth-form-title m-b-90">Mode passe oublié</h1>
                  <div className="col-md-6 offset-md-3 m-b-20">
                    <p className="text-modifier-password">
                      Veuillez entrer votre numéro de téléphone, nous vous
                      enverrons un code pour la rénitialisation de votre mot de passe
                    </p>
                  </div>
                  <div className="col-md-12 auth-input-col m-b-20">
                    <div className="form-group auth-form-group position-relative">
                      <PhoneInput
                        defaultMask={".. ... .. .."}
                        inputClass="form-control auth-form-control pl-5"
                        country={"sn"}
                        // onlyCountries={["sn"]}
                        inputProps={{
                          name: "telephone",
                          required: true,
                          autoFocus: true,
                        }}
                        // disableDropdown={true}
                        enableAreaCodes={true}
                        prefix="+"
                        value={phone}
                        onChange={(phone, country) =>
                          handleChange(phone, country)
                        }
                        countryCodeEditable={false}
                        inputStyle={{
                          width: "100%",
                          paddingBottom: "22px",
                          borderRadius: "10px",
                        }}
                      />
                      {/* <span className="icon-idenfiant">
                        {" "}
                        <IconlyPack.Message set="bold" primaryColor="#C4C4C4" />
                      </span>
                      <input
                        name="email"
                        type="email"
                        {...register("email", {
                          required: {
                            value: true,
                            message: "L'email est obligatoire.",
                          },
                          pattern: {
                            value: new RegExp(
                              // /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
                              /^(?<user>[a-zA-Z0-9._%+'-]+)@(?<domain>[a-zA-Z0-9.'-]+\.[a-zA-Z]{2,4})$/
                            ),
                            message: "Votre email n'est pas valide",
                          },
                        })}
                        className="form-control auth-form-control pl-5"
                        id="mail"
                        placeholder="Identifiant"
                      /> */}
                    </div>
                    {formState.errors && formState.errors.phone && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {formState.errors.phone?.message}
                      </div>
                    )}
                  </div>
                  <div className="col-md-6 offset-md-3 d-flex justify-content-center auth-input-col m-b-5">
                    {!isSuccessfullySubmitted && (
                      <button className="btn-form-dark">ENVOYER</button>
                    )}

                    {isSuccessfullySubmitted && (
                      <button className="btn-form-dark">
                        En cours &nbsp;
                        <Loader />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>
      {/* <ToastContainer /> */}
    </div>
  );
}

export default MotDePassOublier;
