import React, {Component} from 'react';
import Skeleton from 'react-loading-skeleton';

export default class evaluationShopCardSkeleton extends Component {
  constructor(props) {
      super(props);
      this.state = {};
  }
  render() {
    return  <div className="evaluation-boutique-card">
                <div className="head-boutique-card">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8 col-sm-9 col-10">
                            <Skeleton className="h-evaluation-boutique-card mb-0 text-up m-t-5" height={20} />
                        </div>
                        <div className="col-xl-4 col-lg-4 pl-xl-3 pr-xl-3 p-lg-0 col-sm-3 col-2 pl-md-3 pr-md-3 pl-sm-0">
                            <Skeleton className="btn-follow-evaluation-boutique-card-web " height={29} />
                        </div>
                    </div>
                </div>
                <div className="body-evaluation-boutique">
                    <div className="row">
                        <div className="col-xl-5 col-lg-6 col-sm-5 m-b-13">
                            <Skeleton className="titre-evaluation-boutique-card lh-2" height={16} />
                            <Skeleton className="content-evaluation-boutique-card" style={{ marginTop: -2 }} />
                        </div>
                        <div className="col-xl-5 col-lg-6 col-sm-5 m-b-13">
                            <Skeleton className="titre-evaluation-boutique-card lh-2" height={16} />
                            <Skeleton className="content-evaluation-boutique-card" style={{ marginTop: -2 }} />
                        </div>
                    </div>
                </div>
          </div>;
  }
}