import React, { Component } from 'react';
import Skeleton from 'react-loading-skeleton';

export default class shopCardSkeleton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      Array(16).fill().map((shopCard, i) => (
        <div className="col-xl-3 col-lg-3 col-md-6 px-2 col-sm-6 m-b-15" key={i} >
          <div className="card-boutique-item">
            <div className="image-card-boutique">
              <Skeleton className="w-full h-full lh-2" variant="rect" width={`100%`} height={`100%`} />
            </div>
            <div className="content-card-boutique-item">
              <Skeleton className="marque-card-boutique" />
              <Skeleton className="" style={{ border: 'solid #eee', borderRadius: 50, height: 35 }}/>
            </div>
          </div>
        </div>
      ))
    );
  }
}
