import React, { useState } from "react";
import "./addProductSteps.css";
import { Loader, Steps, Modal } from "rsuite";
import ConfirmationIcon from "../../../img/icons/check-icon.png";
import InformationsProduct from "./steps/informationsProduct";
import DetailsProduct from "./steps/detailsProduct";
import TarificationProduct from "./steps/tarificationProduct";
import ImagesProduct from "./steps/imagesProduct";
import UseAddOrEditProduct from "./form/UseAddOrEditProduct";
import { useSelector } from "react-redux";
import { useGetUserShopQuery } from "../../../utils/api/shop.api";
import { useProductFromLocation } from "../../../utils/api/product.api";

function AddOrEditProductSeller() {
  const { user } = useSelector((s) => s?.user);
  const {data = []} = useGetUserShopQuery(user?.id)
  const [item] = useProductFromLocation()
  const form = UseAddOrEditProduct(data[0],item);
  const { onSubmit, step, handleBack, handleNext, isLoading } = form;
  
  return (
    <div className="px-xl-5 px-lg-3 px-md-4  pt-3">
      <Steps current={step} className="m-b-40">
        <Steps.Item
          className="step-horizontal"
          title=""
          description="Détails du produit"
        />
        <Steps.Item
          className="step-horizontal"
          title=""
          description="Plus de détails"
        />
        <Steps.Item
          className="step-horizontal"
          title=""
          description="Tarifications"
        />
        <Steps.Item className="step-horizontal" title="" description="Images" />
      </Steps>

      <div>
        <form onSubmit={onSubmit}>
          {step === 0 ? (
            <InformationsProduct {...form} />
          ) : step === 1 ? (
            <DetailsProduct {...form}/>
          ) : step === 2 ? (
            <TarificationProduct {...form}/>
          ) : step === 3 ? (
            <ImagesProduct {...form}/>
          ) : (
            <></>
          )}

          <div className="row m-t-50">
            <div className="col-lg-3 col-md-3 pr-md-0 mb-3 col-sm-5 pr-sm-2">
              <p
                className={
                  step === 0
                    ? "dis-none"
                    : "btn-theme-light text-up py-3 w-full text-center"
                }
                onClick={(e) => handleBack(e)}
                disabled={step === 0}
              >
                Retour
              </p>
            </div>
            <div
              className={
                step === 3
                  ? "dis-none"
                  : "col-lg-3 pl-lg-2 pl-md-1 col-md-4 mb-3 col-sm-7 ml-auto"
              }
            >
              <p
                className="btn-theme-light dis-flex justify-content-center text-up py-3 w-full hov-pointer"
                onClick={(e) => handleNext(e)}
              >
                Passer l’étape
              </p>
            </div>
            {/* <div
              className={
                step === 3
                  ? "dis-none"
                  : "col-lg-4 pl-lg-2 pl-md-1 col-md-5 mb-3 col-sm-7 pl-sm-2 ml-auto"
              }
            >
              <button
                type="button"
                // onClick={this.suivant}
                className="btn-theme dis-flex justify-content-center py-3 w-full hov-pointer"
              >
                Ajouter des images
              </button>
            </div> */}
            {/* {this.state.creatingProductInProgress === true ? (
              <div
                className={
                  step === 0 || step === 1 || step === 3
                    ? "dis-none"
                    : "col-lg-4 pl-lg-2 pl-md-1 col-md-5 mb-3 col-sm-7 pl-sm-2 ml-auto"
                }
              >
                <button
                  disabled
                  type="submit"
                  className="btn-theme creating-shop"
                >
                  Ajout en cours&nbsp; <Loader />
                </button>
              </div>
            ) : (
              <div
                className={
                  step === 0 || step === 1 || step === 3
                    ? "dis-none"
                    : "col-lg-4 pl-lg-2 pl-md-1 col-md-5 mb-3 col-sm-7 pl-sm-2 ml-auto"
                }
              >
                <button
                  type="button"
                  onClick={this.suivant}
                  className="btn-theme dis-flex justify-content-center py-3 w-full hov-pointer"
                >
                  Ajouter des images
                </button>
              </div>
            )} */}
            <div
              className={
                step !== 3
                  ? "dis-none"
                  : "col-lg-4 pl-lg-2 pl-md-1 col-md-5 mb-3 col-sm-7 pl-sm-2 ml-auto"
              }
            >
              <button
                type="submit"
                className="btn-theme dis-flex justify-content-center py-3 w-full hov-pointer"
                disabled={isLoading}
              >
                {!isLoading && "Enregistrer le produit"}
                {!!isLoading && (
                  <Loader content="Ajout de produit en cours..." />
                )}
              </button>
            </div>
          </div>
        </form>

        {/* <form onSubmit={(e) => this.onSubmitProductImages(e)}>
            {step === 3 ? (
              <ImagesProduct
                onRef={(ref) => (this.child = ref)}
                handleChange={this.handleChange}
                is_empty_images={this.state.is_empty_images}
                errors={this.state.errors}
              />
            ) : (
              <></>
            )}
            <div className="row m-t-50">
              <div
                className={
                  step === 0 || step === 1 || step === 2
                    ? "dis-none"
                    : "col-lg-4 pl-lg-2 pl-md-1 col-md-5 mb-3 col-sm-7 pl-sm-2 ml-auto"
                }
              >
                {this.state.creatingProductInProgress ? (
                  <button
                    type="submit"
                    disabled
                    className="btn-form-dark in-progress-btn"
                  >
                    <Loader content="Ajout de produit en cours..." />
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn-theme dis-flex justify-content-center py-3 w-full hov-pointer"
                  >
                    Enregistrer le produit
                  </button>
                )}
              </div>
            </div>
          </form> */}
      </div>

      {/* Modal ajout article  */}
      {/* <div className="modal-container">
        <Modal
          size="xs"
          show={this.state.showConfirmation}
          onHide={this.closeConformation}
          className="rsuite-content-modal-custom"
        >
          <form>
            <Modal.Header>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <div className="my-5 flex-col itm-center">
              <img
                loading="lazy"
                src={ConfirmationIcon}
                alt=""
                className="icon-confirmation-modal mb-4"
              />
              <p className="title-confirmation-modal my-4">
                Article soumis avec succès
              </p>
            </div>
            <Modal.Footer className="text-center">
              <div className="row px-5">
                <div className="col-12 mb-3">
                  <button
                    onClick={closeConformation}
                    className="btn-change-password"
                  >
                    Fermer
                  </button>
                </div>
              </div>
            </Modal.Footer>
          </form>
        </Modal>
      </div> */}
    </div>
  );
}

export default AddOrEditProductSeller;
