import React, {Component} from 'react';
import './tableCommande.css';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import Moment from 'react-moment'
import { NavLink } from 'react-router-dom';
import { CustomPagination } from '../../../modules/Pagination/CustomPagination';
import TableSkeleton from '../../skeleton/TableSkeleton';


export default class placedOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  orderCreated(cell, row) {
    return (
      <NavLink
        className="over-class linkable"
        style={{ color: "rgba(0, 0, 0, 0.7)" }}
        to={{
          pathname: `/details-commande-admin/${row?.order_code}`,
          state: { order: row },
        }}
      >
        <Moment format="DD/MM/YYYY">{row.order_date}</Moment>
      </NavLink>
    );
  }

  paymentMode = (cell, row) => {
    return (
      <NavLink
        className="over-class linkable"
        style={{ color: "rgba(0, 0, 0, 0.7)" }}
        to={{
          pathname: `/details-commande-admin/${row?.order_code}`,
          state: { order: row },
        }}
      >
        {row.payment_mode.name}
      </NavLink>
    );
  };
  quantity = (cell, row) => {
    return (
      <NavLink
        className="over-class linkable"
        style={{ color: "rgba(0, 0, 0, 0.7)" }}
        to={{
          pathname: `/details-commande-admin/${row?.order_code}`,
          state: { order: row },
        }}
      >
        {row.cart.number_of_items}
      </NavLink>
    );
  };
  shippingMode = (cell, row) => {
    return (
      <NavLink
        className="over-class linkable"
        style={{ color: "rgba(0, 0, 0, 0.7)" }}
        to={{
          pathname: `/details-commande-admin/${row?.order_code}`,
          state: { order: row },
        }}
      >
        {row.delivery.name}
      </NavLink>
    );
  };
  priceOrder = (cell, row) => {
    return (
      <NavLink
        className="over-class linkable"
        style={{ color: "rgba(0, 0, 0, 0.7)" }}
        to={{
          pathname: `/details-commande-admin/${row?.order_code}`,
          state: { order: row },
        }}
      >
        {Intl.NumberFormat("fr-FR").format(parseInt(row.order_total)) + " FCFA"}
      </NavLink>
    );
  };
  supplierOrder = (cell, row) => {
    return (
      <NavLink
        className="over-class linkable"
        style={{ color: "rgba(0, 0, 0, 0.7)" }}
        to={{
          pathname: `/details-commande-admin/${row?.order_code}`,
          state: { order: row },
        }}
      >
        {" "}
        {row?.user?.first_name + " " + row?.user?.last_name}{" "}
      </NavLink>
    );
  };
  orderStatus = (cell, row) => {
    if (row.status === "placed") {
      let status = "En cours de préparation";
      return status;
    }
  };

  render() {
    let { isLoading, page, limit, setLimit, setPage, count, setQuery } =
      this.props;
    const renderPaginationPanel = (props) => {
      return (
        <div class="col-md-12">
          <CustomPagination
            page={page}
            perPage={limit}
            nbPages={count}
            onChange={(page, perPage) => {
              window.scrollTo(200, 200);
              setPage(page);
              setLimit(perPage);
            }}
          />
        </div>
      );
    };

    const renderCustomSearchPanel = (props) => {
      return (
        <div className="form-group form-group-sm react-bs-table-search-form input-group input-group-sm">
          <input
            type="search"
            className="form-control"
            placeholder="Recherche"
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
      );
    };
    const options = {
      noDataText: "Aucune commande disponible",
      paginationPanel: renderPaginationPanel,
      sizePerPage: limit,
      searchPanel: renderCustomSearchPanel,
    };
    let headers = [
      { text: "N ° de commande" },
      { text: "Date" },
      { text: "Prix" },
      { text: "Client" },
      { text: "Mode paiement" },
      { text: "Quantité" },
    ];

    return (
      <div className="table-container">
        {!!isLoading && <TableSkeleton headers={headers} />}
        {!isLoading && ( <BootstrapTable
          className="head-table-custom"
          data={this.props.commandes}
          search={false}
          multiColumnSearch={true}
          striped
          hover
          options={options}
          multiColumnSort={2}
          pagination
          version="4"
        >
          <TableHeaderColumn
            isKey
            dataField="order_code"
            width="150"
            tdStyle={{
              fontWeight: 400,
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.7)",
              borderTop: "none",
              borderBottom: "none",
              padding: "1rem",
              textAlign: "center",
            }}
            thStyle={{ textAlign: "center", whiteSpace: "normal" }}
            className="th-table-custom"
            dataFormat={(cell, row) => {
              return (
                <NavLink
                  className="over-class linkable"
                  style={{ color: "rgba(0, 0, 0, 0.7)" }}
                  to={{
                    pathname: `/details-commande-admin/${row?.order_code}`,
                    state: { order: row },
                  }}
                >
                  {" "}
                  {cell}{" "}
                </NavLink>
              );
            }}
          >
            N ° de commande
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="order_date"
            className="th-table-custom"
            width="150"
            dataFormat={this.orderCreated}
            thStyle={{ textAlign: "center" }}
            tdStyle={{
              fontWeight: 400,
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.7)",
              borderTop: "none",
              borderBottom: "none",
              padding: "1rem",
              textAlign: "center",
            }}
          >
            Date
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="order_total"
            className="th-table-custom"
            dataFormat={this.priceOrder}
            width="200"
            thStyle={{ textAlign: "center" }}
            tdStyle={{
              fontWeight: 400,
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.7)",
              borderTop: "none",
              borderBottom: "none",
              padding: "1rem",
              textAlign: "center",
            }}
          >
            Prix
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="order_total"
            className="th-table-custom"
            dataFormat={this.supplierOrder}
            width="200"
            thStyle={{ textAlign: "center" }}
            tdStyle={{
              fontWeight: 400,
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.7)",
              borderTop: "none",
              borderBottom: "none",
              padding: "1rem",
              textAlign: "center",
            }}
          >
            Client
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="payment_mode"
            className="th-table-custom"
            dataFormat={this.paymentMode}
            width="200"
            thStyle={{ textAlign: "center" }}
            tdStyle={{
              fontWeight: 400,
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.7)",
              borderTop: "none",
              borderBottom: "none",
              padding: "1rem",
              textAlign: "center",
              whiteSpace: "normal",
              textTransform: "capitalize",
            }}
          >
            Mode paiement
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="number_of_items"
            className="th-table-custom"
            dataFormat={this.quantity}
            width="130"
            thStyle={{ textAlign: "center" }}
            tdStyle={{
              fontWeight: 400,
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.7)",
              borderTop: "none",
              borderBottom: "none",
              padding: "1rem",
              textAlign: "center",
            }}
          >
            Quantité
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="mode_livraison"
            className="th-table-custom"
            dataFormat={this.shippingMode}
            width="250"
            thStyle={{ textAlign: "center" }}
            tdStyle={{
              fontWeight: 400,
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.7)",
              borderTop: "none",
              borderBottom: "none",
              padding: "1rem",
              textAlign: "center",
              whiteSpace: "normal",
            }}
          >
            Mode de livraison
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="status"
            className="th-table-custom"
            dataFormat={this.orderStatus}
            width="230"
            thStyle={{ textAlign: "center" }}
            tdStyle={{
              fontWeight: 400,
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.7)",
              borderTop: "none",
              borderBottom: "none",
              padding: "1rem",
              textAlign: "center",
            }}
          >
            Statut
          </TableHeaderColumn>
        </BootstrapTable>)}
       
      </div>
    );
  }
}