import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import $ from 'jquery'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Loader, Message } from 'rsuite'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import api from '../../../http/global-vars'

function ChangeStatutMsgModal({ message, getMessages }) {
  const [isLoading, setIsLoading] = useState(false)
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        status: yup.string().required('Le statut du message est requis'),
      }),
    [],
  )
  const {
    register,
    setValue,
    formState,
    clearErrors,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  let config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
    },
  }

  useEffect(() => {
    if (message?.id) {
      setValue('id', message?.id)
      setValue('status', message?.status)
      setValue('objet', message?.objet)
      setValue('message', message?.message)
    }
  }, [message])
  useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          console.log(formState.errors)
          return clearErrors(key)
        })
      }, 3000)
    }
  }, [formState])

  const onSubmit = async (data) => {
    console.log('data edit', data)
    setIsLoading(true)
    let url = `${api}contact/${data?.id}/`
    await axios
      .put(url, data, config)
      .then(async (response) => {
        console.log('edit', response)
        await getMessages()
        await Swal.fire({
          icon: 'success',
          title: 'Statut modifié avec succès',
          iconColor: '#c501ff',
          showConfirmButton: false,
          timer: 1500,
        })
        await setIsLoading(false)
        await window.$(`#ChangeStatutMsgModal${message?.id}`).modal('hide')
      })
      .catch((err) => {
        setIsLoading(false)
        Swal.fire({
          icon: 'error',
          title: `${
            err?.response?.data?.message
              ? err?.response?.data?.message
              : `Une erreur de status ${
                  err?.response?.status || 'inconnue'
                } est survenue!`
          }`,
          showConfirmButton: false,
          timer: 2000,
        })
      })
  }
  return (
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Modifier le statut du message
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body bg-white ">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3 text-start">
              <div className="col-md-12 crud-col-item">
                <div className="form-group crud-form-group required">
                  <label
                    htmlFor="status"
                    className="form-label form-label-custom"
                  >
                    Statut du message
                  </label>
                  <div className="filter-item-container">
                    <select
                      name="name"
                      id="status"
                      {...register('status')}
                      placeholder="Choisir un type"
                      className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                      aria-label="Default select example"
                      style={{ padding: '10px', height: '46px' }}
                    >
                      <option value="">Statut du message</option>
                      <option value="new">Nouveau</option>
                      <option value="en_cours">En cours</option>
                      <option value="traité">Traiter</option>
                    </select>
                    {formState?.errors.status && (
                      <div className="alert alert-danger sia-alert-danger closer">
                        {formState?.errors.status?.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-close-theme"
                data-bs-dismiss="modal"
                id="btn-close"
              >
                Annuler
              </button>
              {isLoading && (
                <button disabled className="btn btn-theme-profil">
                  Modification&nbsp; <Loader />
                </button>
              )}
              {!isLoading && (
                <button type="submit" className="btn btn-theme-profil">
                  Terminer
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ChangeStatutMsgModal
