import React, {Component} from 'react';
import './detailsEvaluationBoutique.css';
import { NavLink } from 'react-router-dom';
import Header from '../header/index';
import Footer from '../footer/index';
import FeatherIcon from 'feather-icons-react';
import CommentaireItem from '../commentaireItem/index';
import Skeleton from 'react-loading-skeleton';
import api from '../../http/global-vars';
import axios from 'axios';
import { Rate } from 'rsuite';
// import { ThumbUpSharp } from '@material-ui/icons';

export default class detailsEvaluationBoutique extends Component {
  constructor(props) {
      super(props);
      this.state = {
        token: window.localStorage.getItem('userToken'),
        requestConfig: {
            headers: { Authorization: `Bearer ${window.localStorage.getItem('userToken')}` }
        },
        userID: window.localStorage.getItem('userID'),
        shopId: this.props.match.params.id,
        loading: false,
        api: api,
        evaluationByFournisseur: [],
        notEvaluationInShop: false
      };
  }

  componentDidMount() {
    window.scrollTo({top: 0, behavior: 'smooth'});
    this.setState({ loading: true });
    this.onGetEvaluationByFournisseur()
    var config = this.state.requestConfig
    this.onGetShopInfos(config)
  }

  onGetEvaluationByFournisseur = () => {
    var api = this.state.api
    let url = api + `commentairebyfournisseur/${this.state.shopId}`

    axios.get(url)
      .then(response => {
        this.setState({ 
          evaluationByFournisseur: response.data.data,
          loading: false
        });
        if(this.state.evaluationByFournisseur.length === 0){
          this.setState({ 
            notEvaluationInShop: true,
            loading: false 
          }) 
        }
      })
      .catch(error => {
        if(error){
          this.setState({ loading: false })
        }
      })
  }

  onGetShopInfos = (config) =>{
    this.setState({
      loading: true
    })
    var api = this.state.api
    var userID = this.state.userID
    axios.get(api + `fournisseurbyuser/${userID}/`, config)
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          this.setState({ 
            shopDetails: response.data[0], 
            userInfos: response.data[0].user
          })
          setTimeout(() => {
            this.setState({
                loading: false
            })
          }, 5000);
        
        }
      })
      .catch(error => {
          if(error){
              this.setState({
                  loading: false
              })
          }
      })
  }

  render() {
    // var shopDetails = this.state.shopDetails
    return  <div>
              <Header/>
              <div className="body-theme p-t-10 p-b-45 fixed-after-header">
                <div className="container">
                  {this.state.loading === true ?
                    <div className="pos-relative m-t-5 mb-2">
                      <div className="">
                          <Skeleton className="sk-link-previous-ariane" />
                      </div>
                      <Skeleton className="sk-h-previous-ariane m-b-25 text-cap lh-2" />
                    </div>
                    :
                    <div className="pos-relative m-t-5">
                      <NavLink to="" className="">
                          <FeatherIcon className="link-previous-ariane" icon="arrow-left" />
                      </NavLink>
                      <h4 className="h-previous-ariane m-b-25 text-cap">évaluation</h4>
                    </div>
                  }

                  <div className="evaluation-content-details-page mb-3">
                    {this.state.loading === true ?
                      <Skeleton className='sk-titre-evaluation-content-details-page' />
                      :
                      <div className="itm-center">
                        <p className="titre-evaluation-content-details-page">Note</p> 
                        <Rate value={3}  readOnly className="rating-comment-product-head" />
                        <span className="pourcentage-evaluation-content-details-page">3/5</span>
                      </div>
                    }
                  </div>

                  <div className="commentaire-content-details-page m-b-200">
                    <div className="head-commentaire-content-details-page">
                    {this.state.loading === true ?
                      <Skeleton variant="text" width={200} />
                      :
                      <p>Commentaires <span className="fs-14 ml-3">( 245 )</span></p>
                    }
                    </div>
                    <div className="body-commentaire-content-details-page">
                      {this.state.notEvaluationInShop === true ?
                        <div className="row">
                          <div className="col-lg-7">
                            <div className="alert alert-info marketplace-alert-info empty-badge-alert mt-4">
                              <div className="row empty-alert-row">
                                <div className="col-2 empty-icon-col">
                                  <FeatherIcon
                                    icon="alert-circle"
                                    className="m-b-3 empty-alert-icon rotateIn"
                                  />
                                </div>
                                <div className="col-10 empty-textual-col">
                                  <p> Cette boutique n'a pas encore été évalué!</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        :
                        <ul className="p-0 list-none">
                          <CommentaireItem evaluationByFournisseur={this.state.evaluationByFournisseur} />
                        </ul>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>;
  }
}
