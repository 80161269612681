import React, { useEffect } from "react";
import FeatherIcon from "feather-icons-react";
import { NavLink } from "react-router-dom";

function SortShop({ tri, setTri, query, setQuery }) {
  useEffect(() => {
    let queryCopy = { ...query };
    queryCopy = {
      ...queryCopy,
      order: tri,
    };
    // console.log("query selected", queryCopy);
    setQuery(queryCopy);
  }, [tri]);
  return (
    <div className="component-sort-by">
      <div className="dis-flex justify-content-end itm-center m-t-3">
        <p className="fs-10 fw-600 text-black-50 no-viewIn-mobile">
          Trier par :
        </p>
        <div className="dropdown dropdown-sort">
          <select
            name="tri"
            id="tri"
            onChange={(e) => setTri(e?.target?.value)}
            value={tri}
            style={{ textAlign: "start" }}
            className="dropbtn dropbtn-sort no-viewIn-mobile"
          >
            <option value="">Défaut</option>
            <option value="asc">Les mieux notés</option>
            <option value="desc">Les moins notés</option>
          </select>
          {/* <button className="dropbtn dropbtn-sort no-viewIn-mobile">
            Les mieux notés
            <FeatherIcon
              className="icon-chevron-dropdown"
              icon="chevron-down"
            />
          </button>
          <button className="dropbtn dropbtn-sort no-viewIn-tablette">
            <FeatherIcon className="icon-filter-mobile-view" icon="sliders" />
          </button>
          <div className="dropdown-content dropdown-content-sort">
            <NavLink to="#">Link 1</NavLink>
            <NavLink to="#">Link 2</NavLink>
            <NavLink to="#">Link 3</NavLink>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default SortShop;
