import moment from 'moment'
import React, { useEffect, useState } from 'react'
import './MessageContact.css'
import 'react-quill/dist/quill.snow.css'
import HeaderDashboard from '../headerDashboard/index'
import NavVerticalDashboardAdmin from '../navVerticalDashboardAdmin/index'
import api from '../../../http/global-vars'
import axios from 'axios'
import Pagination from '../../../modules/Pagination/Pagination'
import DetailsMessageModal from './DetailsMessageModal'
import ChangeStatutMsgModal from './ChangeStatutMsgModal'

function MessageContact() {
  const [messages, setMessages] = useState([])
  const [page, setPage] = React.useState(1)
  const [perPage, setPerPage] = React.useState(10)
  const [count, setCount] = useState(0)
  const [currentList, setCurrentList] = useState([])
  let config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
    },
  }

  useEffect(() => {
    getMessages()
  }, [])

  useEffect(() => {
    if (messages) {
      formatData(messages?.sort((a, b) => b?.id - a?.id))
    }
  }, [messages, page, perPage])

  const formatData = (Array) => {
    const indexOfLastPost = page * perPage
    const indexOfFirstPage = indexOfLastPost - perPage

    const currentList = Array.slice(indexOfFirstPage, indexOfLastPost)
    setCurrentList(currentList)
  }

  const getMessages = async () => {
    let url = `${api}contact/`
    await axios
      .get(url, config)
      .then((response) => {
        setMessages(response?.data?.results)
        setCount(response?.data?.count)
        console.log('response', response)
      })
      .catch((err) => {
        console.log('error', err?.response)
      })
  }
  const handleChangePage = (newPage, sizePerPage) => {
    setPage(newPage)
    setPerPage(sizePerPage)
  }
  return (
    <div className="component-articles-dashboard-admin">
      <HeaderDashboard />
      <div className="fixed-after-header-dashboard container-fluid">
        <div className="row">
          <div className="col-xl-2 col-lg-3 no-view-tablette">
            <NavVerticalDashboardAdmin />
          </div>
          <div className="col-xl-10 col-lg-9 px-xl-3 py-4 pr-lg-3 pl-lg-1">
            <div className="card-dashboard-content-table">
              <div className="head-card-dashboard-content-table">
                <div className="row mb-5">
                  <div className="col-md-9 col-sm-8 mb-sm-0 mb-3">
                    <p className="title-filter-dashboard">
                      Gestion des messages contact
                    </p>
                  </div>
                </div>
              </div>
              <div className="content-card-dashboard-content-table">
                {/* <UtilisateursTable /> */}
                <div>
                  <div className="table-container">
                    {currentList?.map((msg) => (
                      <div
                        className="row p-3 admin-message-card mb-3 admin-message-card-description-container mx-0"
                        key={msg?.id}
                      >
                        <div className="col-md-6">
                          <button
                            className="admin-message-card-description btn p-0"
                            data-toggle="modal"
                            data-target={`#DetailsMessageModal${msg?.id}`}
                            style={{ textOverflow: 'ellipsis' }}
                          >
                            {msg?.objet}
                          </button>
                          <p className="admin-message-card-frequence mb-0">
                            {moment(msg?.created_at).format(
                              'DD/MM/YYYY  à HH:mm',
                            )}
                          </p>
                        </div>
                        <div className="col-md-3">
                          <button
                            className={`modal-details-message-${msg?.status} btn`}
                            data-toggle="modal"
                            data-target={`#ChangeStatutMsgModal${msg?.id}`}
                          >
                            Nouveau
                          </button>
                        </div>
                        <div className="col-md-3">
                          <p className="admin-message-card-description mb-0">
                            {msg?.email}
                          </p>
                        </div>
                        <div
                          className="modal fade"
                          id={`DetailsMessageModal${msg?.id}`}
                          aria-labelledby="DetailsMessageModalLabel"
                          aria-hidden="true"
                        >
                          <DetailsMessageModal message={msg} />
                        </div>
                        <div
                          className="modal fade"
                          id={`ChangeStatutMsgModal${msg?.id}`}
                          aria-labelledby="ChangeStatutMsgModalLabel"
                          aria-hidden="true"
                        >
                          <ChangeStatutMsgModal
                            message={msg}
                            getMessages={getMessages}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {messages?.length ? (
                  <CustomPagination
                    nbPages={count}
                    page={page}
                    onChange={(page, perPage) => {
                      handleChangePage(page, perPage)
                    }}
                    perPage={perPage}
                  />
                ) : null}

                {/* <dd/> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MessageContact
export function CustomPagination({ nbPages, page, perPage, onChange }) {
  if (parseInt(nbPages) <= 0) return null
  return (
    <div className="d-flex align-items-baseline justify-content-between px-2">
      <div className="footer-form d-flex mt-3">
        <select
          className="form-control select-filter-limit"
          name="limit"
          id="limit"
          value={perPage}
          onChange={(e) => {
            onChange(1, parseInt(e.target.value, 10))
          }}
        >
          <option value="10">10</option>
          <option value="30">30</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>

      {parseInt(nbPages) > 0 ? (
        <Pagination
          page={page}
          total={nbPages}
          perPage={perPage}
          onPageChange={(page) => onChange(page, perPage)}
        />
      ) : null}
    </div>
  )
}
