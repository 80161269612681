import React, {Component} from 'react';
import './helpDashboard.css';
import HeaderDashboard from '../headerDashboard/index';
import NavVerticalDashboard from '../navVerticalDashboard/index';

export default class helpDashboard extends Component {
  constructor(props) {
      super(props);
      this.state = {};
  }
  render() {
    return <div className="p-b-200">
      <HeaderDashboard/>
      <div className="fixed-after-header-dashboard container-fluid">
        <div className="row">
          <div className="col-xl-2 col-lg-3 no-view-tablette">
            <NavVerticalDashboard />
          </div>
          <div className="col-xl-10 col-lg-9 px-xl-3 py-4 pr-lg-3 pl-lg-1">
            
          </div>
        </div>
      </div>
    </div>;
  }
}
