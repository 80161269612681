import React, { Component } from "react";
import "./evaluationShopCard.css";
import { NavLink } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import EvaluationShopCardSkeleton from "../skeleton/evaluationShopCardSkeleton/evaluationShopCardSkeleton";
import api from "../../http/global-vars";
import axios from "axios";

export default class evaluationShopCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      userID: window.localStorage.getItem("userID"),
      loading: false,
      api: api,
      evaluationByFournisseur: [],
      notEvaluationInShop: false,
    };
  }
  componentDidMount() {
    this.setState({ loading: true });
    if (this.props.shopDetails.id) {
      this.onGetEvaluationByFournisseur();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.shopDetails.id !== this.props.shopDetails.id) {
      if (this.props.shopDetails.id) {
        this.onGetEvaluationByFournisseur();
      }
    }
  }

  onGetEvaluationByFournisseur = () => {
    var api = this.state.api;
    let url = api + `commentairebyfournisseur/${this.props.shopDetails.id}`;

    axios
      .get(url)
      .then((response) => {
        this.setState({
          evaluationByFournisseur: response.data.data,
          loading: false,
        });
        if (this.state.evaluationByFournisseur.length === 0) {
          this.setState({
            notEvaluationInShop: true,
            loading: false,
          });
        }
      })
      .catch((error) => {
        if (error) {
          this.setState({ loading: false });
        }
      });
  };
  render() {
    const shopDetails = this.props.shopDetails;
    const loading = this.props.loading;
    // console.log(shopDetails, this.state.evaluationByFournisseur)

    if (loading) {
      return <EvaluationShopCardSkeleton />;
    } else {
      return (
        <div className="evaluation-boutique-card">
          <div className="head-boutique-card">
            <div className="row">
              <div className="col-xl-8 col-lg-8 col-sm-9 col-10 itm-center">
                <h6 className="h-evaluation-boutique-card mb-0 text-up">
                  évaluation
                </h6>
              </div>
              <div className="col-xl-4 col-lg-4 pl-xl-3 pr-xl-3 p-lg-0 col-sm-3 col-2 pl-md-3 pr-md-3 pl-sm-0">
                <form className="pos-relative">
                  <NavLink
                    to={`/details-evaluation-boutique/${shopDetails.id}`}
                    className="btn-follow-evaluation-boutique-card-web"
                  >
                    Détails
                    <FeatherIcon
                      className="icon-btn-follow-evaluation-boutique-card-web"
                      icon="arrow-right"
                    />
                  </NavLink>
                  <NavLink
                    to={`/details-evaluation-boutique/${shopDetails.id}`}
                    className="btn-follow-evaluation-boutique-card-mobile"
                  >
                    <FeatherIcon className="" icon="arrow-right" />
                  </NavLink>
                </form>
              </div>
            </div>
          </div>
          <div className="body-evaluation-boutique">
            <div className="row">
              <div className="col-xl-5 col-lg-6 col-sm-5 col-6 mb-3">
                <p className="titre-evaluation-boutique-card">
                  évaluation positive{" "}
                </p>
                <p className="content-evaluation-boutique-card">
                  {parseInt(shopDetails.note)}/5
                </p>
              </div>
              <div className="col-xl-5 col-lg-6 col-sm-5 col-6 mb-3">
                <p className="titre-evaluation-boutique-card">Commentaires</p>
                <p className="content-evaluation-boutique-card">
                  {this.state.evaluationByFournisseur.length}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
