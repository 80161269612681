import React, { Component } from "react";
import { Message } from "rsuite";

function tarificationProduct({ register, errors }) {
  return (
    <div className="mt-3">
      <p className="consigne-title-steps mb-5">Tarifications</p>

      <div className="row">
        <div className="col-md-6 pr-lg-5">
          <div className="form-group mb-4">
            <input
              type="text"
              {...register("tax")}
              className="form-control input-steps-inscription-vendeur"
              placeholder="Taxes"
            />
            {errors?.tax && (
              <Message
                className="alert-rsuite-custom"
                type="error"
                description={errors?.tax?.message}
              />
            )}
          </div>
          <div className="form-group mb-4">
            <input
              type="number"
              {...register("quantity")}
              min={0}
              className="form-control input-steps-inscription-vendeur"
              placeholder="Quantité"
            />
            {errors?.quantity && (
              <Message
                className="alert-rsuite-custom"
                type="error"
                description={errors?.quantity?.message}
              />
            )}
          </div>
        </div>
        <div className="col-md-6 pl-lg-5">
          <div className="form-group mb-4">
            <input
              type="number"
              min={0}
              {...register("price")}
              className="form-control input-steps-inscription-vendeur number-input-custom"
              placeholder="Prix"
            />
            {errors?.price && (
              <Message
                className="alert-rsuite-custom"
                type="error"
                description={errors?.price?.message}
              />
            )}
          </div>
          {/* <div className="form-group mb-4">
                    <input type="text" 
                        maxLength={30}
                        className="form-control input-steps-inscription-vendeur" 
                        placeholder="Poids" 
                        name="" />
                </div> */}
        </div>
      </div>
    </div>
  );
}

export default tarificationProduct;
