import React, { Component } from "react";
import { Modal } from "rsuite";

export default class ShowPaymentModal extends Component {
  render() {
    return (
      <div className="modal-container">
        <Modal
          size="md"
          show={this.props.showDetails}
          onHide={(e) => this.props.handleCloseDetails(e)}
          className="rsuite-content-modal-custom"
        >
          <form>
            <Modal.Header>
              <Modal.Title>Détails mode paiement</Modal.Title>
            </Modal.Header>
            {/* <Modal.Body> */}
            <div className="add-family-form-container">
              <div className="crud-form">
                <div className="row crud-row">
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <strong htmlFor="numero_contrat">Nom: </strong>

                      <span className="crud-form-control">
                        {this.props.payment?.title}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <strong htmlFor="name">Type: </strong>
                      <span className="crud-form-control">
                        {this.props.payment?.name}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-12 crud-col-item">
                    <div className="form-group crud-form-group">
                      <strong htmlFor="name">Description: </strong>
                      <span className="crud-form-control">
                        {this.props.payment?.description}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* </Modal.Body> */}
            <Modal.Footer className="text-center">
              <div className="row px-5" style={{ textAlign: "end" }}>
                <div className="col-6 mb-2"></div>
                <div className="col-6 mb-2">
                  <button
                    onClick={(e) => this.props.handleCloseDetails(e)}
                    className="btn-light-theme"
                  >
                    Fermer
                  </button>
                </div>
              </div>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    );
  }
}
