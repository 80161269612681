import { TRANSACTIONS } from '../types'

export const transactionReducer = (state = {}, action) => {
    switch (action.type) {
        case TRANSACTIONS:
            return { items: action.payload, filteredItems: action.payload }
        default:
            return state
    }
}
