import React, {Component} from 'react';
import Skeleton from 'react-loading-skeleton';

export default class filterByRateSkeleton extends Component {
  constructor(props) {
      super(props);
      this.state = {};
  }
  render() {
    return <div className="component-filter-by-rate">
      <Skeleton className="titre-filter-type mb-3" width={`80%`} />
      {Array(5).fill().map((rate, i) => ( 
        <Skeleton className="my-1" height={18} width={`85%`} />
      ))}
      
    </div>;
  }
}