import React, {Component} from 'react';
import Skeleton from 'react-loading-skeleton';

export default class infosShopCardSkeleton extends Component {
  constructor(props) {
      super(props);
      this.state = {};
  }
  render() {
    return  <div className="info-boutique-card dis-flex flex-col h-full">
                <div className="head-boutique-card">
                    <div className="row itm-center">
                        <div className="col-sm-9 col-10 ">
                            <Skeleton className="h-info-boutique-card text-up m-sm-0 my-2" />
                        </div>
                        <div className="col-sm-3 col-2 pl-0">
                            <Skeleton className="btn-follow-info-boutique-card-web-light fs-10 lh-2" height={30} style={{ border: '1px solid transparent' }} />
                            <Skeleton className="sk-btn-follow-info-boutique-card-mobile-light" height={35} width={35} style={{ border: '1px solid transparent' }} />
                        </div>
                    </div>
                    
                </div>
                <div className="sk-body-boutique-card-infos">
                <div className="row">
                    <div className="col-sm-4 mb-3">
                        <Skeleton className="titre-info-boutique-card lh-1-8" width={`80%`} height={13} />
                        <Skeleton className="content-info-boutique-card lh-2" width={`60%`} height={20} style={{ marginTop: -6 }} />
                    </div>
                    <div className="col-sm-4 mb-3">
                        <Skeleton className="titre-info-boutique-card lh-1-8" width={`80%`} height={13} />
                        <Skeleton className="content-info-boutique-card lh-2" height={20} style={{ marginTop: -6 }} />
                    </div>
                    <div className="col-sm-4 mb-3">
                        <Skeleton className="titre-info-boutique-card lh-1-8" width={`80%`} height={13} />
                        <Skeleton className="content-info-boutique-card lh-2" width={`40%`} height={20} style={{ marginTop: -6 }} />
                    </div>
                </div>
                </div>
            </div>;
  }
}