import React from "react";
import { NavLink } from "react-router-dom";
import HeaderDashboard from "../../adminDashboard/headerDashboard/headerDashboard";
import NavVerticalDashboardAdmin from "../../adminDashboard/navVerticalDashboardAdmin";
import AddOrEditProductSeller from "../../sellerDashboard/addProductSteps/AddOrEditProductSeller";

function EditProductAdmin() {
  
  return (
    <div className="component-articles-dashboard-admin">
        <HeaderDashboard />
        <div className="fixed-after-header-dashboard container-fluid">
          <div className="row">
            <div className="col-xl-2 col-lg-3 no-view-tablette">
              <NavVerticalDashboardAdmin />
            </div>
            <div className="col-xl-10 col-lg-9 px-xl-3 py-4 pr-lg-3 pl-lg-1">
              <p className="m-t-7 mb-3 dis-inline-block">
                <NavLink to="/articles-admin" className="routing-link">
                  Articles
                </NavLink>{" "}
                /<span className="routing-link"> Ajouter/Modifier un produit</span>
              </p>
              <div className="card-dashboard b-r-15">
                <p className="title-filter-dashboard mb-5">
                  Ajouter/Modifier un produit
                </p>

                <AddOrEditProductSeller />
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default EditProductAdmin;
