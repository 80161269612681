import React, { Component } from 'react'
import './filterByCategory.css'
import api from '../../http/global-vars'
import axios from 'axios'
// import _ from 'lodash';
import FilterByCategorySkeleton from '../skeleton/filterByCategorySkeleton/filterByCategorySkeleton'
import { connect } from 'react-redux'
import { FilterCategory } from '../../productAction/productAction'
import FeatherIcon from 'feather-icons-react'
import { NavLink } from 'react-router-dom'

class filterByCategory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      api: api,
      categoryList: [],
      loading: false,
      checked: [],
    }
  }
  componentDidMount() {
    this.setState({ loading: true })
    // this.onGetCategories()
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.categoryId !== this.props.categoryId) {
      this.setState({ checked: [] });
    }
  }


  onGetCategories = () => {
    var api = this.state.api
    axios
      .get(api + 'category/')
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          let parents = response?.data?.filter(
            (item) => item?.category_id === null && !item?.category_id,
          )
          let childs = response?.data?.filter(
            (item) => item?.category_id !== null && item?.category_id,
          )
          for (let i = 0; i < parents.length; i++) {
            const element = parents[i]
            element.subcategories = childs
              .sort(function (a, b) {
                if (a.name < b.name) {
                  return -1
                }
                if (a.name > b.name) {
                  return 1
                }
                return 0
              })
              .filter((item) => item?.category_id === element?.id)
          }
          this.setState({
            categoryList: parents.sort(function (a, b) {
              if (a.name < b.name) {
                return -1
              }
              if (a.name > b.name) {
                return 1
              }
              return 0
            }),
            loading: false,
          })
        }
      })
      .catch((error) => {
        if (error) {
          this.setState({ loading: false })
        }
      })
  }

  handleChange = (e) => {
    let checked = [...this.state.checked, parseInt(e.target.value)]
    if (this.props.categoryId !== undefined) {
      // checked.push(this.props.categoryId)
      checked[checked?.length] = parseInt(this.props.categoryId)
    }
    // checked = [parseInt(e.target.value)]
    if (
      this.state.checked.includes(parseInt(e.target.value)) &&
      parseInt(e.target.value) !== parseInt(this.props.categoryId)
    ) {
      checked = checked.filter((tes) => tes !== parseInt(e.target.value))
    }

    let x = (checked) => checked.filter((v, i) => checked.indexOf(v) === i)
    x(checked) // 'John', 'Paul', 'George', 'Ringo'
    this.props.FilterCategory(this.props.products, x(checked),this.state.categoryList)

    this.setState({ checked })
  }

  handleChangeSub = (e, category) => {
    let item = this.props.categories?.find((el) => parseInt(el?.id) === parseInt(this.props.categoryId))
    let checked = [...this.state.checked, parseInt(e.target.value)]
    // checked = [...this.state.checked, parseInt(category?.category_id)]
    if (this.props.categoryId !== undefined && !item?.id) {
      
      checked[checked?.length] = parseInt(this.props.categoryId)
    }
    // checked = [parseInt(e.target.value)]
    if (
      this.state.checked.includes(parseInt(e.target.value)) &&
      parseInt(e.target.value) !== parseInt(this.props.categoryId)
    ) {
      checked = checked.filter((tes) => tes !== parseInt(e.target.value))
    }

    let x = (checked) => checked.filter((v, i) => checked.indexOf(v) === i)
    x(checked) // 'John', 'Paul', 'George', 'Ringo'
    if (checked?.length === 0 && item?.id) {
      checked[checked?.length] = parseInt(item?.id)
    } else {
      checked = checked.filter((tes) => parseInt(tes) !== parseInt(this.props.categoryId))
    }
    
    this.props.FilterCategory(this.props.products, x(checked),this.state.categoryList)

    this.setState({ checked })
  }

  render() {
    const { isLoading, data } = this.props.categories;
    if (isLoading) {
      return <FilterByCategorySkeleton />;
    } else {
      return (
        <div>
          <p className="titre-filter-type">Catégories</p>
          {this.props.categoryId === undefined
            ? data?.map((category) => (
                <div
                  key={category.id}
                  className="custom-control custom-checkbox custom-checkbox-filter"
                >
                  <input
                    type="checkbox"
                    value={category.id}
                    onChange={(e) => this.handleChange(e)}
                    className="custom-control-input"
                    id={"filter_category" + category.id}
                    name={category.name}
                    defaultChecked={
                      parseInt(this.props.categoryId) === category?.id
                    }
                    readOnly={parseInt(this.props.categoryId) === category?.id}
                  />
                  <label
                    className="custom-control-label custom-control-label-filter"
                    htmlFor={"filter_category" + category.id}
                  >
                    {category.name}
                  </label>
                </div>
              ))
            : data?.map((category) => (
                <li key={category.id} className="li-categories">
                  <NavLink
                    className="categories-link"
                    to={`/categorie/${category.id}`}
                  >
                    {category.name}
                    <FeatherIcon
                      className="icon-categorie-link"
                      icon="chevron-right"
                    />
                  </NavLink>
                  <ul>
                    {parseInt(this.props.categoryId) ===
                      parseInt(category?.id) ||
                    category?.subcategories?.filter(
                      (el) =>
                        parseInt(el?.id) === parseInt(this.props.categoryId)
                    )?.length
                      ? category?.subcategories?.map((item) => (
                          <div
                            key={item.id}
                            className="custom-control custom-checkbox custom-checkbox-filter"
                          >
                            <input
                              type="checkbox"
                              value={item.id}
                              onChange={(e) => this.handleChangeSub(e, item)}
                              className="custom-control-input"
                              id={"filter_category" + item.id}
                              name={item.name}
                              disabled={
                                parseInt(this.props.categoryId) === item?.id
                              }
                              defaultChecked={
                                parseInt(this.props.categoryId) === item?.id
                              }
                            />
                            <label
                              className="custom-control-label custom-control-label-filter"
                              htmlFor={"filter_category" + item.id}
                            >
                              {item.name}
                            </label>
                          </div>
                        ))
                      : null}
                  </ul>
                </li>
              ))}
        </div>
      );
    }
  }
}

export default connect(
  (state) => ({
    category: state.products.category,
    products: state.products.items,
    filteredCategory: state.products.filteredItems,
    categories: state.categories,
  }),
  {
    FilterCategory,
  },
)(filterByCategory)
