import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import api from "../../http/global-vars";
import { QueryUrl } from "../utils";
import { prepareHeaders } from "./user.api";

export const ShopApi = createApi({
  reducerPath: "shopApi",
  tagTypes: ["shopList", "shopVisitor", "topShop","shopByUser"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${api}`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getShopList: builder.query({
      providesTags: ["shopList"],
      query: (query) => QueryUrl(`web/fournisseur`, query),
    }),
    getshopVisitor: builder.query({
      providesTags: ["shopVisitor"],
      query: (query) => QueryUrl(`visiteur/fournisseur`, query),
    }),
    getTopShop: builder.query({
      providesTags: ["topShop"],
      query: () => `fournisseurbytop/`,
    }),
    getUserShop: builder.query({
      providesTags: ["shopByUser"],
      query: (id) => `fournisseurbyuser/${id}/`,
    }),
  }),
});

export const { useGetShopListQuery, useGetshopVisitorQuery,useGetTopShopQuery,useGetUserShopQuery } = ShopApi;
