import { ORDERS_BY_SHOP, ORDERS } from '../types'

export const orderReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDERS_BY_SHOP:
      return { items: action.payload, filteredItems: action.payload }
    case ORDERS:
      return { items: action.payload, filteredItems: action.payload }
    default:
      return state
  }
}
