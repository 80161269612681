import React, {Component} from 'react';
import Skeleton from 'react-loading-skeleton';

export default class filterByCategorySkeleton extends Component {
  constructor(props) {
      super(props);
      this.state = {
        
      };
  }
  componentDidMount() {

  }
  
  render() {
    return  (
      <div className="m-b-46">
        <Skeleton className="titre-filter-type" width={`60%`} />
        {Array(10).fill().map((category, i) => ( 
          <Skeleton 
          className="custom-control custom-checkbox custom-checkbox-filter"
          height={16}
          width={`85%`}

          />
        ))}
      </div>
    )
  }
}