import axios from 'axios'
import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { Loader, Message } from 'rsuite'
import api from '../../../http/global-vars'

export default class AddDeliveryModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fields: {
        name: '',
        duration: 0,
        amount: 0,
        description: '',
      },
      isLoading: false,
      error: {},
      isEmpty: false,
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
      },
      userID: window.localStorage.getItem('userID'),
      api: api,
    }
  }

  handleChange = (e) => {
    let fields = this.state.fields
    fields[e.target.name] = e.target.value

    this.setState({
      fields: fields,
    })
  }

  onValidateFormData = () => {
    let fields = this.state.fields
    let errors = this.state.error
    var isValidForm = true

    if (!fields['name']) {
      isValidForm = false
      this.setState({
        isEmpty: true,
      })
      setTimeout(() => {
        this.setState({
          isEmpty: false,
        })
      }, 5000)

      errors['name'] = 'Veuillez renseigner le nom!'
    }

    if (!fields['duration']) {
      isValidForm = false
      this.setState({
        isEmpty: true,
      })
      setTimeout(() => {
        this.setState({
          isEmpty: false,
        })
      }, 5000)

      errors['duration'] = 'Veuillez renseigner la durée de la livraison!'
    }

    if (!fields['amount']) {
      isValidForm = false
      this.setState({
        isEmpty: true,
      })
      setTimeout(() => {
        this.setState({
          isEmpty: false,
        })
      }, 5000)

      errors['amount'] = 'Veuillez renseigner le prix de la livraison!'
    }

    this.setState({
      error: errors,
    })

    return isValidForm
  }

  onSubmit = (e) => {
    e.preventDefault()

    if (this.onValidateFormData()) {
      this.setState({
        isLoading: true,
      })

      var api = this.state.api
      var data = this.state.fields

      if (!data?.description) {
        delete data?.description
      }

      axios
        .post(api + 'delivery/', data, this.state.requestConfig)
        .then((response) => {
          console.log('la reponse', response)
          this.props.getDeliveries()
          window.$('.add-delivery-modal').modal('hide')
          e.target.reset()
          this.setState({
            isLoading: false,
            fields: {
              name: '',
              duration: 0,
              amount: 0,
              description: '',
            },
            error: {},
          })
          toast.success('Mode de livraison ajouté avec succés.')
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            error: {},
          })
          toast.error('Une erreur est survenue, veuillez rééssayer plus tard.')
          console.log('erreur', error.response)
        })
    }
  }

  render() {
    return (
      <div className="modal-dialog easypm-modal-dialog modal-lg">
        <div className="modal-content easypm-modal-content">
          <div className="modal-header easypm-modal-header">
            <h5
              className="modal-title easypm-modal-title"
              id="AddingFamilyModal"
            >
              Ajouter un mode de livraison
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body easypm-modal-body">
            <div className="add-family-form-container">
              <form className="crud-form" onSubmit={(e) => this.onSubmit(e)}>
                <div className="row crud-row">
                  <div className="col-md-12 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label for="last_name">Nom</label>
                      <input
                        type="text"
                        placeholder="Nom"
                        name="name"
                        onChange={this.handleChange}
                        className="form-control crud-form-control"
                        id="last_name"
                      />
                      {this.state.isEmpty === true &&
                        this.state.error?.name && (
                          <Message
                            className="alert-rsuite-custom"
                            type="error"
                            description={this.state.error.name}
                          />
                        )}
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label for="duration">Durée</label>
                      <input
                        type="number"
                        placeholder="Durée de la livraison"
                        name="duration"
                        onChange={this.handleChange}
                        className="form-control crud-form-control"
                        id="duration"
                      />
                      {this.state.isEmpty === true &&
                        this.state.error?.duration && (
                          <Message
                            className="alert-rsuite-custom"
                            type="error"
                            description={this.state.error.duration}
                          />
                        )}
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label for="amount">Prix</label>
                      <input
                        type="number"
                        placeholder="Prix de la livraison"
                        name="amount"
                        onChange={this.handleChange}
                        className="form-control crud-form-control"
                        id="amount"
                      />
                      {this.state.isEmpty === true &&
                        this.state.error?.amount && (
                          <Message
                            className="alert-rsuite-custom"
                            type="error"
                            description={this.state.error.amount}
                          />
                        )}
                    </div>
                  </div>
                  <div className="col-md-12 crud-col-item">
                    <div className="form-group crud-form-group ">
                      <label for="dscrp">Description</label>
                      <textarea
                        className="form-control crud-form-control"
                        name="description"
                        id="dscrp"
                        placeholder="Saisissez la description"
                        onChange={this.handleChange}
                        cols="10"
                        rows="3"
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                    <button
                      className="btn btn-default cancelleb-btn"
                      data-dismiss="modal"
                    >
                      Retour
                    </button>
                    {!this.state.isLoading && (
                      <button className="btn btn-primary crud-submit-btn">
                        Enregistrer
                      </button>
                    )}
                    {this.state.isLoading && (
                      <button className="btn btn-primary crud-submit-btn">
                        En cours &nbsp;
                        <Loader />
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
