import api from "../http/global-vars";
import { USERS } from "../types";

export const fetchUsers = () => {
  return async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };
    const url = api + "user/";
    const res = await fetch(url, config);
    const data = await res.json();

    dispatch({
      type: USERS,
      payload: data.results?.filter((user) => user?.user_type !== 'admin'),
    });
  };
};
