import axios from "axios";
import React, { Component } from "react";
import { toast } from "react-toastify";
import { Loader, Message } from "rsuite";
import api from "../../../http/global-vars";

export default class AddAdminModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
      },
      isLoading: false,
      error: {},
      is_empty_first: false,
      is_empty_last: false,
      is_empty_phone: false,
      is_empty_email: false,
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      userID: window.localStorage.getItem("userID"),
      api: api,
    };
  }

  handleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;

    this.setState({
      fields: fields,
    });
  };

  onValidateFormData = () => {
    let fields = this.state.fields;
    let errors = this.state.error;
    var isValidForm = true;

    if (!fields["first_name"]) {
      isValidForm = false;
      this.setState({
        is_empty_first: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_first: false,
        });
      }, 5000);

      errors["first_name"] = "Le prénom est requis.";
    }

    if (!fields["last_name"]) {
      isValidForm = false;
      this.setState({
        is_empty_last: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_last: false,
        });
      }, 5000);

      errors["last_name"] = "Le nom est requis.";
    }

    if (!fields["phone"]) {
      isValidForm = false;
      this.setState({
        is_empty_phone: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_phone: false,
        });
      }, 5000);

      errors["phone"] = "Le numéro de téléphone est requis.";
    }

    if (!fields["email"]) {
      isValidForm = false;
      this.setState({
        is_empty_email: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_email: false,
        });
      }, 5000);

      errors["email"] = "L'email est requis.";
    }

    this.setState({
      error: errors,
    });

    return isValidForm;
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (this.onValidateFormData()) {
      this.setState({
        isLoading: true,
      });

      var api = this.state.api;
      var data = this.state.fields;

      axios
        .post(api + "adminuser/", data, this.state.requestConfig)
        .then((response) => {
          console.log("la reponse", response);
          this.props.getAdmins();
          window.$(".add-admin-modal").modal("hide");
          e.target.reset();
          this.setState({
            isLoading: false,
            fields: { first_name: "", last_name: "", phone: "", email: "" },
            error: {},
          });
          toast.success("Administrateur ajouté avec succés.");
        })
        .catch((error) => {
          console.log("erreur", error.response);
        });
    }
  };

  render() {
    return (
      <div className="modal-dialog easypm-modal-dialog modal-lg">
        <div className="modal-content easypm-modal-content">
          <div className="modal-header easypm-modal-header">
            <h5
              className="modal-title easypm-modal-title"
              id="AddingFamilyModal"
            >
              Ajouter un administrateur
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body easypm-modal-body">
            <div className="add-family-form-container">
              <form className="crud-form" onSubmit={(e) => this.onSubmit(e)}>
                <div className="row crud-row">
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label for="first_name">Prénom</label>
                      <input
                        type="text"
                        placeholder="Prénom"
                        name="first_name"
                        onChange={this.handleChange}
                        className="form-control crud-form-control"
                        id="first_name"
                      />
                      {this.state.is_empty_first === true && (
                        <Message
                          className="alert-rsuite-custom"
                          type="error"
                          description={this.state.error.first_name}
                        />
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label for="last_name">Nom</label>
                      <input
                        type="text"
                        placeholder="Nom"
                        name="last_name"
                        onChange={this.handleChange}
                        className="form-control crud-form-control"
                        id="last_name"
                      />
                      {this.state.is_empty_last === true && (
                        <Message
                          className="alert-rsuite-custom"
                          type="error"
                          description={this.state.error.last_name}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label for="email">Email</label>
                      <input
                        type="email"
                        placeholder="Email"
                        name="email"
                        onChange={this.handleChange}
                        className="form-control crud-form-control"
                        id="email"
                      />
                      {this.state.is_empty_email === true && (
                        <Message
                          className="alert-rsuite-custom"
                          type="error"
                          description={this.state.error.email}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label for="phone">Téléphone</label>
                      <input
                        type="number"
                        placeholder="Téléphone"
                        name="phone"
                        minlength={9}
                        onChange={this.handleChange}
                        className="form-control crud-form-control"
                        id="phone"
                      />
                      {this.state.is_empty_phone === true && (
                        <Message
                          className="alert-rsuite-custom"
                          type="error"
                          description={this.state.error.phone}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                    <button
                      className="btn btn-default cancelleb-btn"
                      data-dismiss="modal"
                    >
                      Retour
                    </button>
                    {!this.state.isLoading && (
                      <button className="btn btn-primary crud-submit-btn">
                        Enregistrer
                      </button>
                    )}
                    {this.state.isLoading && (
                      <button className="btn btn-primary crud-submit-btn">
                        En cours &nbsp;
                        <Loader />
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
