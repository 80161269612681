import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { Loader, Message } from "rsuite";
import axios from "axios";
import api from "../../../http/global-vars";

function ReseauSocial() {
  const [isLoading, setIsLoading] = useState(false);
  const [dataRs, setDataRs] = useState({
    email: "diekkesells@gmail.com",
    telephone: "(+221) 77 168 30 05",
    facebook: "",
    instagram: "",
    twitter: "",
    youtube: "",
    linkedin: "",
    tiktok: "",
  });
  let config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
    },
  };
  const validationSchema = yup.object().shape({
    email: yup.string().email().required().label("L'email"),
    telephone: yup.string().required().label("Le numéro de téléphone"),
    facebook: yup.string().url().label("Facebook").nullable(),
    instagram: yup.string().url().label("Instagram").nullable(),
    youtube: yup.string().url().label("Youtube").nullable(),
    twitter: yup.string().url().label("Twitter").nullable(),
    tiktok: yup.string().url().label("Tiktok").nullable(),
    linkedin: yup.string().url().label("linkedin").nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValue: dataRs,
  });

  useEffect(() => {
    onGetRS();
  }, []);

  useEffect(() => {
    if (dataRs?.slug) {
      const fields = [
        "slug",
        "email",
        "telephone",
        "facebook",
        "instagram",
        "tiktok",
        "twitter",
        "linkedin",
        "youtube",
      ];
      for (let field of fields) {
        register(field);
        setValue(field, dataRs[field]);
      }
    }
  }, [dataRs]);

  const onGetRS = () => {
    axios
      .get(`${api}reseausocial/`, config)
      .then((res) => {
        console.log("get", res);
        if (res.data.results?.length) {
          setDataRs(res.data.results[0]);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (errors) {
      return setTimeout(
        () => Object.entries(errors).map(([key]) => clearErrors(key)),
        3000
      );
    }
  }, [errors]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    let req = null
    if (data?.slug) {
      req = axios.put(`${api}reseausocial/${data?.slug}/`, data, config);
    } else {
      req = axios.post(`${api}reseausocial/`, data, config);
    }
    await req
      .then(async (res) => {
        await onGetRS();
        await setIsLoading(false);
        console.log("added", res);
        await Swal.fire({
          icon: "success",
          title: "Informations enregistrées avec succès!",
          iconColor: "#7b1cc3b0",
          showConfirmButton: false,
          timer: 2000,
        });
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err", err);
        Swal.fire({
          icon: "error",
          title: `${
            err?.response?.data?.message ||
            `Une erreur de statut ${
              err?.response?.status || "inconnue"
            } est survenue`
          }`,
          showConfirmButton: false,
          timer: 5000,
        });
      });
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="my-5">
          <p className="consigne-title-steps parametres">
            Informations supplémentaires
          </p>
          <div className="row">
            <div className="col-md-6 pr-xl-5 pr-lg-0 pr-md-4">
              <div className="form-group pos-relative mb-4">
                <label htmlFor="inputMail" className="user-info-label">
                  Email de Diekkes
                </label>
                <input
                  type="email"
                  id="inputMail"
                  {...register("email")}
                  className="form-control input-steps-inscription-vendeur dashboard-parametre-input"
                  placeholder="Email *"
                />
                {errors?.email && (
                  <Message
                    className="alert-rsuite-custom"
                    type="error"
                    description={errors?.email?.message}
                  />
                )}
              </div>
              <div className="form-group pos-relative mb-4">
                <label htmlFor="inputPhone" className="user-info-label">
                  Téléphone de Diekkes
                </label>
                <input
                  type="tel"
                  id="inputPhone"
                  {...register("telephone")}
                  className="form-control input-steps-inscription-vendeur dashboard-parametre-input"
                  placeholder="Téléphone"
                />
                {errors?.telephone && (
                  <Message
                    className="alert-rsuite-custom"
                    type="error"
                    description={errors?.telephone?.message}
                  />
                )}
              </div>
              {/* <div className="form-group pos-relative mb-4">
                <label htmlFor="inputTwitter" className="user-info-label">
                  Lien twitter de Diekkes
                </label>
                <input
                  type="url"
                  id="inputTwitter"
                  {...register("twitter")}
                  name="twitter"
                  className="form-control input-steps-inscription-vendeur dashboard-parametre-input"
                  placeholder="Lien twitter"
                />
                {errors?.twitter && (
                  <Message
                    className="alert-rsuite-custom"
                    type="error"
                    description={errors?.twitter?.message}
                  />
                )}
              </div>

              <div className="form-group pos-relative mb-4">
                <label htmlFor="inputlinkedin" className="user-info-label">
                  Lien linkedin de Diekkes
                </label>
                <input
                  type="url"
                  id="inputlinkedin"
                  {...register("linkedin")}
                  name="linkedin"
                  className="form-control input-steps-inscription-vendeur dashboard-parametre-input"
                  placeholder="Lien linkedin"
                />
                {errors?.linkedin && (
                  <Message
                    className="alert-rsuite-custom"
                    type="error"
                    description={errors?.linkedin?.message}
                  />
                )}
              </div> */}
              <div className="form-group pos-relative mb-4">
                <label htmlFor="inputyoutube" className="user-info-label">
                  Lien youtube de Diekkes
                </label>
                <input
                  type="url"
                  id="inputyoutube"
                  {...register("youtube")}
                  name="youtube"
                  className="form-control input-steps-inscription-vendeur dashboard-parametre-input"
                  placeholder="Lien youtube"
                />
                {errors?.youtube && (
                  <Message
                    className="alert-rsuite-custom"
                    type="error"
                    description={errors?.youtube?.message}
                  />
                )}
              </div>
            </div>
            <div className="col-md-6 pl-lg-5 pl-md-4">
              <div className="form-group pos-relative mb-4">
                <label htmlFor="inputFb" className="user-info-label">
                  Lien facebook de Diekkes
                </label>
                <input
                  type="url"
                  id="inputFb"
                  {...register("facebook")}
                  name="facebook"
                  className="form-control input-steps-inscription-vendeur dashboard-parametre-input number-input-custom"
                  placeholder="Lien facebook"
                />
                {errors?.facebook && (
                  <Message
                    className="alert-rsuite-custom"
                    type="error"
                    description={errors?.facebook?.message}
                  />
                )}
              </div>
              <div className="form-group pos-relative mb-4">
                <label htmlFor="inputInsta" className="user-info-label">
                  Lien instagram de Diekkes
                </label>
                <input
                  type="url"
                  id="inputInsta"
                  {...register("instagram")}
                  name="instagram"
                  className="form-control input-steps-inscription-vendeur dashboard-parametre-input"
                  placeholder="Lien instagram"
                />
                {errors?.instagram && (
                  <Message
                    className="alert-rsuite-custom"
                    type="error"
                    description={errors?.instagram?.message}
                  />
                )}
              </div>

              {/* <div className="form-group pos-relative mb-4">
                <label htmlFor="inputyoutube" className="user-info-label">
                  Lien youtube de Diekkes
                </label>
                <input
                  type="url"
                  id="inputyoutube"
                  {...register("youtube")}
                  name="youtube"
                  className="form-control input-steps-inscription-vendeur dashboard-parametre-input"
                  placeholder="Lien youtube"
                />
                {errors?.youtube && (
                  <Message
                    className="alert-rsuite-custom"
                    type="error"
                    description={errors?.youtube?.message}
                  />
                )}
              </div> */}

              <div className="form-group pos-relative mb-4">
                <label htmlFor="inputtiktok" className="user-info-label">
                  Lien tiktok de Diekkes
                </label>
                <input
                  type="url"
                  id="inputtiktok"
                  {...register("tiktok")}
                  name="tiktok"
                  className="form-control input-steps-inscription-vendeur dashboard-parametre-input"
                  placeholder="Lien tiktok"
                />
                {errors?.tiktok && (
                  <Message
                    className="alert-rsuite-custom"
                    type="error"
                    description={errors?.tiktok?.message}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row dis-flex">
          <div className="ml-auto col-lg-6 col-md-7 pl-lg-5 pl-md-4">
            <button
              type="submit"
              className="btn-user-info-form text-up"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  {" "}
                  En cours &nbsp;
                  <Loader />
                </>
              ) : (
                "ENREGISTRER"
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ReseauSocial;
