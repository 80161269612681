import React, { Component } from 'react';
import { InputNumber, Row, Col, RangeSlider, InputGroup } from 'rsuite';
import './theme-range.css';
import { sortProducts } from "../../productAction/productAction";
import { connect } from 'react-redux';

class RangeSlide extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: [10, 500]
        }
    }

    render() {
        const { value } = this.state
        return (
            <div>
                <Row>
                    <Col md={24} className="m-b-20">
                    <RangeSlider
                        progress
                        style={{ marginTop: 16 }}
                        value={value}
                        max={1000000}
                        onChange={value => {
                            this.setState({value})
                        this.props.sortProducts(this.props.products, value[0], value[1])
                        }}
                    />
                    </Col>
                    <Col md={11} sm={24}>
                        <InputGroup>
                            <InputNumber
                            min={0}
                            max={1000}
                            value={value[0]}
                            onChange={nextValue => {
                                const [end] = value;
                                if (nextValue > end) {
                                return;
                                }
                               // setValue([nextValue, end]);
                                this.setState([nextValue, end])
                            }}
                            />
                            <InputGroup.Addon> - </InputGroup.Addon>
                            <InputNumber
                            min={0}
                            max={1000}
                            value={value[1]}
                            onChange={nextValue => {
                                const [start] = value;
                                if (start > nextValue) {
                                return;
                                }
                                //setValue([start, nextValue]);
                                this.setState([start, nextValue])
                            }}
                            />
                        </InputGroup>
                    </Col>
                </Row>
            </div>
        );
    }
    
}


export default connect(
    (state) => ({
      min: state.products.min,
      products: !state.products.filteredItems?.length ? state.products.items : state.products.filteredItems,
      filteredProducts: state.products.filteredItems,
    }),
    {
        sortProducts
    }
  )(RangeSlide);
//export default RangeSlide;