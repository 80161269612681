import React, { Component } from "react";
import "./socialLink.css";
// import { NavLink } from "react-router-dom";
import instagramIcon from "../../img/icons/instagram.png";
import tiktopIcon from "../../img/icons/tiktok.png";
import IconYoutube from "../../img/icons/youtube.png";
import IconFacebook from "../../img/icons/facebook.png";

export default class socialLink extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {infos} = this.props
    return (
      <div>
        <a href={infos?.instagram || "#"} target="_blank" rel="noreferrer" >
          <img loading="lazy" className="icon-reseau-social" src={instagramIcon} alt="" />
        </a>
        <a href={infos?.tiktok || "#"} target="_blank" rel="noreferrer">
          <img loading="lazy" className="icon-reseau-social" src={tiktopIcon} alt="" />
        </a>
        {!!infos?.youtube && <a href={infos?.youtube || "#"} target="_blank" rel="noreferrer">
          <img loading="lazy" className="icon-reseau-social" src={IconYoutube} alt="" />
        </a> }
        <a href={infos?.facebook || "#"} target="_blank" rel="noreferrer">
          <img loading="lazy" className="icon-reseau-social" src={IconFacebook} alt="" />
        </a>
      </div>
    );
  }
}
// export default connect(
//     ({ socialLink }) => ({ ...socialLink }),
//     dispatch => bindActionCreators({ ...socialLinkActions }, dispatch)
//   )( socialLink );
