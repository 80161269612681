import { createSlice } from "@reduxjs/toolkit";
import { AppLocalStorage } from "../../storage";
// import { AppLocalStorage } from "../../utils/storage";

const initialState = AppLocalStorage.getItem("user", {
  user: undefined,
  token: undefined,
});
export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    onSetUser: (state, action) => {
      state.user = action.payload;
      AppLocalStorage.setItem("user", state);
    },
    onSetToken: (state, action) => {
      state.token = action.payload;
      AppLocalStorage.setItem("token", state.token);
    },
    onSetUserToken: (state, action) => {
      console.log("user", action);
      state.user = action.payload.user;
      state.token = action.payload.token;
      AppLocalStorage.setItem("user", state);
    },
    onlogout: (state) => {
      state.user = null;
      state.token = null;
      AppLocalStorage.setItem("user", state);
      AppLocalStorage.clear();
      AppLocalStorage.clear();
    },
    onSetUserTokenOnLocalStorage: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      AppLocalStorage.setItem("user", state);
    },
  },
});

export const {
  onSetUser,
  onSetUserToken,
  onSetToken,
  onlogout,
  onSetUserTokenOnLocalStorage,
} = UserSlice.actions;
export default UserSlice.reducer;
