import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { prepareHeaders } from "./user.api";
import api from "../../http/global-vars";

export const FavoriteApi = createApi({
  reducerPath: "favoriteApi",
  tagTypes: ["favoriteList"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${api}`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getFavoriteList: builder.query({
      providesTags: ["favoriteList"],
      query: (id) => `favorisbyuser/${id}/`,
    }),
    addOrDeleteFavorite: builder.mutation({
      invalidatesTags: ["favoriteList"],
      query: ({ id, ...data }) => {
        if (id) {
          return {
            url: `favoris/${id}/`,
            method: "DELETE"
          };
        }
        return {
          url: `favoris/`,
          method: "POST",
          body: data,
        };
      },
    }),
  }),
});

export const { useAddOrDeleteFavoriteMutation, useGetFavoriteListQuery,useLazyGetFavoriteListQuery } =
  FavoriteApi;
