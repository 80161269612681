import { CATEGORIES } from "../types";

export const categoriesReducer = (
  state = { isLoading: true, data: [] },
  action
) => {
  switch (action.type) {
    case CATEGORIES:
      return { isLoading: false, data: action.payload };
    default:
      return state;
  }
};