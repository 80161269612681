import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import api from "../../http/global-vars";
import { QueryUrl } from "../utils";
import { prepareHeaders } from "./user.api";

export const DashboardApi = createApi({
  reducerPath: "dashboardApi",
  tagTypes: ["dashboardAdmin","dashboardSeller"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${api}`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getDashboardAdmin: builder.query({
      providesTags: ["dashboardAdmin"],
      query: (query) => QueryUrl(`dashboard`, query),
    }),
    getDashboardSeller: builder.query({
      providesTags: ["dashboardSeller"],
      query: ({id,...query}) => QueryUrl(`web/dashboardbyvendeur/${id}`, query),
    }),
  }),
});

export const { useGetDashboardAdminQuery,useGetDashboardSellerQuery } = DashboardApi;
