import React, { Component } from "react";
import "./inscriptionVendeur.css";
import Header from "../header/index";
import Footer from "../footer/index";
import StepsInscriptionVendeur from "./StepsInscriptionVendeur";

export default class inscriptionVendeur extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="body-theme">
        <Header />
        <div className="p-t-40 p-b-150 fixed-after-header">
          <div className="container">
            <div className="steps-content">
              <StepsInscriptionVendeur />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
