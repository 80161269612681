import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import api from "../../http/global-vars";
import { QueryUrl } from "../utils";
import { prepareHeaders } from "./user.api";

export const OrderApi = createApi({
  reducerPath: "orderApi",
  tagTypes: ["orderList", "orderVisitor", "orderbyuser","orderbySeller"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${api}`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getOrderList: builder.query({
      providesTags: ["orderList"],
      query: (query) => QueryUrl(`orderbypagination`, query),
    }),
    getOrderByUser: builder.query({
      providesTags: ["orderbyuser"],
      query: ({ id, ...query }) => QueryUrl(`web/orderbyuser/${id}/`, query),
    }),
    getOrderBySeller: builder.query({
      providesTags: ["orderbySeller"],
      query: ({ id, ...query }) => QueryUrl(`web/orderbyfournisseur/${id}/`, query),
    }),
  }),
});

export const { useGetOrderListQuery, useGetOrderByUserQuery,useGetOrderBySellerQuery } = OrderApi;
