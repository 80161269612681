import React, { Component } from "react";
import "./filterByRate.css";
import { Rate } from "rsuite";
import FilterByRateSkeleton from "../skeleton/filterByRateSkeleton/filterByRateSkeleton";
import { connect } from "react-redux";
import { fetchProducts, filterRate } from "../../productAction/productAction";

class filterByRate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      rate: null,
      productByRate: [],
    };
  }
  componentDidMount() {
    this.setState({ loading: true });

    this.props.fetchProducts();
    // console.log(this.props.products)
    const timer = setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);

    return () => clearTimeout(timer);
  }

  handleChange = (e) => {
    e.persist();

    this.props.filterRate(this.props.products, e.target.value);
  };

  render() {
    if (this.state.loading) {
      return <FilterByRateSkeleton />;
    } else {
      return (
        <div className="component-filter-by-rate">
          <p className="titre-filter-type">Évaliation clients</p>

          <div class="radio">
            <label className="mb-0 hov-pointer">
              <input
                type="radio"
                name="optradio"
                className="d-none"
                value={5}
                onChange={(e) => this.handleChange(e)}
              />
              <span className="rating-custom-filter-text dis-block">
                <Rate
                  value={5}
                  allowHalf
                  readOnly
                  className="rating-custom-filter dis-inline-flex"
                />{" "}
                &nbsp; et plus
              </span>
            </label>
          </div>
          <div class="radio">
            <label className="mb-0 hov-pointer">
              <input
                type="radio"
                name="optradio"
                className="d-none"
                value={4}
                onChange={(e) => this.handleChange(e)}
              />
              <span className="rating-custom-filter-text dis-block">
                <Rate
                  value={4}
                  allowHalf
                  readOnly
                  className="rating-custom-filter dis-inline-flex"
                />{" "}
                &nbsp; et plus
              </span>
            </label>
          </div>
          <div class="radio">
            <label className="mb-0 hov-pointer">
              <input
                type="radio"
                name="optradio"
                className="d-none"
                value={3}
                onChange={(e) => this.handleChange(e)}
              />
              <span className="rating-custom-filter-text dis-block">
                <Rate
                  value={3}
                  allowHalf
                  readOnly
                  className="rating-custom-filter dis-inline-flex"
                />{" "}
                &nbsp; et plus
              </span>
            </label>
          </div>
          <div class="radio">
            <label className="mb-0 hov-pointer">
              <input
                type="radio"
                name="optradio"
                className="d-none"
                value={2}
                onChange={(e) => this.handleChange(e)}
              />
              <span className="rating-custom-filter-text dis-block">
                <Rate
                  value={2}
                  allowHalf
                  readOnly
                  className="rating-custom-filter dis-inline-flex"
                />{" "}
                &nbsp; et plus
              </span>
            </label>
          </div>
          <div class="radio">
            <label className="mb-0 hov-pointer">
              <input
                type="radio"
                name="optradio"
                className="d-none"
                value={1}
                onChange={(e) => this.handleChange(e)}
              />
              <span className="rating-custom-filter-text dis-block">
                <Rate
                  value={1}
                  allowHalf
                  readOnly
                  className="rating-custom-filter dis-inline-flex"
                />{" "}
                &nbsp; et plus
              </span>
            </label>
          </div>
        </div>
      );
    }
  }
}
export default connect(
  (state) => ({
    rate: state.products.averagestar,
    products: state.products.items,
    filteredRate: state.products.filteredItems,
  }),
  {
    fetchProducts,
    filterRate,
  }
)(filterByRate);
