// eslint-disable-next-line
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import { cartReducer } from "./components/reduxCart/cartReducer";
import { orderReducer } from "./orderReducer/orderReducer";
import { productReducer } from "./productReducer/productReducer";
import { shopReducer } from "./shopReducer/shopReducer";
import { transactionReducer } from "./transactionReducer/transactionReducer";
import { usersReducer } from "./usersRedux/usersreducer";
import { categoriesReducer } from "./categoryRedux/categoriesReducer";
import { ProductApi } from "./utils/api/product.api";
import { ShopApi } from "./utils/api/shop.api";
import { OrderApi } from "./utils/api/order.api";
import { UserApi } from "./utils/api/user.api";
import { UserSlice } from "./utils/api/slice/user.slice";
import useCartReducer from "./utils/api/slice/useCartSlice";
import { DashboardApi } from "./utils/api/dashboard.api";
import { FavoriteApi } from "./utils/api/favoris.api";
import storage from "redux-persist/lib/storage";
import {
  persistReducer,
} from "redux-persist";
import { CategoryApi } from "./utils/api/category.api";

// eslint-disable-next-line
// const enhancers = compose(
//   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// );

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: [`${UserSlice.name}`, `cart`],
};

const middlewares = [thunk];
const middlewareEnhancer = applyMiddleware(
  ...middlewares,
  ProductApi.middleware,
  ShopApi.middleware,
  OrderApi.middleware,
  UserApi.middleware,
  DashboardApi.middleware,
  FavoriteApi.middleware,
  CategoryApi.middleware
);

const enhancers = [middlewareEnhancer];
const composedEnhancers = composeWithDevTools(...enhancers);

export const rootReducers = combineReducers({
  cart: useCartReducer,
  products: productReducer,
  // cart: cartReducer,
  orders: orderReducer,
  shops: shopReducer,
  transactions: transactionReducer,
  users: usersReducer,
  categories: categoriesReducer,
  [UserSlice.name]: UserSlice.reducer,
  [UserApi.reducerPath]: UserApi.reducer,
  [ProductApi.reducerPath]: ProductApi.reducer,
  [OrderApi.reducerPath]: OrderApi.reducer,
  [FavoriteApi.reducerPath]: FavoriteApi.reducer,
  [DashboardApi.reducerPath]: DashboardApi.reducer,
  [ShopApi.reducerPath]: ShopApi.reducer,
  [CategoryApi.reducerPath]: CategoryApi.reducer
});

const persistedReducer = persistReducer(persistConfig, rootReducers);

const store = createStore(persistedReducer, composedEnhancers);

export default store;
