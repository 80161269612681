import React,{Component} from 'react';
import api from "../../../http/global-vars";
import { Message } from 'rsuite'

export default class Resume extends Component {
    constructor() {
        super();
        this.state = {
            token: window.localStorage.getItem('userToken'),
            requestConfig: {
                headers: { Authorization: `Bearer ${window.localStorage.getItem('userToken')}` }
            },
            api: api,
            categories: [],
        };
    }

    componentDidMount(){
        window.scrollTo({top: 0, behavior: 'smooth'});
        let { api } = this.state;
        let url = api + `category/`;
        var config = this.state.requestConfig
        fetch(url, config)
            .then((response) => {
                return response.json();
            })
            .then((result) => {
                console.log("result cat")
                var categoryList = result
                this.setState({ categories: categoryList})
            })

    }
    
    render() {
        return (
            <div>
                <form>
                    <p className="consigne-title-steps fs-20 mt-5">Résumé</p>
                    <p className="consigne-title-steps mb-4">Compte Vendeur</p>
                    
                    <div className="row">
                        <div className="col-md-6 pr-lg-5">
                            <div className="form-group mb-4">
                                <input type="text" 
                                    maxLength={30}
                                    className="form-control input-steps-inscription-vendeur" 
                                    placeholder="Prénom *"
                                    value={this.props.userInfos.first_name }
                                    onChange={ this.props.handleChangeCreateShop }
                                    name="first_name" />
                                {this.props.is_empty_first_name && (
                                    <Message className="alert-rsuite-custom" type="error" description={this.props.errors.first_name} />
                                )}
                            </div>
                            <div className="form-group mb-4">
                                <input type="text" 
                                    maxLength={30}
                                    className="form-control input-steps-inscription-vendeur" 
                                    placeholder="Nom *"
                                    value={this.props.userInfos.last_name}
                                    onChange={ this.props.handleChangeCreateShop }
                                    name="last_name" />
                                {this.props.is_empty_last_name && (
                                    <Message className="alert-rsuite-custom" type="error" description={this.props.errors.last_name} />
                                )}
                            </div>
                            <div className="form-group mb-4">
                                <input type="text" 
                                    maxLength={30}
                                    className="form-control input-steps-inscription-vendeur" 
                                    placeholder="N° téléphone *"
                                    value={this.props.userInfos.phone}
                                    onChange={ this.props.handleChangeCreateShop }
                                    name="phone" />
                                {this.props.is_empty_phone && (
                                    <Message className="alert-rsuite-custom" type="error" description={this.props.errors.phone} />
                                )}
                            </div>

                            <div className="form-group mb-4">
                                <input type="text" 
                                    maxLength={30}
                                    className="form-control input-steps-inscription-vendeur" 
                                    placeholder="Nom d'affichage / Nom de la boutique *"
                                    value={this.props.userInfos.shop_name}
                                    onChange={ this.props.handleChangeCreateShop }
                                    name="shop_name" />
                                {this.props.is_empty_shop_name && (
                                    <Message className="alert-rsuite-custom" type="error" description={this.props.errors.shop_name} />
                                )}
                            </div>
                            <div className="form-group mb-4">
                                <input type="text" 
                                    maxLength={30}
                                    className="form-control input-steps-inscription-vendeur" 
                                    placeholder="Whatsapp / Numéro de téléphone alternatif"
                                    value={this.props.userInfos.alternative_phone}
                                    onChange={ this.props.handleChangeCreateShop }
                                    name="alternative_phone" />
                            </div>

                            <div className="form-group mb-4">
                                <input type="text" 
                                    maxLength={30}
                                    className="form-control input-steps-inscription-vendeur" 
                                    placeholder="Statut"
                                    value={this.props.userInfos.statut}
                                    onChange={ this.props.handleChangeCreateShop }
                                    name="statut" />
                            </div>
                            <div className="form-group mb-4">
                                <input type="email" 
                                    maxLength={50}
                                    className="form-control input-steps-inscription-vendeur" 
                                    placeholder="Adresse mail *"
                                    disabled="disabled"
                                    value={this.props.userInfos.email}
                                    onChange={ this.props.handleChangeCreateShop }
                                    name="email" />
                                {this.props.is_empty_email && (
                                    <Message className="alert-rsuite-custom" type="error" description={this.props.errors.email} />
                                )}
                            </div>
                        </div>
                    </div>
                    

                    <div className="row">
                        <div className="col-md-6 pr-lg-5">
                            <div id="accrodionResumeSteps">
                                <div className="accordion" id="faq">
                                    <div className="card">
                                        <div className="card-header" id="faqhead1">
                                            <a href="/" className="btn-header-link collapsed" data-toggle="collapse" data-target="#faq1"
                                            aria-expanded="true" aria-controls="faq1">Informations d’affaires</a>
                                        </div>

                                        <div id="faq1" className="collapse" aria-labelledby="faqhead1" data-parent="#faq">
                                            <div className="py-3">
                                                <div className="form-group mb-4">
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-9">
                                                            <select name="option" 
                                                                value={this.props.userInfos.option}
                                                                className="form-control select-steps-inscription-vendeur" 
                                                                onChange={ this.props.handleChangeCreateShop }
                                                            >
                                                                <option disabled selected>Choisir une option</option>
                                                                <option value="Entité commerciale">Personne physique</option>
                                                                <option value="Entreprise">Entité commerciale/Entreprise</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {this.props.is_empty_option && (
                                                        <Message className="alert-rsuite-custom" type="error" description={this.props.errors.option} />
                                                    )}
                                                </div>

                                                <div className="form-group mb-4">
                                                    <input type="text" 
                                                        maxLength={30}
                                                        className="form-control input-steps-inscription-vendeur" 
                                                        placeholder="Nom Légal / Raison sociale" 
                                                        name="legal_name"
                                                        value={this.props.userInfos.legal_name}
                                                        onChange={ this.props.handleChangeCreateShop }/>
                                                    {this.props.is_empty_legal_name && (
                                                        <Message className="alert-rsuite-custom" type="error" description={this.props.errors.legal_name} />
                                                    )}
                                                </div>
                                                <div className="form-group mb-4">
                                                    <input type="text" 
                                                        maxLength={15}
                                                        className="form-control input-steps-inscription-vendeur" 
                                                        placeholder="NINEA" 
                                                        name="ninea"
                                                        value={this.props.userInfos.ninea}
                                                        onChange={ this.props.handleChangeCreateShop }/>
                                                    {this.props.is_empty_ninea && (
                                                        <Message className="alert-rsuite-custom" type="error" description={this.props.errors.ninea} />
                                                    )}
                                                </div>
                                                <div className="form-group mb-4">
                                                    <input type="text" 
                                                        maxLength={30}
                                                        className="form-control input-steps-inscription-vendeur" 
                                                        placeholder="Quel est le nom du / des compte (s)?"
                                                        name="account_name"
                                                        value={this.props.userInfos.account_name}
                                                        onChange={ this.props.handleChangeCreateShop } />
                                                    {this.props.is_empty_account_name && (
                                                        <Message className="alert-rsuite-custom" type="error" description={this.props.errors.account_name} />
                                                    )}
                                                </div>
                                                <div className="form-group mb-4">
                                                    <input type="text" 
                                                        maxLength={30}
                                                        className="form-control input-steps-inscription-vendeur" 
                                                        placeholder="Forme juridique" 
                                                        name="legal_form"
                                                        value={this.props.userInfos.legal_form}
                                                        onChange={ this.props.handleChangeCreateShop }/>
                                                    {this.props.is_empty_legal_form && (
                                                        <Message className="alert-rsuite-custom" type="error" description={this.props.errors.legal_form} />
                                                    )}
                                                </div>
                                                <div className="form-group mb-4">
                                                    <input type="text" 
                                                        maxLength={30}
                                                        className="form-control input-steps-inscription-vendeur" 
                                                        placeholder="Adresse" 
                                                        name="adresse"
                                                        value={this.props.userInfos.adresse}
                                                        onChange={ this.props.handleChangeCreateShop }/>
                                                    {this.props.is_empty_adresse && (
                                                        <Message className="alert-rsuite-custom" type="error" description={this.props.errors.adresse} />
                                                    )}
                                                </div>
                                                <div className="form-group mb-4">
                                                    <input type="text" 
                                                        maxLength={15}
                                                        className="form-control input-steps-inscription-vendeur" 
                                                        placeholder="Pays *" 
                                                        name="country"
                                                        value={this.props.userInfos.country}
                                                        onChange={ this.props.handleChangeCreateShop } />
                                                    {this.props.is_empty_country && (
                                                        <Message className="alert-rsuite-custom" type="error" description={this.props.errors.country} />
                                                    )}
                                                </div>
                                                <div className="form-group mb-4">
                                                    <input type="text" 
                                                        maxLength={30}
                                                        className="form-control input-steps-inscription-vendeur" 
                                                        placeholder="Commune / Ville *" 
                                                        name="city"
                                                        value={this.props.userInfos.city}
                                                        onChange={ this.props.handleChangeCreateShop }/>
                                                    {this.props.is_empty_city && (
                                                        <Message className="alert-rsuite-custom" type="error" description={this.props.errors.city} />
                                                    )}
                                                </div>
                                                <div className="form-group mb-4">
                                                    <input type="text" 
                                                        maxLength={5}
                                                        className="form-control input-steps-inscription-vendeur" 
                                                        placeholder="Code postal" 
                                                        name="code_postal"
                                                        value={this.props.userInfos.code_postal}
                                                        onChange={ this.props.handleChangeCreateShop }/>
                                                    {this.props.is_empty_code_postal && (
                                                        <Message className="alert-rsuite-custom" type="error" description={this.props.errors.code_postal} />
                                                    )}
                                                </div>
                                                <div className="form-group mb-4">
                                                    <input type="text"
                                                        maxLength={30} 
                                                        className="form-control input-steps-inscription-vendeur" 
                                                        placeholder="Personne responsable" 
                                                        name="person_in_charge"
                                                        value={this.props.userInfos.person_in_charge}
                                                        onChange={ this.props.handleChangeCreateShop }/>
                                                    {this.props.is_empty_person_in_charge && (
                                                        <Message className="alert-rsuite-custom" type="error" description={this.props.errors.person_in_charge} />
                                                    )}
                                                </div>
                                                <div className="form-group mb-4">
                                                    <select name="products_main_category" 
                                                        className="form-control select-steps-inscription-vendeur" 
                                                        value={this.props.userInfos.products_main_category}
                                                        onChange={ this.props.handleChangeCreateShop }
                                                    >
                                                        <option disabled>Catégorie Principale de vos produits</option>
                                                        {this.state.categories.map(category => (
                                                            <option value={category.id}>{category.name}</option>
                                                        ))}
                                                    </select>
                                                    {this.props.is_empty_products_main_category && (
                                                        <Message className="alert-rsuite-custom" type="error" description={this.props.errors.products_main_category} />
                                                    )}
                                                </div>
                                                <div className="form-group mb-4">
                                                    <input type="text" 
                                                        maxLength={30}
                                                        className="form-control input-steps-inscription-vendeur" 
                                                        placeholder="N° d'Enregistrement des Entreprises" 
                                                        name="company_registration_number"
                                                        value={this.props.userInfos.company_registration_number}
                                                        onChange={ this.props.handleChangeCreateShop }/>
                                                    {this.props.is_empty_company_registration_number && (
                                                        <Message className="alert-rsuite-custom" type="error" description={this.props.errors.company_registration_number} />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="faqhead2">
                                            <a href="/" className="btn-header-link collapsed" data-toggle="collapse" data-target="#faq2"
                                            aria-expanded="true" aria-controls="faq2">Compte bancaire</a>
                                        </div>

                                        <div id="faq2" className="collapse" aria-labelledby="faqhead2" data-parent="#faq">
                                            <div className="py-3">
                                                <div className="form-group mb-4">
                                                    <input type="text" 
                                                        maxLength={30}
                                                        className="form-control input-steps-inscription-vendeur" 
                                                        placeholder="Nom de votre banque" 
                                                        name="bank_name"
                                                        value={this.props.userInfos.bank_name}
                                                        onChange={ this.props.handleChangeCreateShop }
                                                    />
                                                    {this.props.is_empty_bank_name && (
                                                        <Message className="alert-rsuite-custom" type="error" description={this.props.errors.bank_name} />
                                                    )}
                                                </div>
                                                <div className="form-group mb-4">
                                                    <input type="text" 
                                                        maxLength={30}
                                                        className="form-control input-steps-inscription-vendeur" 
                                                        placeholder="Numéro de compte" 
                                                        name="account_number"
                                                        value={this.props.userInfos.account_number}
                                                        onChange={ this.props.handleChangeCreateShop }
                                                    />
                                                    {this.props.is_empty_account_number && (
                                                        <Message className="alert-rsuite-custom" type="error" description={this.props.errors.account_number} />
                                                    )}
                                                </div>
                                                <div className="form-group mb-4">
                                                    <input type="text" 
                                                        maxLength={23}
                                                        className="form-control input-steps-inscription-vendeur" 
                                                        placeholder="IBAN" 
                                                        name="iban"
                                                        value={this.props.userInfos.iban}
                                                        onChange={ this.props.handleChangeCreateShop }
                                                    />
                                                    {this.props.is_empty_iban && (
                                                        <Message className="alert-rsuite-custom" type="error" description={this.props.errors.iban} />
                                                    )}
                                                </div>
                                                <div className="form-group mb-4">
                                                    <input type="text" 
                                                        maxLength={30}
                                                        className="form-control input-steps-inscription-vendeur" 
                                                        placeholder="Nom du titulaire de compte" 
                                                        name="account_holder_name"
                                                        value={this.props.userInfos.account_holder_name}
                                                        onChange={ this.props.handleChangeCreateShop }
                                                    />
                                                    {this.props.is_empty_account_holder_name && (
                                                        <Message className="alert-rsuite-custom" type="error" description={this.props.errors.account_holder_name} />
                                                    )}
                                                </div>
                                                <div className="form-group mb-4">
                                                    <input type="text" 
                                                        maxLength={11}
                                                        className="form-control input-steps-inscription-vendeur" 
                                                        placeholder="SWIFT" 
                                                        name="swift"
                                                        value={this.props.userInfos.swift}
                                                        onChange={ this.props.handleChangeCreateShop }
                                                    />
                                                    {this.props.is_empty_swift && (
                                                        <Message className="alert-rsuite-custom" type="error" description={this.props.errors.swift} />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </form>
            </div>
        );
    }
}

