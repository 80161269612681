import React, { Component, useEffect, useState } from "react";
import "./headerDashboard.css";
import Logo from "../../../img/Diekkes.png";
import { NavLink } from "react-router-dom";
import ShopIconHeader from "../../../img/icons/home-icon-dashboard-header.png";
import { Rate } from "rsuite";
import api from "../../../http/global-vars";
import axios from "axios";
import FeatherIcon from "feather-icons-react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { onlogout } from "../../../utils/api/slice/user.slice";
import { useGetUserShopQuery } from "../../../utils/api/shop.api";

function HeaderDashboard() {
  const dispatch = useDispatch();
  const [shopDetails, setShopDetails] = useState({});
  const { user } = useSelector((s) => s?.user);
  const { data = [], isLoading } = useGetUserShopQuery(user?.id);
  const [note, setNote] = useState(0);

  useEffect(() => {
    if (user?.provider) {
      setShopDetails(user?.provider);
      setNote(parseInt(user?.provider?.note));
    }
  }, [user]);

  const onLogoutUser = () => {
    dispatch(onlogout());
    window.location = "/connexion";
  };
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white box-shadow nav-header-custom fixed-top">
      <div className="container-fluid px-lg-5">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarDashboard"
          aria-controls="navbarDashboard"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <NavLink className="navbar-brand ml-lg-5" to="/">
          <img
            loading="lazy"
            className="logo-header"
            src={Logo}
            alt="Marketplace"
          />
        </NavLink>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarDashboard"
          aria-controls="navbarDashboard"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className="collapse navbar-collapse mt-lg-0 mt-3 body-theme-mobile"
          id="navbarDashboard"
        >
          <ul className="navbar-nav mx-auto py-lg-0 py-5 pl-lg-0 pl-3">
            <div className="d-flex">
              {isLoading ? (
                <div className="itm-center mb-lg-0 mb-3">
                  <Skeleton
                    className="shop-icon-header-dashboard"
                    width={20}
                    height={20}
                  />
                  <Skeleton
                    className="shop-name-header-dashboard"
                    width={200}
                    height={28}
                  />
                </div>
              ) : (
                <div className="itm-center mb-lg-0 mb-3">
                  <img
                    loading="lazy"
                    className="shop-icon-header-dashboard"
                    src={ShopIconHeader}
                    alt={shopDetails.shop_name}
                  />
                  <p className="shop-name-header-dashboard">
                    {shopDetails.shop_name}
                  </p>
                </div>
              )}
            </div>

            {isLoading ? (
              <Skeleton
                className="link-rate-header-dashboard ml-lg-5 mb-lg-0 mb-3"
                width={170}
              />
            ) : (
              <NavLink
                className="link-rate-header-dashboard ml-lg-5 mb-lg-0 mb-3"
                to={`/details-evaluation-boutique/${shopDetails.id}`}
              >
                <Rate
                  value={note}
                  allowHalf
                  readOnly
                  className="rate-header-dashboard"
                />
                <span className="rate-number-header-dashboard">{note}/5</span>
                <FeatherIcon className="icon-header-dashboard" icon="eye" />
              </NavLink>
            )}
          </ul>
          <div className="pl-lg-0 pl-3 mb-lg-0 mb-3">
            <button className="logout" onClick={() => onLogoutUser()}>
              Déconnexion
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default HeaderDashboard;
