import api from "../http/global-vars";
import _ from "lodash";
import {
  FETCH_PRODUCTS,
  FILTER_PRODUCTS_BY_SHOP,
  PRODUCTS_BY_SHOP,
} from "../types";
import {
  FILTER_PRODUCTS_BY_SIZE,
  FILTER_PRODUCTS_BY_PRICE,
  FILTER_PRODUCTS_BY_CATEGORY,
  FILTER_PRODUCTS_BY_RATE,
} from "../types";
import { useSelector } from "react-redux";

export const fetchProducts = () => {
  return async (dispatch) => {
    const url = api + "productbyvisitor/";
    const res = await fetch(url);
    const data = await res.json();
    // console.log("data product", data);
    dispatch({
      type: FETCH_PRODUCTS,
      payload: data?.filter((item) => item?.is_archive === false),
      isLoading: false,
    });
  };
};

export const fetchProductsByShop = (id) => {
  return async (dispatch) => {
    const url = api + "productbyfournisseur/" + id;
    const res = await fetch(url);
    const data = await res.json();
    console.log("produits par fournisseur", data);

    dispatch({
      type: PRODUCTS_BY_SHOP,
      payload: data?.results,
      isLoading: false,
    });
  };
};

export const filterProducts = (products, size) => {
  return (dispatch) => {
    dispatch({
      type: FILTER_PRODUCTS_BY_SIZE,
      payload: {
        size: size,
        items:
          size === ""
            ? products
            : products.filter((x) => x.availableSizes.indexOf(size) >= 0),
      },
    });
  };
};

export const filterRate = (products, rate) => {
  let data = [];

  // eslint-disable-next-line
  products.filter((x) => {
    for (let i = 0; i < rate.length; i++) {
      const element = rate[i];
      if (x.averagestar.indexOf(element) >= 0) {
        data.push(x);
      }
    }
    // console.log( 'les produits filtré', data, rate)
  });
  return (dispatch) => {
    dispatch({
      type: FILTER_PRODUCTS_BY_RATE,
      payload: {
        rate: rate,
        items: !rate?.length
          ? products
          : _.uniqBy(data, function (e) {
              return e.id;
            }),
      },
    });
  };
};

export const FilterCategory = (products, categoryID = [], categories = []) => {
  let selected = categories?.filter((item) =>
    categoryID?.includes(parseInt(item?.id))
  );
  //products.filter((x) => console.log('object', x.category.indexOf(2)))
  let subs = [];
  let x = (checked) => checked.filter((v, i) => checked.indexOf(v) === i);
  for (let i = 0; i < selected.length; i++) {
    const element = selected[i];
    subs = [...subs, ...element?.subcategories?.map((el) => el?.id)];
  }
  let CatSubs = [...categoryID, ...subs];

  // console.log('voir', categoryID,subs,x(CatSubs))
  let final = x(CatSubs);
  let data = [];
  // eslint-disable-next-line
  products?.filter((x) => {
    for (let i = 0; i < final.length; i++) {
      const element = final[i];
      if (x.category.indexOf(element) >= 0) {
        data.push(x);
      }
    }
  });
  return (dispatch) => {
    dispatch({
      type: FILTER_PRODUCTS_BY_CATEGORY,
      payload: {
        category: final,
        items: !final?.length
          ? products
          : _.uniqBy(data, function (e) {
              return e.id;
            }),
      },
    });
  };
};

export const filterShop = (products, shopID = []) => {
  //products.filter((x) => console.log('object', x.category.indexOf(2)))
  let data = [];
  // eslint-disable-next-line
  if (products?.length) {
    products?.filter((x) => {
      for (let i = 0; i < shopID.length; i++) {
        const element = shopID[i];
        if (x.provider?.id === element) {
          data.push(x);
        }
      }
    });
  }

  return (dispatch) => {
    dispatch({
      type: FILTER_PRODUCTS_BY_SHOP,
      payload: {
        shop: shopID,
        items: !shopID?.length
          ? products
          : _.uniqBy(data, function (e) {
              return e.id;
            }),
      },
    });
  };
};

export const sortProducts = (filteredProducts, min, max) => (dispatch) => {
  let data = [];
  // eslint-disable-next-line
  filteredProducts.filter((p) => {
    if (min <= p.price && max >= p.price) {
      data.push(p);
    }
  });
  dispatch({
    type: FILTER_PRODUCTS_BY_PRICE,
    payload: {
      min: min,
      max: max,
      items:
        max <= 0
          ? filteredProducts
          : _.uniqBy(data, function (e) {
              return e.id;
            }),
    },
  });
};
