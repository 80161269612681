import React, { Component, useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import "./addLogoShopModal.css";
import { Modal, ButtonToolbar, Button, Message, Loader } from "rsuite";
import EditIcon from "@material-ui/icons/Edit";
import api from "../../../http/global-vars";
import axios from "axios";
import Swal from "sweetalert2";
import { cleannerError } from "../../../utils/utils";
import { useEditShopMutation } from "../../../utils/api/user.api";
import { useSelector } from "react-redux";

function AddLogoShopModal() {
  const validationSchema = yup.object().shape({
    logo: yup.mixed().required().label("L'image"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [placeholder, setPlaceholder] = useState("Importer un ficher");
  const [show, setShow] = useState(false);
  const [sendData, { isLoading, isSuccess, error, isError }] =
    useEditShopMutation();
  const { user } = useSelector((s) => s?.user);

  useEffect(() => {
    if (isSuccess) {
      setPlaceholder("Importer un ficher");
      close();
    }
    const err = error;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const close = () => {
    setShow(false);
  };
  const open = () => {
    setShow(true);
  };

  const handleChange = (e) => {
    let file = e.target.files[0];
    if (file) {
      setValue("logo", file);
      setPlaceholder(file?.name);
    }
  };

  const onSubmit = async (data) => {
    const fd = new FormData();

    for (let key of Object.keys(data)) {
      if (key === "logo" || key === "banner") {
        if (data[key]) {
          fd.append(key, data[key]);
        }
      } else {
        fd.append(key, data[key]);
      }
    }
    await sendData({
      id: user?.provider?.id,
      data: fd,
    });
  };
  return (
    <div className="modal-container">
      <ButtonToolbar>
        <Button className="edit-btn-modal" onClick={() => open()}>
          <EditIcon className="edit-icon-modal" />
        </Button>
      </ButtonToolbar>

      <Modal
        size="xs"
        show={show}
        onHide={() => close()}
        className="rsuite-content-modal-custom"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header>
            <Modal.Title>Ajouter votre logo</Modal.Title>
          </Modal.Header>
          <div>
            <div className="custom-file mt-5 mb-5">
              <input
                type="file"
                name="logo"
                className="custom-file-input input-custom-import-file"
                id="customFile"
                onChange={(e) => handleChange(e)}
              />
              <label
                className="custom-file-label label-custom-import-file"
                htmlFor="customFile"
              >
                {placeholder}
              </label>
              {errors?.logo && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={errors?.logo?.message}
                />
              )}
            </div>
          </div>
          <Modal.Footer className="text-center mt-4">
            <button className="btn-change-password" disabled={isLoading}>
              {" "}
              {!!isLoading && (
                <>
                  EN COURS &nbsp;
                  <Loader />
                </>
              )}{" "}
              {!isLoading && "Terminer"}{" "}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}

export default AddLogoShopModal;
