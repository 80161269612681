import React from "react";
import { Steps } from "rsuite";
import CompteVendeur from "./steps/CompteVendeur";
import InfosEntreprise from "./steps/InfosEntreprise";
import InfosBancaire from "./steps/InfosBancaire";
import Resume from "./steps/Resume";
import api from "../../http/global-vars";
import axios from "axios";
import { withRouter } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import { Loader, Modal } from "rsuite";
import ConfirmationIcon from "../../img/icons/check-icon.png";

class StepsInscriptionVendeur extends React.Component {
  constructor() {
    super();
    this.state = {
      step: 0,
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
      errors: {},
      userID: window.localStorage.getItem("userID"),
      userInfos: {
        id: "",
        first_name: "",
        last_name: "",
        phone: "",
        shop_name: "",
        statut: "",
        ninea: "",
        adresse: "",
        person_in_charge: "",
      },
      checked: false,
      fields: {},
      isPageLoading: false,
      creatingShopInProgress: false,
      isSuccesCreateShop: false,
      isErrorCreateShop: false,
      is_empty_first_name: false,
      is_empty_last_name: false,
      is_empty_phone: false,
      is_empty_shop_name: false,
      is_empty_status: false,
      is_empty_terms_and_condition: false,
      is_empty_ninea: false,
      is_empty_adresse: false,
      is_empty_person_in_charge: false,
      showConfirmation: false,
    };
    this.handleChangeCreateShop = this.handleChangeCreateShop.bind(this);
    this.closeConformation = this.closeConformation.bind(this);
    this.openConfirmation = this.openConfirmation.bind(this);
  }
  changePercent(nextstep) {
    const step = nextstep < 0 ? 0 : nextstep > 3 ? 3 : nextstep;
    this.setState({
      step,
    });
  }
  precedent = () => {
    this.changePercent(this.state.step - 1);
  };
  suivant = () => {
    var step = this.state.step;
    if (step === 0) {
      if (this.onValidateStep1CreateShop()) {
        this.changePercent(this.state.step + 1);
      }
    }
    if (step === 1) {
      if (this.onValidateStep2CreateShop()) {
        this.changePercent(this.state.step + 1);
      }
    }
    if (step === 2) {
      if (this.onValidateStep3CreateShop()) {
        this.changePercent(this.state.step + 1);
      }
    }
  };

  componentDidMount() {
    var config = this.state.requestConfig;
    this.onGetConnectedUserInfos(config);
    window.scrollTo({ top: 0, behavior: "smooth" });
    // this.openConfirmation();
  }

  onGetConnectedUserInfos = (config) => {
    this.setState({
      isPageLoading: true,
    });
    var api = this.state.api;
    axios
      .get(api + "me/", config)
      .then((response) => {
        this.setState({
          isPageLoading: false,
        });
        this.setState({
          isLoginInProgress: false,
        });
        if (response.status === 200) {
          this.setState({
            userInfos: response.data.data,
          });
        }
      })
      .catch((error) => {
        this.setState({
          isPageLoading: false,
        });
      });
  };

  getInitialState() {
    return { checked: false };
  }

  handleChangeCreateShop = (e) => {
    // let fields = this.state.userInfos;
    e.persist();

    if (e.target.name === "terms_and_condition") {
      if (this.state.checked === false) {
        this.setState({
          checked: true,
        });
      } else {
        this.setState({
          checked: false,
        });
      }

      if (this.state.checked === true) {
        // eslint-disable-next-line
        this.state.userInfos.terms_and_condition = false;
      } else {
        // eslint-disable-next-line
        this.state.userInfos.terms_and_condition = true;
      }
    } else {
      this.setState((prevState) => ({
        userInfos: { ...prevState.userInfos, [e.target.name]: e.target.value },
      }));
    }
  };


  onValidateCreateShop = () => {
    let fields = this.state.userInfos;
    let errors = this.state.errors;
    var isValidForm = true;

    if (!fields["first_name"]) {
      isValidForm = false;
      this.setState({
        is_empty_first_name: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_first_name: false,
        });
      }, 5000);
      errors["first_name"] = "Le prénom est obligatoire!";
    }
    if (!fields["last_name"]) {
      isValidForm = false;
      this.setState({
        is_empty_last_name: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_last_name: false,
        });
      }, 5000);
      errors["last_name"] = "Le nom de famille est obligatoire!";
    }
   
    if (!fields["phone"]) {
      isValidForm = false;
      this.setState({
        is_empty_phone: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_phone: false,
        });
      }, 5000);
      errors["phone"] = "Le numéro de téléphone est obligatoire!";
    }
    if (!fields["adresse"]) {
      isValidForm = false;
      this.setState({
        is_empty_adresse: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_adresse: false,
        });
      }, 5000);
      errors["adresse"] = "L'adresse de la boutique est obligatoire!";
    }
    if (!fields["shop_name"]) {
      isValidForm = false;
      this.setState({
        is_empty_shop_name: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_shop_name: false,
        });
      }, 5000);
      errors["shop_name"] = "Le nom de la boutique est obligatoire!";
    }
    if (this.state.checked === false) {
      // eslint-disable-next-line
      this.state.userInfos.terms_and_condition = false;
      isValidForm = false;
      this.setState({
        is_empty_terms_and_condition: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_terms_and_condition: false,
        });
      }, 5000);
      errors["terms_and_condition"] =
        "Veuillez accepter les terms et conditions d'utilisation pour pouvoir vous inscrire";
    }

    this.setState({
      errors,
    });

    return isValidForm;
  };

  closeConformation(e) {
    e?.preventDefault();
    this.setState({ showConfirmation: false });
    this.props.history.push("/");
  }
  openConfirmation(e) {
    this.setState({ showConfirmation: true });
  }

  onSubmitCreateShop = async(e) => {
    e.preventDefault();

    if (this.onValidateCreateShop() === true) {
      this.setState({
        creatingShopInProgress: true,
      });

      let userInfos = this.state.userInfos;
      userInfos["user"] = this.state.userInfos.id;
      this.setState(userInfos);

      var api = this.state.api;
      var data = this.state.userInfos;
      var config = this.state.requestConfig;

      console.log("data", data);

      await axios
        .post(api + "create-fournisseur/", data, config)
        .then((response) => {
          console.log("response", response);
          this.setState({
            creatingShopInProgress: false,
          });
          if (response.status === 200 || response.status === 201) {
            this.openConfirmation();
            // toast.success("Votre boutique à été crée avec succès !")
            this.setState({
              isSuccesCreateShop: true,
            });
            setTimeout(() => {
              this.setState({
                isSuccesCreateShop: false,
              });
              this.closeConformation(e);
            }, 10000);
          } else {
            toast.error(
              "Une erreur est survenue lors de la création de votre boutique!",
              "Veuillez réessayer plus tard."
            );
            this.setState({
              isErrorCreateShop: true,
            });
            setTimeout(() => {
              this.setState({
                isErrorCreateShop: false,
              });
            }, 5000);
          }
        })
        .catch((error) => {
          this.setState({
            creatingShopInProgress: false,
          });
          console.log("error",error?.response)
          if (error) {
            // toast.error('Votre e-mail est déja associé à une boutique!')
            toast.error(
              "Une erreur s'est produite lors de la création de la boutique. Veuillez réessayer!"
            );
            this.setState({
              creatingShopInProgress: false,
              isErrorCreateShop: true,
            });
            setTimeout(() => {
              this.setState({
                isErrorCreateShop: false,
              });
            }, 5000);
          }
        });
    }
  };

  render() {
    const { step } = this.state;
    return (
      <form onSubmit={(e) => this.onSubmitCreateShop(e)}>

        <div>
          
          <CompteVendeur
            userInfos={this.state.userInfos}
            handleChangeCreateShop={this.handleChangeCreateShop}
            is_empty_first_name={this.state.is_empty_first_name}
            is_empty_last_name={this.state.is_empty_last_name}
            is_empty_email={this.state.is_empty_email}
            is_invalid_email={this.state.invalid_email}
            is_empty_phone={this.state.is_empty_phone}
            is_empty_shop_name={this.state.is_empty_shop_name}
            same_number_phone={this.state.same_number_phone}
            is_empty_adresse={this.state.is_empty_adresse}
            is_empty_terms_and_condition={
              this.state.is_empty_terms_and_condition
            }
            errors={this.state.errors}
            checked={this.state.checked}
          />
         
        </div>

        <div className="row m-t-50">
          <div className="col-lg-3 col-md-3 pr-md-0 mb-3 col-sm-5 pr-sm-2">
            <p
              className={"btn-theme-light text-up py-3 w-full text-center"}
              onClick={() => this.props.history.goBack()}
            >
              Retour
            </p>
          </div>

          <div
            className={
              "col-lg-4 pl-lg-2 pl-md-1 col-md-5 mb-3 col-sm-7 pl-sm-2 ml-auto"
            }
          >
            {this.state.creatingShopInProgress === false ? (
              <button className="btn-theme dis-flex justify-content-center py-3 w-full hov-pointer">
                Enregistrer
              </button>
            ) : (
              <button
                type="submit"
                className="btn-theme creating-shop"
                disabled
              >
                Création de la boutique &nbsp;
                <Loader />
              </button>
            )}
          </div>
        </div>

        {this.state.isSuccesCreateShop && <ToastContainer className="fs-13" />}
        {this.state.isErrorCreateShop && <ToastContainer className="fs-13" />}

        {/* Modal creation boutique  */}
        <div className="modal-container">
          <Modal
            size="xs"
            show={this.state.showConfirmation}
            onHide={this.closeConformation}
            className="rsuite-content-modal-custom"
          >
            <form>
              <Modal.Header>
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <div className="my-5 flex-col itm-center">
                <img loading="lazy"
                  src={ConfirmationIcon}
                  alt=""
                  className="icon-confirmation-modal mb-4"
                />
                <p className="title-confirmation-modal">
                  Bravo! Votre boutique a été bien créée avec succès. Elle est
                  en cours de validation. Vous serez notifié de la suite.
                </p>
                {/* <p className="title-confirmation-modal my-4">
                  Elle est soumise à l'administrateur pour une validation.
                </p> */}
              </div>
              <Modal.Footer className="text-center">
                <div className="row px-5">
                  <div className="col-12 mb-3">
                    <button
                      onClick={this.closeConformation}
                      className="btn-change-password"
                    >
                      Fermer
                    </button>
                  </div>
                </div>
              </Modal.Footer>
            </form>
          </Modal>
        </div>
      </form>
    );
  }
}

export default withRouter(StepsInscriptionVendeur);
