import React, { Component } from "react";
import "./contactUs.css";
import api from "../../http/global-vars";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Message, Loader } from "rsuite";

export default class contactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: api,
      fields: {},
      errors: {},
      is_empty_email: false,
      is_invalid_email: false,
      is_empty_objet: false,
      is_empty_message: false,
      onSendMessageProgress: false,
      isSuccessMessageSend: false,
      isErrorMessageSend: false,
    };
    this.handleChangeContactUs = this.handleChangeContactUs.bind(this);
  }

  handleChangeContactUs = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;

    this.setState({
      fields: fields,
    });
  };

  onValidateContactUs = () => {
    let fields = this.state.fields;
    let errors = this.state.errors;
    var isValidForm = true;

    if (!fields["email"]) {
      isValidForm = false;
      this.setState({
        is_empty_email: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_email: false,
        });
      }, 5000);

      errors["email"] = "Veuillez renseigner votre adresse e-mail!";
    }
    if (typeof fields["email"] !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields["email"])) {
        isValidForm = false;
        this.setState({
          is_invalid_email: true,
        });
        setTimeout(() => {
          this.setState({
            is_invalid_email: false,
          });
        }, 5000);

        errors["invalid_email"] = "Votre adresse email n'est pas valide";
      }
    }
    if (!fields["objet"]) {
      isValidForm = false;
      this.setState({
        is_empty_objet: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_objet: false,
        });
      }, 5000);
      errors["objet"] = "Veuillez saisir l'objet de votre message!";
    }
    if (!fields["message"]) {
      isValidForm = false;
      this.setState({
        is_empty_message: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_message: false,
        });
      }, 5000);
      errors["message"] = "Veuillez saisir votre message!";
    }

    this.setState({
      errors: errors,
    });
    return isValidForm;
  };

  onSendMessage = (e) => {
    e.preventDefault();

    if (this.onValidateContactUs()) {
      this.setState({
        onSendMessageProgress: true,
      });
      var api = this.state.api;
      var data = this.state.fields;

      axios
        .post(api + "contact/", data)
        .then((response) => {
          this.setState({
            onSendMessageProgress: false,
          });
          if (response.status === 200 || response.status === 201) {
            toast.success("Le message à été envoyé avec succès !");
            this.setState({
              isSuccessMessageSend: true,
              fields: {}
            });
            setTimeout(() => {
              this.setState({
                isSuccessMessageSend: false,
              });
            }, 5000);
          } else {
            toast.error(
              "Une erreur est survenu lors de l'envoie du message! Veuillez réessayer plus tard."
            );
            this.setState({
              isErrorMessageSend: true,
            });
            setTimeout(() => {
              this.setState({
                isErrorMessageSend: false,
              });
            }, 5000);
          }
        })
        .catch((error) => {
          this.setState({
            onSendMessageProgress: false,
          });
          if (error) {
            toast.error(
              "Une erreur est survenu lors de l'envoie du message! Veuillez réessayer plus tard."
            );
          }
        });
    }
  };

  render() {
    return (
      <form onSubmit={(e) => this.onSendMessage(e)}>
        <div className="form-group mb-2">
          <input
            name="email"
            maxLength={50}
            type="email"
            onChange={this.handleChangeContactUs}
            className="form-control form-control-footer b-r-50"
            placeholder="Adresse mail"
          />
          {this.state.is_empty_email === true && (
            <Message
              className="alert-rsuite-custom small"
              type="error"
              description={this.state.errors.email}
            />
          )}
          {this.state.is_invalid_email === true && (
            <Message
              className="alert-rsuite-custom small"
              type="error"
              description={this.state.errors.invalid_email}
            />
          )}
        </div>
        <div className="form-group mb-2">
          <input
            name="objet"
            maxLength={30}
            type="text"
            onChange={this.handleChangeContactUs}
            className="form-control form-control-footer b-r-50"
            placeholder="Objet"
          />
          {this.state.is_empty_objet === true && (
            <Message
              className="alert-rsuite-custom small"
              type="error"
              description={this.state.errors.objet}
            />
          )}
        </div>
        <div className="form-group">
          <textarea
            name="message"
            maxLength={1000}
            onChange={this.handleChangeContactUs}
            className="form-control form-control-footer b-r-10"
            rows="5"
            placeholder="Message"
          ></textarea>
          {this.state.is_empty_message === true && (
            <Message
              className="alert-rsuite-custom small"
              type="error"
              description={this.state.errors.message}
            />
          )}
        </div>
        <div className="d-flex justify-content-end">
          {this.state.onSendMessageProgress === false ? (
            <button type="submit" className="btn-submit-form-footer">
              ENVOYER
            </button>
          ) : (
            <button
              disabled
              className="btn-submit-form-footer in-progress-btn itm-center disabled"
            >
              Envoie en cours... &nbsp;
              <Loader />
            </button>
          )}
        </div>

        {this.state.isSuccessMessageSend && (
          <ToastContainer className="fs-13" />
        )}
        {this.state.isErrorMessageSend && <ToastContainer className="fs-13" />}
      </form>
    );
  }
}
