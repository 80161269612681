import React, {Component} from 'react';
import './wishlistView.css';
import Header from '../header/index';
import Footer from '../footer/index';
import ProductItemWhishlist from '../productItemWhishlist/index';

export default class wishlistView extends Component {
  constructor(props) {
      super(props);
      this.state = {};
  }

  componentDidMount(){
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  render() {
    return  <div>
              <Header/>
              <div className="body-theme p-t-10 p-b-45 fixed-after-header">
                <div className="container bg-white pt-3 pb-5">
                  <div className="mt-3 mb-4 text-center">
                    <h2 className="h-theme">Ma liste d'envies</h2>
                  </div>

                  {/* <div className="row px-2 mt-5"> */}
                      <ProductItemWhishlist/>
                  {/* </div> */}
                </div>
              </div>
              <Footer />
            </div>;
  }
}
