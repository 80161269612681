import React, { Component } from "react";
import "./filterBySeller.css";
import FeatherIcon from "feather-icons-react";
import api from "../../http/global-vars";
import axios from "axios";
import FilterBySellerSkeleton from "../skeleton/filterBySellerSkeleton/filterBySellerSkeleton";
import { connect } from "react-redux";
import { filterShop } from "../../productAction/productAction";
import { fetchShops } from "../../shopAction/shopAction";
import { NavLink } from "react-router-dom";

class filterBySeller extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: api,
      providerList: [],
      loading: false,
      checked: [],
      shopId: [],
      query: "",
      results: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    // this.onGetSellers();
    this.props.fetchShops()
  }

  onGetSellers = () => {
    var api = this.state.api;
    axios
      .get(api + "fournisseur/")
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.setState({
            providerList: response.data.results?.filter(
              (f) => f.is_archive === false && f.is_active === true
            ),
            loading: false,
          });
        }
      })
      .catch((error) => {
        if (error) {
          this.setState({ loading: false });
        }
      });
  };

  handleChange = (e) => {
    /** ====================================== */
    let checked = [...this.state.checked, parseInt(e.target.value)];
    if (this.props.shopId !== undefined) {
      // checked.push(this.props.shopId)
      checked[checked?.length] = parseInt(this.props.shopId);
    }
    // checked = [parseInt(e.target.value)]
    if (
      this.state.checked.includes(parseInt(e.target.value)) &&
      parseInt(e.target.value) !== parseInt(this.props.shopId)
    ) {
      checked = checked.filter((tes) => tes !== parseInt(e.target.value));
    }

    let x = (checked) => checked.filter((v, i) => checked.indexOf(v) === i);
    x(checked); // 'John', 'Paul', 'George', 'Ringo'
    this.props.filterShop(this.props.products, x(checked));

    this.setState({ checked });
  };

  handleInputChange = () => {
    /* const test = this.state.providerList.filter(item =>
      item.shop_name.toLowerCase().includes(this.search.value.toLowerCase()))
    */
    if (this.search.value.trim().length) {
      this.setState(
        {
          query: this.search.value,
        },
        () => {
          if (this.state.query && this.state.query.length > 1) {
            if (this.state.query.length % 2 === 0) {
              this.onGetSellers();
            }
          } else if (!this.state.query.length) {
            this.setState({
              results: [],
              searching: false,
            });
          }
        }
      );
    } else {
      this.setState({
        results: [],
        searching: false,
      });
    }
  };

  render() {
    const { isLoading, items } = this.props.shops;
    if (isLoading) {
      return <FilterBySellerSkeleton />;
    } else {
      let data = [];
      if (this.state.query.trim().length) {
        data = this.state.providerList.filter((item) =>
          item.shop_name.toLowerCase().includes(this.search.value.toLowerCase())
        );
      } else {
        data = this.state.providerList;
      }

      return (
        <div className="component-filter-by-seller">
          <p className="titre-filter-type">Vendeur</p>
          <div className="form-group has-search ">
            <FeatherIcon icon="search" className="icon-search-filter" />
            <input
              type="text"
              className="form-control"
              placeholder="Chercher un vendeur"
              ref={(input) => (this.search = input)}
              onChange={this.handleInputChange}
              autoComplete
            />
          </div>
          {this.props.shopId === undefined
            ? items?.slice(0, 10)?.map((provider) => (
                <div
                  key={provider.id}
                  className="custom-control custom-checkbox custom-checkbox-filter"
                >
                  <input
                    type="checkbox"
                    value={provider.id}
                    onChange={(e) => this.handleChange(e)}
                    className="custom-control-input"
                    id={"filter_provider" + provider.id}
                    name={provider.shop_name}
                    defaultChecked={
                      parseInt(this.props.shopId) === provider?.id
                    }
                    readOnly={parseInt(this.props.shopId) === provider?.id}
                  />
                  <label
                    className="custom-control-label custom-control-label-filter"
                    htmlFor={"filter_provider" + provider.id}
                  >
                    {provider.shop_name}
                  </label>
                </div>
              ))
            : items
                ?.filter((item) => item?.id !== parseInt(this.props.shopId))
                .slice(0, 10)
                .map((provider) => (
                  <li key={provider.id} className="li-categories">
                    <NavLink
                      className="categories-link"
                      to={`/boutique/${provider.id}`}
                    >
                      {provider.shop_name}
                      <FeatherIcon
                        className="icon-categorie-link"
                        icon="chevron-right"
                      />
                    </NavLink>
                  </li>
                ))}
          {/* {this.props.categoryList.slice(0, 8).map(category => (
        <li key={category.id} className="li-categories">
          <NavLink className="categories-link" to={`/categorie/${category.id}`}>
            {category.name}
            <FeatherIcon className="icon-categorie-link" icon="chevron-right" />
          </NavLink>
        </li>
      ))} */}
        </div>
      );
    }
  }
}

export default connect(
  (state) => ({
    shop: state.products.shop,
    products: state.products.items,
    filteredShop: state.products.filteredItems,
    shops: state.shops,
  }),
  {
    filterShop,
    fetchShops,
  }
)(filterBySeller);
