import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { useEditShopMutation } from "../../../../utils/api/user.api";
import { useSelector } from "react-redux";
import { cleannerError } from "../../../../utils/utils";
import { useGetCategoryListQuery } from "../../../../utils/api/category.api";

function UseAditShopInfos() {
  const validationSchema = yup.object().shape({
    shop_name: yup.string().required().label("Le nom de la boutique"),
    option: yup.string().label("L'entité commerciale"),
    ninea: yup.string().required().label("Le ninea"),
    legal_form: yup.string().label("La forme juridique"),
    adresse: yup.string().required().label("L'adresse"),
    country: yup.string().label("Le pays"),
    city: yup.string().label("La ville"),
    code_postal: yup.string().label("Le code postal"),
    person_in_charge: yup.string().required().label("La personne en charge"),
    products_main_category: yup
      .string()
      .label("La catégorie principale")
      .nullable(),
    bank_name: yup.string().label("Le nom de la banque"),
    account_number: yup.string().label("Le numéro de compte"),
    iban: yup.string().label("L'iban"),
    account_holder_name: yup.string().label("Le nom du titulaire"),
    swift: yup.string().label("Le swift"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const {data = []} = useGetCategoryListQuery({})
  const [sendData, { isLoading, isSuccess, error, isError }] =
    useEditShopMutation();
  const { user } = useSelector((s) => s?.user);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Les informations ont été modifiées avec succès!`,
        iconColor: "#c501ff",
        showConfirmButton: false,
        timer: 2000,
      });
    }
    const err = error;
    if (isError) {
      if (err?.data?.email?.includes("user with this email already exists.")) {
        Swal.fire({
          icon: "error",
          title: "Un utilisateur avec cet email exite déjà!",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Erreur de statut ${err?.status}`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [isLoading]);

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (user?.id && user?.provider) {
      // console.log("user", user);
      let fields = [
        "shop_name",
        "adresse",
        "option",
        "statut",
        "legal_name",
        "ninea",
        "account_name",
        "legal_form",
        "country",
        "city",
        "code_postal",
        "person_in_charge",
        "products_main_category",
        "company_registration_number",
        "bank_name",
        "account_number",
        "iban",
        "account_holder_name",
        "swift",
      ];

      for (let field of fields) {
        register(field);

        setValue(field, user?.provider[field]);
      }
    }
  }, [user]);

  const onSubmit = async (data) => {
    const fd = new FormData();

    for (let key of Object.keys(data)) {
      if (key === "logo" || key === "banner") {
        if (data[key]) {
          fd.append(key, data[key]);
        }
      } else {
        fd.append(key, data[key]);
      }
    }
    await sendData({
      id: user?.provider?.id,
      data: fd,
    });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    categoryList: data
  };
}

export default UseAditShopInfos;
