import React, {Component} from 'react';
import Skeleton from 'react-loading-skeleton';

export default class contentProductDetailsSkeleton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return  <div className="content-details-product p-lg-5 p-md-5 p-4 m-b-15">
      <div className="row m-b-100">
        <div className="col-xl-6 col-lg-7 col-md-6 m-b-50">
          <Skeleton variant="rect" className="lh-2" height={600} />
        </div>
        <div className="col-xl-6 col-lg-5 col-md-6">
          <div className="row px-2 mb-3">
            <div className="col-xl-6 col-lg-7 col-md-7 col-sm-6 px-2 mb-2">
              <p className="name-shop-content-details-product h-theme">
                <Skeleton width={20} height={18} className="icon-name-shop-content-details-product lh-1-2" />
                <Skeleton width={`80%`} height={18} className="lh-1-2" />
              </p>
            </div>
            <div className="col-xl-3 col-lg-5 col-md-5 col-sm-3 mb-2 px-2">
              <Skeleton height={16} className="link-name-shop-content-details-product lh-2-5"/>
            </div>
          </div>

          <Skeleton height={18} className="name-product-content-details-product" />

          <Skeleton width={130} className="rating-custom-medium mb-3" />

          <div className="row ">
            <div className="col-lg-8 col-md-8 col-sm-6 m-b-15">
              <Skeleton width={200} className="price-content-details-product mt-3" variant="text" />
            </div>
            {/* <div className="col-lg-4 col-md-4 col-sm-6 m-b-15 qty-select-mobile">
                  <p className="text-dark ff-raleway fs-14 fw-600 text-up mr-sm-0 mr-5 mb-1">Quantité</p>
                  <div className="content-qty-cart-item">
                    <span className="amount-btn left">
                      <FeatherIcon className="icon-amount-btn" icon="minus" />
                    </span>
                    <p className="amount">1</p>
                    <span className="amount-btn right">
                      <FeatherIcon className="icon-amount-btn" icon="plus" />
                    </span>
                  </div>
            </div> */}
          </div>

          <div className="row">
            <div className="col-xl-8 col-lg-10 pr-md-0 col-md-11 col-sm-8">
              <Skeleton width={100} className="text-theme mt-5 mb-3" />

              <Skeleton className="audio-lecteur-content" style={{ borderRadius: 50 }} />
              
              
              <div className="m-t-40">
                <form>
                  <Skeleton className="btn-theme-add-to-cart m-b-75" style={{ borderRadius: 50 }} />
                </form>
              </div>
                
              <Skeleton className="ligne-theme"/>
              <Skeleton width={200} height={16} className="text-black ff-raleway fw-700 m-b-10 lh-2" />
              <div className="row">
                <div className="col-sm-7">
                  <Skeleton height={14} className="name-info-vendeur-content-details-product fs-18 lh-1"/>
                  <Skeleton height={12} width={100} className="name-info-vendeur-content-details-product fs-12 mb-3 lh-2"/>
                </div>
                <div className="col-sm-5 px-md-0 dis-flex">
                  <Skeleton className="see-shop-info-vendeur-content-details-product ml-md-auto dis-block" />
                </div>
              </div>
            </div>
          </div>

          
        </div>
      </div>
      <div className="row mt-md-5">
        <div className="col-md-11">
          <Skeleton width={100} className="h-theme" />
          <Skeleton className="details-content-details-product" />
        </div>
      </div>
      <div className="my-5">
        <Skeleton className="h-theme mb-3" width={150} />
        <div className="mb-3">
          <div className="pl-0 mb-3 col-xl-4 col-lg-6 col-md-6 col-sm-8">
            <Skeleton className="fullname-user-evaluation" width={`80%`}  />
            <Skeleton className="rating-user-evaluation" width={100} />
            <Skeleton className="commentaire-user-evaluation"/>
          </div>
        </div>
      </div>
      <Skeleton width={250} className="btn-evaluate-product-modal" style={{ border: '1px solid #ccc' }} />
    </div>;
  }
}