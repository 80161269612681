import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";

import "./MotdePasseOublie.css";
import Header from "../header/index";
// import { toast } from "react-toastify";
import api from "../../http/global-vars";
import { Loader } from "rsuite";
import Swal from "sweetalert2";

function ModifierMotDePasseUser() {
  const [isInProgress, setIsInProgress] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const {
    register,
    handleSubmit,
    formState,
    clearErrors,
    setValue,
    watch,
    reset,
  } = useForm();

  useEffect(() => {
    if (location?.state) {
      console.log("location", location);
      register("phone");
      setValue("phone", location?.state);
    }
  }, [location]);

  React.useEffect(() => {
    //register('new_code')
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]) => {
          return clearErrors(key);
        });
      }, 5000);
    }
  }, [formState, formState?.errors, clearErrors, register]);

  const onReset = async (data, e) => {
    setIsInProgress(true);
    console.log("data", data);
    await axios
      .post(`${api}reset-code/`, data)
      .then((res) => {
        console.log(res);
        setIsInProgress(false);
        Swal.fire({
          icon: "success",
          title: "Votre mot de passe a été modifié avec succès",
          showConfirmButton: false,
          //   allowOutsideClick: false,
          timer: 1000,
        }).then((result) => {
          history.push("/connexion");
          e.target.reset();
          reset();
        });
      })
      .catch((e) => {
        setIsInProgress(false);
        console.log(e?.response);
        if (
          e?.response?.data?.status === "failure" &&
          e?.response?.data?.message !== "not such item"
        ) {
          //   toast.error(error?.response?.data);
          Swal.fire({
            icon: "error",
            title: e?.response?.data?.message,
            showConfirmButton: false,
            timer: 3000,
          });
        } else if (
          e?.response?.data?.status === "failure" &&
          e?.response?.data?.message === "not such item"
        ) {
          //   toast.error(error?.response?.data);
          Swal.fire({
            icon: "error",
            title: "Code incorrect .",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title:
              "Oupsss! Une erreur est survenue. Veuillez réessayer plus tard.",
            showConfirmButton: false,
            timer: 3000,
          });
        }
      });
  };
  return (
    <div>
      <Header />
      <div className="login-page-container p-t-40 p-b-150 fixed-after-header">
        <section className="container">
          <form onSubmit={handleSubmit(onReset)}>
            <div className="row auth-page-row">
              <div className="col-md-6 offset-md-3 auth-page-col">
                <div className="row auth-form-row">
                  <h1 className="auth-form-title m-b-90">
                    Modification de mot de passe
                  </h1>
                  <div className="col-md-6 offset-md-3 m-b-20">
                    <p className="text-modifier-password">
                      Créer un nouveau mot de passe
                    </p>
                  </div>
                  <div className="col-md-12 auth-input-col m-b-20">
                    <div className="form-group auth-form-group position-relative">
                      <input
                        name="code"
                        type="number"
                        autoComplete="off"
                        className="form-control auth-form-control pl-5"
                        id="code"
                        {...register("code", {
                          required: "le code est requis",
                        })}
                        placeholder="Entrer le code reçu par sms"
                      />
                      {formState.errors.code && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {" "}
                          {formState.errors.code.message}{" "}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 auth-input-col m-b-20">
                    <div className="form-group auth-form-group position-relative">
                      <input
                        type="password"
                        autoComplete="off"
                        name="new_code"
                        id="new_code"
                        {...register("new_code", {
                          required: "Le nouveau mot de passe est requis.",
                        })}
                        className="form-control auth-form-control pl-5"
                        placeholder="Entrer mot de passe"
                      />
                      {formState.errors.new_code && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {" "}
                          {formState.errors.new_code.message}{" "}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 auth-input-col m-b-20">
                    <div className="form-group auth-form-group position-relative">
                      <input
                        autoComplete="new-password"
                        name="new_code_confirm"
                        id="new_code_confirm"
                        type="password"
                        {...register("new_code_confirm", {
                          required: {
                            value: true,
                            message: "La confirmation est obligatoire.",
                          },
                          validate: (value) => {
                            return (
                              value === watch("new_code") ||
                              "Veuillez entrer deux mots de passe identiques."
                            );
                          },
                        })}
                        className="form-control auth-form-control pl-5"
                        
                        placeholder="Confirmer votre mot de passe"
                      />
                      {formState.errors.new_code_confirm && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {" "}
                          {formState.errors.new_code_confirm.message}{" "}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 offset-md-3 d-flex justify-content-center auth-input-col m-b-5">
                    {/* <NavLink
                      to="/dashboard"
                      className="btn btn-primary auth-submit-btn"
                    >
                      ENVOYER
                    </NavLink> */}
                    {isInProgress === false ? (
                      <button type="submit" className="btn-form-dark">
                        ENVOYER
                      </button>
                    ) : (
                      <button className="btn-form-dark in-progress-btn">
                        Envoi en cours &nbsp;
                        <Loader />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>
    </div>
  );
}

export default ModifierMotDePasseUser;
