import axios from "axios";
import api from "../http/global-vars";
import { CATEGORIES } from "../types";

export const fetchCategories = () => {
  return async (dispatch) => {
      axios
        .get(api + "category/")
        .then((response) => {
          console.log("response category", response);
          if (response.status === 200 || response.status === 201) {
            let parents = response?.data?.filter(
              (item) => item?.category_id === null && !item?.category_id
            );
            let childs = response?.data?.filter(
              (item) =>
                item?.category_id !== null &&
                item?.category_id &&
                parents?.some((el) => el?.id === item?.category_id)
            );

            let subs = response?.data?.filter(
              (item) =>
                item?.category_id !== null &&
                item?.category_id &&
                childs?.some((el) => el?.id === item?.category_id)
            );
            for (let i = 0; i < childs.length; i++) {
              const element = childs[i];
              element.subcategories = subs
                .sort(function (a, b) {
                  if (a.name < b.name) {
                    return -1;
                  }
                  if (a.name > b.name) {
                    return 1;
                  }
                  return 0;
                })
                .filter((item) => item?.category_id === element?.id);
            }
            for (let i = 0; i < parents.length; i++) {
              const element = parents[i];
              element.subcategories = childs
                .sort(function (a, b) {
                  if (a.name < b.name) {
                    return -1;
                  }
                  if (a.name > b.name) {
                    return 1;
                  }
                  return 0;
                })
                .filter((item) => item?.category_id === element?.id);
            }
            dispatch({
              type: CATEGORIES,
              payload: parents.sort(function (a, b) {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              }),
              isLoading: false,
            });
          }
        })
        .catch((error) => {
          if (error) {
            dispatch({
              type: CATEGORIES,
              payload: [],
              isLoading: false
            });
            // setLoading(false);
          }
        });
    
  }
}