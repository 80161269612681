import React, { Component, useEffect, useState } from "react";
import "./tableArticles.css";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import FeatherIcon from "feather-icons-react";
import EditIcon from "@material-ui/icons/Edit";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import api from "../../../http/global-vars";
import axios from "axios";
import { Alert } from "rsuite";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import Moment from "react-moment";
import { NavLink } from "react-router-dom";
import {
  useAddOrEditProductMutation,
  useGetProductBySellerQuery,
} from "../../../utils/api/product.api";
import { useGetUserShopQuery } from "../../../utils/api/shop.api";
import Swal from "sweetalert2";
import { CustomPagination } from "../../../modules/Pagination/CustomPagination";
import { useSelector } from "react-redux";
import TableSkeleton from "../../skeleton/TableSkeleton";

// export default class tableArticles extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       token: window.localStorage.getItem("userToken"),
//       requestConfig: {
//         headers: {
//           Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
//         },
//       },
//       userID: window.localStorage.getItem("userID"),
//       checked: true,
//       productActivToggle: false,
//       api: api,
//       fields: {},
//       onArchiveProduct: true,
//       loading: false,
//       activationProductInProgress: false,
//       archivageProductInProgress: false,
//     };
//     this.handleChange = this.handleChange;
//     this.onDeleteProduct = this.onDeleteProduct;
//   }

//   render() {
//     const options = {
//       noDataText: "Aucun produit disponible",
//     };
//     return (

//     );
//   }
// }

function TableArticles({ filter }) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState("");
  const { user } = useSelector((s) => s?.user);
  const {
    data = { results: [], count: 0 },
    isLoading,
    refetch,
  } = useGetProductBySellerQuery({
    id: user?.provider?.id,
    page,
    limit,
    q: query,
    status: filter,
  });

  useEffect(() => {
    if (!isLoading) {
      refetch();
    }
  }, []);

  const productActivation = (cell, row) => {
    return (
      <div className="d-flex justify-content-center">
        <ToggleActivation item={row} refetch={refetch} />
      </div>
    );
  };

  const productCreated = (cell, row) => {
    return <Moment format="DD/MM/YYYY">{row.created_at}</Moment>;
  };

  const productVisible = (cell, row) => {
    return (
      <div className="dis-flex justify-content-center">
        {row.is_active === true ? (
          <CheckIcon className="check-icon-table-marketplace" />
        ) : (
          <CloseIcon className="close-icon-table-marketplace" />
        )}
      </div>
    );
  };

  const productAction = (cell, row) => {
    return (
      <div className="dis-flex justify-content-center">
        <NavLink
          to={{
            pathname: `/details-article/${row?.slug}`,
            state: { product: row },
          }}
          className="btn-action-table-grey edit"
        >
          <FeatherIcon icon="eye" className="icon-action-table-grey" />
        </NavLink>
        <NavLink to={`/article/${cell}`} className="btn-action-table-grey edit">
          <EditIcon className="icon-action-table-grey" />
        </NavLink>
        <HandleToggle item={row} />
      </div>
    );
  };

  const productPrice = (cell, row) => {
    return Intl.NumberFormat("fr-FR").format(parseInt(row.price)) + " FCFA";
  };

  const renderPaginationPanel = (props) => {
    const { count } = data;
    return (
      <div class="col-md-12">
        <CustomPagination
          page={page}
          perPage={limit}
          nbPages={count}
          onChange={(page, perPage) => {
            window.scrollTo(200, 200);
            setPage(page);
            setLimit(perPage);
          }}
        />
      </div>
    );
  };

  const renderCustomSearchPanel = (props) => {
    return (
      <div className="form-group form-group-sm react-bs-table-search-form input-group input-group-sm">
        <input
          type="search"
          className="form-control"
          placeholder="Recherche"
          onChange={(e) => setQuery(e.target.value)}
        />
        {/* <span className="input-group-btn">
          <button
            className="btn btn-warning my-custom-class react-bs-table-search-clear-btn"
            type="button"
            onClick={(e) => setQuery("")}
          >
            <span>Effacer</span>
          </button>
        </span> */}
      </div>
    );
  };

  const options = {
    noDataText: "Aucune donnée disponible",
    paginationPanel: renderPaginationPanel,
    sizePerPage: limit,
    searchPanel: renderCustomSearchPanel,
  };
  let headers = [
    { text: "Nom" },
    { text: "Créé" },
    { text: "Prix" },
    { text: "Stock disponible" },
    { text: "Visible" },
    { text: "Actions" },
  ];
  return (
    <div className="table-container">
      {!!isLoading && <TableSkeleton headers={headers} />}
      {!isLoading && (
        <BootstrapTable
          className="head-table-custom"
          options={options}
          data={data?.results}
          search={false}
          multiColumnSearch={true}
          striped
          hover
          multiColumnSort={2}
          pagination
          version="4"
        >
          <TableHeaderColumn
            isKey
            dataField="name"
            width="230"
            tdStyle={{
              fontWeight: 400,
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.7)",
              borderTop: "none",
              borderBottom: "none",
              padding: "1rem",
              whiteSpace: "normal",
              lineHeight: 1,
            }}
            thStyle={{ textAlign: "center" }}
            className="th-table-custom"
          >
            Nom
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="created_at"
            className="th-table-custom"
            width="150"
            dataFormat={productCreated}
            thStyle={{ textAlign: "center" }}
            tdStyle={{
              fontWeight: 400,
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.7)",
              borderTop: "none",
              borderBottom: "none",
              padding: "1rem",
              textAlign: "center",
            }}
          >
            Crée
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="price"
            className="th-table-custom"
            dataFormat={productPrice}
            width="200"
            thStyle={{ textAlign: "center" }}
            tdStyle={{
              fontWeight: 400,
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.7)",
              borderTop: "none",
              borderBottom: "none",
              padding: "1rem",
              textAlign: "center",
            }}
          >
            Prix
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="quantity"
            className="th-table-custom"
            width="200"
            thStyle={{ textAlign: "center" }}
            tdStyle={{
              fontWeight: 400,
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.7)",
              borderTop: "none",
              borderBottom: "none",
              padding: "1rem",
              textAlign: "center",
            }}
          >
            Stock disponible
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="action"
            className="th-table-custom"
            dataFormat={productVisible}
            width="150"
            thStyle={{ textAlign: "center" }}
            tdStyle={{
              fontWeight: 400,
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.7)",
              borderTop: "none",
              borderBottom: "none",
              padding: "1rem",
              textAlign: "center",
            }}
          >
            Visible
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="is_active"
            className="th-table-custom"
            dataFormat={productActivation}
            width="150"
            thStyle={{ textAlign: "center" }}
            tdStyle={{
              fontWeight: 400,
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.7)",
              borderTop: "none",
              borderBottom: "none",
              padding: "1rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Actif
          </TableHeaderColumn>
          <TableHeaderColumn
            className="th-table-custom"
            dataFormat={productAction}
            dataField="id"
            width="150"
            thStyle={{ textAlign: "center" }}
            tdStyle={{
              fontWeight: 400,
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.7)",
              borderTop: "none",
              borderBottom: "none",
              padding: "1rem",
              textAlign: "center",
            }}
          >
            Actions
          </TableHeaderColumn>
        </BootstrapTable>
      )}
    </div>
  );
}

export default TableArticles;

const ToggleActivation = ({ item, refetch }) => {
  const [sendData] = useAddOrEditProductMutation();

  const handleChange = () => {
    // var cel = cell

    Swal.fire({
      title: "Confirmez-vous l'action ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return sendData({
          id: item?.id,
          data: { is_active: !item?.is_active },
        });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("res", result);
        Swal.fire({
          icon: "success",
          title: item?.is_active
            ? "Le produit à été désactivé avec succès."
            : "Le produit à été activé avec succès.",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };
  return (
    <>
      <BootstrapSwitchButton
        checked={item?.is_active}
        size="xs"
        width="55"
        onstyle="outline-success"
        offstyle="outline-danger"
        onlabel="OUI"
        offlabel="NON"
        onChange={(checked) => handleChange(checked, item.id)}
      />
    </>
  );
};

const HandleToggle = ({ item }) => {
  const [sendData] = useAddOrEditProductMutation();

  const onDeleteProduct = () => {
    // var cel = cell

    Swal.fire({
      title: "Êtes-vous sûr de vouloir supprimer ce produit ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return sendData({
          id: item?.id,
          data: { is_archive: !item?.is_archive },
        });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("res", result);
        Swal.fire({
          icon: "success",
          title: "Le produit à été supprimé avec succès.",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };

  return (
    <>
      <button
        // onClick={() => this.onDeleteProduct(row)}
        onClick={() => onDeleteProduct()}
        className="btn-action-table-grey clear"
      >
        <FeatherIcon icon="trash-2" className="icon-action-table-grey" />
      </button>
    </>
  );
};
