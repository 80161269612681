import React, { Component } from "react";
import "./checkoutProcessSteps.css";
// import ImgOrangeMoney from "../../img/orangemoney.png";
import ChangeShippingAddressModal from "../changeShippingAddressModal/index";
import { Message } from "rsuite";
import api from "../../http/global-vars";
import axios from "axios";

export default class checkoutProcessSteps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: api,
      userAdresse: this.props.userInfos.adresse,
      delivery: [],
      payment: [],
      loading: false,
    };
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.onGetDelivery();
    // this.props.onRef(this);
    this.onGetPaiementMode();
  }

  onGetDelivery = () => {
    this.setState({ loading: true });

    var api = this.state.api;

    axios
      .get(api + "delivery/")
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.setState({
            loading: false,
            delivery: response.data.results,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onGetPaiementMode = () => {
    this.setState({ loading: true });
    var api = this.state.api;

    axios
      .get(api + "paymentmode/")
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.setState({ loading: false, payment: response.data.results });
        }
      })
      .catch((error) => {
        if (error) {
          this.setState({ loading: false });
        }
      });
  };

  render() {
    const userInfos = this.props.userInfos;
    return (
      <div className="component-checkout-process-steps">
        <div className="row px-md-0 m-b-15">
          <div className="col-lg-4 col-md-6 m-b-20 px-md-2 dis-flex">
            <div className="item-step-checkout">
              <div className="row m-b-25">
                <div className="col">
                  <span className="step-item-process-card">1</span>
                  <p className="title-proccess-checkout-card m-b-10">
                    Adresse{" "}
                  </p>
                </div>
                <div
                  type="button"
                  className="btn-edit-step-item-process-card"
                  data-toggle="modal"
                  data-target="#exampleModal"
                >
                  <ChangeShippingAddressModal
                    userAdresse={this.state.userAdresse}
                  />
                </div>
                <div>
                  {this.props.is_empty_adresse && (
                    <Message
                      className="alert-rsuite-custom"
                      type="error"
                      description={this.props.errors.adresse}
                    />
                  )}
                </div>
              </div>

              <p className="client-name-proccess-checkout">
                {userInfos.first_name + " " + userInfos.last_name}
              </p>
              <p className="client-info-proccess-checkout">
                {userInfos.adresse}
              </p>
              <p className="client-info-proccess-checkout m-b-20">
                {userInfos.phone}
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 m-b-20 px-md-2 dis-flex">
            <div className="item-step-checkout">
              <div className="row m-b-25">
                <div className="col">
                  <span className="step-item-process-card-reverse">2</span>
                  <p className="title-proccess-checkout-card m-b-10">
                    Mode de livraison{" "}
                  </p>
                </div>
              </div>
              <p className="fs-12 fw-400 text-dark lh-1-4 m-t-3 m-b-10">
                Choisissez un mode de livraison
              </p>
              <div>
                {this.state.delivery.map((deliveryType) => (
                  <div
                    key={deliveryType.id}
                    className="custom-control custom-radio m-b-30"
                  >
                    <input
                      type="radio"
                      onChange={(e) => this.props.handleChange(e, deliveryType)}
                      className="custom-control-input"
                      id={`delivery${deliveryType.id}`}
                      name="livraison"
                      value={deliveryType.id}
                    />
                    <label
                      className="custom-control-label input-item-step-checkout fw-600 fs-14 lh-1-4 mb-10"
                      htmlFor={`delivery${deliveryType.id}`}
                    >
                      {deliveryType.name}
                    </label>
                    <p className="input-item-step-checkout fs-12 fw-300 lh-1-2">
                      {deliveryType.description}
                    </p>
                    <div className="mt-2 dis-flex">
                      <p className="info-input-item-step-checkout">
                        <span className="fw-400">Durée:</span>{" "}
                        {deliveryType.duration} jours
                      </p>
                      <p className="info-input-item-step-checkout ml-auto">
                        <span className="fw-400">Tarif:</span>{" "}
                        {deliveryType.amount} Fcfa
                      </p>
                    </div>
                  </div>
                ))}
                {this.props.is_empty_livraison && (
                  <Message
                    className="alert-rsuite-custom"
                    type="error"
                    description={this.props.errors.livraison}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 m-b-20 px-md-2 dis-flex">
            <div className="item-step-checkout">
              <div className="row m-b-25">
                <div className="col">
                  <span className="step-item-process-card-reverse">3</span>
                  <p className="title-proccess-checkout-card m-b-10">
                    Mode de paiement
                  </p>
                </div>
              </div>

              {this.state.payment.map((payementMode) => (
                <div
                  key={payementMode.id}
                  className="custom-control custom-radio m-b-35"
                >
                  <input
                    type="radio"
                    onChange={(e) => this.props.handleChange(e, payementMode)}
                    className="custom-control-input"
                    id={`payment${payementMode.id}`}
                    name="paiement"
                    value={payementMode.id}
                  />
                  <label
                    className="custom-control-label input-item-step-checkout fw-600 fs-14 lh-1-4 m-b-10"
                    htmlFor={`payment${payementMode.id}`}
                  >
                    {payementMode.title}:
                  </label>
                  <p className="input-item-step-checkout fs-12 fw-300 lh-1-2">
                    {payementMode.description}
                  </p>
                </div>
              ))}
              {/* <div className="custom-control custom-radio m-b-30">
                  <input
                    type="radio"
                    onChange={this.props.handleChange}
                    className="custom-control-input"
                    id="paiementOrangemoney"
                    name="paiement"
                    value="Paiement Orange money"
                  />
                  <label
                    className="custom-control-label input-item-step-checkout fw-600 fs-14"
                    htmlFor="paiementOrangemoney"
                  >
                    <img loading="lazy"
                      className="orange-money-icon-item-step-checkout"
                      src={ImgOrangeMoney}
                      alt="Orange money"
                    />
                  </label>
                  <p className="input-item-step-checkout fs-12 fw-300 lh-1-2">
                    Payez simplement vos achats avec Orange Money.
                  </p>
                </div> */}

              {this.props.is_empty_paiement && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={this.props.errors.paiement}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
