import React, { Component } from "react";
import "./navVerticalDashboard.css";
import { Sidenav, Nav, Badge } from "rsuite";
import { NavLink } from "react-router-dom";
import AssessmentIcon from "@material-ui/icons/Assessment";
import SettingsIcon from "@material-ui/icons/Settings";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import api from "../../../http/global-vars";
import axios from "axios";
// import _ from 'lodash';
import { connect, useSelector } from "react-redux";
import { fetchProductsByShop } from "../../../productAction/productAction";
import { fetchOrdersByShop } from "../../../orderAction/orderAction";
import { useGetProductBySellerQuery } from "../../../utils/api/product.api";
import { useGetOrderBySellerQuery } from "../../../utils/api/order.api";

const NavItemProduct = () => {
  const { user } = useSelector((s) => s?.user);
  const { data = { count: 0 } } = useGetProductBySellerQuery({
    limit: 1,
    id: user?.provider?.id,
  });
  return (
    <Badge className="badge-nav-vertical-dashboard" content={data?.count}>
      Articles
    </Badge>
  );
};

const NavItemOrder = () => {
  const { user } = useSelector((s) => s?.user);
  const {
    data = {
      count: 0,
    },
  } = useGetOrderBySellerQuery({ limit: 1, id: user?.provider?.id });
  return (
    <Badge className="badge-nav-vertical-dashboard" content={data?.count}>
      Commandes
    </Badge>
  );
};

class navVerticalDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      userID: window.localStorage.getItem("userID"),
      api: api,
      commandes: [],
      produits: [],
      loading: false,
      shopDetails: [],
    };
  }

  componentDidMount() {
    var config = this.state.requestConfig;
    this.onGetShopInfos(config);
  }

  onGetShopInfos = (config) => {
    this.setState({
      loading: true,
    });
    var api = this.state.api;
    var userID = this.state.userID;
    axios
      .get(api + `fournisseurbyuser/${userID}/`, config)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.props.fetchProductsByShop(response.data[0]?.id);
          this.props.fetchOrdersByShop(response.data[0]?.id);
          this.setState({
            shopDetails: response.data[0],
            userInfos: response.data[0].user,
            loading: false,
          });
        }
      })
      .catch((error) => {
        if (error) {
          this.setState({
            loading: false,
          });
        }
      });
  };

  render() {
    var countProducts = this.props.products?.filter(
      (f) => f.is_archive === false && f.is_active === true
    ).length;
    var countOrders = this.props.orders?.filter(
      (f) => f.status === "placed"
    ).length;

    return (
      <div className="component-nav-vertical-dashboard">
        <Sidenav activeKey="1">
          <Sidenav.Body>
            <Nav>
              <NavLink className="no-decoration" to="/dashboard-vendeur">
                <Nav.Item
                  className="link-item-sidenav-dashboard"
                  eventKey="1"
                  icon={
                    <AssessmentIcon className="icon-item-sidenav-dashboard" />
                  }
                >
                  Dashboard
                </Nav.Item>
              </NavLink>
              <NavLink className="no-decoration" to="/articles">
                <Nav.Item
                  className="link-item-sidenav-dashboard"
                  eventKey="2"
                  icon={
                    <ViewModuleIcon className="icon-item-sidenav-dashboard" />
                  }
                >
                  {/* <Badge
                    className="badge-nav-vertical-dashboard"
                    content={countProducts}
                  >
                    Articles
                  </Badge> */}
                  <NavItemProduct />
                </Nav.Item>
              </NavLink>
              <NavLink className="no-decoration" to="/commandes">
                <Nav.Item
                  className="link-item-sidenav-dashboard"
                  eventKey="5"
                  icon={
                    <ShoppingBasketIcon className="icon-item-sidenav-dashboard" />
                  }
                >
                  <NavItemOrder />
                </Nav.Item>
              </NavLink>
              <NavLink className="no-decoration" to="/parametres">
                <Nav.Item
                  className="link-item-sidenav-dashboard"
                  eventKey="4"
                  icon={
                    <SettingsIcon className="icon-item-sidenav-dashboard" />
                  }
                >
                  Paramètres
                </Nav.Item>
              </NavLink>
              {/* <NavLink className="no-decoration" to="/aide">
              <Nav.Item className="link-item-sidenav-dashboard" eventKey="6">
                Aide
              </Nav.Item>
            </NavLink> */}
            </Nav>
          </Sidenav.Body>
        </Sidenav>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    products: state.products.items,
    orders: state.orders.items,
  }),
  {
    fetchProductsByShop,
    fetchOrdersByShop,
  }
)(navVerticalDashboard);
