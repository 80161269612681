import React from 'react'
import Skeleton from 'react-loading-skeleton';

const TableSkeleton = ({ headers }) => {
  return (
    <div className="col-md-12">
      <table className="table table striped">
        <thead>
          <tr>
            {headers?.map((col, i) => (
              <th
                scope="col"
                style={{
                  textAlign: "center",
                //   color: "#010235",
                //   fontFamily: "Poppins",
                //   fontSize: "15px",
                //   fontWeight: "400",
                }}
                className="th-table-custom"
                key={col.text + i}
              >
                {col.text}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {[...Array(10)]?.map((item, i) => (
            <tr key={i}>
              {headers?.map((col, i) => (
                <td key={col.text + i}>
                  <Skeleton height={30} />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TableSkeleton