import React, { Component } from 'react'
import './mandatoryLoginProductItem.css'
import { Modal, ButtonToolbar } from 'rsuite'
import FeatherIcon from 'feather-icons-react'
import { NavLink } from 'react-router-dom'

export default class mandatoryLoginProductItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
    }
  }

  close = () => {
    this.setState({ show: false })
  }
  open = () => {
    this.setState({ show: true })
  }

  render() {
    return (
      <div className="component-mandatory-login-product-item">
        <ButtonToolbar>
          <button
            className="btn-product-card-item-add-card"
            onClick={this.open}
          >
            <FeatherIcon
              className="icon-btn-product-card-item-add-card"
              icon="shopping-cart"
            />
            Ajouter au panier
          </button>
        </ButtonToolbar>

        <Modal
          size="xs"
          show={this.state.show}
          onHide={this.close}
          className="theme-light-rs-modal mandatory-login"
        >
          <div className="row dis-flex justify-content-center m-b-70">
            <div className="col-sm-9 col-10 text-center text-dark fs-13 ff-raleway fw-300">
              Vous avez déjà un compte? <br />
              Connectez-vous pour effectuer vos achats
            </div>
          </div>

          <NavLink
            className="mandatory-login-btn btn-form-dark"
            to={`/connexion/?next=${window.location.pathname}`}
          >
            Connexion
          </NavLink>

          <p className="fs-13 lh-1 ff-raleway fw-300 text-black text-center">
            Première visite ?{' '}
            <NavLink
              className="link-form-signup-login h-theme fs-13"
              to="/inscription"
            >
              Inscrivez-vous
            </NavLink>{' '}
            en quelques clics !{' '}
          </p>
        </Modal>
      </div>
    )
  }
}
