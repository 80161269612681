import React, { Component } from "react";
// import './tableArticles.css';
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import FeatherIcon from "feather-icons-react";
import api from "../../../http/global-vars";
import axios from "axios";
import { toast } from "react-toastify";
import AddCategoryModal from "../TableCatgories/AddCategoryModal";
import DeleteCategoryModal from "../TableCatgories/DeleteCategoryModal";
import EditCategoryModal from "../TableCatgories/EditCategoryModal";

export default class TableSubCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subcategories: [],
      categories: [],
      category: {},
      checked: true,
      productActivToggle: false,
      api: api,
      fields: {},
      onArchiveProduct: true,
      loading: false,
      archivageProductInProgress: false,
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      show: false,
      archivageInProgress: false,
    };
  }

  // componentDidMount() {
  //   this.getCategories();
  // }
  componentDidMount() {
    // this.getCategories();
    if (this.props.categories) {
      console.log('categories', this.props.categories)
      this.setState({
        subcategories: this.props.categories
          .filter((item) => item?.category_id !== null && item?.category_id)
          .sort((a, b) => b?.id - a?.id),
        categories: this.props.categories
          .filter((item) => item?.category_id === null && !item?.category_id)
          .sort((a, b) => b?.id - a?.id),
      })
    }
  }

  componentDidUpdate(prevProps) {
    // console.log("props", prevProps);
    if (prevProps?.categories !== this.props.categories) {
      console.log('categories', this.props.categories)
      this.setState({
        subcategories: this.props.categories
          .filter((item) => item?.category_id !== null && item?.category_id)
          .sort((a, b) => b?.id - a?.id),
        categories: this.props.categories
          .filter((item) => item?.category_id === null && !item?.category_id)
          .sort((a, b) => b?.id - a?.id),
      })
    }
  }

  // getCategories = () => {
  //   axios.get(`${api}category/`, this.state.requestConfig).then((response) => {
  //     console.log("response", response);
  //     this.setState({
  //       subcategories: response?.data?.results
  //         .filter((item) => item?.category_id !== null && item?.category_id)
  //         .sort((a, b) => b?.id - a?.id),
  //       categories: response?.data?.results
  //         .filter((item) => item?.category_id === null && !item?.category_id)
  //         .sort((a, b) => b?.id - a?.id),
  //     });
  //   });
  // };

  handleClose = (e) => {
    e.preventDefault();
    this.setState({ show: false });
    this.setState({
      category: {},
      archivageInProgress: false,
    });
  };
  handleOpen = (row) => {
    this.setState({
      category: row,
    });
    this.setState({ show: true });
  };

  onDeleteCategory = (e) => {
    e.preventDefault();
    this.setState({ archivageInProgress: true });
    let { category } = this.state;

    axios
      .delete(`${api}category/${category?.id}/`, this.state.requestConfig)
      .then((response) => {
        console.log(response);
        // this.setState({ archivageInProgress: false });
        if (response) {
          // Alert.success("Le produit à été supprimé.");
          setTimeout(() => {
            // this.props.onGetProductShop();
          }, 3000);
          this.setState({
            category: {},
            archivageInProgress: false,
            show: false,
          });
          this.props.getCategories();
          toast.success("Sous catégorie supprimée avec succés.");
        }
      })
      .catch((error) => {
        if (error) {
          this.setState({ archivageInProgress: false });
        }
      });
  };

  categoryFormatter = (cell, row) => {
    let name = this.state.categories.filter(
      (item) => item?.id === parseInt(cell)
    )[0]?.name;
    return name;
  };

  actionFormatter = (cell, row) => {
    return (
      <div className="dis-flex justify-content-center">
        <button
          // onClick={() =>
          //   this.setState({
          //     category: row,
          //   })
          // }
          data-toggle="modal"
          data-target={`.edit-sub-category-modal${row?.id}`}
          className="btn-action-table-grey clear"
        >
          <FeatherIcon icon="edit" className="icon-action-table-grey" />
        </button>
        <button
          onClick={() => this.handleOpen(row)}
          className="btn-action-table-grey clear"
        >
          <FeatherIcon icon="trash-2" className="icon-action-table-grey" />
        </button>

        {/* MOdal Edit */}
        <div
          className={`modal fade easypm-modal edit-sub-category-modal${row?.id}`}
          id="AddCategoryMoal"
          tabIndex="-1"
          data-backdrop="static"
          aria-labelledby="AddCategoryMoalLabel"
          aria-hidden="true"
        >
          <EditCategoryModal
            category={row}
            getCategories={this.getCategories}
            from="sub"
            categories={this.state.categories}
          />
        </div>
      </div>
    );
  };

  render() {
    const options = {
      noDataText: "Aucune donnée disponible",
    };
    return (
      <div>
        <div className="row mb-3">
          <div className="col-md-9 col-sm-8 mb-sm-0 mb-5">
            {/* <p className="title-filter-dashboard">Tous les produits</p> */}
          </div>
          <div className="col-md-3 col-sm-4 pl-sm-0">
            <div
              className="adding-item-container linkable"
              data-toggle="modal"
              data-target=".add-sub-category-modal"
              style={{ justifyContent: "center" }}
            >
              <span className="icon-container">
                <FeatherIcon
                  icon="plus-circle"
                  className="icon-action-table-grey"
                />
              </span>
              <span className="adding-item-label">Ajouter une categorie</span>
            </div>
          </div>
        </div>
        <div className="table-container">
          <BootstrapTable
            className="head-table-custom"
            options={options}
            data={this.state.subcategories}
            search={true}
            multiColumnSearch={true}
            striped
            hover
            multiColumnSort={2}
            pagination
            version="4"
          >
            <TableHeaderColumn
              isKey
              dataField="id"
              hidden={true}
              tdStyle={{
                fontWeight: 400,
                fontSize: 14,
                color: "rgba(0, 0, 0, 0.7)",
                borderTop: "none",
                borderBottom: "none",
                padding: "1rem",
                whiteSpace: "normal",
                lineHeight: 1,
              }}
              thStyle={{ textAlign: "center" }}
              className="th-table-custom"
            >
              N°
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="name"
              width="230"
              tdStyle={{
                fontWeight: 400,
                fontSize: 14,
                color: "rgba(0, 0, 0, 0.7)",
                borderTop: "none",
                borderBottom: "none",
                padding: "1rem",
                whiteSpace: "normal",
                lineHeight: 1,
              }}
              dataAlign="center"
              // thStyle={{ textAlign: "center" }}
              className="th-table-custom"
            >
              Nom
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="category_id"
              className="th-table-custom"
              width="150"
              dataFormat={(cell, row) => this.categoryFormatter(cell, row)}
              // thStyle={{ textAlign: "center" }}
              dataAlign="center"
              tdStyle={{
                fontWeight: 400,
                fontSize: 14,
                color: "rgba(0, 0, 0, 0.7)",
                borderTop: "none",
                borderBottom: "none",
                padding: "1rem",
                // textAlign: "center",
              }}
            >
              Parent
            </TableHeaderColumn>
            <TableHeaderColumn
              className="th-table-custom"
              dataFormat={this.actionFormatter}
              dataField="id"
              width="150"
              dataAlign="center"
              // thStyle={{ textAlign: "center" }}
              tdStyle={{
                fontWeight: 400,
                fontSize: 14,
                color: "rgba(0, 0, 0, 0.7)",
                borderTop: "none",
                borderBottom: "none",
                padding: "1rem",
                // textAlign: "center",
              }}
            >
              Actions
            </TableHeaderColumn>
          </BootstrapTable>
        </div>
        {/* MOdal Add */}
        <div
          className="modal fade easypm-modal add-sub-category-modal"
          id="AddCategoryMoal"
          tabIndex="-1"
          data-backdrop="static"
          aria-labelledby="AddCategoryMoalLabel"
          aria-hidden="true"
        >
          <AddCategoryModal
            getCategories={this.props.getCategories}
            from="sub"
            categories={this.state.categories}
          />
        </div>
        <DeleteCategoryModal
          handleClose={this.handleClose}
          show={this.state.show}
          onDeleteCategory={this.onDeleteCategory}
          archivageInProgress={this.state.archivageInProgress}
          from="sub"
        />
      </div>
    );
  }
}
