import React, { useEffect, useState } from "react";
import MicRecorder from "mic-recorder-to-mp3";
import FeatherIcon from "feather-icons-react";
import { Message } from "rsuite";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

function DetailsProduct({ register, errors, audio, callBackRecorder }) {
  const [isRecording, setIsRecording] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [audioDisplay, setAudioDisplay] = useState(false);
  const [onRecording, setOnRecording] = useState(false);
  const [blobURL, setBlobURL] = useState("");

  useEffect(() => {
    setAudioDisplay(true);

    navigator?.mediaDevices?.getUserMedia(
      { audio: true },
      () => {
        setIsBlocked(false);
      },
      () => {
        setIsBlocked(true);
      }
    );
  }, []);

  const start = () => {
    if (isBlocked) {
    } else {
      Mp3Recorder.start()
        .then(() => {
          setIsRecording(true);
          setOnRecording(true);
        })
        .catch((e) => console.error(e));
    }
  };
  const stop = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const file = new File(buffer, "product_description.mp3", {
          type: blob.type,
          lastModified: Date.now(),
        });

        const urlFile = URL.createObjectURL(blob);
        setBlobURL(urlFile);
        setIsRecording(false);
        setOnRecording(false);
        setAudioDisplay(false);

        callBackRecorder(file);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <div className="mt-3">
      <p className="consigne-title-steps mb-5">
        Ajouter les details du produit
      </p>

      <div className="row">
        <div className="col-md-6 pr-lg-5">
          <div className="form-group mb-4">
            <textarea
              className="textarea-theme-marketplace"
              rows="9"
              placeholder="Description du produit"
              {...register("description")}
            ></textarea>
          </div>
        </div>
        <div className="col-md-6 pl-lg-5">
          <div className="mb-4 d-flex align-items-center">
            {audioDisplay === false ? (
              onRecording === true ? (
                <p
                  className="w-80 h-theme-dark d-flex align-items-center"
                  style={{ height: 54 }}
                >
                  Enregistrement en cours ...{" "}
                </p>
              ) : (
                <p
                  className="w-80 h-theme-dark d-flex align-items-center"
                  style={{ height: 54 }}
                >
                  Cliquez sur le micro pour enregistrer votre description
                </p>
              )
            ) : (
              <audio className="w-80" src={blobURL} controls="controls" />
            )}

            {isRecording === false ? (
              <span
                className="btn-start-recorder ml-auto"
                onClick={() => start()}
              >
                <FeatherIcon icon="mic" />
              </span>
            ) : (
              <></>
            )}
            {isRecording === true ? (
              <span
                className="btn-finish-recorder ml-auto"
                onClick={() => stop()}
              >
                <FeatherIcon icon="stop-circle" />
              </span>
            ) : (
              <></>
            )}
          </div>
          <div className="form-group mb-4">
            <input
              type="text"
              {...register("dimension")}
              className="form-control input-steps-inscription-vendeur"
              placeholder="Dimensions"
            />
            {errors?.dimension && (
              <Message
                className="alert-rsuite-custom"
                type="error"
                description={errors?.dimension?.message}
              />
            )}
          </div>
          <div className="form-group mb-4">
            <input
              type="number"
              min="0"
              className="form-control input-steps-inscription-vendeur"
              placeholder="Poids"
              {...register("weight")}
            />
            {errors?.weight && (
              <Message
                className="alert-rsuite-custom"
                type="error"
                description={errors?.weight?.message}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsProduct;
