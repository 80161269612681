import React, { Component } from "react";
import "./addProductSteps.css";
import HeaderDashboard from "../headerDashboard/index";
import NavVerticalDashboard from "../navVerticalDashboard/index";
import { NavLink } from "react-router-dom";
import StepsAddingProduct from "./stepsAddingProduct";
import api from "../../../http/global-vars";
import axios from "axios";
import AddOrEditProductSeller from "./AddOrEditProductSeller";

export default class addProductSteps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      userID: window.localStorage.getItem("userID"),
      api: api,
      loading: false,
      shopDetails: [],
    };
  }

  

  render() {
    return (
      <div className="p-b-200">
        <HeaderDashboard />
        <div className="fixed-after-header-dashboard container-fluid">
          <div className="row">
            <div className="col-xl-2 col-lg-3 no-view-tablette">
              <NavVerticalDashboard />
            </div>
            <div className="col-xl-10 col-lg-9 px-xl-3 py-4 pr-lg-3 pl-lg-1">
              <p className="m-t-7 mb-3 dis-inline-block">
                <NavLink to="/articles" className="routing-link">
                  Articles
                </NavLink>{" "}
                /<span className="routing-link"> Ajouter/Modifier un produit</span>
              </p>
              <div className="card-dashboard b-r-15">
                <p className="title-filter-dashboard mb-5">
                  Ajouter/Modifier un produit
                </p>

                <AddOrEditProductSeller />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
