import React, { Component, useState } from 'react'
import './articlesDashboard.css'
import HeaderDashboard from '../headerDashboard/index'
import NavVerticalDashboard from '../navVerticalDashboard/index'
import { NavLink } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react'
import TableArticles from '../tableArticles/index'
import api from '../../../http/global-vars';
import axios from 'axios';
import ProductSkeleton from '../backSkeleton/ProductSkeleton';
import { connect } from 'react-redux';
import { fetchProductsByShop } from '../../../productAction/productAction';

// class articlesDashboard extends Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//       token: window.localStorage.getItem('userToken'),
//       requestConfig: {
//         headers: {
//           Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
//         },
//       },
//       userID: window.localStorage.getItem('userID'),
//       api: api,
//       fields: {},
//       produits: [],
//       loading: false,
//       shopDetails: [],
//     }
//   }

//   componentDidMount() {
//     var config = this.state.requestConfig
//     this.onGetShopInfos(config)
//   }

//   onGetShopInfos = (config) => {
//     this.setState({ loading: true })
//     var api = this.state.api
//     var userID = this.state.userID
//     axios
//       .get(api + `fournisseurbyuser/${userID}/`, config)
//       .then((response) => {
//         if (response.status === 200 || response.status === 201) {
//           this.setState({
//             shopDetails: response.data[0],
//             userInfos: response.data[0].user,
//           })
//           this.props.fetchProductsByShop(response.data[0]?.id)
//           setTimeout(() => {
//             this.setState({ loading: false })
//           }, 1000)
//         }
//       })
//       .catch((error) => {
//         if (error) {
//           this.setState({ loading: false })
//         }
//       })
//   }

//   render() {

//     let products = this.props.products?.filter((f) => f.is_archive === false )
//     let productsInactifs = this.props.products?.filter((f) => f.is_archive === false && f.is_active === false );
//     let produitsActifs = this.props.products?.filter((f) => f.is_archive === false && f.is_active === true);
//     let produitsEnRupture = this.props.products?.filter((f) => f.is_archive === false && f.quantity === 0);
//     let produitsAuTop = this.props.products?.filter((f) => f.is_archive === false && f.averagestar >= 4);
//     // console.log('top', produitsActifs, productsInactifs, produitsAuTop)
//     console.log('top1', this.props.products?.filter((f) => f.is_archive === false))
//     return (
//       <div className="p-b-200">
//         <HeaderDashboard />
//         <div className="fixed-after-header-dashboard container-fluid">
//           <div className="row">
//             <div className="col-xl-2 col-lg-3 no-view-tablette">
//               <NavVerticalDashboard />
//             </div>
//             <div className="col-xl-10 col-lg-9 px-xl-3 py-4 pr-lg-3 pl-lg-1">
//               <div className="card-dashboard-content-table">
//                 <div className="head-card-dashboard-content-table">
//                   <div className="row mb-5">
//                     <div className="col-md-9 col-sm-8 mb-sm-0 mb-3">
//                       <p className="title-filter-dashboard">Catalogue</p>
//                     </div>
//                     <div className="col-md-3 col-sm-4 pl-sm-0">
//                       <NavLink
//                         className="link-add-with-icon fw-500"
//                         to="/ajouter-un-produit"
//                       >
//                         <FeatherIcon
//                           size="30"
//                           className="icon-link-add-with-icon"
//                           icon="plus-circle"
//                         />
//                         Ajouter un produit
//                       </NavLink>
//                     </div>
//                   </div>

//                   <div className="row">
//                     <div className="col-xl-9">
//                       <nav>
//                         <div
//                           className="nav nav-tabs nav-tabs-horizontal-commandes"
//                           id="nav-tab"
//                           role="tablist"
//                         >
//                           <a
//                             className="nav-link nav-horizontal-commandes active"
//                             id="nav-tout-tab"
//                             data-toggle="tab"
//                             href="#nav-tout"
//                             role="tab"
//                             aria-controls="nav-tout"
//                             aria-selected="true"
//                           >
//                             Tout
//                           </a>
//                           <a
//                             className="nav-link nav-horizontal-commandes"
//                             id="nav-en-ligne-tab"
//                             data-toggle="tab"
//                             href="#nav-en-ligne"
//                             role="tab"
//                             aria-controls="nav-en-ligne"
//                             aria-selected="false"
//                           >
//                             En ligne
//                           </a>
//                           {/* <a
//                             className="nav-link nav-horizontal-commandes"
//                             id="nav-produit-rejetes-tab"
//                             data-toggle="tab"
//                             href="#nav-produit-rejetes"
//                             role="tab"
//                             aria-controls="nav-produit-rejetes"
//                             aria-selected="false"
//                           >
//                             Produits rejetés
//                           </a> */}
//                           <a
//                             className="nav-link nav-horizontal-commandes"
//                             id="nav-en-rupture-tab"
//                             data-toggle="tab"
//                             href="#nav-en-rupture"
//                             role="tab"
//                             aria-controls="nav-en-rupture"
//                             aria-selected="false"
//                           >
//                             En rupture de stock
//                           </a>
//                           <a
//                             className="nav-link nav-horizontal-commandes"
//                             id="nav-top-produit-tab"
//                             data-toggle="tab"
//                             href="#nav-top-produit"
//                             role="tab"
//                             aria-controls="nav-top-produit"
//                             aria-selected="false"
//                           >
//                             Top produits
//                           </a>
//                           <a
//                             className="nav-link nav-horizontal-commandes"
//                             id="nav-inactifs-tab"
//                             data-toggle="tab"
//                             href="#nav-inactifs"
//                             role="tab"
//                             aria-controls="nav-inactifs"
//                             aria-selected="false"
//                           >
//                             Inactifs
//                           </a>
//                           {/* <a
//                             className="nav-link nav-horizontal-commandes"
//                             id="nav-non-autorises-tab"
//                             data-toggle="tab"
//                             href="#nav-non-autorises"
//                             role="tab"
//                             aria-controls="nav-non-autorises"
//                             aria-selected="false"
//                           >
//                             Non autorisé
//                           </a> */}
//                         </div>
//                       </nav>
//                     </div>
//                     <div className="col-xl-3"></div>
//                   </div>
//                 </div>
//                 <div className="content-card-dashboard-content-table">
//                   <div className="tab-content" id="nav-tabContent">
//                     <div
//                       className="tab-pane fade show active"
//                       id="nav-tout"
//                       role="tabpanel"
//                       aria-labelledby="nav-tout-tab"
//                     >
//                       {this.state.loading ? (
//                         <ProductSkeleton />
//                       ) : (
//                         <TableArticles
//                           onGetProductShop={this.onGetProductShop}
//                           produits={products}
//                         />
//                       )}
//                     </div>
//                     <div
//                       className="tab-pane fade"
//                       id="nav-en-ligne"
//                       role="tabpanel"
//                       aria-labelledby="nav-en-ligne-tab"
//                     >
//                       {this.state.loading ? (
//                         <ProductSkeleton />
//                       ) : (
//                         <TableArticles
//                           onGetProductShop={this.onGetProductShop}
//                           produits={produitsActifs}
//                         />
//                       )}
//                     </div>
//                     <div
//                       className="tab-pane fade"
//                       id="nav-produit-rejetes"
//                       role="tabpanel"
//                       aria-labelledby="nav-produit-rejetes-tab"
//                     >
//                       Produits rejetés
//                     </div>
//                     <div
//                       className="tab-pane fade"
//                       id="nav-en-rupture"
//                       role="tabpanel"
//                       aria-labelledby="nav-en-rupture-tab"
//                     >
//                       {this.state.loading ? (
//                         <ProductSkeleton />
//                       ) : (
//                         <TableArticles
//                           onGetProductShop={this.onGetProductShop}
//                           produits={produitsEnRupture}
//                         />
//                       )}
//                     </div>
//                     <div
//                       className="tab-pane fade"
//                       id="nav-top-produit"
//                       role="tabpanel"
//                       aria-labelledby="nav-top-produit-tab"
//                     >
//                       {this.state.loading ? (
//                         <ProductSkeleton />
//                       ) : (
//                         <TableArticles
//                           onGetProductShop={this.onGetProductShop}
//                           produits={produitsAuTop}
//                         />
//                       )}
//                     </div>
//                     <div
//                       className="tab-pane fade"
//                       id="nav-inactifs"
//                       role="tabpanel"
//                       aria-labelledby="nav-inactifs-tab"
//                     >
//                       {this.state.loading ? (
//                         <ProductSkeleton />
//                       ) : (
//                         <TableArticles
//                           onGetProductShop={this.onGetProductShop}
//                           produits={productsInactifs}
//                         />
//                       )}
//                     </div>
//                     <div
//                       className="tab-pane fade"
//                       id="nav-non-autorises"
//                       role="tabpanel"
//                       aria-labelledby="nav-non-autorise-tab"
//                     >
//                       Non autorisé
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

// export default connect(
//   (state) => ({
//     products: state.products.items,
//     filteredShop: state.products.filteredItems,
//   }),
//   {
//     fetchProductsByShop,
//   },
// )(articlesDashboard)

function ArticlesDashboard() {
  const [filter, setFilter] = useState("")
  return (
    <div className="component-articles-dashboard-admin">
      <HeaderDashboard />
      <div className="fixed-after-header-dashboard container-fluid">
        <div className="row">
          <div className="col-xl-2 col-lg-3 no-view-tablette">
            <NavVerticalDashboard />
          </div>
          <div className="col-xl-10 col-lg-9 px-xl-3 py-4 pr-lg-3 pl-lg-1">
            <div className="card-dashboard-content-table">
              <div className="head-card-dashboard-content-table">
                <div className="row mb-5">
                  <div className="col-md-9 col-sm-8 mb-sm-0 mb-3">
                    <p className="title-filter-dashboard">Tous les produits</p>
                  </div>
                  <div className="col-md-3 col-sm-4 pl-sm-0"></div>
                </div>

                <div className="row">
                  <div className="col-xl-9">
                    <nav>
                      <div
                        className="nav nav-tabs nav-tabs-horizontal-commandes"
                        id="nav-tab"
                        role="tablist"
                      >
                        <button
                          className={`nav-link nav-horizontal-commandes ${
                            !filter ? "active" : ""
                          }`}
                          id="nav-tout-tab"
                          onClick={() => setFilter("")}
                        >
                          Tout
                        </button>
                        <button
                          className={`nav-link nav-horizontal-commandes ${
                            filter === "active" ? "active" : ""
                          }`}
                          id="nav-active-tab"
                          onClick={() => setFilter("active")}
                        >
                          Activées
                        </button>
                        <button
                          className={`nav-link nav-horizontal-commandes ${
                            filter === "desactive" ? "active" : ""
                          }`}
                          id="nav-desactive-tab"
                          onClick={() => setFilter("desactive")}
                        >
                          Desactivées
                        </button>
                        <button
                          className={`nav-link nav-horizontal-commandes ${
                            filter === "rupture" ? "active" : ""
                          }`}
                          id="nav-en-rupture-tab"
                          onClick={() => setFilter("rupture")}
                        >
                          En rupture
                        </button>
                        <button
                          className={`nav-link nav-horizontal-commandes ${
                            filter === "best" ? "active" : ""
                          }`}
                          id="nav-top-produits-tab"
                          onClick={() => setFilter("best")}
                        >
                          Meilleurs produits
                        </button>
                      </div>
                    </nav>
                  </div>
                  <div className="col-xl-3"></div>
                </div>
              </div>
              <div className="content-card-dashboard-content-table">
                <div className="tab-content" id="nav-tabContent">
                  <div className="tab-pane fade show active">
                    <TableArticles filter={filter} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArticlesDashboard