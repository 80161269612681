import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartItems: [],
  number: 0,
  delivery: 0,
  tax: 0,
  subAmount: 0,
  totalAmount: 0,
};
const useCartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addCartProduct: (state, action) => {
      let cartIndex = state.cartItems.findIndex(
        (item) => item?.product.id === action.payload.product?.id
      );
      if (cartIndex >= 0) {
        state.cartItems[cartIndex].qty += action?.payload?.qty;
      } else {
        let tempProduct = { ...action.payload };
        state.cartItems.push(tempProduct);
      }
    },
    getCartProducts: (state) => {
      return {
        ...state,
      };
    },
    getCartCount: (state) => {
      let cartCount = state.cartItems.reduce((total, item) => {
        return item?.qty + total;
      }, 0);
      state.totalCount = cartCount;
    },
    getSubTotal: (state) => {
      state.subAmount = state.cartItems.reduce((acc, item) => {
        return acc + item?.product?.price * item?.qty;
      }, 0);
    },
    removeCartItem: (state, action) => {
      let index = state.cartItems.findIndex(
        (item) => item?.product?.id === action.payload?.id
      );
      if (index !== -1) {
        state.cartItems.splice(index, 1);
      }
    },
    increment: (state, action) => {
      let index = state.cartItems.findIndex(
        (item) => item?.product?.id === action.payload?.product?.id
      );
      state.cartItems[index].qty += 1;
    },
    decrement: (state, action) => {
      let index = state.cartItems.findIndex(
        (item) => item?.product?.id === action.payload?.product?.id
      );
      if (state.cartItems[index].qty <= 0) {
        state.cartItems[index].qty = 0;
        state.cartItems.splice(index, 1);
      } else {
        state.cartItems[index].qty -= 1;
      }
    },
    calculateTax: (state) => {
      // GST value: 18% => action.payload
      let totalTax = (18 / 100) * state.subAmount;
      state.tax = totalTax;
    },
    getDeliveryAmount: (state, action) => {
      state.delivery = action.payload;
    },
    // getTotalAmount: (state) => {
    //   state.totalAmount = state?.tax + state?.subAmount + state.delivery;
    // },
    getTotalAmount: (state) => {
      state.totalAmount = parseInt(state?.subAmount) + parseInt(state.delivery);
    },
    removeCart: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const {
  addCartProduct,
  getCartProducts,
  removeCartItem,
  getCartCount,
  getSubTotal,
  increment,
  decrement,
  calculateTax,
  getTotalAmount,
  removeCart,
  getDeliveryAmount,
} = useCartSlice.actions;

export default useCartSlice.reducer;
