import {
    FETCH_PRODUCTS,
    FILTER_PRODUCTS_BY_SIZE,
    FILTER_PRODUCTS_BY_PRICE,
    FILTER_PRODUCTS_BY_CATEGORY,
    FILTER_PRODUCTS_BY_SHOP,
    FILTER_PRODUCTS_BY_RATE,
    PRODUCTS_BY_SHOP
  } from "../types";
  
  export const productReducer = (
    state = { isLoading: true, items: [], filteredItems: [] },
    action
  ) => {
    switch (action.type) {
      case FILTER_PRODUCTS_BY_SIZE:
        return {
          ...state,
          size: action.payload.size,
          filteredItems: action.payload.items,
        };
      case FILTER_PRODUCTS_BY_CATEGORY:
        return {
          ...state,
          category: action.payload.category,
          filteredItems: action.payload.items,
        };
      case FILTER_PRODUCTS_BY_RATE:
        return {
          ...state,
          rate: action.payload.rate,
          filteredItems: action.payload.items,
        };
      case FILTER_PRODUCTS_BY_SHOP:
        return {
          ...state,
          shop: action.payload.shop,
          filteredItems: action.payload.items,
        };
      case FILTER_PRODUCTS_BY_PRICE:
        return {
          ...state,
          min: action.payload.min,
          max: action.payload.max,
          filteredItems: action.payload.items,
        };
      case PRODUCTS_BY_SHOP:
        return {
          items: action.payload,
          filteredItems: action.payload,
          isLoading: action?.loading,
        };

      case FETCH_PRODUCTS:
        return {
          items: action.payload,
          filteredItems: action.payload,
          isLoading: action?.isLoading,
        };
      default:
        return state;
    }
  };
  