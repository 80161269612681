import axios from "axios";
import React, { useState } from "react";
import { Modal, Message, Loader } from "rsuite";
import api from "../../../http/global-vars";

function EditBannerShopModal({ shop, show, close, fn }) {
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  let config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
    },
  };

  const handleChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleClose = () => {
    setFile(null)
    close()
  }

  const onSubmit = async (e) => {
    e?.preventDefault();

    if (file) {
      setIsLoading(true);
      let fd = new FormData();
      fd.append("banner", file);
      await axios
        .put(`${api}fournisseur/${shop?.id}/`, fd, config)
        .then((res) => {
          console.log(res);
          fn();
          setIsLoading(false);
          close()
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } else {
      setError("Veuiller uploader une image");
      setTimeout(() => {
        setError("")
      }, 3000);
    }
  };

  return (
    <div>
      <Modal
        size="xs"
        show={show}
        onHide={handleClose}
        className="rsuite-content-modal-custom"
      >
        <form onSubmit={(e) => onSubmit(e)}>
          <Modal.Header>
            <Modal.Title>Ajouter une bannière à la boutique</Modal.Title>
          </Modal.Header>
          <div>
            <div className="custom-file mt-5 mb-5">
              <input
                type="file"
                name="banner"
                className="custom-file-input input-custom-import-file"
                id="addBanner"
                onChange={handleChange}
                accept="image/*"
              />
              <label
                className="custom-file-label label-custom-import-file"
                htmlFor="addBanner"
              >
                {!file ? "Importer un ficher" : file?.name}
              </label>
              {error && (
                <Message
                  className="alert-rsuite-custom"
                  type="error"
                  description={error}
                />
              )}
            </div>
          </div>
          <Modal.Footer className="text-center mt-4">
            <button
              type="submit"
              className="btn-change-password"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  en cours &nbsp;
                  <Loader />
                </>
              ) : (
                "Terminer"
              )}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}

export default EditBannerShopModal;
