import React, {Component} from 'react';
import './modalShopDeleted.css'
import { Modal } from 'rsuite';
import ConfirmationIcon from '../../img/icons/check-icon.png';
import { NavLink } from "react-router-dom";

export default class modalShopDeleted extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {};
    // }
    render() {
      return <div className="modal-container">
        <Modal size="xs" show={this.props.showShopDeleted} onHide={this.props.closeShopDeleted} className="rsuite-content-modal-custom">
            <form>
            <Modal.Header>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <div className="my-5 flex-col itm-center">
                <img loading="lazy" src={ConfirmationIcon} alt="" className="icon-confirmation-modal mb-4" />
                <p className="title-confirmation-modal my-4">Votre boutique n'existe plus!!! veuillez contactez l'administrateur.</p>
            </div>
            <Modal.Footer className="text-center">
                <div className="row px-5">
                    {/* <div className="col-12 mb-2">
                        <button onClick={this.props.closeShopDeleted} className="btn-change-password">Continuer mes achats</button>
                    </div> */}
                    <div className="col-12 mb-3">
                        <NavLink to="/checkout" className="btn-light-theme">Valider ma commande</NavLink>
                    </div>
                </div>
            </Modal.Footer>
            </form>
        </Modal>
      </div>;
    }
  }
// export default connect(
//     ({ modalShopDeleted }) => ({ ...modalShopDeleted }),
//     dispatch => bindActionCreators({ ...modalShopDeletedActions }, dispatch)
//   )( modalShopDeleted );