import React, { Component } from 'react'
import './productDetails.css'
import Header from '../header/index'
import Footer from '../footer/index'
import { NavLink } from 'react-router-dom'
import SimilaryProduct from '../similaryProduct/index'
import ContentProductDetails from '../contentProductDetails/index'
import axios from 'axios'
import api from '../../http/global-vars'

export default class productDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      productId: this.props.match.params.id,
      product: {},
      isLoading: true
    }
  }

  componentDidMount() {
    
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.getProductById()
  }

  getProductById = () => {
    const url = `${api}product-detail/${this.state.productId}/`;
    if (this.state.productId && this.state.productId !== undefined) {
      axios
        .get(url)
        .then((response) => {
          console.log('this',response.data)
          this.setState({
            product: response.data,
            isLoading: false
          });

      
        })
        .catch((error) => {
          if (error) {
            this.setState({ isLoading: false });
          }
        });
    }
  };

  render() {
    const { product } = this.state
    return (
      <div className="body-theme">
        <Header />
        <div className="fixed-after-header">
          <div className="container">
            <p className="m-t-7 dis-inline-block">
              <NavLink to="/" className="routing-link">
                Accueil
              </NavLink>{" "}
              /
              <NavLink
                to={`/boutique/${product?.provider?.id}`}
                className="routing-link"
              >
                {" "}
                {product?.provider?.shop_name}
              </NavLink>{" "}
              /
              <NavLink to="" className="routing-link">
                {" "}
                {product?.name}
              </NavLink>
            </p>
          </div>

          <div className="container-fluid pl-md-5 pr-md-5 p-b-150 pt-md-2 pt-sm-2 pt-2">
            <ContentProductDetails productId={this.state.productId} />

            <SimilaryProduct id={this.props.match.params.id} />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
