import axios from "axios";
import React, { Component } from "react";
import { toast } from "react-toastify";
import { Loader, Message } from "rsuite";
import api from "../../../http/global-vars";
import Select from "react-select";

class EditCategoryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        name: this.props.category?.name,
        position: this.props.category?.position,
      },
      isLoading: false,
      error: {},
      isEmpty: false,
      isEmptyParent: false,
      isEmptyPosition: false,
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      userID: window.localStorage.getItem("userID"),
      api: api,
      options: [],
      category: {
        value: this.props.category?.category_id,
        label: this.props.categories?.filter(
          (item) => item?.id === parseInt(this.props.category?.category_id)
        )[0]?.name,
      },
    };
  }

  componentDidMount() {
    if (this.props?.categories?.length && this.props?.from === "sub") {
      console.log("categories");
      this.setState({
        options: this.props.categories.map((item) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        }),
      });
    }
  }

  handleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;

    this.setState({
      fields: fields,
    });
  };

  onValidateFormData = () => {
    let fields = this.state.fields;
    let errors = this.state.error;
    var isValidForm = true;

    if (!fields["name"]) {
      isValidForm = false;
      this.setState({
        isEmpty: true,
      });
      setTimeout(() => {
        this.setState({
          isEmpty: false,
        });
      }, 5000);

      errors["name"] = "Veuillez renseigner le nom de la catégorie!";
    }

    if (!fields["position"] && this.props?.from !== "sub") {
      isValidForm = false;
      this.setState({
        isEmptyPosition: true,
      });
      setTimeout(() => {
        this.setState({
          isEmptyPosition: false,
        });
      }, 5000);

      errors["position"] = "Veuillez renseigner la position de la catégorie!";
    }

    if (!fields["category_id"] && this.props?.from === "sub") {
      isValidForm = false;
      this.setState({
        isEmptyParent: true,
      });
      setTimeout(() => {
        this.setState({
          isEmptyParent: false,
        });
      }, 5000);

      errors["category_id"] = "La catégorie est requise.";
    }

    if (this.props?.from !== "sub") {
      delete fields?.category_id;
    }

    this.setState({
      error: errors,
    });

    return isValidForm;
  };

  handleSelect = (option) => {
    let fields = this.state.fields;
    fields["category_id"] = option.value;

    this.setState({
      fields: fields,
      category: option,
    });
    console.log("option", option);
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (this.onValidateFormData()) {
      this.setState({
        isLoading: true,
      });

      var api = this.state.api;
      var data = this.state.fields;

      axios
        .put(
          api + `category/${this.props.category?.id}/`,
          data,
          this.state.requestConfig
        )
        .then((response) => {
          console.log("la reponse", response);
          this.props.getCategories();
          window
            .$(`.edit-category-modal${this.props.category?.id}`)
            .modal("hide");
          window
            .$(`.edit-sub-category-modal${this.props.category?.id}`)
            .modal("hide");
          e.target.reset();
          this.setState({
            isLoading: false,
            fields: {},
            error: {},
            category: "",
          });
          toast.success("Catégorie modifiée avec succés.");
        })
        .catch((error) => {
          console.log("erreur", error.response);
        });
    }
  };

  handleReset = () => {
    this.setState({ category: {} });
  };
  render() {
    // console.log("Category", this.props.category);
    return (
      <div className="modal-dialog easypm-modal-dialog modal-lg">
        <div className="modal-content easypm-modal-content">
          <div className="modal-header easypm-modal-header">
            <h5
              className="modal-title easypm-modal-title"
              id="AddingFamilyModal"
            >
              Modifier une catégorie
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.handleReset()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body easypm-modal-body">
            <div className="add-family-form-container">
              <form className="crud-form" onSubmit={(e) => this.onSubmit(e)}>
                <div className="row crud-row">
                  <div className="col-md-12 crud-col-item">
                    <div
                      className="form-group crud-form-group required"
                      style={{ textAlign: "start" }}
                    >
                      <label for="last_name">Nom</label>
                      <input
                        type="text"
                        placeholder="Nom"
                        name="name"
                        value={this.state.fields?.name}
                        onChange={this.handleChange}
                        className="form-control crud-form-control"
                        id="last_name"
                      />
                      {this.state.isEmpty === true && (
                        <Message
                          className="alert-rsuite-custom"
                          type="error"
                          description={this.state.error.name}
                        />
                      )}
                    </div>
                  </div>

                  {this.props?.from === "sub" ? (
                    <div className="col-md-12 crud-col-item">
                      <div className="form-group crud-form-group required text-left">
                        <label for="category_id">Catégorie</label>

                        <Select
                          className="basic-single"
                          classNamePrefix="Choisir"
                          isSearchable={true}
                          name="category_id"
                          options={this.state.options}
                          onChange={this.handleSelect}
                          value={this.state.category}
                        />

                        {this.state.isEmptyParent === true && (
                          <Message
                            className="alert-rsuite-custom"
                            type="error"
                            description={this.state.error.category_id}
                          />
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="col-md-12 crud-col-item">
                      <div
                        className="form-group crud-form-group required"
                        style={{ textAlign: "start" }}
                      >
                        <label htmlFor="position">Position</label>
                        <input
                          type="number"
                          placeholder="Position"
                          name="position"
                          value={this.state.fields?.position}
                          onChange={this.handleChange}
                          className="form-control crud-form-control"
                          id="position"
                        />
                        {this.state.isEmptyPosition === true && (
                          <Message
                            className="alert-rsuite-custom"
                            type="error"
                            description={this.state.error.position}
                          />
                        )}
                      </div>
                    </div>
                  )}
                  <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                    <button
                      className="btn btn-default cancelleb-btn"
                      data-dismiss="modal"
                    >
                      Retour
                    </button>
                    {!this.state.isLoading && (
                      <button className="btn btn-primary crud-submit-btn">
                        Enregistrer
                      </button>
                    )}
                    {this.state.isLoading && (
                      <button className="btn btn-primary crud-submit-btn">
                        En cours &nbsp;
                        <Loader />
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditCategoryModal;
