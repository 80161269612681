import { SHOPS, PROMOTESHOP,ALLSHOPS } from '../types'

export const shopReducer = (
  state = { isLoading: true, items: [], filteredItems: [],allItems: [] },
  action
) => {
  switch (action.type) {
    case SHOPS:
      return {
        items: action.payload,
        filteredItems: action.payload,
        isLoading: action?.isLoading,
      };
    case PROMOTESHOP:
      return {
        items: action.payload,
        filteredItems: action.payload,
        isLoading: action?.isLoading,
      };
    case ALLSHOPS:
      return {
        ...state,
        allItems: action.payload,
        isLoading: action?.isLoading,
      };
    default:
      return state;
  }
};
