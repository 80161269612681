import React, { useEffect, useState } from "react";
import * as IconlyPack from "react-iconly";
import ThumbsProduct from "../../../img/product1.png";
import { NavLink, useLocation } from "react-router-dom";
import baseUrl from "../../../http/backend-base-url";
import { formatMoney } from "../../../utils/utils";
import HeaderDashboard from "../headerDashboard/headerDashboard";
import NavVerticalDashboardAdmin from "../navVerticalDashboardAdmin";
import moment from "moment";
function DetailsCommande() {
  const [order, setOrder] = useState({});
  const location = useLocation();
  // const format = useFormatAmount();
  useEffect(() => {
    if (location?.state?.order?.id) {
      let oldOrder = {...location.state.order};
      // setOrder(location.state.order);

      let groups = oldOrder?.cartitem.reduce((groups, item) => {
        const key = item?.item?.provider?.id;
        if (!groups[key]) {
          groups[key] = [];
        }
        groups[key].push(item);
        return groups;
      }, {});

      let itemsByShops = Object.keys(groups).map((key) => {
        console.log("item show", groups[key][0]);
        return {
          provider: groups[key][0]?.item?.provider,
          data: groups[key],
        };
      });
      oldOrder["cartitem"] = itemsByShops;
      console.log("order", oldOrder);
      setOrder(oldOrder);
    }
    // return () => {
    //   setOrder({});
    // };
  }, [location]);
  return (
    <div className="component-articles-dashboard-admin">
      <HeaderDashboard />
      <div className="fixed-after-header-dashboard container-fluid">
        <div className="row">
          <div className="col-xl-2 col-lg-3 no-view-tablette">
            <NavVerticalDashboardAdmin />
          </div>
          <div className="col-xl-10 col-lg-9 px-xl-3 py-4 pr-lg-3 pl-lg-1">
            <div className="card-dashboard-content-table">
              <div className="page-content-container">
                <div className="page-first-row-container">
                  <div className="page-title-container">
                    <h1 className="page-title d-flex">
                      <NavLink
                        to={"/commandes-admin"}
                        className="title-filter-dashboard"
                        title="Retour"
                      >
                        <IconlyPack.ArrowLeft
                          set="bold"
                          primaryColor="#000"
                          className="action-btn-icon action-edit-icon"
                          // onClick={() => onDesarchive(row?.id)}
                        />
                      </NavLink>
                      <p className="title-filter-dashboard">
                        {" "}
                        Détails Commande
                      </p>
                    </h1>
                  </div>
                </div>
                <div className="parent-table-container">
                  {/* Commande */}
                  <div>
                    <div className="commande-item">
                      {!!order?.cartitem?.length &&
                        order?.cartitem?.map((cart, i) => (
                          <div
                            className="component-cart-item b-b-1 mb-3"
                            key={i}
                          >
                            <div className="mb-3 shop-cart">
                              Boutique:&nbsp;
                              <NavLink
                                to={{
                                  pathname: `/details-boutique-admin/${cart?.provider?.id}`,
                                  state: cart?.provider,
                                }}
                                className="no-link"
                              >
                                <span className="shop-name">
                                  {cart?.provider?.shop_name}
                                </span>
                              </NavLink>
                            </div>
                            {cart?.data?.map((item) => {
                              return (
                                <div className="row m-b-20" key={item.id}>
                                  <div className="col-xl-7 col-lg-7 col-md-12">
                                    <div className="row">
                                      <div className="col-sm-3 m-b-20">
                                        <img loading="lazy"
                                          className="img-thumbs-commande-item"
                                          src={
                                            item?.item?.image?.length ||
                                            (item?.item?.couverture &&
                                              item?.item?.couverture !==
                                                undefined)
                                              ? item?.item?.couverture
                                                ? baseUrl +
                                                  item?.item?.couverture
                                                : baseUrl +
                                                  item?.item?.image[0]
                                                    ?.image_small
                                              : ThumbsProduct
                                          }
                                          alt=""
                                        />
                                      </div>
                                      <div className="col-sm-9 m-b-20">
                                        <p className="text-commande-item m-b-10 fw-300 fs-14">
                                          {item?.item?.name}
                                          {/* Boubou Enfant en wax */}
                                        </p>
                                        <p
                                          className="text-commande-item m-b-30 fw-300 fs-12"
                                          dangerouslySetInnerHTML={{
                                            __html: item?.item?.description,
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 m-b-20">
                                    <div>
                                      <p className="h-theme fw-600 text-up">
                                        Quantité
                                      </p>
                                      <p
                                        className="text-dark fs-14 ff-open fw-400 text-center"
                                        id="inputRegion"
                                      >
                                        {item?.quantity}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 m-b-20">
                                    <div>
                                      <p className="h-theme fw-600 text-up">
                                        Prix unitaire
                                      </p>
                                      <p className="text-dark fs-14 ff-open fw-400 text-center">
                                        {/* {Intl.NumberFormat("fr-FR").format(
                                parseInt(item.item.price)
                              )} */}
                                        {/* {format(item?.item?.price)} */}
                                        {formatMoney(
                                          item?.price > 0
                                            ? item?.price
                                            : item?.item?.price,
                                          order?.currency
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 m-b-20">
                                    {/* <div>
                              <p className="h-theme fw-600 text-up">Action</p>
                              <button className="btn-icon-action-commande">
                                <FeatherIcon
                                  className="icon-commande-favoris"
                                  icon="heart"
                                />
                              </button>
                              <button className="btn-icon-action-commande">
                                <FeatherIcon
                                  className="icon-commande-delete"
                                  icon="trash-2"
                                />
                              </button>
                            </div> */}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ))}

                      <div className="row">
                        <div
                          className="col-lg-8 dis-flex align-items-start "
                          style={{ flexDirection: "column" }}
                        >
                          <div className="mb-3">
                            <p className="resume-item fw-300 fs-14">
                              Prénom et Nom client
                            </p>
                            <p className="resume-item fw-600 fs-14 ">
                              {order?.user?.first_name +
                                " " +
                                order?.user?.last_name}
                            </p>
                          </div>
                          <div className="mb-3">
                            <p className="resume-item fw-300 fs-14">
                              Email client
                            </p>
                            <p className="resume-item fw-600 fs-14 ">
                              {order?.user?.email}
                            </p>
                          </div>
                          <div className="mb-3">
                            <p className="resume-item fw-300 fs-14">
                              Téléphone client
                            </p>
                            <p className="resume-item fw-600 fs-14 ">
                              {order?.user?.phone}
                            </p>
                          </div>
                          <div className="mb-3">
                            <p className="resume-item fw-300 fs-14">
                              Adresse de livraison
                            </p>
                            <p className="resume-item fw-600 fs-14 ">
                              {order?.shipping_address}
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <p className="resume-item fw-400 fs-14 m-b-20">
                            Code commande :{" "}
                            <span className="value-resume-item fs-14 fw-700">
                              {order?.order_code}
                            </span>
                          </p>
                          <p className="resume-item fw-400 fs-14 m-b-20">
                            Date de la commande :{" "}
                            <span className="value-resume-item fs-14 fw-700">
                              {moment(order?.order_date).format(
                                "DD/MM/YYYY à HH:mm"
                              )}
                            </span>
                          </p>
                          <p className="resume-item fw-400 fs-14 m-b-20">
                            Prix ​​sous-total :{" "}
                            <span className="value-resume-item fs-14 fw-700">
                              {/* {Intl.NumberFormat("fr-FR").format(
                            parseInt(ord.order_total)
                          )} */}
                              {/* {format(order?.order_total)} */}
                              {formatMoney(order?.cart?.total, order?.currency)}
                            </span>
                          </p>
                          <p className="resume-item fw-400 fs-14 m-b-20">
                            Frais de livraison (+) :{" "}
                            <span className="value-resume-item fs-14 fw-700">
                              {order?.shipping > 0
                                ? formatMoney(order?.shipping, order?.currency)
                                : "Livraison offerte"}
                            </span>
                          </p>
                          <p className="resume-item fw-400 fs-14 m-b-20">
                            Remise (-) :{" "}
                            <span className="value-resume-item fs-14 fw-700">
                              {formatMoney(0, order?.currency)}
                            </span>
                          </p>
                          <p className="resume-item fw-700">
                            Total à payer :{" "}
                            <span className="value-resume-item fw-700 fs-17 lh-1-4">
                              {/* {format(
                                parseInt(order?.order_total) +
                                  parseInt(order?.shipping)
                              )} */}
                              {formatMoney(
                                parseFloat(order.order_total),
                                order?.currency
                              )}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsCommande;
