import React, { Component } from "react";
// import './tableArticles.css';
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import FeatherIcon from "feather-icons-react";
import api from "../../../http/global-vars";
import axios from "axios";
import { toast } from "react-toastify";
import { formatMoney } from "../../../utils/utils";
import AddPaymentModal from "./AddPaymentModal";
import EditPaymentModal from "./EditPaymentModal";
import DeletePaymentModal from "./DeletePaymentModal";
import ShowPaymentModal from "./ShowPaymentModal";

class PaymentTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payments: [],
      payment: {},
      api: api,
      fields: {},
      loading: false,
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      show: false,
      showDetails: false,
      archivageInProgress: false,
    };
  }

  componentDidMount() {
    this.getpayments();
  }

  getpayments = () => {
    axios
      .get(`${api}paymentmode/`, this.state.requestConfig)
      .then((response) => {
        console.log("response", response);
        this.setState({
          payments: response?.data?.results.sort((a, b) => b?.id - a?.id),
        });
      });
  };

  handleClose = (e) => {
    e.preventDefault();
    this.setState({ show: false });
    this.setState({
      payment: {},
      archivageInProgress: false,
    });
  };
  handleOpen = (row) => {
    this.setState({
      payment: row,
    });
    this.setState({ show: true });
  };

  handleCloseDetails = (e) => {
    e.preventDefault();
    this.setState({ showDetails: false });
    this.setState({
      payment: {},
      archivageInProgress: false,
    });
  };
  handleOpenDetails = (row) => {
    this.setState({
      payment: row,
    });
    this.setState({ showDetails: true });
  };

  onDeletePayment = (e) => {
    e.preventDefault();
    this.setState({ archivageInProgress: true });
    let { payment } = this.state;

    axios
      .delete(`${api}paymentmode/${payment?.id}/`, this.state.requestConfig)
      .then((response) => {
        console.log(response);
        // this.setState({ archivageInProgress: false });
        if (response) {
          // Alert.success("Le produit à été supprimé.");
          setTimeout(() => {
            // this.props.onGetProductShop();
          }, 3000);
          this.setState({
            delivery: {},
            archivageInProgress: false,
            show: false,
          });
          this.getpayments();
          toast.success("Mode de paiement supprimé avec succés.");
        }
      })
      .catch((error) => {
        if (error) {
          this.setState({ archivageInProgress: false });
        }
      });
  };

  priceFormatter = (cell, row) => {
    return formatMoney(cell);
  };

  actionFormatter = (cell, row) => {
    return (
      <div className="dis-flex justify-content-center">
        <button
          data-toggle="modal"
          onClick={() => this.handleOpenDetails(row)}
          className="btn-action-table-grey clear"
          title="Détails"
        >
          <FeatherIcon icon="eye" className="icon-action-table-grey" />
        </button>
        <button
          data-toggle="modal"
          data-target={`.edit-payment-modal${row?.id}`}
          className="btn-action-table-grey clear"
          title="Modification"
        >
          <FeatherIcon icon="edit" className="icon-action-table-grey" />
        </button>
        <button
          title="Suppression"
          onClick={() => this.handleOpen(row)}
          className="btn-action-table-grey clear"
        >
          <FeatherIcon icon="trash-2" className="icon-action-table-grey" />
        </button>

        {/* MOdal Edit */}
        <div
          className={`modal fade easypm-modal edit-payment-modal${row?.id}`}
          id="AddDeliveryMoal"
          tabIndex="-1"
          data-backdrop="static"
          aria-labelledby="AddDeliveryMoalLabel"
          aria-hidden="true"
        >
          <EditPaymentModal payment={row} getpayments={this.getpayments} />
        </div>
      </div>
    );
  };

  render() {
    const options = {
      noDataText: "Aucune donnée disponible",
    };
    return (
      <div>
        <div className="row mb-5">
          <div className="col-md-9 col-sm-8 mb-sm-0 mb-3">
            {/* <p className="title-filter-dashboard">Tous les produits</p> */}
          </div>
          <div className="col-md-3 col-sm-4 pl-sm-0">
            <div
              className="adding-item-container linkable"
              data-toggle="modal"
              data-target=".add-payment-modal"
              style={{ justifyContent: "center" }}
            >
              <span className="icon-container">
                <FeatherIcon
                  icon="plus-circle"
                  className="icon-action-table-grey"
                />
              </span>
              <span className="adding-item-label">
                Ajouter un mode de paiement
              </span>
            </div>
          </div>
        </div>
        <div className="table-container">
          <BootstrapTable
            className="head-table-custom"
            options={options}
            data={this.state.payments}
            search={true}
            multiColumnSearch={true}
            striped
            hover
            multiColumnSort={2}
            pagination
            version="4"
          >
            <TableHeaderColumn
              isKey
              dataField="id"
              hidden={true}
              tdStyle={{
                fontWeight: 400,
                fontSize: 14,
                color: "rgba(0, 0, 0, 0.7)",
                borderTop: "none",
                borderBottom: "none",
                padding: "1rem",
                whiteSpace: "normal",
                lineHeight: 1,
              }}
              thStyle={{ textAlign: "center" }}
              className="th-table-custom"
            >
              N°
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="title"
              width="230"
              tdStyle={{
                fontWeight: 400,
                fontSize: 14,
                color: "rgba(0, 0, 0, 0.7)",
                borderTop: "none",
                borderBottom: "none",
                padding: "1rem",
                whiteSpace: "normal",
                lineHeight: 1,
              }}
              dataAlign="center"
              // thStyle={{ textAlign: "center" }}
              className="th-table-custom"
            >
              Nom
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="name"
              className="th-table-custom"
              width="150"
              // thStyle={{ textAlign: "center" }}
              dataAlign="center"
              tdStyle={{
                fontWeight: 400,
                fontSize: 14,
                color: "rgba(0, 0, 0, 0.7)",
                borderTop: "none",
                borderBottom: "none",
                padding: "1rem",
                // textAlign: "center",
              }}
            >
              Type
            </TableHeaderColumn>
            <TableHeaderColumn
              className="th-table-custom"
              dataFormat={this.actionFormatter}
              dataField="id"
              width="150"
              dataAlign="center"
              // thStyle={{ textAlign: "center" }}
              tdStyle={{
                fontWeight: 400,
                fontSize: 14,
                color: "rgba(0, 0, 0, 0.7)",
                borderTop: "none",
                borderBottom: "none",
                padding: "1rem",
                // textAlign: "center",
              }}
            >
              Actions
            </TableHeaderColumn>
          </BootstrapTable>
        </div>
        {/* MOdal Add */}
        <div
          className="modal fade easypm-modal add-payment-modal"
          id="AddPaymentMoal"
          tabIndex="-1"
          data-backdrop="static"
          aria-labelledby="AddPaymentMoalLabel"
          aria-hidden="true"
        >
          <AddPaymentModal getpayments={this.getpayments} />
        </div>
        <DeletePaymentModal
          handleClose={this.handleClose}
          show={this.state.show}
          onDeletePayment={this.onDeletePayment}
          archivageInProgress={this.state.archivageInProgress}
        />
        <ShowPaymentModal
          handleCloseDetails={this.handleCloseDetails}
          showDetails={this.state.showDetails}
          payment={this.state.payment}
        />
      </div>
    );
  }
}

export default PaymentTable;
