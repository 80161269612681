import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import api from "../../http/global-vars";
import { QueryUrl } from "../utils";
import { AppLocalStorage } from "../storage";

export const prepareHeaders = (headers, { getState }) => {
  const token = getState().user.token ?? AppLocalStorage.getItem("user")?.token;
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }
  return headers;
};

export const UserApi = createApi({
  reducerPath: "userApi",
  tagTypes: ["user", "userList", "userById", "adminsList", "adminById"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${api}`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: (data) => ({
        url: "auth/connexion/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["user"],
    }),
    loginSocial: builder.mutation({
      query: (data) => ({
        url: "auth/registersocial/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["user"],
    }),
    me: builder.query({
      providesTags: ["user"],
      transformResponse: ({ data }) => data,
      query: () => {
        return "me/";
      },
    }),
    getListUser: builder.query({
      providesTags: ["userList"],
      query: (query) => QueryUrl("users", query),
    }),
    addOrEditUser: builder.mutation({
      invalidatesTags: ["user"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `user/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `user/`,
          method: "POST",
          body: data,
        };
      },
    }),
    editShop: builder.mutation({
      invalidatesTags: ["user"],
      query: ({ id, data }) => {
        return {
          url: `fournisseur/${id}/`,
          method: "PUT",
          body: data,
        };
      },
    }),
  }),
});

export const {
  useMeQuery,
  useLazyMeQuery,
  useGetListUserQuery,
  useLoginSocialMutation,
  useLoginUserMutation,
  useAddOrEditUserMutation,
  useEditShopMutation
} = UserApi;
