import React, { useEffect, useMemo, useState } from "react";
import "./boutique.css";
import Header from "../header/index";
import Footer from "../footer/index";
import api from "../../http/global-vars";
import axios from "axios";
import { toast } from "react-toastify";
import SortBy from "../sortBy/index";
import BannerShop from "../bannerShop/index";
import InfosShopCard from "../infosShopCard/index";
import EvaluationShopCard from "../evaluationShopCard/index";
import ProductItemProvider from "../productItemByProvider/ProductItemProvider";
import { useParams, useLocation } from "react-router-dom";
import FilterItems from "../allProductPage/FilterItems";


function Boutique() {
  const { id } = useParams();
  const [length, setLength] = useState(0);
  const [page, setPage] = useState(1);
  
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const [query, setQuery] = React.useState({
    category: searchParams?.getAll("category")
      ? searchParams?.getAll("category")
      : "",
    price_min: searchParams?.get("price_min") || "",
    price_max: searchParams?.get("price_max") || "",
    note: "",
  });
  const [userFollow, setUserFollow] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [userInfos, setUserInfos] = useState({});
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  let token = window.localStorage.getItem("userToken");
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
    },
  };
  let userID = window.localStorage.getItem("userID");
  const [shopDetails, setShopDetails] = useState({});

  useEffect(() => {
    if (id) {
      getShopById();

      if (token) {
        onGetConnectedUserInfos();
        onGetSubscribers();
      }
    }
  }, [id, token]);
  const getShopById = () => {
    const url = `${api}fournisseur-detail/${id}/`;
    axios
      .get(url)
      .then((response) => {
        console.log(response, "shopInf");
        if (response.status === 200) {
          setShopDetails(response.data);
          setLoading(false);

          // var productNumber = this.state.shopDetails.number_of_products;
        }
      })
      .catch((error) => {
        if (error) {
          setLoading(false);
        }
      });
  };

  const onGetConnectedUserInfos = () => {
    if (token) {
      axios
        .get(api + "me/", config)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            setUserInfos(response.data.data);
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    }
  };

  const onFollowShop = (e) => {
    e.preventDefault();
    let checker = (arr) =>
      arr.every((v) => parseInt(v?.user?.id) !== userInfos.id);

    let dataSubscribe = {};
    dataSubscribe["user"] = userID;
    dataSubscribe["provider"] = shopDetails.id;
    dataSubscribe["is_subscriber"] = true;
    let follower = followers.find((f) => f.user.id === userInfos.id);
    if (follower && checker(followers) === false && userID) {
      axios
        .delete(`${api}subscriber/${followers?.id}/`, config)
        .then((response) => {
          onGetSubscribers();
          getShopById();
        })
        .catch((error) => {
          if (error) {
            console.log("err", error);
          }
        });
    } else {
      axios
        .post(api + "subscriber/", dataSubscribe, config)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            //toast.success("Vous suivez cette boutique !")
            console.log("res", response);
          } else {
            toast.error(
              "Une erreur est survenue! Veuillez rééssayer plus tard."
            );
          }
          onGetSubscribers();
          getShopById();
        })
        .catch((error) => {
          if (error) {
            console.log("err", error);
          }
        });
    }
    //  this.setState({ is_subscriber: !this.state.is_subscriber });
  };

  const onGetSubscribers = async () => {
    // var userID = parseInt(this.state.userID)
    if (userID) {
      await axios
        .get(`${api}subscriberbyprovider/${id}/`, config)
        .then((response) => {
          setFollowers(response.data);
          setUserFollow(response.data.filter((f) => f.user.id === id));
        })
        .catch((error) => {
          if (error) {
            console.log("err", error);
          }
        });
    }
  };
  return (
    <div>
      <Header />
      <div className="body-theme p-t-10 p-b-45 fixed-after-header">
        <div className="container">
          <BannerShop shopDetails={shopDetails} loading={loading} />
          <div className="row">
            <div className="col-lg-7 m-b-12 pr-md-3">
              <InfosShopCard
                shopDetails={shopDetails}
                loading={loading}
                is_user_connected={token ? true : false}
                onFollowShop={onFollowShop}
                userFollow={userFollow}
              />
            </div>
            <div className="col-lg-5 m-b-12 pl-lg-0 dis-flex">
              <EvaluationShopCard shopDetails={shopDetails} loading={loading} />
            </div>
          </div>

          <div className="row">
            <div className="col-xl-3 col-lg-3">
              <FilterItems
                searchParams={searchParams}
                query={query}
                setQuery={setQuery}
                setPage={setPage}
              />
            </div>
            <div className="col-xl-9 col-lg-9 pl-lg-0 dis-flex">
              <div className="content-item-card w-full">
                <div className="content-item-card-head">
                  <div className="row">
                    <div className="col-xl-3 col-lg-4 col-md-4 col-10">
                      <p className="fs-14 fw-600 text-black-50">
                        <span className="fw-300 m-l-10">
                          ( {length} résultats)
                        </span>
                      </p>
                    </div>
                    <div className="col-xl-3 col-lg-4 pl-lg-4 col-md-4 col-2 ml-auto">
                      <SortBy />
                    </div>
                  </div>
                </div>
                <div className="content-item-card-body px-4">
                  {/* {!isLoading &&
                    (length <= 0 ? (
                      <div className="alert alert-info marketplace-alert-info empty-badge-alert">
                        <div className="row empty-alert-row">
                          <div className="col-2 empty-icon-col">
                            <FeatherIcon
                              icon="alert-circle"
                              className="m-b-3 empty-alert-icon rotateIn"
                            />
                          </div>
                          <div className="col-10 empty-textual-col">
                            <p>
                              {" "}
                              Cette boutique n'a pas encore de produit
                              disponible!
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      
                    ))} */}
                  <div className="row">
                    <ProductItemProvider
                      setLength={setLength}
                      query={query}
                      setIsLoading={setIsLoading}
                      setPage={setPage}
                      page={page}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Boutique;
