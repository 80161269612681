import React, {Component} from 'react';
import Skeleton from 'react-loading-skeleton';

export default class filterByPriceSkeleton extends Component {
  constructor(props) {
      super(props);
      this.state = {};
  }
  

  render() {
    return <div className="component-filter-by-price">
      <Skeleton className="titre-filter-type mb-4" width={`49%`} />
      
      <Skeleton height={12} className="mb-3" />
      <Skeleton height={36} width={`47%`} />
      <Skeleton style={{ marginLeft: `6%` }} height={36} width={`47%`} />
    </div>;
  }
}