import React, {  } from "react";
import "./headerDashboard.css";
import Logo from "../../../img/Diekkes.png";
import { NavLink } from "react-router-dom";
import ShopIconHeader from "../../../img/icons/home-icon-dashboard-header.png";
import { useDispatch } from "react-redux";
import { onlogout } from "../../../utils/api/slice/user.slice";

function HeaderDashboard() {
  const dispatch = useDispatch();

  const onLogoutUser = () => {
    dispatch(onlogout());
    window.location = "/connexion";
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white box-shadow nav-header-custom fixed-top">
      <div className="container-fluid px-lg-5">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarDashboard"
          aria-controls="navbarDashboard"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <NavLink className="navbar-brand ml-lg-5" to="/">
          <img loading="lazy" className="logo-header" src={Logo} alt="Marketplace" />
        </NavLink>

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarDashboard"
          aria-controls="navbarDashboard"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className="collapse navbar-collapse mt-lg-0 mt-3 body-theme-mobile"
          id="navbarDashboard"
        >
          <ul className="navbar-nav mx-auto py-lg-0 py-5 pl-lg-0 pl-3">
            <div className="itm-center mb-lg-0 mb-3">
              <img loading="lazy"
                className="shop-icon-header-dashboard"
                src={ShopIconHeader}
                alt=""
              />
              <p className="shop-name-header-dashboard">Administrateur</p>
            </div>
          </ul>
          <div className="pl-lg-0 pl-3 mb-lg-0 mb-3">
            <button
              className="logout"
              type="button"
              onClick={() => onLogoutUser()}
            >
              Déconnexion
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default HeaderDashboard;
