import React,{Component} from 'react';
import api from "../../../http/global-vars";
import { Message } from 'rsuite';
import axios from 'axios';

export default class InfosEntreprise extends Component {
    constructor() {
        super();
        this.state = {
            token: window.localStorage.getItem('userToken'),
            requestConfig: {
                headers: { Authorization: `Bearer ${window.localStorage.getItem('userToken')}` }
            },
            api: api,
            categoryList: [],
            loading:false
        };
    }

    componentDidMount(){
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.onGetCategories()
    }

    onGetCategories = () => {
        var api = this.state.api
        axios.get(api + 'category/')
          .then(response => {
            if (response.status === 200 || response.status === 201) {
              this.setState({
                categoryList: response.data,
                loading: false
              })
            }
          })
          .catch(error => {
            if (error) {
                this.setState({ loading: false })
            }
          })
    }
    
    render() {
        return (
            <div>
                <p className="consigne-title-steps mb-4">Ajouter les informations sur l'entreprise</p>

                <div className="row">
                    <div className="col-md-5 mb-3">
                        <p className="label-steps-inscription-vendeur">Veuillez sélectionner si vous êtes une personne physique ou une entité commerciale / entreprise</p>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-md-6 pr-lg-5">
                        <div className="form-group mb-4">
                            <div className="row">
                                <div className="col-lg-6 col-md-9">
                                    <select name="option" 
                                        value={this.props.userInfos.option}
                                        className="form-control select-steps-inscription-vendeur" 
                                        onChange={ this.props.handleChangeCreateShop }
                                    >
                                        <option disabled selected>Choisir une option</option>
                                        <option value="Entité commerciale">Personne physique</option>
                                        <option value="Entreprise">Entité commerciale/Entreprise</option>
                                    </select>
                                </div>
                            </div>
                            {this.props.is_empty_option && (
                                <Message className="alert-rsuite-custom" type="error" description={this.props.errors.option} />
                            )}
                        </div>

                        <div className="form-group mb-4">
                            <input type="text" 
                                maxLength={30}
                                className="form-control input-steps-inscription-vendeur" 
                                placeholder="Nom Légal / Raison sociale" 
                                name="legal_name"
                                value={this.props.userInfos.legal_name}
                                onChange={ this.props.handleChangeCreateShop }/>
                            {this.props.is_empty_legal_name && (
                                <Message className="alert-rsuite-custom" type="error" description={this.props.errors.legal_name} />
                            )}
                        </div>
                        <div className="form-group mb-4">
                            <input type="text" 
                                maxLength={15}
                                className="form-control input-steps-inscription-vendeur" 
                                placeholder="NINEA" 
                                name="ninea"
                                value={this.props.userInfos.ninea}
                                onChange={ this.props.handleChangeCreateShop }/>
                            {this.props.is_empty_ninea && (
                                <Message className="alert-rsuite-custom" type="error" description={this.props.errors.ninea} />
                            )}
                        </div>
                        <div className="form-group mb-4">
                            <input type="text" 
                                maxLength={30}
                                className="form-control input-steps-inscription-vendeur" 
                                placeholder="Quel est le nom du / des compte (s)?"
                                name="account_name"
                                value={this.props.userInfos.account_name}
                                onChange={ this.props.handleChangeCreateShop } />
                            {this.props.is_empty_account_name && (
                                <Message className="alert-rsuite-custom" type="error" description={this.props.errors.account_name} />
                            )}
                        </div>
                        <div className="form-group mb-4">
                            <input type="text" 
                                maxLength={30}
                                className="form-control input-steps-inscription-vendeur" 
                                placeholder="Forme juridique" 
                                name="legal_form"
                                value={this.props.userInfos.legal_form}
                                onChange={ this.props.handleChangeCreateShop }/>
                            {this.props.is_empty_legal_form && (
                                <Message className="alert-rsuite-custom" type="error" description={this.props.errors.legal_form} />
                            )}
                        </div>
                        <div className="form-group mb-4">
                            <input type="text" 
                                maxLength={30}
                                className="form-control input-steps-inscription-vendeur" 
                                placeholder="Adresse" 
                                name="adresse"
                                value={this.props.userInfos.adresse}
                                onChange={ this.props.handleChangeCreateShop }/>
                            {this.props.is_empty_adresse && (
                                <Message className="alert-rsuite-custom" type="error" description={this.props.errors.adresse} />
                            )}
                        </div>
                    </div>
                    <div className="col-md-6 pl-lg-5">
                        <div className="form-group mb-4">
                            <input type="text" 
                                maxLength={15}
                                className="form-control input-steps-inscription-vendeur" 
                                placeholder="Pays *" 
                                name="country"
                                value={this.props.userInfos.country}
                                onChange={ this.props.handleChangeCreateShop } />
                            {this.props.is_empty_country && (
                                <Message className="alert-rsuite-custom" type="error" description={this.props.errors.country} />
                            )}
                        </div>
                        <div className="form-group mb-4">
                            <input type="text" 
                                maxLength={30}
                                className="form-control input-steps-inscription-vendeur" 
                                placeholder="Commune / Ville *" 
                                name="city"
                                value={this.props.userInfos.city}
                                onChange={ this.props.handleChangeCreateShop }/>
                            {this.props.is_empty_city && (
                                <Message className="alert-rsuite-custom" type="error" description={this.props.errors.city} />
                            )}
                        </div>
                        <div className="form-group mb-4">
                            <input type="text" 
                                maxLength={5}
                                className="form-control input-steps-inscription-vendeur" 
                                placeholder="Code postal" 
                                name="code_postal"
                                value={this.props.userInfos.code_postal}
                                onChange={ this.props.handleChangeCreateShop }/>
                            {this.props.is_empty_code_postal && (
                                <Message className="alert-rsuite-custom" type="error" description={this.props.errors.code_postal} />
                            )}
                        </div>
                        <div className="form-group mb-4">
                            <input type="text" 
                                maxLength={30}
                                className="form-control input-steps-inscription-vendeur" 
                                placeholder="Personne responsable" 
                                name="person_in_charge"
                                value={this.props.userInfos.person_in_charge}
                                onChange={ this.props.handleChangeCreateShop }/>
                            {this.props.is_empty_person_in_charge && (
                                <Message className="alert-rsuite-custom" type="error" description={this.props.errors.person_in_charge} />
                            )}
                        </div>
                        <div className="form-group mb-4">
                            <select name="products_main_category" 
                                className="form-control select-steps-inscription-vendeur" 
                                value={this.props.userInfos.products_main_category}
                                onChange={ this.props.handleChangeCreateShop }
                            >
                                <option selected disabled>Catégorie Principale de vos produits</option>
                                {this.state.categoryList.map(category => (
                                    <option value={category.id}>{category.name}</option>
                                ))}
                            </select>
                            {this.props.is_empty_products_main_category && (
                                <Message className="alert-rsuite-custom" type="error" description={this.props.errors.products_main_category} />
                            )}
                        </div>
                        <div className="form-group mb-4">
                            <input type="text" 
                                maxLength={30}
                                className="form-control input-steps-inscription-vendeur" 
                                placeholder="N° d'Enregistrement des Entreprises" 
                                name="company_registration_number"
                                value={this.props.userInfos.company_registration_number}
                                onChange={ this.props.handleChangeCreateShop }/>
                            {this.props.is_empty_company_registration_number && (
                                <Message className="alert-rsuite-custom" type="error" description={this.props.errors.company_registration_number} />
                            )}
                        </div>
                        
                    </div>
                </div>
            </div>
        );
    }
}

