import React, { Component } from 'react';
import './whishlist.css';
import ProductItemWhishlist from '../productItemWhishlist/index';

export default class whishlist extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return <div className="content-item-card-body">
      <div className="row px-2">
        <ProductItemWhishlist />
      </div>
    </div>
  }
}
