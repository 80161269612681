import React, { Component } from "react";
import "./account.css";
import Header from "../header/index";
import Footer from "../footer/index";
import FeatherIcon from "feather-icons-react";
import UserInformations from "../userInformations/index";
import Commandes from "../commandes/index";
import Cart from "../cart/index";
import Whishlist from "../whishlist/index";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { onlogout } from "../../utils/api/slice/user.slice";
import { isSeller, isUser } from "../../utils/utils";

function Account() {
  const { user } = useSelector((s) => s?.user);
  const dispatch = useDispatch();

  const onLogoutUser = () => {
    dispatch(onlogout());
    setTimeout(() => {
      window.location = "/connexion";
    }, 1000);
  };
  return (
    <div>
      <Header />
      <div className="breadcrumb-theme fixed-after-header">
        <div className="container pos-relative">
          <h3 className="h-theme text-center fw-700 mb-sm-5 mb-4">
            Mon compte
          </h3>
          <div className="content-vendeur-compte-link mb-md-0 mb-5">
            {!!isUser(user) && (
              <NavLink
                className="link-header-item mr-xl-3 mr-lg-2 b-rh-0 devenir-vendeur-compte-link"
                to="/inscription-vendeur"
              >
                <p className="text-dark fw-400 fs-14 ff-raleway text-left">
                  Devenir
                </p>
                <p className="text-item-header h-theme fw-700 fs-14 text-left ff-raleway">
                  Vendeur
                </p>
              </NavLink>
            )}
            {!!isSeller(user) && (
              <NavLink
                className="access-to-shop-link devenir-vendeur-compte-link"
                to="/dashboard-vendeur"
              >
                <FeatherIcon className="icon-access-to-shop-link" icon="home" />
                Acceder à ma boutique
              </NavLink>
            )}
          </div>
          <div className="row">
            <div className="col-xl-10">
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <a
                    className="nav-link nav-link-tabs-account active"
                    id="nav-infospersonnelles-tab"
                    data-toggle="tab"
                    href="#nav-infospersonnelles"
                    role="tab"
                    aria-controls="nav-infospersonnelles"
                    aria-selected="true"
                  >
                    <FeatherIcon
                      className="icon-nav-link-tabs-account"
                      icon="user"
                    />
                    Informations personnelles
                  </a>
                  <a
                    className="nav-link nav-link-tabs-account"
                    id="nav-commandes-tab"
                    data-toggle="tab"
                    href="#nav-commandes"
                    role="tab"
                    aria-controls="nav-commandes"
                    aria-selected="false"
                  >
                    <FeatherIcon
                      className="icon-nav-link-tabs-account"
                      icon="archive"
                    />
                    Mes commandes
                  </a>
                  <a
                    className="nav-link nav-link-tabs-account"
                    id="nav-panier-tab"
                    data-toggle="tab"
                    href="#nav-panier"
                    role="tab"
                    aria-controls="nav-panier"
                    aria-selected="false"
                  >
                    <FeatherIcon
                      className="icon-nav-link-tabs-account"
                      icon="shopping-cart"
                    />
                    Mon panier
                  </a>
                  <a
                    className="nav-link nav-link-tabs-account"
                    id="nav-envies-tab"
                    data-toggle="tab"
                    href="#nav-envies"
                    role="tab"
                    aria-controls="nav-envies"
                    aria-selected="false"
                  >
                    <FeatherIcon
                      className="icon-nav-link-tabs-account"
                      icon="heart"
                    />
                    Ma liste d’envies
                  </a>
                </div>
              </nav>
            </div>
            <div className="col-xl-2 dis-flex justify-content-center itm-center p-t-14 p-b-14">
              <button
                className="logout-link-account"
                onClick={() => onLogoutUser()}
              >
                Déconnexion
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="body-theme p-t-15 p-b-200">
        <div className="container">
          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              id="nav-infospersonnelles"
              role="tabpanel"
              aria-labelledby="nav-infospersonnelles-tab"
            >
              <UserInformations />
            </div>
            <div
              className="tab-pane fade"
              id="nav-commandes"
              role="tabpanel"
              aria-labelledby="nav-commandes-tab"
            >
              <Commandes />
            </div>
            <div
              className="tab-pane fade"
              id="nav-panier"
              role="tabpanel"
              aria-labelledby="nav-panier-tab"
            >
              <Cart />
            </div>
            <div
              className="tab-pane fade"
              id="nav-envies"
              role="tabpanel"
              aria-labelledby="nav-envies-tab"
            >
              <Whishlist />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Account;
