import { TRANSACTIONS } from '../types';
import api from '../http/global-vars';


export const fetchTransactions = () => {
    return async (dispatch) => {
      const config = {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
      }
      const url = api + 'transaction/'
      const res = await fetch(url, config)
      const data = await res.json()
      
      dispatch({
        type: TRANSACTIONS,
        payload: data.results,
      })
    }
}

