import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'

const Suggestions = ({ results,query }) => {
  const options = results.slice(0, 4).map((r) => (
    <li key={r?.id} className="search-result-item">
      <NavLink
        to={
          r?.name_type !== 'shop'
            ? `/details-produit/${r?.id}`
            : `/boutique/${r?.id}`
        }
        key={`search${r?.slug}`}
      >
        {r?.name}
      </NavLink>
    </li>
  ))
  /* const options = props.results.map(r => (

    <li key={r.id}>
      {r.name}
    </li>
  )) */

  useEffect(() => {
    console.log('results',results?.filter((d) => d?.name?.toLowerCase()?.includes(query?.toLowerCase())))
  }, [query, results])

  let data = results?.filter((d) => d?.name?.toLowerCase()?.includes(query?.toLowerCase()))

  return (
    <ul className="search-result-container">
      {data.map((r) => (
        <li key={r?.slug} className="search-result-item">
          <NavLink
            to={
              r?.name_type !== 'shop'
                ? `/details-produit/${r?.id}`
                : `/boutique/${r?.id}`
            }
            key={`search${r?.slug}`}
          >
            {r?.name}
          </NavLink>
        </li>
      ))}
    </ul>
  )
}

export default Suggestions
